/**
 * Wrapper for upload file in views.
 *
 * Helps with the dual nature of files in the front-end: a file value can be a
 * File object when it has been selected for upload or a filepath String in all
 *  other cases.
 */
import { isString, isFile, dig } from './Utils'

export default class {
  static from(value) {
    switch (true) {
      case isFile(value):
        return new this({ isFile: true, filepath: null, file: value })
      case isString(value):
        return new this({ filepath: value })
      default:
        return new this({
          isFile: true,
          filepath: null,
          file: null
        })
    }
  }

  constructor({ isFile = false, filepath = '', file = null } = {}) {
    this.isFile = isFile
    this.filepath = filepath ? filepath.trim() : filepath
    this.file = file
  }

  isFileURI() {
    return !this.isFile
  }

  isFileFormObject() {
    return this.isFile
  }

  name() {
    if (this.isFile) return dig(this.file, 'name') || ''
    if (!this.filepath) return ''
    const parts = this.filepath.split('/')
    return parts.slice(-1).pop() // last one
  }

  url() {
    return this.filepath || ''
  }
}
