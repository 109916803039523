import Base from './Base'
import Person from '@/domain/entities/Person'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null

    const entity = this.fieldNamesFromResponse(resource)

    entity.nation = resource.nation
      ? resource.nation.toUpperCase()
      : resource.nation

    entity.fiscalCode = resource.fiscalCode
      ? resource.fiscalCode.toUpperCase()
      : ''

    let photo = resource.photo
    if (photo && process.env.VUE_APP_TEST)
      photo = `http://localhost/${resource.photo}`
    entity.photo = photo

    return new Person(entity)
  }

  static toRequestResource(person) {
    if (!person) return null
    const resource = this.fieldNamesForRequest(person)
    resource.nation ? resource.nation.toUpperCase() : resource.nation
    return resource
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    if (!resource) return null

    const {
      id,
      userActive,
      name,
      surname,
      fiscalCode,
      phone,
      mobile,
      email,
      notes,
      intolerances,
      photo,
      birthDate,
      nation,
      region,
      county,
      city,
      zipCode,
      address1,
      address2,
      startDate,
      endDate
    } = resource

    const result = {
      id,
      active: userActive,
      name,
      surname,
      fiscalCode,
      phone,
      mobile,
      email,
      notes,
      intolerances,
      photo,
      nation,
      birthDate,
      region,
      county,
      city,
      postalCode: zipCode,
      address1,
      address2,
      startDate,
      endDate
    }

    this.formatInternalValuesFromResponse(resource, result)

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(person, removeEmpty = false) {
    const {
      id,
      active,
      name,
      surname,
      fiscalCode,
      phone,
      mobile,
      email,
      notes,
      intolerances,
      //photo, // this is handled separately
      nation,
      birthDate,
      region,
      county,
      city,
      postalCode,
      address1,
      address2,
      startDate,
      endDate
    } = person

    const result = {
      id,
      userActive: active,
      name,
      surname,
      fiscalCode,
      phone,
      mobile,
      email,
      notes,
      intolerances,
      nation,
      birthDate,
      region,
      county,
      city,
      zipCode: postalCode,
      address1,
      address2,
      startDate,
      endDate
    }

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }
}
