import Payment from './Payment'

export default class {
  static new() {
    return new this({})
  }

  static default() {
    return this.new()
  }

  constructor({
    id = null,
    payment = new Payment(),
    jobAndDetail = null,
    percentage = null,
    amount = null,
    notes = null
  } = {}) {
    this.id = id
    this.payment = payment
    this.jobAndDetail = jobAndDetail
    this.percentage = percentage
    this.amount = amount
    this.notes = notes
  }
}
