<template>
  <SelectField
    :class="_class"
    :label="label"
    :value="value"
    :items="items"
    :rules="_rules"
    :error="error"
    :required="required"
    @input="onChange"
  />
</template>

<script>
import SelectField from '@/components/common/form/SelectField'
import validators from '@/domain/validations/rules'

export default {
  name: 'PurchaseStatusSelect',
  components: { SelectField },
  props: {
    label: String,
    value: String,
    required: Boolean,
    error: String
  },
  data() {
    return {
      items: [
        {
          text: this.$t('entities.purchase.purchaseStatuses.KO'),
          value: 'KO'
        },
        {
          text: this.$t('entities.purchase.purchaseStatuses.OK'),
          value: 'OK'
        }
      ]
    }
  },
  computed: {
    _rules() {
      return this.required ? [validators(this).presence] : []
    },
    _class() {
      return this.required ? 'required' : ''
    }
  },
  methods: {
    onChange(newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>
