var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"dense":""},scopedSlots:_vm._u([{key:"item.contract.discount",fn:function(ref){
var item = ref.item;
return [(_vm.overridesEnabled)?_c('span',{staticClass:"text-decoration-line-through"},[_vm._v(" "+_vm._s(_vm._f("percentage")(item.contract.discount))+" ")]):_c('span',[_vm._v(_vm._s(_vm._f("percentage")(item.contract.discount)))])]}},{key:"item._overridden",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"color":"primary","disabled":!_vm.overridesEnabled},on:{"change":function($event){return _vm.overrideChanged(item)}},model:{value:(item._active),callback:function ($$v) {_vm.$set(item, "_active", $$v)},expression:"item._active"}})]}},{key:"item.discountType",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":[
        {
          value: 'percentage',
          text: _vm.$t(
            'entities.offer.contractOverrides.discountTypes.percentage'
          )
        },
        {
          value: 'value',
          text: _vm.$t('entities.offer.contractOverrides.discountTypes.value')
        }
      ],"disabled":!_vm.discountOverridden(item),"dense":""},on:{"input":function($event){return _vm.overrideChanged(item)}},model:{value:(item.discountType),callback:function ($$v) {_vm.$set(item, "discountType", $$v)},expression:"item.discountType"}})]}},{key:"item.discount",fn:function(ref){
      var item = ref.item;
return [(item.discountType === 'percentage')?_c('TextField',{attrs:{"type":"percentage","disabled":!_vm.discountOverridden(item)},on:{"input":function($event){return _vm.overrideChanged(item)}},model:{value:(item.discountPercentage),callback:function ($$v) {_vm.$set(item, "discountPercentage", $$v)},expression:"item.discountPercentage"}}):_vm._e(),(item.discountType === 'value')?_c('TextField',{attrs:{"type":"currency","disabled":!_vm.discountOverridden(item),"rules":[_vm.positiveDiscountedValueRule]},on:{"input":function($event){return _vm.overrideChanged(item)}},model:{value:(item.discountValue),callback:function ($$v) {_vm.$set(item, "discountValue", $$v)},expression:"item.discountValue"}}):_vm._e()]}},{key:"item._discountedValue",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("append")(item._discountedValue,'€'))+" ")]}}],null,true)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }