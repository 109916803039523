<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12" lg="9">
        <material-card
          icon="mdi-package-variant"
          icon-small
          color="primary"
          :title="$t('common.sections.titles.payment')"
        >
          <PaymentCard :entity="entity" />
          <PaymentCostCentersCard :value="entity" />
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsAShowPage from '@/mixins/actsAsAShowPage'

import PaymentCard from '@/components/payments/PaymentCard.vue'
import PaymentCostCentersCard from '@/components/payments/PaymentCostCentersCard.vue'

import Payment from '@/domain/entities/Payment'
import GetPayment from '@/domain/useCases/payments/GetPayment'
import { get } from 'vuex-pathify'

export default {
  name: 'EditPaymentCostCentersPage',
  mixins: [actsAsAShowPage],
  components: {
    PaymentCard,
    PaymentCostCentersCard
  },
  props: { id: String },
  data() {
    return {
      actsAsAShowPage: {
        resource: 'payments',
        entity: 'payment',
        get: GetPayment
      },
      entity: Payment.default(),
      sendDialog: false
    }
  },
  computed: {
    user: get('user')
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
