import {
  PaymentsListPage,
  NewPaymentPage,
  EditPaymentPage,
  ShowPaymentPage,
  EditPaymentCostCenters,
  ShowPaymentCostCenters,
  EditPaymentJobs,
  ShowPaymentJobs
} from '@/views/payments/index.js'

export default [
  {
    path: '/payments',
    name: 'PaymentsPage',
    component: PaymentsListPage,
    meta: {
      title: 'menu.long.resources.payments'
    }
  },
  {
    path: '/payments/new',
    name: 'NewPaymentPage',
    component: NewPaymentPage,
    meta: {
      title: 'menu.long.resources.new-payment'
    }
  },
  {
    path: '/payments/:id/edit',
    name: 'EditPaymentPage',
    component: EditPaymentPage,
    props: true,
    meta: {
      title: 'menu.long.resources.edit-payment'
    }
  },
  {
    path: '/payments/:id',
    name: 'ShowPaymentPage',
    component: ShowPaymentPage,
    props: true,
    meta: {
      title: 'menu.long.resources.show-payment'
    }
  },
  {
    path: '/payments/:id/edit/cost-centers',
    name: 'EditPaymentCostCenters',
    component: EditPaymentCostCenters,
    props: true,
    meta: {
      title: 'menu.long.resources.edit-payment-cost-centers'
    }
  },
  {
    path: '/payments/:id/show/cost-centers',
    name: 'ShowPaymentCostCenters',
    component: ShowPaymentCostCenters,
    props: true,
    meta: {
      title: 'menu.long.resources.show-payment-cost-centers'
    }
  },
  {
    path: '/payments/:id/edit/jobs',
    name: 'EditPaymentJobs',
    component: EditPaymentJobs,
    props: true,
    meta: {
      title: 'menu.long.resources.edit-payment-jobs'
    }
  },
  {
    path: '/payments/:id/show/jobs',
    name: 'ShowPaymentJobs',
    component: ShowPaymentJobs,
    props: true,
    meta: {
      title: 'menu.long.resources.show-payment-jobs'
    }
  }
]
