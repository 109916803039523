/**
 * Provides a method to fetch placeholder values from the BE and fill the
 * current entity with them.
 *
 * The actual replacement of empty values with the placeholders should to be
 * done by the use case defined in the `get` option, it's not done by the
 * method provided here.
 *
 * Requires:
 *  - data.entity:              the entity to be complemented with placeholder values;
 *
 *  - data.hasPlaceholders.get: the use-case Function to be called to fetch the
 *                              placeholders from the BE and set the entity values,
 *                              The use case's call() method should expect
 *                              the entity as argument;
 *
 *  - methods.validate():       a validation method (usually provided by other mixins);
 *
 * Provides:
 *  - this.user:                              the currently logged-in User;
 *
 *  - this.fillEmptyFieldsWithPlaceholders(): the method to call to do the placeholdering;
 */

export default {
  methods: {
    fillEmptyFieldsWithPlaceholders() {
      const useCase = this.hasPlaceholders.get
      new useCase(this.user).call(this.entity).then(() => this.validate())
    }
  }
}
