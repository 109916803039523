import validateUser from '@/lib/validateUser'
import productTypesListAdapter from '@/adapters/backoffice/products/getTypes'

export default class {
  user = null

  constructor(user) {
    this.user = user
  }

  async call() {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    return await productTypesListAdapter(this.user.token)
  }
}
