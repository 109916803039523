import preparePurchaseInvoiceAdapter from '@/adapters/backoffice/purchases/prepareInvoice'
import Error from '@/lib/Error'
import { isArray } from '@/lib/Utils'

export default class {
  user = null

  constructor(user) {
    this.user = user
  }

  async call(ids) {
    if (!this.user || !this.user.token)
      return new Error({ message: 'errors.auth.unauthorized' })

    if (!isArray(ids))
      return new Error({ message: 'errors.invoice.no-details' })

    return await preparePurchaseInvoiceAdapter(this.user.token, ids)
  }
}
