var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"dense":""},scopedSlots:_vm._u([{key:"item.discount",fn:function(ref){
var item = ref.item;
return [(_vm.overridesEnabled)?_c('span',{staticClass:"text-decoration-line-through"},[_vm._v(" "+_vm._s(_vm._f("percentage")(item.discount))+" ")]):_c('span',[_vm._v(_vm._s(_vm._f("percentage")(item.discount)))])]}},{key:"item._discount",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.overridesEnabled)?_c('span',[(_vm.overrides[index].discountType === 'percentage')?_c('span',[_vm._v(" "+_vm._s(_vm._f("percentage")(_vm.overrides[index].discountPercentage))+" ")]):_vm._e(),(_vm.overrides[index].discountType === 'value')?_c('span',[_vm._v(" "+_vm._s(_vm._f("append")(_vm.overrides[index].discountValue,'€'))+" ")]):_vm._e()]):_vm._e()]}},{key:"item._discountedValue",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.discountedValue(index))+" ")]}}],null,true)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }