class User {
  constructor({
    id = null,
    name = null,
    email = null,
    token = null,
    refreshToken = null
  } = {}) {
    this.id = id
    this.name = name
    this.email = email
    this.token = token
    this.refreshToken = refreshToken
  }
}
export default User
