import Error from '@/lib/Error'

export default function(exception, resourceFormatter, resource = null) {
  if (!exception.response) return false

  const { status, data } = exception.response

  if (!data) return false

  if (status !== 400 || !data.errors) return false

  let { extraMessages, fields } = resourceFormatter.fromResponseErrors(
    data.errors,
    resource
  )

  for (const field in fields) {
    const value = fields[field]

    if (!value) delete fields[field]
  }

  return new Error({
    message: 'errors.invalid-values',
    fields,
    extraMessages
  })
}
