import { today } from '@/lib/Utils'
export default class {
  static new() {
    return new this({})
  }

  static default() {
    return this.new()
  }

  constructor({
    id = null,
    name = null,
    details = [],
    notes = null,
    startDate = null,
    endDate = null
  } = {}) {
    this.id = id
    this.name = name
    this.details = details
    this.notes = notes
    this.startDate = startDate
    this.endDate = endDate
  }

  isActive() {
    const todayStr = today()
    console.log(todayStr)
    return (
      this.startDate &&
      this.endDate &&
      this.startDate <= todayStr &&
      todayStr < this.endDate
    )
  }
}
