<template>
  <v-form ref="form" @submit.prevent>
    <v-container>
      <v-row>
        <v-col cols="12" lg="4">
          <CardTextField
            :label="$t('entities.invoice.code')"
            :value="entity.code"
          />
        </v-col>
        <v-col cols="12" lg="4">
          <CardTextField
            :label="$t('entities.invoice.version')"
            :value="entity.version"
          />
        </v-col>
        <v-col cols="12" lg="4">
          <CardTextField
            :label="$t('entities.invoice.branch')"
            :value="entity.branch | dig('description')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="2">
          <CardTextField
            :label="$t('entities.invoice.type')"
            :value="invoiceType"
          />
        </v-col>
        <v-col cols="12" lg="2">
          <CardDateField
            :label="$t('entities.invoice.date')"
            :value="entity.date"
          />
        </v-col>
        <v-col cols="12" lg="2">
          <CardDateField
            :label="$t('entities.invoice.dueDate')"
            :value="entity.dueDate"
          />
        </v-col>
        <v-col cols="12" lg="2">
          <CardDateField
            :label="$t('entities.invoice.paymentDate')"
            :value="entity.paymentDate"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="2">
          <CardTextField
            :label="$t('entities.invoice.purchaseSourceDescription')"
            :value="entity.purchaseSourceDescription"
          />
        </v-col>
        <v-col cols="12" lg="2">
          <CardTextField
            :label="$t('entities.invoice.paymentSourceDescription')"
            :value="entity.paymentSourceDescription"
          />
        </v-col>
        <v-col cols="12" lg="2">
          <CardTextField
            :label="$t('entities.invoice.fiscalType')"
            :value="entity.fiscalType"
          />
        </v-col>
        <v-col cols="12" lg="4">
          <CardTextField
            :label="$t('entities.invoice.fiscalTypeDescription')"
            :value="entity.fiscalTypeDescription"
          />
        </v-col>
        <v-col cols="12" lg="4">
          <CardTextField
            :label="$t('entities.invoice.fiscalTypeRegsReference')"
            :value="entity.fiscalTypeRegsReference"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="4">
          <CardTextField
            :label="$t('entities.invoice.name')"
            :value="entity.name"
          />
        </v-col>
        <v-col cols="12" lg="2">
          <CardTextField
            :label="$t('entities.invoice.vatCode')"
            :value="entity.vatCode"
          />
        </v-col>
        <v-col cols="12" lg="2">
          <CardTextField
            type="fiscal-code"
            :label="$t('entities.invoice.fiscalCode')"
            :value="entity.fiscalCode"
          />
        </v-col>
        <v-col cols="12" lg="2">
          <CardTextField
            :label="$t('entities.invoice.deductionType')"
            :value="entity.deductionType"
          />
        </v-col>
        <v-col cols="12" lg="2">
          <CardTextField
            type="percentage"
            :label="$t('entities.invoice.deductionRate')"
            :value="entity.deductionRate"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12">
          <CardTextField
            :label="$t('entities.invoice.address')"
            :value="entity.address"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="4"> </v-col>
        <v-col cols="12" lg="4">
          <CardTextField
            :label="$t('entities.invoice.bankAccountName')"
            :value="entity.bankAccountName"
          />
        </v-col>
        <v-col cols="12" lg="4">
          <CardTextField
            :label="$t('entities.invoice.bankAccountIban')"
            :value="entity.bankAccountIban"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg>
          <TextField
            type="any-currency"
            :label="$t('entities.invoice.amount')"
            v-model="entity.amount"
            :error="errors.amount"
          />
        </v-col>
        <v-col cols="12" lg>
          <TextField
            type="any-currency"
            :label="$t('entities.invoice.vatAmount')"
            v-model="entity.vatAmount"
            :error="errors.vatAmount"
          />
        </v-col>
        <v-col cols="12" lg>
          <TextField
            type="any-currency"
            :label="$t('entities.invoice.taxAmount')"
            v-model="entity.taxAmount"
            :error="errors.taxAmount"
          />
        </v-col>
        <v-col cols="12" lg>
          <TextField
            type="any-currency"
            :label="$t('entities.invoice.stampAmount')"
            v-model="entity.stampAmount"
            :error="errors.stampAmount"
          />
        </v-col>
        <v-col cols="12" lg>
          <CardTextField
            type="currency"
            :label="$t('entities.invoice.totalAmount')"
            :value="totalAmount"
            :error="errors.totalAmount"
          />
          <span v-if="errors.totalAmount" class="error--text v-messages">
            {{ $t(`errors.field.${errors.totalAmount}`) }}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12">
          <Textarea
            :label="$t('entities.invoice.notes')"
            v-model="entity.notes"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="2">
          <CardTextField
            :label="$t('entities.invoice.sdi')"
            :value="entity.sdi"
          />
        </v-col>
        <v-col cols="12" lg="2">
          <CardYesNoField
            :label="$t('entities.invoice.issuable')"
            :value="entity.issuable"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import actsAsAForm from '@/mixins/actsAsAForm'

import {
  CardDateField,
  CardTextField,
  CardYesNoField
} from '@/components/common/card'
import { Textarea, TextField } from '@/components/common/form'
import Invoice from '@/domain/entities/Invoice'
import Decimal from '@/lib/Decimal'

export default {
  name: 'InvoicePartialAmendForm',
  mixins: [actsAsAForm],
  components: {
    CardDateField,
    CardTextField,
    CardYesNoField,
    Textarea,
    TextField
  },
  props: {
    value: Invoice,
    errors: Object
  },
  computed: {
    totalAmount() {
      return Decimal.sumFromUI(
        this.entity.amount,
        this.entity.vatAmount,
        this.entity.taxAmount,
        this.entity.stampAmount
      )
    },
    invoiceType() {
      return this.entity.type == Invoice.DEBIT()
        ? this.$t('entities.invoice.types.ND')
        : this.$t('entities.invoice.types.NC')
    }
  },
  watch: {
    totalAmount() {
      this.entity.totalAmount = this.totalAmount
      if (Decimal.isValid(this.entity.totalAmount)) {
        const totalAmountDecimal = Decimal.fromUI(this.entity.totalAmount)
        if (totalAmountDecimal.isPositive()) this.entity.type = Invoice.DEBIT()
        else this.entity.type = Invoice.CREDIT()
      }
    }
  }
}
</script>
