<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="3">
        <CardTextField
          type="currency"
          :label="$t('entities.offer.editionsTotalPrice')"
          :value="entity.editionsTotalPrice"
        />
      </v-col>

      <v-col cols="12" lg="3">
        <CardTextField
          type="currency"
          :label="$t('entities.offer.offerPrice')"
          :value="entity.offerPrice"
        />
      </v-col>

      <v-col cols="12" lg="3">
        <CardTextField
          type="currency"
          :label="$t('entities.offer.deposit')"
          :value="entity.deposit"
        />
      </v-col>

      <v-col cols="12" lg="3">
        <CardTextField
          type="number"
          :label="$t('entities.offer.paymentDelay')"
          :value="entity.paymentDelay"
        />
      </v-col>

      <v-col cols="12" lg="2">
        <CardTextField
          type="number"
          :label="$t('entities.offer.installmentsNumber')"
          :value="entity.installmentsNumber"
        />
      </v-col>

      <v-col cols="12" lg="2">
        <CardTextField
          type="currency"
          :label="$t('entities.offer.installmentsAmountEach')"
          :value="entity.installmentsAmountEach"
        />
      </v-col>

      <v-col cols="12" lg="2">
        <CardTextField
          type="currency"
          :label="$t('entities.offer.installmentsAmountExtra')"
          :value="entity.installmentsAmountExtra"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="2">
        <v-checkbox
          :label="$t('entities.offer.standardDiscountApplicable')"
          :value="entity.standardDiscountApplicable"
          disabled
        />
      </v-col>

      <v-col cols="12" lg="2">
        <v-checkbox
          :label="$t('entities.offer.discountOverride')"
          :value="entity.discountOverride"
          disabled
        />
      </v-col>
    </v-row>

    <v-row v-if="showDiscounts">
      <v-col>
        <v-card>
          <v-card-title>
            {{ $t('entities.offer.contractOverrides.title') }}
          </v-card-title>
          <OverridesCard :entity="entity" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { CardTextField } from '@/components/common/card'
import OverridesCard from '@/components/offers/OfferContractsOverridesCard'

import Offer from '@/domain/entities/Offer'

export default {
  name: 'OfferPricesCard',
  components: { CardTextField, OverridesCard },
  props: { entity: Offer },
  computed: {
    showDiscounts() {
      return (
        this.entity.standardDiscountApplicable || this.entity.discountOverride
      )
    }
  }
}
</script>
