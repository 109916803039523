var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('entities.offer.editions'))+" ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.entity.editions,"hide-default-footer":"","no-data-text":_vm.$t('common.offer.editions.no-editions')},scopedSlots:_vm._u([{key:"item.genericProductType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.translateProductType(item.genericProductType))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("append")(item.price,'€'))+" ")]}},{key:"item.fromDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.fromDate))+" ")]}},{key:"item.toDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.toDate))+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }