import { today } from '@/lib/Utils'

export default class {
  static CREDIT = () => 'NC'
  static DEBIT = () => 'ND'

  static new() {
    return new this()
  }

  static default() {
    return new this({
      date: today(),
      dueDate: today(),
      issuable: false
    })
  }

  constructor({
    id = null,
    code = null, // internal unique code (String)
    version = null, // internal version number (Number)
    type = null, // values: "ND" (positive value, income) or "NC" (negative value, expense)
    branch = null,
    date = null,
    dueDate = null,
    paymentDate = null,
    canPartiallyAmend = null,
    canAmend = null,
    canEdit = null,
    canSend = null,

    sdiInvoiceNumber = null, // Auto created by the Backend
    sdiSubmissionDate = null,
    sdiResponseDate = null,
    sdiCheckResult = null, // "OK"/"KO" (String)
    sdiResponseCode = null,

    // Customer or supplier
    name = null,
    vatCode = null,
    fiscalCode = null, // if freelancer or foreigner
    address = null,
    sdi = null,
    pec = null,

    amount = null,
    vatAmount = null,
    taxAmount = null, // Don't ask, don't know, 'ritenuta acconto' => 'deduction'?
    // Alternative "taxation" system for invoices to non-italians
    stampAmount = null, // Don't ask, don't know, 'ritenuta acconto' => 'deduction'?
    totalAmount = null,
    paymentSourceDescription = null, // from Purchase
    bankAccount = null,
    bankAccountName = null,
    bankAccountIban = null,

    notes = null,

    issuable = null, // possible values: "Y" = null, "N"
    fiscalType = null, // FiscalType = null, a bureaucratic "type" for invoices (something to do with how taxes apply)
    fiscalTypeDescription = null,
    fiscalTypeRegsReference = null,

    purchaseSourceDescription = null, // PurchaseSource.name?
    deductionType = null,
    deductionRate = null, // Number (0..100),

    details = [], // Array of InvoiceDetail
    costCenterDetails = [],
    jobDetails = [],

    creationDate = null,
    startDate = null,
    lastModified = null,
    lastModifiedBy = null
  } = {}) {
    this.id = id
    this.code = code
    this.version = version
    this.type = type
    this.branch = branch
    this.date = date
    this.dueDate = dueDate
    this.paymentDate = paymentDate
    this.canPartiallyAmend = canPartiallyAmend
    this.canAmend = canAmend
    this.canEdit = canEdit
    this.canSend = canSend

    this.sdiInvoiceNumber = sdiInvoiceNumber
    this.sdiSubmissionDate = sdiSubmissionDate
    this.sdiResponseDate = sdiResponseDate
    this.sdiCheckResult = sdiCheckResult
    this.sdiResponseCode = sdiResponseCode

    this.name = name
    this.vatCode = vatCode
    this.fiscalCode = fiscalCode
    this.address = address
    this.sdi = sdi
    this.pec = pec

    this.amount = amount
    this.vatAmount = vatAmount
    this.taxAmount = taxAmount
    this.stampAmount = stampAmount
    this.totalAmount = totalAmount
    this.paymentSourceDescription = paymentSourceDescription
    this.bankAccount = bankAccount
    this.bankAccountName = bankAccountName
    this.bankAccountIban = bankAccountIban

    this.notes = notes

    this.issuable = issuable
    this.fiscalType = fiscalType
    this.fiscalTypeDescription = fiscalTypeDescription
    this.fiscalTypeRegsReference = fiscalTypeRegsReference

    this.purchaseSourceDescription = purchaseSourceDescription
    this.deductionType = deductionType
    this.deductionRate = deductionRate

    this.details = details
    this.costCenterDetails = costCenterDetails
    this.jobDetails = jobDetails

    this.creationDate = creationDate
    this.startDate = startDate
    this.lastModified = lastModified
    this.lastModifiedBy = lastModifiedBy
  }

  sdiNotSent() {
    return !this.sdiSubmissionDate
  }
  sdiPending() {
    return this.sdiSubmissionDate && !this.sdiResponseCode
  }
  sdiFailed() {
    return this.sdiSubmissionDate && this.sdiResponseCode !== 'OK'
  }
  statusAsString() {
    if (this.sdiNotSent()) return 'sdiNotSent'
    else if (this.sdiPending()) return 'sdiPending'
    else if (this.sdiFailed()) return 'sdiFailed'
    else return 'sdiOk'
  }
}
