var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"payments-list","fluid":"","tag":"section"}},[_c('material-card',{attrs:{"icon":"mdi-credit-card-minus","icon-small":"","title":_vm.$t('list.payments.title'),"color":"primary"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"footer-props":_vm.footerOptions,"server-items-length":_vm.totalItems,"loading":_vm.loading,"no-data-text":_vm.$t('common.list.no-data')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{staticClass:"mx-1"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('Searchbar',{on:{"submit":_vm.searchbarSubmitted}})],1),_c('v-col',{staticClass:"pt-0 pl-5",attrs:{"cols":"1"}},[_c('RoundButton',{attrs:{"icon":"mdi-plus","alt":_vm.$t('list.payments.new')},on:{"click":function($event){return _vm.$emit('goto-new')}}})],1)],1)],1)]},proxy:true},{key:"item.paymentDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.paymentDate))+" ")]}},{key:"item.duoDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.duoDate))+" ")]}},{key:"item.untaxedPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("append")(item.untaxedPrice,'€'))+" ")]}},{key:"item.creationDate",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("dateTime")(item.creationDate)))])]}},{key:"item.lastModified",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("dateTime")(item.lastModified)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions"},[_c('v-icon',{staticClass:"mr-2 show-payment-button",on:{"click":function($event){return _vm.$emit('goto-show', item.id)}}},[_vm._v("mdi-eye")]),_c('v-icon',{staticClass:"mr-2 edit-payment-button",on:{"click":function($event){return _vm.$emit('goto-edit', item.id)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{staticClass:"delete-payment-button",on:{"click":function($event){return _vm.$emit('delete', item.id)}}},[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }