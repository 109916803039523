<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12" lg="9">
        <material-card
          icon="mdi-credit-card"
          icon-small
          :title="$t('common.sections.titles.purchase')"
          color="primary"
        >
          <PurchaseForm ref="form" v-model="entity" :errors="errors" />
        </material-card>
      </v-col>
      <v-col cols="12" lg="3" order="first" order-lg="last">
        <PurchaseSummary :entity="entity">
          <v-divider />
          <template #controls>
            <v-row>
              <v-col cols="3" md="6">
                <v-btn color="success" width="100%" @click="save()">
                  {{ $t('form.customers.new.submit') }}
                </v-btn>
              </v-col>
              <v-col cols="3" md="6">
                <v-btn color="warning" width="100%" @click="leave()">
                  {{ $t('common.leave') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </PurchaseSummary>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsAnEditPage from '@/mixins/actsAsAnEditPage'

import PurchaseForm from '@/components/purchases/PurchaseForm.vue'
import PurchaseSummary from '@/components/purchases/PurchaseSummary.vue'

import Purchase from '@/domain/entities/Purchase'
import GetPurchase from '@/domain/useCases/purchases/GetPurchase'
import EditPurchase from '@/domain/useCases/purchases/EditPurchase'

export default {
  name: 'EditPurchasePage',
  mixins: [actsAsAnEditPage],
  components: { PurchaseForm, PurchaseSummary },
  props: { id: String },
  data() {
    return {
      actsAsAnEditPage: {
        entity: 'purchase',
        resource: 'purchases',
        get: GetPurchase,
        edit: EditPurchase
      },
      entity: Purchase.default()
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
