import validateUser from '@/lib/validateUser'
import invoiceJobsAdapter from '@/adapters/backoffice/invoices/updateJobs'

export default class {
  user = null
  id = null
  invoice = null

  constructor(user, id, invoice) {
    this.user = user
    this.id = id
    this.invoice = invoice
  }

  async call() {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    return await invoiceJobsAdapter(this.user.token, this.id, this.invoice)
  }
}
