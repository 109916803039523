import Base from './Base'
import CompanyFormatter from './Company'
import PersonCompanyRoleFormatter from './PersonCompanyRole'
import PersonCompanies from '@/domain/entities/PersonCompanies'

import { isEmpty, isArray } from '@/lib/Utils'
export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null

    const personCompanies = this.fieldNamesFromResponse(resource)
    personCompanies.roles = PersonCompanyRoleFormatter.fromResponseResourcesList(
      resource.roles
    )
    personCompanies.company = CompanyFormatter.fromResponseResource(
      resource.company
    )

    return new PersonCompanies(personCompanies)
  }

  static toRequestResource(entity) {
    if (!entity) return null
    const fields = this.fieldNamesForRequest(entity)

    return Object.assign(fields, {
      roles: PersonCompanyRoleFormatter.toResourceIdsList(entity.roles),
      company: CompanyFormatter.toRequestResource(entity.company)
    })
  }

  static fromResponseErrors(errors) {
    if (!errors) return null
    const { fields, extraMessages } = CompanyFormatter.fromResponseErrors(
      errors.company
    )

    return { fields: { company: fields }, extraMessages }
  }

  static formatFilterOptions(options) {
    if (isEmpty(options)) return null

    const result = this.fieldNamesForRequest(options, true)

    if (isArray(options.roles)) {
      result.roles = options.roles.map(el =>
        PersonCompanyRoleFormatter.formatFilterOptions(el)
      )
    }

    if (options.company)
      result.company = CompanyFormatter.formatFilterOptions(options.company)

    return this.removeEmptyAttributes(result)
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    if (!resource) return null

    const { id, type, position, roles, startDate, endDate } = resource
    const company = CompanyFormatter.fieldNamesFromResponse(resource.company)

    const result = { id, type, position, roles, company, startDate, endDate }

    this.formatInternalValuesFromResponse(resource, result)
    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(entity, removeEmpty = false) {
    if (!entity) return null

    const { id, type, position, roles, startDate, endDate } = entity
    const company = CompanyFormatter.fieldNamesFromResponse(entity.company)
    const result = { id, type, position, roles, company, startDate, endDate }

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }
}
