<template>
  <v-autocomplete
    :class="_class"
    :label="$t('entities.invoice.costCenter.detail.name')"
    :clearable="clearable"
    @input="onChange"
    :no-data-text="$t('common.autocomplete.no-data')"
    :rules="_rules"
    :required="required"
    :outlined="readonly || outlined"
    :filled="readonly"
    :hide-details="hideDetails"
    dense
    :items="itemsProp"
    v-model="item"
    return-object
    item-text="name"
    item-value="id"
  />
</template>

<script>
import actsAsAnAutocomplete from '@/mixins/actsAsAnAutocomplete'
import { dig } from '@/lib/Utils'

export default {
  name: 'CostCenterDetailSelect',
  mixins: [actsAsAnAutocomplete],
  props: {
    itemsProp: Array
  },
  computed: {
    costCenterDetails() {
      return dig(this.item, 'details') || []
    }
  },
  watch: {
    itemsProp() {
      this.item = null
    }
  }
}
</script>
