<template>
  <v-autocomplete
    :label="currentLabel"
    v-model="autocompleteSelectedItem"
    :items="autocompleteItems"
    item-value="id"
    return-object
    :search-input.sync="autocompleteSearchword"
    clearable
    no-filter
    :no-data-text="noDataText"
    :loading="autocompleteLoading"
    :rules="rules"
    :required="required"
    :error-messages="autocompleteErrors"
    :outlined="outlined"
    dense
  >
    <template v-slot:selection="data">
      {{ data.item.name }} ({{ data.item.code }})
      {{ $t('common.version', { version: data.item.version }) }}
    </template>

    <template v-slot:item="data">
      <v-list-item-avatar>
        <v-icon>mdi-file-document-multiple</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-html="data.item.name"></v-list-item-title>
        <v-list-item-subtitle>
          {{ $t('entities.contract.code') }}:{{ data.item.code }}
          {{ $t('entities.contract.version') }}:{{ data.item.version }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { get } from 'vuex-pathify'
import validators from '@/domain/validations/rules'
import handlesErrors from '@/mixins/handlesErrors'

import Contract from '@/domain/entities/Contract'
import GetContracts from '@/domain/useCases/contracts/GetContracts'

export default {
  name: 'ContractSelect',
  mixins: [handlesErrors],
  props: {
    label: String,
    value: Contract,
    required: Boolean,
    outlined: Boolean
  },
  data() {
    return {
      autocompleteLoading: false,
      autocompleteItems: [],
      autocompleteSelectedItem: this.value,
      autocompleteSearchword: '',
      autocompleteErrors: []
    }
  },
  computed: {
    user: get('user'),
    autocompleteMinChars: get('app/autocompleteMinChars'),
    noDataText() {
      const text = this.search || ''
      if (text.length < this.autocompleteMinChars)
        return this.$t('common.autocomplete.at-least-n-chars', {
          n: this.autocompleteMinChars
        })

      return this.$t('common.autocomplete.no-data')
    },
    rules() {
      return this.required ? [validators(this).presence] : []
    },
    currentLabel() {
      return this.label || this.$t('common.contract.search')
    }
  },
  methods: {
    validate() {
      const item = this.autocompleteSelectedItem
      if (!item || !item.id) {
        this.autocompleteErrors = [this.$t('errors.field.required')]
        return false
      } else {
        this.autocomplateErrors = []
        return true
      }
    }
  },
  watch: {
    autocompleteSearchword(text) {
      if (text !== null) {
        if (text.length < this.autocompleteMinChars) return
        if (this.autocompleteLoading) return

        this.autocompleteLoading = true

        this.autocompleteItems = []
        if (this.autocompleteSelectedItem && this.autocompleteSelectedItem.id)
          this.autocompleteItems = [this.autocompleteSelectedItem]

        new GetContracts(this.user)
          .call({ fullText: text, itemsPerPage: 10, page: 1 })
          .then(result => {
            if (result.error) {
              this.$_handleError({
                message: 'common.autocomplete.error-loading'
              })
              console.info('Error loading contracts for autocomplete:', result)
            } else {
              this.autocompleteItems = [
                ...this.autocompleteItems,
                ...result.contracts
              ]
              this.autocompleteLoading = false
            }
          })
      }
    },
    value() {
      if (this.value) {
        this.autocompleteItems = [this.value]
        this.autocompleteSelectedItem = this.value
      } else {
        this.autocompleteItems = []
        this.autocompleteSelectedItem = null
      }
    },
    autocompleteSelectedItem() {
      this.$emit('input', this.autocompleteSelectedItem)
    }
  }
}
</script>
