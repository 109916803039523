import Base from './Base'
import ProductPriceList from '@/domain/entities/ProductPriceList'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null
    const entity = this.fieldNamesFromResponse(resource)
    return new ProductPriceList(entity)
  }

  static toRequestResource(entity) {
    if (!entity) return null
    return this.fieldNamesForRequest(entity)
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    if (!resource) return null
    const { id, type, location, totalFee, hourlyFee } = resource
    const result = { id, type, location, fee: totalFee, hourlyFee }
    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(entity, removeEmpty = false) {
    if (!entity) return null
    const { id, type, location, fee, hourlyFee } = entity
    const result = { id, type, location, totalFee: fee, hourlyFee }
    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }
}
