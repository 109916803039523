<template>
  <v-card>
    <v-card-title class="headline">{{ message }}</v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="$emit('okClick')">{{
        okText
      }}</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
export default {
  name: 'ConfirmDialog',
  props: { message: String, okText: String }
}
</script>
