import validateUser from '@/lib/validateUser'
import Error from '@/lib/Error'
import costCenterUpdateAdapter from '@/adapters/backoffice/costCenters/update'

export default class {
  user = null
  id = null
  costCenter = null

  constructor(user, id, costCenter) {
    this.user = user
    this.id = id
    this.costCenter = costCenter
  }

  async call() {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    if (!this.costCenter || !this.id)
      return new Error({ message: 'errors.resource.invalid' })

    return await costCenterUpdateAdapter(
      this.user.token,
      this.id,
      this.costCenter
    )
  }
}
