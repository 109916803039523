import validateUser from '@/lib/validateUser'
import invoiceCostCentersAdapter from '@/adapters/backoffice/invoices/updateCostCenters'

export default class {
  user = null
  id = null
  invoice = null

  constructor(user, id, invoice) {
    this.user = user
    this.id = id
    this.invoice = invoice
  }

  async call() {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    return await invoiceCostCentersAdapter(
      this.user.token,
      this.id,
      this.invoice
    )
  }
}
