<template>
  <v-autocomplete
    :class="_class"
    :label="label"
    :value="value"
    :return-object="returnObject"
    :item-value="itemValue"
    :item-text="itemText"
    :items="items"
    :no-data-text="$t('common.autocomplete.no-data')"
    :rules="rules"
    :error-messages="error"
    :hint="hint"
    :loading="loading"
    :required="required"
    :outlined="readonly"
    :filled="readonly"
    @input="onChange"
    dense
  ></v-autocomplete>
</template>

<script>
import validators from '@/domain/validations/rules'

export default {
  name: 'AutocompleteField',
  props: {
    label: String,
    value: null,
    returnObject: Boolean,
    itemValue: {
      type: String,
      default: 'id'
    },
    itemText: {
      type: String,
      default: 'name'
    },
    items: Array,
    hint: String,
    loading: Boolean,
    required: Boolean,
    readonly: Boolean,
    error: String
  },
  computed: {
    rules() {
      return this.required ? [validators(this).presence] : []
    },
    _class() {
      return this.required ? 'required' : ''
    }
  },
  methods: {
    onChange(newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>
