import { make } from 'vuex-pathify'

const state = {
  /// Navigation history: last url visited:
  lastUrl: '/',
  flash: null,
  /// TODO: move to translation files, then remove!
  nations: [
    { value: 'IT', text: 'Italia' },
    { value: 'FR', text: 'Francia' },
    { value: 'EN', text: 'Gran Bretagna' },
    { value: 'XX', text: '?' }
  ],
  languages: [
    { value: 'IT', text: 'Italiano' },
    { value: 'FR', text: 'Francese' },
    { value: 'EN', text: 'Inglese' },
    { value: 'XX', text: '?' }
  ],
  drawer: null,
  mini: false,
  autocompleteMinChars: 3,
  leftMenuItems: [
    {
      title: 'menu.long.dashboard',
      icon: 'mdi-view-dashboard',
      to: '/dashboard'
    },
    {
      title: 'menu.long.manage',
      icon: 'mdi-menu-open',
      items: [
        {
          title: 'menu.short.resources.customers',
          icon: 'mdi-account-details',
          to: '/customers'
        },
        {
          title: 'menu.short.resources.suppliers',
          icon: 'mdi-account-details-outline',
          to: '/suppliers'
        },
        {
          title: 'menu.short.resources.products',
          icon: 'mdi-archive',
          to: '/products'
        },
        {
          title: 'menu.short.resources.editions',
          icon: 'mdi-calendar',
          to: '/editions'
        },
        {
          title: 'menu.short.resources.offers',
          icon: 'mdi-cart',
          to: '/offers'
        },
        {
          title: 'menu.short.resources.contracts',
          icon: 'mdi-file-document-multiple',
          to: '/contracts'
        },
        {
          title: 'menu.short.resources.purchases',
          icon: 'mdi-credit-card-plus',
          to: '/purchases'
        },
        {
          title: 'menu.short.resources.customer-contracts',
          icon: 'mdi-file-document-multiple',
          to: '/customerContracts'
        },
        {
          title: 'menu.short.resources.invoices',
          icon: 'mdi-receipt',
          to: '/invoices'
        },
        {
          title: 'menu.short.resources.payments',
          icon: 'mdi-credit-card-minus',
          to: '/payments'
        },
        {
          title: 'menu.short.resources.cost-centers',
          icon: 'mdi-package-variant',
          to: '/costCenters'
        },
        {
          title: 'menu.short.resources.jobs',
          icon: 'mdi-package-variant',
          to: '/jobs'
        }
      ]
    }
    /*/,
    {
      title: 'menu.long.wizards',
      icon: 'mdi-menu-open',
      items: [
        {
          title: 'menu.short.procedures.contract-offer',
          icon: 'mdi-account-details',
          to: '/procedures/contract-offer'
        }
      ]
    }/*/
  ]
}

const mutations = make.mutations(state)
const actions = make.actions(state)

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
