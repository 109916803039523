import Job from './Job'
import JobDetail from './JobDetail'

export default class {
  static new() {
    return new this({})
  }

  static default() {
    return this.new()
  }

  constructor({ id = null, job = Job.new(), detail = JobDetail.new() } = {}) {
    this.id = id
    this.job = job
    this.detail = detail
  }
}
