import Invoice from './Invoice'

export default class {
  static new() {
    return new this({})
  }

  static default() {
    return this.new()
  }

  constructor({
    id = null,
    invoice = new Invoice(),
    jobAndDetail = null,
    percentage = null,
    amount = null,
    notes = null
  } = {}) {
    this.id = id
    this.invoice = invoice
    this.jobAndDetail = jobAndDetail
    this.percentage = percentage
    this.amount = amount
    this.notes = notes
  }
}
