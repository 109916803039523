<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12" lg="9">
        <material-card
          icon="mdi-credit-card"
          icon-small
          color="primary"
          :title="$t('common.sections.titles.invoice')"
        >
          <InvoiceCard :entity="entity" />

          <InvoiceCostCentersCard :value="entity" />
          <InvoiceJobsCard :value="entity" />

          <InvoiceDetailsCard :items="entity.details" />
        </material-card>

        <v-dialog v-model="sendDialog" max-width="400px">
          <ConfirmDialog
            :title="$t('common.invoice.send.title')"
            :message="$t('common.invoice.send.confirm-text')"
            :yesText="$t('common.yes')"
            :noText="$t('common.no')"
            @yesClick="sendConfirmed"
            @noClick="sendCanceled"
          />
        </v-dialog>

        <v-dialog
          v-model="previewInvoiceDialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="previewInvoiceDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                dark
                x-large
                color="warning"
                @click="saveInvoice"
                class="font-weight-bold"
              >
                {{ $t('common.form.save') }}
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <iframe
            :src="invoicePreviewData"
            id="invoicePreview"
            title="invoicePreview"
          ></iframe>
        </v-dialog>

        <v-dialog v-model="invoiceSavedDialog" width="500">
          <ConfirmDialog
            :title="$t('common.invoice.saveConfirmed')"
            :yesText="$t('common.ok')"
            @yesClick="invoiceSavedDialog = false"
          />
        </v-dialog>
      </v-col>
      <v-col cols="12" lg="3" order="first" order-lg="last">
        <InvoiceSummary :entity="entity">
          <v-divider />
          <SummaryCommandBanner
            icon="mdi-package-variant"
            :text="$t('common.invoice.costCenterDetail.edit')"
            @click="gotoCostCenters"
          />
          <SummaryCommandBanner
            icon="mdi-package-variant"
            :text="$t('common.invoice.jobDetail.edit')"
            @click="gotoJobs"
          />
          <SummaryCommandBanner
            icon="mdi-printer-eye"
            :text="$t('common.invoice.preview')"
            @click="preview"
          />
          <SummaryCommandBanner
            v-if="entity.canSend"
            icon="mdi-email-fast"
            :text="$t('common.invoice.send.title')"
            @click="askSend"
          />
          <SummaryCommandBanner
            v-if="entity.canPartiallyAmend"
            icon="mdi-arrow-u-right-bottom-bold"
            :text="$t('common.invoice.partial-amend')"
            @click="gotoPartialAmend"
          />
          <SummaryCommandBanner
            v-if="entity.canAmend"
            icon="mdi-arrow-u-right-bottom-bold"
            :text="$t('common.invoice.amend')"
            @click="gotoAmend"
          />
          <SummaryCommandBanner
            v-if="entity.canEdit"
            icon="mdi-pencil"
            :text="$t('common.invoice.edit')"
            @click="gotoEdit"
          />
          <SummaryCommandBanner
            icon="mdi-view-list"
            :text="$t('common.back-to-list')"
            @click="gotoList"
          />
        </InvoiceSummary>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsAShowPage from '@/mixins/actsAsAShowPage'

import InvoiceCard from '@/components/invoices/InvoiceCard.vue'
import InvoiceCostCentersCard from '@/components/invoices/InvoiceCostCentersCard.vue'
import InvoiceJobsCard from '@/components/invoices/InvoiceJobsCard.vue'
import InvoiceDetailsCard from '@/components/invoices/InvoiceDetailsCard.vue'
import InvoiceSummary from '@/components/invoices/InvoiceSummary.vue'
import SummaryCommandBanner from '@/components/common/SummaryCommandBanner.vue'
import ConfirmDialog from '@/components/common/ConfirmDialog.vue'

import Invoice from '@/domain/entities/Invoice'
import GetInvoice from '@/domain/useCases/invoices/GetInvoice'
import SendInvoice from '@/domain/useCases/invoices/SendInvoice'

import { get } from 'vuex-pathify'
import PreviewInvoice from '@/domain/useCases/invoices/PreviewInvoice'
import SaveInvoiceDocument from '@/domain/useCases/invoices/SaveInvoiceDocument'

export default {
  name: 'ShowInvoicePage',
  mixins: [actsAsAShowPage],
  components: {
    InvoiceCard,
    InvoiceDetailsCard,
    InvoiceCostCentersCard,
    InvoiceJobsCard,
    InvoiceSummary,
    SummaryCommandBanner,
    ConfirmDialog
  },
  props: { id: String },
  data() {
    return {
      actsAsAShowPage: {
        resource: 'invoices',
        entity: 'invoice',
        get: GetInvoice
      },
      entity: Invoice.default(),
      sendDialog: false,
      sendCanceled: false,
      previewInvoiceDialog: false,
      invoicePreviewData: null,
      invoiceSavedDialog: false
    }
  },
  computed: {
    user: get('user')
  },
  methods: {
    gotoPartialAmend() {
      this.$router.push(`/${this.option('resource')}/${this.id}/partial-amend`)
    },
    gotoAmend() {
      this.$router.push(`/${this.option('resource')}/${this.id}/amend`)
    },
    gotoCostCenters() {
      this.$router.push(
        `/${this.option('resource')}/${this.id}/edit/cost-centers`
      )
    },
    gotoJobs() {
      this.$router.push(`/${this.option('resource')}/${this.id}/edit/jobs`)
    },
    askSend() {
      this.sendDialog = true
    },
    sendCancelled() {
      this.sendDialog = false
    },
    sendConfirmed() {
      const sendInvoiceUseCase = new SendInvoice(
        this.user,
        this.entity.id,
        this.entity
      )
      sendInvoiceUseCase.call().then(result => {
        if (result.error) {
          this.$_handleError(result)
        } else {
          this.entity = result.invoice
        }
        this.sendDialog = false
      })
    },
    preview() {
      new PreviewInvoice(this.user).call(this.entity).then(result => {
        if (result.error) {
          this.invoicePreviewData = null
          result.saveAlert(this)
          console.info(`Preview error :`, result)
        } else {
          this.invoicePreviewData = URL.createObjectURL(result.preview)
          this.previewInvoiceDialog = true
        }
      })
    },
    saveInvoice() {
      new SaveInvoiceDocument(this.user).call(this.entity).then(result => {
        this.previewInvoiceDialog = false
        if (result.error) {
          result.saveAlert(this)
          console.info(`Save invoice error :`, result)
        } else {
          this.invoiceSavedDialog = true
        }
      })
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>

<style>
.v-toolbar__content {
  padding-right: 0;
}
</style>

<style scoped>
#invoicePreview {
  display: block;
  height: calc(100vh - 64px);
}
</style>
