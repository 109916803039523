<template>
  <LoginLayout>
    <FlashAlert />
    <transition name="fade" mode="out-in">
      <LoginForm :onLoginRequest="loginRequested" />
    </transition>
  </LoginLayout>
</template>

<script>
import LoginLayout from '@/layouts/LoginLayout.vue'
import FlashAlert from '@/components/common/FlashAlert.vue'
import LoginForm from '@/components/LoginForm.vue'

import LoginUser from '@/domain/useCases/users/LoginUser.js'

export default {
  name: 'LoginPage',
  components: {
    FlashAlert,
    LoginLayout,
    LoginForm
  },
  methods: {
    async loginRequested(email, password) {
      const login = new LoginUser(email, password)
      const result = await login.call()

      if (result.error) {
        this.$store.set('app/flash', result.toAlert())
      } else {
        this.user = result.user
        let nextUrl = this.$route.params.nextUrl
        await this.$store.dispatch('setUser', result.user)

        if (!nextUrl) {
          this.$router.push('/dashboard')
        } else {
          this.$router.push(nextUrl)
        }
      }
    }
  },
  metaInfo() {
    const title = this.$t('menu.long.login')
    return { title }
  },
  created() {
    this.$store.dispatch('setUser', null)
  }
}
</script>
