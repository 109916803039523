<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12" lg="9">
        <material-card
          icon="mdi-package-variant"
          icon-small
          color="primary"
          :title="$t('common.sections.titles.costCenter')"
        >
          <CostCenterCard :entity="entity" />
        </material-card>
      </v-col>
      <v-col cols="12" lg="3" order="first" order-lg="last">
        <CostCenterSummary :entity="entity">
          <SummaryCommandBanner
            icon="mdi-view-list"
            :text="$t('common.back-to-list')"
            @click="gotoList"
          />
          <SummaryCommandBanner
            icon="mdi-pencil"
            :text="$t('common.costCenter.edit')"
            @click="gotoEdit"
          />
        </CostCenterSummary>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsAShowPage from '@/mixins/actsAsAShowPage'

import CostCenterCard from '@/components/costCenters/CostCenterCard.vue'
import CostCenterSummary from '@/components/costCenters/CostCenterSummary.vue'
import SummaryCommandBanner from '@/components/common/SummaryCommandBanner.vue'

import CostCenter from '@/domain/entities/CostCenter'
import GetCostCenter from '@/domain/useCases/costCenters/GetCostCenter'

export default {
  name: 'ShowCostCenterPage',
  mixins: [actsAsAShowPage],
  components: { CostCenterCard, CostCenterSummary, SummaryCommandBanner },
  props: { id: String },
  data() {
    return {
      actsAsAShowPage: {
        resource: 'costCenters',
        entity: 'costCenter',
        get: GetCostCenter
      },
      entity: CostCenter.default()
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
