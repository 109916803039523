import axios from '@/axios.js'

import JobFormatter from '../formatters/Job'
import resourceErrorsToResponse from '../formatters/resourceErrorsToResponse'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, entity) {
  const headers = { Authorization: `Bearer ${token}` }
  const payload = { job: JobFormatter.toRequestResource(entity) }

  try {
    const { data } = await axios.post('/api/v1/jobs', payload, {
      headers
    })

    const job = JobFormatter.fromResponseResource(data.job)

    return { error: false, job }
  } catch (e) {
    return (
      resourceErrorsToResponse(e, JobFormatter, 'job') || exceptionToResponse(e)
    )
  }
}
