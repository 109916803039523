import PersonAndCompany from '@/domain/entities/PersonAndCompany'
import Base from './Base'
import PersonFormatter from './Person'
import PersonCompanyFormatter from './PersonCompany'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null

    const entity = this.fieldNamesFromResponse(resource)
    return new PersonAndCompany(entity)
  }

  static toRequestResource(entity) {
    if (!entity) return null

    return this.fieldNamesForRequest(entity)
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    if (!resource) return null

    const formattedPersonCompany = PersonCompanyFormatter.fromResponseResource(
      resource
    )

    const person = resource.user
      ? PersonFormatter.fromResponseResource(resource.user)
      : null

    const result = formattedPersonCompany
      ? { person, ...formattedPersonCompany }
      : person

    this.formatInternalValuesFromResponse(resource, result)
    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(entity, removeEmpty = false) {
    if (!entity) return null

    const formattedUserCompany = PersonCompanyFormatter.fieldNamesForRequest(
      entity
    )
    const user = entity.person
      ? PersonFormatter.fieldNamesForRequest(entity.person)
      : null

    const result = formattedUserCompany
      ? { user, ...formattedUserCompany }
      : user

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }
}
