import validateUser from '@/lib/validateUser'
import Error from '@/lib/Error'
import invoiceUpdateAdapter from '@/adapters/backoffice/invoices/update'

export default class {
  user = null
  id = null
  invoice = null

  constructor(user, id, invoice) {
    this.user = user
    this.id = id
    this.invoice = invoice
  }

  async call() {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    if (!this.invoice || !this.id)
      return new Error({ message: 'errors.resource.invalid' })

    return await invoiceUpdateAdapter(this.user.token, this.id, this.invoice)
  }
}
