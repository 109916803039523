<template>
  <AutocompleteField
    :label="_label"
    :value="value"
    return-object
    item-text="name"
    :items="items"
    :hint="hint"
    :loading="loading"
    :required="required"
    :error="error"
    dense
    @input="onChange"
  />
</template>

<script>
import { get } from 'vuex-pathify'

import { AutocompleteField } from '@/components/common/form'

import GetPurchaseSources from '@/domain/useCases/purchases/GetPurchaseSources'
import Error from '@/lib/Error'

export default {
  name: 'PurchaseSourceSelect',
  components: { AutocompleteField },
  props: {
    label: String,
    value: Object,
    readonly: Boolean,
    hint: String,
    required: Boolean,
    error: String
  },
  data() {
    return {
      items: [],
      loading: true
    }
  },
  computed: {
    user: get('user'),
    _class() {
      return this.required ? 'required' : ''
    },
    _label() {
      return this.label || this.$t('entities.purchase.purchaseSource')
    }
  },
  methods: {
    onChange(newValue) {
      this.$emit('input', newValue)
    }
  },
  created() {
    new GetPurchaseSources(this.user).call().then(result => {
      if (result.error) {
        new Error({
          message: 'common.autocomplete.error-loading'
        }).toAlert()
        console.info(`Error loading payment sources for autocomplete:`, result)
      } else {
        this.items = result.purchaseSources || []
        this.loading = false
      }
    })
  }
}
</script>
