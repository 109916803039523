<template>
  <v-container fluid tag="section">
    <material-card
      icon="mdi-cart"
      icon-small
      :title="$t('list.offers.title')"
      color="primary"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :footer-props="footerOptions"
        :server-items-length="totalItems"
        :loading="loading"
        :no-data-text="$t('common.list.no-data')"
      >
        <template v-slot:top>
          <v-container>
            <v-row dense>
              <v-col cols="11">
                <Searchbar @submit="searchbarSubmitted" />
              </v-col>
              <v-col cols="1" class="pt-0 pl-5">
                <RoundButton
                  icon="mdi-plus"
                  :alt="$t('list.offers.new')"
                  @click="$emit('goto-new')"
                />
              </v-col>
            </v-row>
          </v-container>
        </template>

        <template v-slot:[`item.active`]="{ item }">
          {{ item.active | yesNo }}
        </template>

        <template v-slot:[`item.fromDate`]="{ item }">
          {{ item.fromDate | date }}
        </template>

        <template v-slot:[`item.toDate`]="{ item }">
          {{ item.toDate | date }}
        </template>

        <template v-slot:[`item.offerPrice`]="{ item }">
          {{ item.offerPrice | append('€') }}
        </template>

        <template v-slot:[`item.description`]="{ item }">
          <div style="height: 100px; overflow-y: scroll; max-width: 350px;">
            {{ item.description }}
          </div>
        </template>

        <template v-slot:[`item.creationDate`]="{ item }">
          <small>{{ item.creationDate | dateTime }}</small>
        </template>

        <template v-slot:[`item.lastModified`]="{ item }">
          <small>{{ item.lastModified | dateTime }}</small>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="actions">
            <v-icon
              class="mr-2 show-product-button"
              @click="$emit('goto-show', item.id)"
              >mdi-eye</v-icon
            >
            <v-icon
              class="mr-2 edit-product-button"
              @click="$emit('goto-edit', item.id)"
              >mdi-pencil</v-icon
            >
            <v-icon
              class="delete-product-button"
              @click="$emit('delete', item.id)"
              >mdi-delete</v-icon
            >
          </div>
        </template>
      </v-data-table>
    </material-card>
  </v-container>
</template>

<script>
import actsAsAList from '@/mixins/actsAsAList'

import Searchbar from './OfferSearchbar.vue'
import RoundButton from '@/components/common/RoundButton.vue'

export default {
  name: 'OffersList',
  mixins: [actsAsAList],
  components: { Searchbar, RoundButton },
  props: {
    loading: Boolean,
    items: Array,
    page: Number,
    totalItems: Number
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('entities.offer.active'),
          value: 'active'
        },
        {
          text: this.$t('entities.offer.name'),
          value: 'name'
        },
        {
          text: this.$t('entities.offer.offerPrice'),
          value: 'offerPrice'
        },
        {
          text: this.$t('entities.offer.fromDate'),
          value: 'fromDate'
        },
        {
          text: this.$t('entities.offer.toDate'),
          value: 'toDate'
        },
        {
          text: this.$t('common.fields.creationDate'),
          value: 'creationDate'
        },
        {
          text: this.$t('common.fields.lastModified'),
          value: 'lastModified'
        },
        { text: '', value: 'actions' }
      ]
    }
  }
}
</script>
