import validateUser from '@/lib/validateUser'
import PurchaseSourcesListAdapter from '@/adapters/backoffice/purchases/getPurchaseSources'

export default class {
  user = null

  constructor(user) {
    this.user = user
  }

  async call() {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    return await PurchaseSourcesListAdapter(this.user.token)
  }
}
