<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        fab
        :color="color"
        :class="cssClass"
        v-bind="attrs"
        v-on="on"
        @click="$emit('click')"
        :small="small"
        ><v-icon dark>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ alt }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'RoundButton',
  props: {
    icon: String,
    alt: String,
    cssClass: { type: String, default: '' },
    color: { type: String, default: 'primary' },
    small: { type: Boolean, default: false }
  }
}
</script>
