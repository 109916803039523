<template>
  <v-form ref="form" @submit.prevent>
    <v-container>
      <v-row v-if="genericProductSelect">
        <v-col cols="12" lg="12">
          <GenericProductSelect
            v-model="entity.genericProduct"
            :type.sync="entity.genericProductType"
            required
            :error="errors.genericProductType"
          />
        </v-col>
      </v-row>

      <v-row class="align-items-center" v-if="!genericProductSelect">
        <v-col cols="12" class="pb-0">
          <CardTextField
            :label="$t('common.edition.selected-generic-product')"
            :value="genericProductName"
            :error="errors.genericProductName"
          />
        </v-col>
        <v-col class="pt-0">
          <v-btn
            class="mb-2"
            small
            left
            color="secondary"
            @click="removeGenericProduct"
          >
            <v-icon left dark>mdi-close</v-icon>
            {{ $t('common.edition.remove-generic-product') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="3">
          <v-switch
            color="success"
            :label="$t('entities.edition.active')"
            v-model="entity.active"
            :error="errors.active"
          />
        </v-col>

        <v-col cols="12" lg="2">
          <LanguageSelect
            :label="$t('entities.edition.language')"
            v-model="entity.language"
            :error="errors.language"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <TextField
            type="currency"
            :label="$t('entities.edition.price')"
            v-model="entity.price"
            required
            :error="errors.price"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="3">
          <v-switch
            color="success"
            :label="$t('entities.edition.showOnCalendar')"
            v-model="entity.showOnCalendar"
            :error="errors.showOnCalendar"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <DateField
            :label="$t('entities.edition.fromDate')"
            v-model="entity.fromDate"
            :error="errors.fromDate"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <DateField
            :label="$t('entities.edition.toDate')"
            v-model="entity.toDate"
            :error="errors.toDate"
          />
        </v-col>

        <v-col cols="12" lg="2">
          <TextField
            type="number"
            :label="$t('entities.edition.durationHours')"
            v-model="entity.durationHours"
            :required="isAboutAProduct"
            :errors="errors.durationHours"
          />
        </v-col>

        <v-col cols="12" lg="2">
          <TextField
            type="number"
            :label="$t('entities.edition.durationDays')"
            v-model="entity.durationDays"
            :required="isAboutAProduct"
            :errors="errors.durationDays"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="2">
          <TextField
            :label="$t('entities.edition.placeDescription')"
            v-model="entity.placeDescription"
            :errors="errors.placeDescription"
          />
        </v-col>

        <v-col cols="12" lg="6">
          <TextField
            :label="$t('entities.edition.place')"
            v-model="entity.place"
            :errors="errors.place"
          />
        </v-col>

        <v-col cols="12" lg="2">
          <TextField
            type="number"
            :label="$t('entities.edition.minAttendees')"
            v-model="entity.minAttendees"
            :required="isAboutAProduct"
            :errors="errors.minAttendees"
          />
        </v-col>

        <v-col cols="12" lg="2">
          <TextField
            type="number"
            :label="$t('entities.edition.maxAttendees')"
            v-model="entity.maxAttendees"
            :required="isAboutAProduct"
            :errors="errors.maxAttendees"
          />
        </v-col>

        <v-col cols="12">
          <Textarea
            :label="$t('entities.edition.notes')"
            v-model="entity.notes"
            :error="errors.notes"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              {{ $t('entities.edition.lecturers') }}
            </v-card-title>
            <EditionLecturersTable
              v-model="entity.lecturers"
              :errors="errors.lecturers"
              :entity="this.entity"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import actsAsAForm from '@/mixins/actsAsAForm'

import GenericProductSelect from './EditionGenericProductSelect.vue'
import EditionLecturersTable from './EditionLecturersEditableTable.vue'
import { TextField, DateField, Textarea } from '@/components/common/form'
import { CardTextField } from '@/components/common/card'
import LanguageSelect from '@/components/common/LanguageSelect.vue'

import Edition from '@/domain/entities/Edition'

export default {
  name: 'EditionForm',
  mixins: [actsAsAForm],
  components: {
    GenericProductSelect,
    EditionLecturersTable,
    CardTextField,
    TextField,
    DateField,
    Textarea,
    LanguageSelect
  },
  props: {
    value: Edition,
    errors: Object
  },
  computed: {
    genericProductName() {
      return this.entity.genericProduct ? this.entity.genericProduct.name : ''
    },
    isAboutAProduct() {
      return this.entity.isBoundToProduct()
    },
    genericProductSelect() {
      return !this.entity.genericProduct
    }
  },
  methods: {
    removeGenericProduct() {
      this.entity.genericProduct = null
    }
  },
  watch: {
    'entity.genericProduct': function() {
      if (this.entity.genericProduct) {
        switch (this.entity.genericProductType) {
          case 'product':
            this.entity = this.entity.copyValuesFromProduct(
              this.entity.genericProduct
            )
            break
          case 'contract':
            this.entity = this.entity.copyValuesFromContract(
              this.entity.genericProduct
            )
            break
        }
        this.entity.toDate = this.entity.calculateToDate()
      }
    },
    'entity.fromDate': function() {
      this.entity.toDate = this.entity.calculateToDate()
    }
  }
}
</script>
