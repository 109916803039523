<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="3">
        <CostCenterSelect
          :costCenters="costCenters"
          v-model="entity.costCenter"
          @input="onCostCenterChanged"
          :error="errors | dig('costCenter')"
          required
        />
      </v-col>
      <v-col cols="12" lg="3">
        <CostCenterDetailSelect
          :itemsProp="avaibleCostCenterDetails"
          v-model="entity.costCenterDetail"
          :error="errors | dig('costCenterDetail')"
          required
        />
      </v-col>
      <v-col cols="12" lg="2">
        <TextField
          type="percentage"
          :label="$t('entities.invoice.costCenter.percentage')"
          v-model="entity.percentage"
          :error="errors.percentage"
          @input="calculateAmount"
          required
        />
      </v-col>
      <v-col cols="12" lg="2">
        <TextField
          type="currency"
          :label="$t('entities.invoice.costCenter.amount')"
          v-model="entity.amount"
          :error="errors.amount"
          @input="calculatePercentage"
          required
        />
      </v-col>
      <v-col cols="auto">
        <v-btn icon @click="$emit('remove', $vnode.key)">
          <v-icon
            color="primary"
            :alt="$t('form.costCenters.remove.title')"
            :title="$t('form.costCenters.remove.title')"
            >mdi-minus</v-icon
          >
        </v-btn>
      </v-col>
      <v-col cols="12" lg="12">
        <Textarea
          :label="$t('entities.invoice.notes')"
          v-model="entity.notes"
          :error="errors.notes"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsAForm from '@/mixins/actsAsAForm'
import { TextField, Textarea } from '@/components/common/form'
import CostCenterSelect from '@/components/costCenters/CostCenterSelect.vue'
import CostCenterDetailSelect from '@/components/costCenters/CostCenterDetailSelect.vue'
import InvoiceCostCenterDetail from '@/domain/entities/InvoiceCostCenterDetail'
import Decimal from '@/lib/Decimal'
import { dig } from '@/lib/Utils'
import Invoice from '@/domain/entities/Invoice'

export default {
  name: 'InvoiceCostCenterForm',
  mixins: [actsAsAForm],
  components: {
    TextField,
    Textarea,
    CostCenterSelect,
    CostCenterDetailSelect
  },
  props: {
    value: InvoiceCostCenterDetail,
    invoice: { type: Invoice, required: true },
    errors: Object,
    costCenters: Array
  },
  computed: {
    avaibleCostCenterDetails() {
      return dig(this.entity.costCenter, 'details') || []
    }
  },
  methods: {
    calculateAmount(percentage) {
      this.entity.amount = Decimal.fromUI(
        this.invoice.amount || Decimal.zeroForUI()
      )
        .percentage(Decimal.fromUI(percentage) || Decimal.zero())
        .forUI()
    },
    calculatePercentage(amount) {
      this.entity.percentage = Decimal.fromUI(amount || Decimal.zeroForUI())
        .multiply(Decimal.fromBE(100))
        .divide(Decimal.fromUI(this.invoice.amount) || Decimal.zero())
        .forUI()
    },
    onCostCenterChanged(costCenter) {
      this.entity.costCenter = costCenter
    }
  }
}
</script>
