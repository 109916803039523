import {
  JobsListPage,
  NewJobPage,
  EditJobPage,
  ShowJobPage
} from '@/views/jobs/index.js'

export default [
  {
    path: '/jobs',
    name: 'JobsPage',
    component: JobsListPage,
    meta: {
      title: 'menu.long.resources.jobs'
    }
  },
  {
    path: '/jobs/new',
    name: 'NewJobPage',
    component: NewJobPage,
    meta: {
      title: 'menu.long.resources.new-cost-center'
    }
  },
  {
    path: '/jobs/:id/edit',
    name: 'EditJobPage',
    component: EditJobPage,
    props: true,
    meta: {
      title: 'menu.long.resources.edit-cost-center'
    }
  },
  {
    path: '/jobs/:id',
    name: 'ShowJobPage',
    component: ShowJobPage,
    props: true,
    meta: {
      title: 'menu.long.resources.show-cost-center'
    }
  }
]
