import {
  SuppliersListPage,
  NewSupplierPage,
  EditSupplierPage,
  ShowSupplierPage
} from '@/views/suppliers/index.js'

export default [
  {
    path: '/suppliers',
    name: 'SuppliersPage',
    component: SuppliersListPage,
    meta: {
      title: 'menu.long.resources.suppliers'
    }
  },
  {
    path: '/suppliers/new',
    name: 'NewSupplierPage',
    component: NewSupplierPage,
    meta: {
      title: 'menu.long.resources.new-supplier'
    }
  },
  {
    path: '/suppliers/:id/edit',
    name: 'EditSupplierPage',
    component: EditSupplierPage,
    props: true,
    meta: {
      title: 'menu.long.resources.edit-customer'
    }
  },
  {
    path: '/suppliers/:id',
    name: 'ShowSupplierPage',
    component: ShowSupplierPage,
    props: true,
    meta: {
      title: 'menu.long.resources.show-customer'
    }
  }
]
