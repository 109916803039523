export default class {
  static new() {
    return new this()
  }

  static default() {
    return new this()
  }

  constructor({
    id = null,
    date = null,
    contractName = null,
    contractNumber = null,

    offerName = null,
    description = null,

    amount = null,
    discount = null,
    vatAmount = null,
    vatPerc = null,

    creationDate = null,
    startDate = null,
    lastModified = null,
    lastModifiedBy = null,
    purchase = null
  } = {}) {
    this.id = id

    this.date = date
    this.contractName = contractName
    this.contractNumber = contractNumber
    this.offerName = offerName
    this.description = description

    this.amount = amount
    this.discount = discount
    this.vatAmount = vatAmount
    this.vatPerc = vatPerc

    this.creationDate = creationDate
    this.startDate = startDate
    this.lastModified = lastModified
    this.lastModifiedBy = lastModifiedBy
    this.purchase = purchase
  }
}
