import { dateForUI } from '@/lib/Utils'

export default {
  'contract-offer': {
    steps: {
      contract: 'Contratto',
      edition: 'Edizione',
      offer: 'Dati offerta',
      'offer-prices': "Prezzi dell'offerta"
    }
  },
  'edition-of': ({ named }) => {
    return `Edizione di ${named('name')}`
  },
  'contract-offer-title': ({ named }) => {
    const offer = named('offer')
    if (!offer) return 'Offerta di edizione.'

    const edition = named('offer').editions[0]
    const contract = edition.genericProduct
    const from = dateForUI(edition.fromDate)
    const to = dateForUI(edition.toDate)
    return `Offerta per edizione ${from} - ${to} di ${contract.name}`
  },
  'contract-offer-prices-title': ({ named }) => {
    const offer = named('offer')
    if (!offer) return 'Prezzi per offerta di edizione.'

    const edition = named('offer').editions[0]
    const contract = edition.genericProduct
    const from = dateForUI(edition.fromDate)
    const to = dateForUI(edition.toDate)
    return `Prezzi per offerta ${from} - ${to} di ${contract.name}`
  }
}
