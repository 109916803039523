import validateUser from '@/lib/validateUser'
import Error from '@/lib/Error'
import jobUpdateAdapter from '@/adapters/backoffice/jobs/update'

export default class {
  user = null
  id = null
  job = null

  constructor(user, id, job) {
    this.user = user
    this.id = id
    this.job = job
  }

  async call() {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    if (!this.job || !this.id)
      return new Error({ message: 'errors.resource.invalid' })

    return await jobUpdateAdapter(this.user.token, this.id, this.job)
  }
}
