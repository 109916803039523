<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12" lg="9">
        <material-card
          icon="mdi-calendar"
          icon-small
          color="primary"
          :title="$t('common.sections.titles.edition')"
        >
          <EditionCard :entity="entity" />
        </material-card>
      </v-col>
      <v-col cols="12" lg="3" order="first" order-lg="last">
        <EditionSummary :entity="entity">
          <v-divider />
          <SummaryCommandBanner
            icon="mdi-view-list"
            :text="$t('common.back-to-list')"
            @click="gotoList"
          />
          <SummaryCommandBanner
            icon="mdi-pencil"
            :text="$t('common.edition.edit')"
            @click="gotoEdit"
          />
        </EditionSummary>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsAShowPage from '@/mixins/actsAsAShowPage'

import EditionCard from '@/components/editions/EditionCard'
import EditionSummary from '@/components/editions/EditionSummary'
import SummaryCommandBanner from '@/components/common/SummaryCommandBanner.vue'

import Edition from '@/domain/entities/Edition'
import GetEdition from '@/domain/useCases/editions/GetEdition'

export default {
  name: 'ShowEditionPage',
  mixins: [actsAsAShowPage],
  components: { EditionCard, EditionSummary, SummaryCommandBanner },
  props: { id: String },
  data() {
    return {
      actsAsAShowPage: {
        resource: 'editions',
        entity: 'edition',
        get: GetEdition
      },
      entity: Edition.default()
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
