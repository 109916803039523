<template>
  <material-card
    icon="mdi-card-account-details"
    icon-small
    :title="$t('common.sections.titles.person')"
    color="primary"
  >
    <v-form v-if="entity" ref="form" @submit.prevent>
      <v-container>
        <v-row>
          <v-col cols="12" lg="3">
            <TextField
              :label="$t('entities.person.name')"
              v-model="entity.name"
              required
              :error="errors.name"
            />
          </v-col>

          <v-col cols="12" lg="3">
            <TextField
              :label="$t('entities.person.surname')"
              v-model="entity.surname"
              required
              :error="errors.surname"
            />
          </v-col>

          <v-col cols="12" lg="3">
            <DateField
              :label="$t('entities.person.birthDate')"
              v-model="entity.birthDate"
              :error="errors.birthdate"
            />
          </v-col>

          <v-col cols="12" lg="3">
            <TextField
              type="fiscal-code"
              :label="$t('entities.person.fiscalCode')"
              v-model="entity.fiscalCode"
              :error="errors.fiscalCode"
            />
          </v-col>

          <v-col cols="12" lg="4">
            <TextField
              type="email"
              :label="$t('entities.person.email')"
              v-model="entity.email"
              required
              :error="errors.email"
            />
          </v-col>

          <v-col cols="12" lg="4">
            <TextField
              type="tel"
              :label="$t('entities.person.mobile')"
              v-model="entity.mobile"
              :error="errors.mobile"
            />
          </v-col>

          <v-col cols="12" lg="4">
            <TextField
              type="tel"
              :label="$t('entities.person.phone')"
              v-model="entity.phone"
              :error="errors.phone"
            />
          </v-col>

          <v-col cols="12">
            <TextField
              :label="$t('entities.person.address1')"
              v-model="entity.address1"
              required
              :error="errors.address1"
            />
          </v-col>

          <v-col cols="12">
            <TextField
              :label="$t('entities.person.address2')"
              v-model="entity.address2"
              :error="errors.address2"
            />
          </v-col>

          <v-col cols="12" lg="2">
            <TextField
              :label="$t('entities.person.postalCode')"
              v-model="entity.postalCode"
              required
              :error="errors.postalCode"
            />
          </v-col>

          <v-col cols="12" lg="3">
            <TextField
              :label="$t('entities.person.city')"
              v-model="entity.city"
              required
              :error="errors.city"
            />
          </v-col>

          <v-col cols="12" lg="2">
            <TextField
              :label="$t('entities.person.county')"
              v-model="entity.county"
              required
              :error="errors.county"
            />
          </v-col>

          <v-col cols="12" lg="3">
            <TextField
              :label="$t('entities.person.region')"
              v-model="entity.region"
              :error="errors.region"
            />
          </v-col>

          <v-col cols="12" lg="2">
            <NationSelect
              :label="$t('entities.person.nation')"
              v-model="entity.nation"
              required
              :error="errors.nation"
            />
          </v-col>

          <v-col cols="12">
            <Textarea
              :label="$t('entities.person.notes')"
              v-model="entity.notes"
              :error="errors.notes"
            />
          </v-col>

          <v-col cols="12">
            <Textarea
              :label="$t('entities.person.intolerances')"
              v-model="entity.intolerances"
              :error="errors.intolerances"
            />
          </v-col>

          <v-col cols="12" lg="3">
            <DateField
              :label="$t('entities.person.startDate')"
              v-model="entity.startDate"
              :error="errors.startDate"
            />
          </v-col>
          <v-col cols="12" lg="3">
            <DateField
              :label="$t('entities.person.endDate')"
              v-model="entity.endDate"
              :error="errors.endDate"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </material-card>
</template>

<script>
import actsAsAForm from '@/mixins/actsAsAForm'

import { DateField, TextField, Textarea } from '@/components/common/form'
import NationSelect from '@/components/common/NationSelect.vue'

import Person from '@/domain/entities/Person'

export default {
  name: 'PersonForm',
  mixins: [actsAsAForm],
  components: { TextField, DateField, NationSelect, Textarea },
  props: {
    value: Person,
    errors: Object
  }
}
</script>
