import axios from '@/axios.js'

import ListOptionsFormatter from '../formatters/ListOptions'
import InvoiceFormatter from '../formatters/Invoice'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, listOptions) {
  try {
    const options = ListOptionsFormatter.toRequestOptions(
      listOptions,
      InvoiceFormatter
    )

    let url = '/api/v1/invoPurchases'
    url = !listOptions.purchase
      ? url
      : url + '/purchase/' + listOptions.purchase

    const { data } = await axios.get(url, {
      params: options,
      headers: { Authorization: `Bearer ${token}` }
    })

    const { results, totalItems, page } = data
    const invoices = InvoiceFormatter.fromResponseResourcesList(results)

    return { error: false, invoices, totalItems, page }
  } catch (e) {
    return exceptionToResponse(e)
  }
}
