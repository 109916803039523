var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"costCenters-list","fluid":"","tag":"section"}},[_c('material-card',{attrs:{"icon":"mdi-package-variant","icon-small":"","title":_vm.$t('list.costCenters.title'),"color":"primary"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"footer-props":_vm.footerOptions,"server-items-length":_vm.totalItems,"loading":_vm.loading,"no-data-text":_vm.$t('common.list.no-data'),"show-expand":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{staticClass:"mx-1"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('Searchbar',{on:{"submit":_vm.searchbarSubmitted}})],1),_c('v-col',{staticClass:"pt-0 pl-5",attrs:{"cols":"1"}},[_c('RoundButton',{attrs:{"icon":"mdi-plus","alt":_vm.$t('list.costCenters.new')},on:{"click":function($event){return _vm.$emit('goto-new')}}})],1)],1)],1)]},proxy:true},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("date")(item.startDate)))])]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("date")(item.endDate)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions"},[_c('v-icon',{staticClass:"mr-2 show-costCenter-button",on:{"click":function($event){return _vm.$emit('goto-show', item.id)}}},[_vm._v("mdi-eye")]),_c('v-icon',{staticClass:"mr-2 edit-costCenter-button",on:{"click":function($event){return _vm.$emit('goto-edit', item.id)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{staticClass:"delete-costCenter-button",on:{"click":function($event){return _vm.$emit('delete', item.id)}}},[_vm._v("mdi-delete")])],1)]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"mb-3 mt-2 mx-2"},[_c('v-card-title',{staticClass:"pb-1"},[_vm._v(" "+_vm._s(_vm.$t('entities.costCenter.details'))+" ")]),_c('v-card-text',[_c('ul',_vm._l((item.details),function(detail){return _c('li',{key:detail.id},[_vm._v(" "+_vm._s(detail.name)+" ")])}),0)])],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }