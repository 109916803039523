<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="3">
        <CardTextField
          :label="$t('entities.invoice.costCenter.name')"
          :value="value.costCenter | dig('name')"
        />
      </v-col>
      <v-col cols="12" lg="3">
        <CardTextField
          :label="$t('entities.invoice.costCenter.detail.name')"
          :value="value.costCenterDetail | dig('name')"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <CardTextField
          type="percentage"
          :label="$t('entities.invoice.costCenter.percentage')"
          :value="value.percentage"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <CardTextField
          type="currency"
          :label="$t('entities.invoice.costCenter.amount')"
          :value="value.amount"
        />
      </v-col>
      <v-col cols="12" lg="12">
        <CardTextArea
          :label="$t('entities.invoice.notes')"
          :value="value.notes"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardTextField from '@/components/common/card/CardTextField.vue'
import CardTextArea from '@/components/common/card/CardTextarea.vue'
import InvoiceCostCenterDetail from '@/domain/entities/InvoiceCostCenterDetail'

export default {
  name: 'InvoiceCostCenterCard',
  components: {
    CardTextField,
    CardTextArea
  },
  props: {
    value: InvoiceCostCenterDetail,
    errors: Object
  }
}
</script>
