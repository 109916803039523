<template>
  <v-autocomplete
    :class="_class"
    :label="label"
    :value="value"
    :items="items"
    :rules="_rules"
    :required="required"
    :error="error"
    :no-data-text="$t('common.autocomplete.no-data')"
    dense
    @input="onChange"
  ></v-autocomplete>
</template>

<script>
import validators from '@/domain/validations/rules'

export default {
  name: 'ContractTypeSelect',
  props: {
    value: String,
    label: String,
    required: Boolean,
    error: String
  },
  data() {
    return {
      items: [
        {
          value: 'coaching',
          text: this.$t('entities.contract.types.coaching')
        },
        {
          value: 'product',
          text: this.$t('entities.contract.types.product')
        }
      ]
    }
  },
  computed: {
    _class() {
      return this.required ? 'required' : ''
    },
    _label() {
      return this.label || this.$t('entities.contract.type')
    },
    _rules() {
      return this.required ? [validators(this).presence] : []
    }
  },
  methods: {
    onChange(newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>

<style>
.required label.v-label::after {
  content: ' *';
}
</style>
