<template>
  <div>
    <PersonForm
      ref="personForm"
      v-model="entity.person"
      :errors="personErrors"
    />
    <div v-if="noCompaniesForPrimaryType" class="py-2">
      <material-alert color="warning" icon="mdi-alert-outline">
        {{ $t(`common.personCompany.required.${primaryType}`) }}
      </material-alert>
    </div>

    <material-card
      v-for="(personCompany, index) in entity.personCompanies"
      :key="personCompany.id || personCompany._key"
      :as="personCompany.type"
      icon-small
      :icon="personCompanyIcon(personCompany)"
      :color="personCompanyColor(personCompany)"
      :title="personCompanyTitle(personCompany)"
    >
      <template #title>
        <div class="float-right">
          <RoundButton
            icon="mdi-minus"
            color="warning"
            :alt="$t('common.personCompany.remove.title')"
            @click="$emit('remove-company-at', index)"
          />
        </div>
      </template>
      <PersonCompaniesForm
        ref="personCompaniesForm"
        :as="personCompany.type"
        v-model="entity.personCompanies[index]"
        :errors="personCompanyErrors(index)"
        :new-item="!personCompany.id"
      />
    </material-card>
  </div>
</template>

<script>
import { dig } from '@/lib/Utils'
import actsAsAForm from '@/mixins/actsAsAForm'
import Error from '@/lib/Error.js'

import PersonForm from '@/components/people/PersonForm.vue'
import PersonCompaniesForm from '@/components/personCompanies/PersonCompaniesForm.vue'
import RoundButton from '../common/RoundButton.vue'

import PersonAndCompanies from '@/domain/entities/PersonAndCompanies'

export default {
  name: 'PersonAndCompaniesForm',
  mixins: [actsAsAForm],
  components: {
    PersonForm,
    RoundButton,
    PersonCompaniesForm
  },
  props: {
    as: String,
    value: PersonAndCompanies,
    errors: Object,
    newItem: Boolean
  },
  computed: {
    primaryType() {
      return this.as
    },
    primaryTypePersonCompanies() {
      const personCompanies = this.entity.personCompanies || []

      return personCompanies.filter(
        personCompany => personCompany.type === this.primaryType
      )
    },
    personErrors() {
      return dig(this.errors, 'person') || {}
    },
    newPersonCompanyErrors() {
      return this.personCompanyErrors[0]
    },
    primaryTypePersonCompanyErrors() {
      return this.errors && this.errors.personCompanies
        ? this.errors.personCompanies
        : []
    },
    noCompaniesForPrimaryType() {
      return this.primaryTypePersonCompanies.length <= 0
    }
  },
  methods: {
    personCompanyIcon(personCompany) {
      return personCompany.type === 'customer'
        ? 'mdi-cash-plus'
        : 'mdi-cash-minus'
    },
    personCompanyTitle(personCompany) {
      return this.$t(`common.personCompany.edit.${personCompany.type}`)
    },
    personCompanyColor(personCompany) {
      return personCompany.type == 'customer' ? 'primary' : 'indigo'
    },
    personCompanyErrors(index) {
      const errors = dig(this.errors, 'personCompanies')
      return errors && errors.length ? errors[index] : {}
    },
    validate() {
      const personValidation = this.$refs.personForm.validate()

      if (this.newItem) {
        const companyValidation = this.$refs.personCompaniesForm.validate()
        return personValidation && companyValidation
      }

      let personCompaniesValid = true
      if (
        this.$refs.personCompaniesForm &&
        this.$refs.personCompaniesForm.length
      )
        for (let i = 0; i < this.$refs.personCompaniesForm.length; i++)
          if (!this.$refs.personCompaniesForm[i].validate())
            personCompaniesValid = false

      const atLeastOnePrimaryCompany = !this.noCompaniesForPrimaryType
      if (!atLeastOnePrimaryCompany)
        new Error({ message: 'common.personCompany.required' }).saveAlert(this)

      return (
        personValidation && atLeastOnePrimaryCompany && personCompaniesValid
      )
    }
  }
}
</script>

<style scoped>
.person-data-menu {
  position: sticky;
  top: 93px;
}
</style>
