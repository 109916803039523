export default class {
  static new() {
    return new this()
  }

  static default() {
    return this.new()
  }

  constructor({
    id = null,
    supplier = null,
    type = null,
    date = null,
    duoDate = null,
    paymentDate = null,
    bankAccount = null,
    paymentCard = null,
    untaxedPrice = null,
    vatAmount = null,
    description = null,
    costCenterDetails = [],
    jobDetails = [],
    notes = null,
    invoice = null,
    creationDate = null,
    lastModified = null,
    lastModifiedBy = null
  } = {}) {
    this.id = id
    this.supplier = supplier
    this.type = type
    this.date = date
    this.duoDate = duoDate
    this.paymentDate = paymentDate
    this.bankAccount = bankAccount
    this.paymentCard = paymentCard
    this.untaxedPrice = untaxedPrice
    this.vatAmount = vatAmount
    this.description = description
    this.costCenterDetails = costCenterDetails
    this.jobDetails = jobDetails
    this.notes = notes
    this.invoice = invoice
    this.creationDate = creationDate
    this.lastModified = lastModified
    this.lastModifiedBy = lastModifiedBy
  }
}
