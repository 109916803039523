<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="4">
        <CardTextField
          :label="$t('entities.invoice.code')"
          :value="entity.code"
        />
      </v-col>
      <v-col cols="12" lg="4">
        <CardTextField
          :label="$t('entities.invoice.version')"
          :value="entity.version"
        />
      </v-col>
      <v-col cols="12" lg="4">
        <CardTextField
          :label="$t('entities.invoice.branch')"
          :value="entity.branch | dig('description')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="2">
        <CardTextField
          :label="$t('entities.invoice.type')"
          :value="invoiceType"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <CardDateField
          :label="$t('entities.invoice.date')"
          :value="entity.date"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <CardDateField
          :label="$t('entities.invoice.dueDate')"
          :value="entity.dueDate"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <CardDateField
          :label="$t('entities.invoice.paymentDate')"
          :value="entity.paymentDate"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="2">
        <CardTextField
          :label="$t('entities.invoice.purchaseSourceDescription')"
          :value="entity.purchaseSourceDescription"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <CardTextField
          :label="$t('entities.invoice.paymentSourceDescription')"
          :value="entity.paymentSourceDescription"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <CardTextField
          :label="$t('entities.invoice.fiscalType')"
          :value="entity.fiscalType"
        />
      </v-col>
      <v-col cols="12" lg="4">
        <CardTextField
          :label="$t('entities.invoice.fiscalTypeDescription')"
          :value="entity.fiscalTypeDescription"
        />
      </v-col>
      <v-col cols="12" lg="4">
        <CardTextField
          :label="$t('entities.invoice.fiscalTypeRegsReference')"
          :value="entity.fiscalTypeRegsReference"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="4">
        <CardTextField
          :label="$t('entities.invoice.name')"
          :value="entity.name"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <CardTextField
          :label="$t('entities.invoice.vatCode')"
          :value="entity.vatCode"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <CardTextField
          :label="$t('entities.invoice.sdi')"
          :value="entity.sdi"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <CardTextField
          :label="$t('entities.invoice.pec')"
          :value="entity.pec"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <CardTextField
          type="fiscal-code"
          :label="$t('entities.invoice.fiscalCode')"
          :value="entity.fiscalCode"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <CardTextField
          :label="$t('entities.invoice.deductionType')"
          :value="entity.deductionType"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <CardTextField
          type="percentage"
          :label="$t('entities.invoice.deductionRate')"
          :value="entity.deductionRate"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="12">
        <CardTextField
          :label="$t('entities.invoice.address')"
          :value="entity.address"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="4"> </v-col>
      <v-col cols="12" lg="4">
        <CardTextField
          :label="$t('entities.invoice.bankAccountName')"
          :value="entity.bankAccountName"
        />
      </v-col>
      <v-col cols="12" lg="4">
        <CardTextField
          :label="$t('entities.invoice.bankAccountIban')"
          :value="entity.bankAccountIban"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg>
        <CardTextField
          type="currency"
          :label="$t('entities.invoice.amount')"
          :value="entity.amount"
        />
      </v-col>
      <v-col cols="12" lg>
        <CardTextField
          type="currency"
          :label="$t('entities.invoice.vatAmount')"
          :value="entity.vatAmount"
        />
      </v-col>
      <v-col cols="12" lg>
        <CardTextField
          type="currency"
          :label="$t('entities.invoice.taxAmount')"
          :value="entity.taxAmount"
        />
      </v-col>
      <v-col cols="12" lg>
        <CardTextField
          type="currency"
          :label="$t('entities.invoice.stampAmount')"
          :value="entity.stampAmount"
        />
      </v-col>
      <v-col cols="12" lg>
        <CardTextField
          type="currency"
          :label="$t('entities.invoice.totalAmount')"
          :value="entity.totalAmount"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="12">
        <CardTextarea
          :label="$t('entities.invoice.notes')"
          :value="entity.notes"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="2">
        <CardYesNoField
          :label="$t('entities.invoice.issuable')"
          :value="entity.issuable"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  CardDateField,
  CardTextField,
  CardTextarea,
  CardYesNoField
} from '@/components/common/card'

import Invoice from '@/domain/entities/Invoice'

export default {
  name: 'InvoiceCard',
  components: {
    CardDateField,
    CardTextField,
    CardTextarea,
    CardYesNoField
  },
  props: { entity: Invoice },
  data() {
    return {
      invoiceTypes: {
        ND: this.$t('entities.invoice.types.ND'),
        NC: this.$t('entities.invoice.types.NC')
      }
    }
  },
  computed: {
    invoiceType() {
      return this.invoiceTypes[this.entity.type] || ''
    }
  }
}
</script>
