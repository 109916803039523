<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="6">
        <CardTextField
          :label="$t('entities.costCenter.name')"
          :value="entity.name"
          required
        />
      </v-col>
      <v-col cols="12" lg="3">
        <CardDateField
          :label="$t('common.fields.startDate')"
          :value="entity.startDate"
        />
      </v-col>
      <v-col cols="12" lg="3">
        <CardDateField
          :label="$t('common.fields.endDate')"
          :value="entity.endDate"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <CardTextarea
          :label="$t('entities.costCenter.notes')"
          :value="entity.notes"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            {{ $t('entities.costCenter.details') }}
          </v-card-title>
          <DetailsTable :items="entity.details" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { CardTextField, CardDateField } from '@/components/common/card'

import CostCenter from '@/domain/entities/CostCenter'
import CardTextarea from '../common/card/CardTextarea.vue'
import DetailsTable from './CostCenterDetailsSimpleTable.vue'

export default {
  name: 'CostCenterCard',
  components: {
    CardTextField,
    CardTextarea,
    CardDateField,
    DetailsTable
  },
  props: { entity: CostCenter }
}
</script>
