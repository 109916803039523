import Base from './Base'
import PaymentSource from '@/domain/entities/PaymentSource'

export default class extends Base {
  static fromResponseResource(resource) {
    const entity = this.fieldNamesFromResponse(resource)
    return new PaymentSource(entity)
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    if (!resource) return null

    const { id, descr } = resource
    const result = { id, name: descr }

    this.formatInternalValuesFromResponse(resource, result)
    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(entity, removeEmpty = false) {
    if (!entity) return null

    const { id, name } = entity
    const result = { id, descr: name }

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }
}
