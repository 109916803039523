import axios from '@/axios.js'

import CompanyFormatter from '../formatters/Company'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token) {
  try {
    const { data } = await axios.get(`/api/v1/companies/unknown`, {
      headers: { Authorization: `Bearer ${token}` }
    })

    const company = CompanyFormatter.formatPlaceholdersResource(data.company)

    return { error: false, company }
  } catch (e) {
    return exceptionToResponse(e)
  }
}
