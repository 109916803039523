<template>
  <v-expansion-panels inset>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <span class="text-h4">
          {{ $t('common.list.search.title') }}
        </span>
        <template v-slot:actions>
          <v-icon color="primary"> mdi-chevron-down </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form
          ref="form"
          @submit.prevent="submitted"
          class="list-searchbar mx-0"
        >
          <v-row dense>
            <v-col cols="2">
              <v-text-field
                :label="$t('entities.purchase.contractNumber')"
                v-model="filters.contractNumber"
                hide-details
                outlined
                dense
              />
            </v-col>
            <v-col cols="2">
              <v-text-field
                :label="$t('entities.purchase.offer')"
                v-model="filters.offer.name"
                hide-details
                outlined
                dense
              />
            </v-col>
            <v-col cols="2">
              <DateField
                :label="$t('entities.purchase.purchaseDateFilter.start')"
                v-model="filters.purchaseDate.start"
                hide-details
                outlined
                dense
              />
            </v-col>
            <v-col cols="2">
              <DateField
                :label="$t('entities.purchase.purchaseDateFilter.end')"
                v-model="filters.purchaseDate.end"
                hide-details
                outlined
                dense
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="2">
              <v-text-field
                :label="$t('entities.purchase.customer')"
                v-model="filters.customer"
                hide-details
                outlined
                dense
              />
            </v-col>
            <v-col cols="2">
              <v-text-field
                :label="$t('entities.purchase.beneficiary')"
                v-model="filters.beneficiary"
                hide-details
                outlined
                dense
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="auto">
              <v-btn color="primary" outlined @click="reset()">
                <v-icon left dark> mdi-undo-variant </v-icon>
                {{ $t('common.list.search.reset') }}
              </v-btn>
            </v-col>
            <v-col cols="2">
              <v-btn type="submit" color="primary" dark>
                <v-icon left dark> mdi-magnify </v-icon>
                {{ $t('common.list.search.submit') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import Purchase from '@/domain/entities/Purchase'
import { DateField } from '@/components/common/form'

export default {
  name: 'purchaseSearchBar',
  components: { DateField },
  data() {
    return { filters: this.getInitialData() }
  },
  methods: {
    getInitialData() {
      let filters = Purchase.new()
      filters.offer = {}
      filters.purchaseDate = {}
      return filters
    },
    reset() {
      this.filters = this.getInitialData()
    },
    submitted() {
      this.$emit('submit', this.filters)
    }
  }
}
</script>
