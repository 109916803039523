<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12" lg="9">
        <material-card
          icon="mdi-cart"
          icon-small
          :title="$t('common.sections.titles.offer')"
          color="primary"
        >
          <OfferCard :entity="entity" />

          <v-divider />

          <v-container>
            <OfferEditionsCard :entity="entity" />
          </v-container>

          <v-divider />

          <v-container>
            <OfferPricesCard ref="offerPricesCard" :entity="entity" />
          </v-container>
        </material-card>
      </v-col>
      <v-col cols="12" lg="3" order="first" order-lg="last">
        <OfferSummary :entity="entity">
          <v-divider />
          <v-divider />
          <SummaryCommandBanner
            icon="mdi-view-list"
            :text="$t('common.back-to-list')"
            @click="gotoList"
          />
          <SummaryCommandBanner
            icon="mdi-pencil"
            :text="$t('common.offer.edit')"
            @click="gotoEdit"
          />
        </OfferSummary>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsAShowPage from '@/mixins/actsAsAShowPage'

import OfferCard from '@/components/offers/OfferCard.vue'
import OfferEditionsCard from '@/components/offers/OfferEditionsCard.vue'
import OfferPricesCard from '@/components/offers/OfferPricesCard.vue'
import OfferSummary from '@/components/offers/OfferSummary.vue'
import SummaryCommandBanner from '@/components/common/SummaryCommandBanner.vue'

import Offer from '@/domain/entities/Offer'
import GetOffer from '@/domain/useCases/offers/GetOffer'

export default {
  name: 'EditOfferPage',
  mixins: [actsAsAShowPage],
  props: { id: String },
  components: {
    OfferCard,
    OfferPricesCard,
    OfferEditionsCard,
    OfferSummary,
    SummaryCommandBanner
  },
  data() {
    return {
      actsAsAShowPage: {
        resource: 'offers',
        entity: 'offer',
        get: GetOffer
      },
      entity: Offer.default()
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
