import validateUser from '@/lib/validateUser'
import Error from '@/lib/Error'
import editionDeleteAdapter from '@/adapters/backoffice/editions/delete'

export default class {
  user = null
  id = null

  constructor(user, id) {
    this.user = user
    this.id = id
  }

  async call() {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    if (!this.id) return new Error({ message: 'errors.resource.invalid' })
    return await editionDeleteAdapter(this.user.token, this.id)
  }
}
