import axios from '@/axios.js'

import PersonAndCompaniesFormatter from '../formatters/PersonAndCompanies'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, id) {
  try {
    const { data } = await axios.get(`/api/v1/userCompanies/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    })

    const supplier = PersonAndCompaniesFormatter.fromResponseResource(data)

    return { error: false, supplier }
  } catch (e) {
    return exceptionToResponse(e)
  }
}
