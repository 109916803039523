import axios from '@/axios.js'

import InvoiceFormatter from '../formatters/Invoice'
import resourceErrorsToResponse from '../formatters/resourceErrorsToResponse'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, id, data) {
  const payload = { invoPurchase: InvoiceFormatter.toRequestResource(data) }

  if (!payload || !id) {
    return {
      error: true,
      errorData: { message: 'errors.resource.invalid' }
    }
  }

  try {
    const { data } = await axios.put(`/api/v1/invoPurchases/${id}`, payload, {
      headers: { Authorization: `Bearer ${token}` }
    })

    const invoice = InvoiceFormatter.fromResponseResource(data.invoPurchase)
    return { error: false, invoice }
  } catch (e) {
    return (
      resourceErrorsToResponse(e, InvoiceFormatter) || exceptionToResponse(e)
    )
  }
}
