import axios from '@/axios.js'

import ListOptionsFormatter from '../formatters/ListOptions'
import ContractFormatter from '../formatters/Contract'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, listOptions) {
  try {
    const options = ListOptionsFormatter.toRequestOptions(
      listOptions,
      ContractFormatter
    )
    const { data } = await axios.get('/api/v1/contracts', {
      params: options,
      headers: { Authorization: `Bearer ${token}` }
    })

    const { results, totalItems, page } = data
    const contracts = ContractFormatter.fromResponseResourcesList(results)

    return { error: false, contracts, totalItems, page }
  } catch (e) {
    return exceptionToResponse(e)
  }
}
