<template>
  <div>
    <v-simple-table class="lecturersTable">
      <template v-slot:default>
        <tbody>
          <tr>
            <td colspan="6">
              <v-btn
                class="mb-2"
                small
                left
                color="primary"
                @click="addNewItem()"
                :alt="label.add"
                :title="label.add"
              >
                <v-icon left dark>mdi-plus</v-icon>
                {{ label.add }}
              </v-btn>
            </td>
          </tr>
          <tr v-for="(item, index) in items" :key="index">
            <td>
              <SupplierSelect
                :label="$t('entities.edition.lecturer.lecturer')"
                v-model="item.lecturer"
                :error="error(index, 'lecturer')"
                required
              />
            </td>
            <td class="value">
              <TextField
                type="currency"
                :label="$t('entities.edition.lecturer.fee')"
                v-model="item.fee"
                required
                :error="error(index, 'fee')"
              />
            </td>
            <td class="actions">
              <v-icon
                color="primary"
                @click="addNewItemBelow(index)"
                :alt="label.addBelow"
                :title="label.addBelow"
                >mdi-plus</v-icon
              >
              <v-icon
                v-if="index > 0"
                color="primary"
                @click="moveItemUp(index)"
                :alt="label.moveUp"
                :title="label.moveUp"
                >mdi-arrow-up</v-icon
              >
              <v-icon
                v-if="index < items.length - 1"
                color="primary"
                @click="moveItemDown(index)"
                :alt="label.moveDown"
                :title="label.moveDown"
                >mdi-arrow-down</v-icon
              >
            </td>
            <td class="delete">
              <v-icon
                color="primary"
                @click="removeItemAt(index)"
                :alt="label.remove"
                :title="label.remove"
                >mdi-delete</v-icon
              >
            </td>
            <td></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-title>
      {{ $t('common.product.productList') }}
    </v-card-title>
    <v-data-table
      :headers="priceListHeaders"
      :items="priceList"
      :items-per-page="-1"
      :no-data-text="$t('common.list.no-data')"
    >
      <template v-slot:[`item.type`]="{ item }">
        {{ $t(`entities.personCompany.positions.${item.type}`) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { TextField } from '@/components/common/form'
import SupplierSelect from '../personCompanies/SupplierSelect.vue'
import { isArray, dig } from '@/lib/Utils'
import EditionLecturer from '@/domain/entities/Lecturer'
import { get } from 'vuex-pathify'
import GetProductPriceList from '@/domain/useCases/products/GetProductPriceList'
import Edition from '@/domain/entities/Edition'

export default {
  name: 'EditionLecturersEditableTable',
  components: { TextField, SupplierSelect },
  props: { value: Array, errors: Object, entity: Edition },
  data() {
    return {
      items: this.value,
      priceList: [],
      priceListHeaders: [
        { text: this.$t('entities.edition.lecturer.type'), value: 'type' },
        { text: this.$t('entities.edition.place'), value: 'location' },
        { text: this.$t('entities.edition.lecturer.fee'), value: 'fee' },
        {
          text: this.$t('entities.edition.lecturer.hourlyFee'),
          value: 'hourlyFee'
        }
      ]
    }
  },
  computed: {
    user: get('user'),
    label() {
      return {
        add: this.$t('common.edition.lecturer.add'),
        moveUp: this.$t('common.edition.lecturer.moveUp'),
        moveDown: this.$t('common.edition.lecturer.moveDown'),
        remove: this.$t('common.edition.lecturer.remove')
      }
    }
  },
  methods: {
    formatLecturer(lecturer) {
      const name = dig(lecturer, 'person', 'name')
      const surname = dig(lecturer, 'person', 'surname')
      const company = dig(lecturer, 'company', 'invoiceName')
      return `${name} ${surname} (${company})`
    },
    error(index, field) {
      if (isArray(this.errors) && this.errors[index])
        return this.error[index][field] || ''
    },
    addNewItem() {
      this.addNewItemBelow(-1)
    },
    addNewItemBelow(index) {
      this.items.splice(index + 1, 0, EditionLecturer.default())
    },
    moveItemUp(index) {
      if (index > 0)
        this.items.splice(
          index - 1,
          2,
          this.items[index],
          this.items[index - 1]
        )
    },
    moveItemDown(index) {
      if (index < this.items.length - 1)
        this.items.splice(index, 2, this.items[index + 1], this.items[index])
    },
    removeItemAt(index) {
      this.items.splice(index, 1)
    },
    retrievePriceList() {
      if (!this.entity.genericProduct) {
        this.priceList = []
      } else {
        new GetProductPriceList(this.user)
          .call(this.entity.genericProduct.id)
          .then(result => {
            if (!result.error) this.priceList = result.productPriceList
          })
      }
    }
  },
  watch: {
    value() {
      if (this.value) this.items = this.value
    },
    'entity.genericProduct'() {
      this.retrievePriceList()
    }
  }
}
</script>

<style scoped>
.lecturersTable td {
  padding: 0.9em 0.4em 0.9em 0.4em !important;
}
td.value {
  width: 120px !important;
}
</style>
