import validateUser from '@/lib/validateUser'
import customerGetAdapter from '@/adapters/backoffice/customers/get'
import coachingContractsGetAdapter from '@/adapters/backoffice/customers/getCoachingContracts'
export default class {
  user = null

  constructor(user) {
    this.user = user
  }

  async call(id) {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    const userToken = this.user.token
    const customerData = await customerGetAdapter(userToken, id)

    if (customerData && !customerData.error) {
      const coachingContractsData = await coachingContractsGetAdapter(
        userToken,
        customerData.customer.person.id
      )
      customerData.customer.person.coachingContracts =
        coachingContractsData.contracts
    }

    return customerData
  }
}
