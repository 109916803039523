import Base from './Base'
import PersonFormatter from './Person'
import CompanyFormatter from './Company'
import PersonCompanyFormatter from './PersonCompany'
import PersonAndCompanies from '@/domain/entities/PersonAndCompanies'

import { isString, isEmpty } from '@/lib/Utils'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource || !resource.user) return null

    const person = PersonFormatter.fromResponseResource(resource.user)
    const personCompanies = PersonCompanyFormatter.fromResponseResourcesList(
      resource.usersCompanies
    )

    return new PersonAndCompanies({ person, personCompanies })
  }

  static toRequestResource(entity) {
    if (!entity) return null
    return {
      user: PersonFormatter.toRequestResource(entity.person),
      usersCompanies: PersonCompanyFormatter.toRequestResourcesList(
        entity.personCompanies
      )
    }
  }

  /// TODO: refactor
  static fromResponseErrors(errors) {
    if (!errors) return { extraMessages: [], fields: {} }

    const extraMessages = []
    if (errors['customer_supplier'])
      extraMessages.push('common.companies.required')

    if (isString(errors.userCompanies)) {
      return {
        fields: {},
        extraMessages: [
          `errors.userCompanies.userCompanies.${errors.userCompanies}`
        ]
      }
    }

    const {
      fields: personFields,
      extraMessages: personExtraMessages
    } = PersonFormatter.fromResponseErrors(errors.user)
    const {
      fields: personCompaniesFieldsArray,
      extraMessages: personCompaniesExtraMessages
    } = PersonCompanyFormatter.fromResponseErrorsList(errors.userCompanies)

    return {
      fields: {
        person: personFields,
        personCompanies: personCompaniesFieldsArray
      },
      extraMessages: [
        ...extraMessages,
        ...personExtraMessages,
        ...personCompaniesExtraMessages
      ]
    }
  }

  static formatFilterOptions(filter) {
    if (isEmpty(filter)) return null

    const result = {}

    if (!isEmpty(filter.person))
      result.user = PersonFormatter.formatFilterOptions(filter.person)

    if (!isEmpty(filter.personCompanies))
      result.userCompanies = PersonCompanyFormatter.formatFilterOptions(
        filter.personCompanies
      )

    return this.removeEmptyAttributes(result)
  }

  static formatPlaceholdersResource(resource) {
    if (isEmpty(resource)) return null
    const result = {}

    if (resource.user)
      result.person = PersonFormatter.formatPlaceholdersResource(resource.user)
    if (resource.company)
      result.personCompanies = {
        company: CompanyFormatter.formatPlaceholdersResource(resource.company)
      }

    return !isEmpty(result) ? result : null
  }
}
