<template>
  <div>
    <v-card>
      <v-card-title> {{ $t('entities.offer.editions') }} </v-card-title>
      <v-card-text>
        <v-data-table
          v-if="offerEditions.length || offerEditionsLoading"
          show-select
          disable-sort
          v-model="offerEditionsSelected"
          :headers="headers"
          :items="offerEditions"
          :loading="offerEditionsLoading"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar
              v-if="offerEditionsSelected.length"
              flat
              color="info"
              class="mx-auto"
              dark
            >
              <strong>{{
                $t('common.list.n-selected', {
                  n: offerEditionsSelected.length
                })
              }}</strong>
              <v-spacer />
              <v-btn @click="unlinkEditions()" color="error">
                <v-icon small left dark>mdi-delete</v-icon>
                {{
                  $t('common.offer.editions.unlink', {
                    n: offerEditionsSelected.length
                  })
                }}
              </v-btn>
            </v-toolbar>
            <v-toolbar v-else flat class="mx-auto"> </v-toolbar>
          </template>

          <template v-slot:[`item.genericProductType`]="{ item }">
            {{ translateProductType(item.genericProductType) }}
          </template>

          <template v-slot:[`item.price`]="{ item }">
            {{ item.price | append('€') }}
          </template>

          <template v-slot:[`item.fromDate`]="{ item }">
            {{ item.fromDate | date }}
          </template>

          <template v-slot:[`item.toDate`]="{ item }">
            {{ item.toDate | date }}
          </template>
        </v-data-table>

        <p v-else class="text--primary">
          <material-alert color="warning" icon="mdi-alert-outline">
            {{ $t('common.offer.editions.no-editions') }}
          </material-alert>
        </p>
      </v-card-text>
    </v-card>

    <v-card class="mt-2">
      <v-card-title>
        {{ $t('common.offer.editions.add-title') }}
      </v-card-title>
      <v-card-text>
        <v-data-table
          show-select
          v-model="editionsSelected"
          :headers="headers"
          :items="editions"
          :options.sync="editionsOptions"
          :server-items-length="editionsTotalItems"
          :loading="editionsLoading"
          :no-data-text="$t('common.list.no-data')"
        >
          <template v-slot:top>
            <v-toolbar
              v-if="editionsSelected.length"
              color="info"
              dark
              class="mx-0"
            >
              <strong>{{
                $t('common.list.n-selected', { n: editionsSelected.length })
              }}</strong>
              <v-spacer />
              <v-btn right @click="linkEditions()" color="success">
                <v-icon small left dark>mdi-plus</v-icon>
                {{
                  $t('common.offer.editions.link', {
                    n: editionsSelected.length
                  })
                }}
              </v-btn>
            </v-toolbar>
          </template>

          <template v-slot:[`item.genericProductType`]="{ item }">
            {{ translateProductType(item.genericProductType) }}
          </template>

          <template v-slot:[`item.fromDate`]="{ item }">
            {{ item.fromDate | date }}
          </template>

          <template v-slot:[`item.toDate`]="{ item }">
            {{ item.toDate | date }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { get } from 'vuex-pathify'

import Offer from '@/domain/entities/Offer'
import GetEditions from '@/domain/useCases/editions/GetEditions'

export default {
  name: 'OfferEditionsForm',
  props: {
    value: Offer,
    errors: Object
  },
  data() {
    return {
      offerEditions: this.value.editions,
      offerEditionsSelected: [],
      offerEditionsLoading: false,

      editions: [],
      editionsTotalItems: 0,
      editionsOptions: { itemsPerPage: 5 },
      editionsLoading: false,
      editionsSelected: [],
      search: {}
    }
  },
  computed: {
    user: get('user'),
    offer() {
      return this.value
    },
    headers() {
      return [
        {
          text: this.$t('entities.edition.genericProduct'),
          value: 'genericProduct.name'
        },
        {
          text: this.$t('entities.edition.genericProductType'),
          value: 'genericProductType'
        },
        { text: this.$t('entities.edition.price'), value: 'price' },
        { text: this.$t('entities.edition.fromDate'), value: 'fromDate' },
        { text: this.$t('entities.edition.toDate'), value: 'toDate' }
      ]
    }
  },
  methods: {
    validate() {
      return this.offerEditions && this.offerEditions.length
    },
    translateProductType(type) {
      if (!type) return ''
      return this.$t(`entities.edition.genericProductTypes.${type}`)
    },
    reset() {
      this.offerEditionsSelected = []
      this.editionsSelected = []
    },
    refresh() {
      this.reset()
      this.editionsLoading = true

      const options = Object.assign({}, this.editionsOptions, {
        except: this.offerEditions.map(edition => edition.id),
        search: this.search
      })

      new GetEditions(this.user).call(options).then(result => {
        if (result.error) {
          console.error(result.errorData.message)
        } else {
          this.editions = result.editions || []
          this.editionsTotalItems = result.totalItems
          this.editionsLoading = false
          this.offerEditionsLoading = false
        }
      })
    },
    linkEditions() {
      if (this.editionsSelected.length) {
        this.offerEditions = [...this.offerEditions, ...this.editionsSelected]
        this.refresh()
      }
    },
    unlinkEditions() {
      if (this.offerEditionsSelected.length) {
        this.offerEditionsLoading = true
        this.editionsLoading = true

        this.offerEditions = this.offerEditions.filter(
          edition =>
            !this.offerEditionsSelected.find(
              removed => removed.id === edition.id
            )
        )
      }
      this.refresh()
    }
  },
  watch: {
    value() {
      if (this.value) this.offerEditions = this.value.editions
    },
    editionsOptions() {
      this.refresh()
    },
    offerEditions() {
      const offer = new Offer(this.offer)
      offer.editions = this.offerEditions
      offer.recalculateEditionTotals()
      this.$emit('input', offer)
      this.refresh()
    }
  }
}
</script>
