export default {
  'invalid-values': 'Valori non validi nella risorsa',
  'not-found': 'Risorsa non trovata',
  unknown: 'Errore di sistema imprevisto',

  auth: {
    forbidden: 'Accesso negato',
    'invalid-login': 'E-Mail o Password errata',
    unauthorized: 'Sessione scaduta, per favore identificarsi nuovamente'
  },
  connection: {
    'backend-error': 'Errore nella richiesta al sistema',
    'backend-unreachable': 'Impossibile connettersi al sistema',
    error: 'Errore di sistema imprevisto',
    'invalid-request': 'Errore nella richiesta al sistema'
  },
  resource: {
    invalid: 'Risorsa non valida, impossibile salvare',
    existing: 'Questa risorsa è già salvata, impossibile salvare come nuova'
  },
  field: {
    inUse: 'Già presente nel sistema',
    between: { '1-12': 'Richiesto numero mesi tra 1 e 12' },
    currency: 'Richiesto un importo valido',
    email: 'Indirizzo E-mail non valido',
    'positive-currency': 'Richiesto un importo positivo',
    date: 'Richiesta una data valida in formato GG/MM/YYYY',
    invalid: 'Valore non valido',
    'invalid-money': 'Valore non valido',
    number: 'Richiesto un numero',
    'greater-equal-than': 'Il numero deve essere maggiore o uguale a {min}',
    length: 'Richiesti {length} caratteri o numeri',
    integer: 'Richiesto un numero',
    'offer-net-price-different-from-expected': 'Le somme non tornano...',
    'offer-net-price-over-base-price':
      'Prezzo offerta più alto del costo originale',
    'out-of-range': '',
    percent: 'Richiesto numero tra 0 e 100',
    'positive-integer': 'Richiesto un numero positivo',
    required: 'Valore richiesto',
    'required-or-fiscalCode': 'Richiesto almeno questo valore o Codice fiscale',
    'required-or-vatCode': 'Richiesto almeno questo valore o Partita IVA'
  },
  avatar: {
    image: "Richiesto file d'immagine",
    size: "L'immagine deve essere più piccola di {size}MB"
  },
  file: {
    extension: 'Il file è di un tipo non consentito',
    size: 'Dimesioni del file troppo grandi'
  },
  company: {
    'vat-or-fiscal-code-required':
      'Rischiesto almeno un valore tra codice fiscale e IVA'
  },
  product: {
    'max-and-min-attendees-not-congruent':
      "Il minimo e il massimo numero di partecipanti devono essere l'uno minore dell'altro"
  },
  contract: {
    details: { required: 'Un contratto deve avere almeno un dettaglio' }
  },
  customerContract: {
    details: {
      required: "Richiesti dettagli per l'istanza di contratto"
    }
  },
  offer: {
    'editions-required': 'Richiesta almeno una edizione',
    contractOverride: {
      'negative-price': 'Questo sconto causa un prezzo negativo'
    }
  },
  invoice: {
    'no-details': 'Nessuna informazione di pagamento, impossibile proseguire',
    'invoice-sent-to-SDI': 'Fattura già inviata allo SDI',
    'cannot-amend': 'Impossibile stornare',
    'cannot-edit': 'Impossibile modificare',
    purchase: {
      'already-invoiced': ({ named }) => {
        return `L'acquisto n. ${named('i')} è già presente in una fattura`
      },
      'not-the-same-customer': ({ named }) => {
        return `L'acquisto n. ${named('i')} ha un cliente differente`
      },
      'not-same-payment-source': ({ named }) => {
        return `L'acquisto n. ${named('i')} \
                ha una fonte di pagamento differente`
      }
    }
  },
  costCenter: {
    details: {
      required: 'Sotto centro di costo richiesto'
    }
  },
  jobDetRel: {
    details: {
      required: 'Sotto commessa richiesta'
    }
  }
}
