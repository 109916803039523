import axios from '@/axios.js'
import exceptionToResponse from '../../formatters/exceptionToResponse'

export default async function(token, id, photo) {
  if (!photo) return null

  const payload = photo.formData

  try {
    const { data } = await axios.post(`/api/v1/users/${id}/photo`, payload, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return { error: false, photo: data.path }
  } catch (e) {
    return exceptionToResponse(e)
  }
}
