<template>
  <v-alert
    cols="9"
    dismissible
    v-model="show"
    :type="type"
    class="text-left mx-3"
  >
    <span v-html="_message" />
  </v-alert>
</template>

<script>
import { isArray } from '@/lib/Utils'
import { sync } from 'vuex-pathify'

export default {
  name: 'FlashAlert',
  data: function() {
    return {
      show: false,
      type: null,
      message: '',
      extraMessages: [],
      showOnNextPage: false
    }
  },
  computed: {
    flash: sync('app/flash'),
    _message() {
      const messages = [this.message]
      this.extraMessages.forEach(m => {
        if (isArray(m)) messages.push(this.$t(...m))
        else messages.push(this.$t(m))
      })
      return messages.join('<br>')
    }
  },
  methods: {
    showAlertAndClearFlash() {
      if (this.flash) {
        this.type = this.flash.type
        this.message = this.flash.rawMessage || this.$t(this.flash.message)
        this.extraMessages = this.flash.extraMessages
        this.showOnNextPage = this.flash.showOnNextPage
        this.flash = null
        this.show = true
      }
    },
    clearAlert() {
      this.show = false
      this.type = null
      this.message = ''
      this.extraMessages = []
      this.showOnNextPage = false
    }
  },
  watch: {
    flash() {
      this.showAlertAndClearFlash()
    },
    $route() {
      if (this.show && !this.showOnNextPage) this.clearAlert()
      else this.showOnNextPage = false
    }
  },
  mounted() {
    this.showAlertAndClearFlash()
  }
}
</script>
