import axios from '@/axios.js'
import Error from '@/lib/Error'

import createContractAdapter from '../contracts/create'

import OfferFormatter from '../formatters/Offer'
import resourceErrorsToResponse from '../formatters/resourceErrorsToResponse'
import exceptionToResponse from '../formatters/exceptionToResponse'

/// TODO: generalize for multiple editions with products
export default async function(token, offer) {
  let contractId = offer.editions[0].genericProduct.id

  // Create Contract if it does not already exists
  if (!contractId) {
    const contractResponse = await createContractAdapter(
      token,
      offer.edition.genericProduct
    )

    // convert a contract error in an offer error
    if (contractResponse.error) {
      const { message, extraMessages, fields } = contractResponse
      const error = Error({ message, extraMessages })
      if (fields) {
        error.fields = {
          offer: { editions: [{ genericProduct: fields }] }
        }
      }
      return error
    } else contractId = contractResponse.id
  }

  // Create Offer with new edition, linked to existing Contract, and with new overrides
  // TODO: use ContractFormatter.toResourceIdObject()
  offer.editions[0].genericProduct = { id: contractId }
  const payload = { offer: OfferFormatter.toRequestResource(offer) }

  try {
    const headers = { Authorization: `Bearer ${token}` }
    const { data } = await axios.post('/api/v1/offers', payload, { headers })

    const offer = OfferFormatter.fromResponseResource(data.offer)
    return { error: false, offer }
  } catch (e) {
    return resourceErrorsToResponse(e, OfferFormatter) || exceptionToResponse(e)
  }
}
