<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="3">
        <JobSelect
          :jobs="jobs"
          v-model="selectedJob"
          :error="errors | dig('jobAndDetail', 'job')"
          @input="onSelectedJob"
          required
        />
      </v-col>
      <v-col cols="12" lg="3">
        <JobAndDetailSelect
          :job="selectedJob"
          v-model="entity.jobAndDetail"
          :error="errors | dig('jobAndDetail', 'jobDetail')"
          required
        />
      </v-col>
      <v-col cols="12" lg="2">
        <TextField
          type="percentage"
          :label="$t('entities.invoice.job.percentage')"
          v-model="entity.percentage"
          :error="errors.percentage"
          @input="calculateAmount"
          required
        />
      </v-col>
      <v-col cols="12" lg="2">
        <TextField
          type="currency"
          :label="$t('entities.invoice.job.amount')"
          v-model="entity.amount"
          :error="errors.amount"
          @input="calculatePercentage"
          required
        />
      </v-col>
      <v-col cols="auto">
        <v-btn icon @click="$emit('remove', $vnode.key)">
          <v-icon
            color="primary"
            :alt="$t('form.jobs.remove.title')"
            :title="$t('form.jobs.remove.title')"
            >mdi-minus</v-icon
          >
        </v-btn>
      </v-col>
      <v-col cols="12" lg="12">
        <Textarea
          :label="$t('entities.invoice.notes')"
          v-model="entity.notes"
          :error="errors.notes"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsAForm from '@/mixins/actsAsAForm'
import { TextField, Textarea } from '@/components/common/form'
import JobSelect from '@/components/jobs/JobSelect.vue'
import JobAndDetailSelect from '@/components/jobs/JobAndDetailSelect.vue'
import InvoiceJobDetail from '@/domain/entities/InvoiceJobDetail'
import Decimal from '@/lib/Decimal'
import Invoice from '@/domain/entities/Invoice'
import JobAndDetail from '@/domain/entities/JobAndDetail'
import { dig } from '@/lib/Utils'

export default {
  name: 'InvoiceJobForm',
  mixins: [actsAsAForm],
  components: {
    TextField,
    Textarea,
    JobSelect,
    JobAndDetailSelect
  },
  props: {
    value: InvoiceJobDetail,
    invoice: { type: Invoice, required: true },
    jobs: Array,
    errors: Object
  },
  data() {
    return {
      selectedJob: null
    }
  },
  created() {
    this.selectedJob = dig(this.value, 'jobAndDetail', 'job')
  },
  methods: {
    calculateAmount(percentage) {
      this.entity.amount = Decimal.fromUI(
        this.invoice.amount || Decimal.zeroForUI()
      )
        .percentage(Decimal.fromUI(percentage) || Decimal.zero())
        .forUI()
    },
    calculatePercentage(amount) {
      this.entity.percentage = Decimal.fromUI(amount || Decimal.zeroForUI())
        .multiply(Decimal.fromBE(100))
        .divide(Decimal.fromUI(this.invoice.amount) || Decimal.zero())
        .forUI()
    },
    onSelectedJob(job) {
      this.entity.jobAndDetail = new JobAndDetail({ job: job })
    }
  }
}
</script>
