<template>
  <v-expansion-panels inset>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <span class="text-h4">
          {{ $t('common.list.search.title') }}
        </span>
        <template v-slot:actions>
          <v-icon color="primary"> mdi-chevron-down </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form
          ref="form"
          @submit.prevent="submitted"
          class="list-searchbar mx-0"
        >
          <v-row dense>
            <v-col cols="3">
              <v-text-field
                :label="$t('entities.invoice.code')"
                v-model="filters.code"
                hide-details
                outlined
                dense
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                :label="$t('entities.invoice.version')"
                v-model="filters.version"
                hide-details
                outlined
                dense
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                :label="$t('entities.company.invoiceName')"
                v-model="filters.name"
                hide-details
                outlined
                dense
              />
            </v-col>
            <v-col cols="3">
              <InvoiceBranchSelect outlined v-model="filters.branch" />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="auto">
              <v-btn color="primary" outlined @click="reset()">
                <v-icon left dark> mdi-undo-variant </v-icon>
                {{ $t('common.list.search.reset') }}
              </v-btn>
            </v-col>
            <v-col cols="2">
              <v-btn type="submit" color="primary" dark>
                <v-icon left dark> mdi-magnify </v-icon>
                {{ $t('common.list.search.submit') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import Invoice from '@/domain/entities/Invoice'
import InvoiceBranchSelect from '@/components/invoices/InvoiceBranchSelect'

export default {
  name: 'invoiceSearchBar',
  components: { InvoiceBranchSelect },
  data() {
    return { filters: Invoice.new() }
  },
  methods: {
    reset() {
      this.filters = Invoice.new()
    },
    submitted() {
      this.$emit('submit', this.filters)
    }
  }
}
</script>
