/**
 * Models a notification message, uses vuex to store itself,
 *  so it can be used as the basis for a flash system.
 */
export default class Alert {
  _types = ['success', 'info', 'warning', 'error']
  type = 'info'
  rawMessage = null /// this will take priority over message, and should contain an already-translated String
  message = ''
  extraMessages = []
  showOnNextPage = false

  static load(vue) {
    return vue.$store.get('app/flash')
  }

  static show(vue, alert) {
    new this(alert).save(vue)
  }

  constructor({
    type = 'info',
    rawMessage = null,
    message = '',
    extraMessages = [],
    showOnNextPage = false
  } = {}) {
    this.type = type
    this.message = message
    this.rawMessage = rawMessage
    this.extraMessages = extraMessages
    this.showOnNextPage = showOnNextPage
  }

  save(vue) {
    vue.$store.set('app/flash', this)
  }
}
