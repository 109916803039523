<template>
  <v-simple-table class="table">
    <template v-slot:default>
      <tbody>
        <tr>
          <td colspan="6">
            <v-btn
              class="mb-2"
              small
              left
              color="primary"
              @click="addNewItem()"
              :alt="label.add"
              :title="label.add"
            >
              <v-icon left dark>mdi-plus</v-icon>
              {{ label.add }}
            </v-btn>
          </td>
        </tr>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <TextField
              :label="$t('entities.costCenter.detail.name')"
              v-model="item.name"
              required
              :error="error(index, 'name')"
            />
          </td>
          <!--
          <td class="actions">
            <v-icon
              color="primary"
              @click="addNewItemBelow(index)"
              :alt="label.addBelow"
              :title="label.addBelow"
              >mdi-plus</v-icon
            >
            <v-icon
              v-if="index > 0"
              color="primary"
              @click="moveItemUp(index)"
              :alt="label.moveUp"
              :title="label.moveUp"
              >mdi-arrow-up</v-icon
            >
            <v-icon
              v-if="index < items.length - 1"
              color="primary"
              @click="moveItemDown(index)"
              :alt="label.moveDown"
              :title="label.moveDown"
              >mdi-arrow-down</v-icon
            >
          </td>
          <td class="delete">
            <v-icon
              color="primary"
              @click="removeItemAt(index)"
              :alt="label.remove"
              :title="label.remove"
              >mdi-delete</v-icon
            >
          </td>
          -->
          <td></td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { TextField } from '@/components/common/form'
import { isArray } from '@/lib/Utils'
import CostCenterDetail from '@/domain/entities/CostCenterDetail'

export default {
  name: 'CostCenterDetailsEditableTable',
  components: { TextField },
  props: { value: Array, errors: Object },
  data() {
    return { items: this.value }
  },
  computed: {
    label() {
      return {
        add: this.$t('common.costCenter.detail.add'),
        moveUp: this.$t('common.costCenter.detail.moveUp'),
        moveDown: this.$t('common.costCenter.detail.moveDown'),
        remove: this.$t('common.costCenter.detail.remove')
      }
    }
  },
  methods: {
    error(index, field) {
      if (isArray(this.errors) && this.errors[index])
        return this.error[index][field] || ''
    },
    addNewItem() {
      this.addNewItemBelow(-1)
    },
    addNewItemBelow(index) {
      this.items.splice(index + 1, 0, CostCenterDetail.default())
    },
    moveItemUp(index) {
      if (index > 0)
        this.items.splice(
          index - 1,
          2,
          this.items[index],
          this.items[index - 1]
        )
    },
    moveItemDown(index) {
      if (index < this.items.length - 1)
        this.items.splice(index, 2, this.items[index + 1], this.items[index])
    },
    removeItemAt(index) {
      this.items.splice(index, 1)
    }
  },
  watch: {
    value() {
      if (this.value) this.items = this.value
    }
  }
}
</script>

<style scoped>
td {
  padding: 0.9em 0.4em 0.9em 0.4em !important;
}
td.value {
  width: 90px !important;
}
td.actions,
td.checkbox {
  text-align: center;
  width: 120px !important;
}
td.delete {
  width: 20px !important;
}
</style>
