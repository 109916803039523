<template>
  <CardTextField :label="label" :value="value" textarea />
</template>

<script>
import CardTextField from './CardTextField.vue'

export default {
  name: 'CardTextarea',
  components: { CardTextField },
  props: ['label', 'value']
}
</script>

<style scoped>
.container {
  margin-top: 8px !important;
  position: relative;
  display: block;
  padding: 12px 4px 4px 8px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.38);
  border-radius: 4px;
}

.label {
  display: block;
  position: absolute;
  top: -12px;
  padding: 0 2px 0 2px;
  background-color: transparent;
  color: #000;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.value {
  color: #000;
  font-size: 16px;
  min-height: 24px;
}
</style>
