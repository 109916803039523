<template>
  <CardTextField :label="label" :value="_value" />
</template>

<script>
import CardTextField from './CardTextField'

export default {
  name: 'CardYesNoField',
  components: { CardTextField },
  props: { label: String, value: Boolean },
  computed: {
    _value() {
      return this.value ? this.$t('common.yes') : this.$t('common.no')
    }
  }
}
</script>
