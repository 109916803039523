<template>
  <v-autocomplete
    :class="_class"
    :label="label"
    v-model="item"
    :items="items"
    return-object
    item-value="id"
    :search-input.sync="searchword"
    clearable
    no-filter
    :no-data-text="noDataText"
    :loading="searchword"
    :rules="_rules"
    :required="required"
    :error-messages="error"
    dense
    :hint="hint"
    @input="onChange"
  >
    <template v-slot:selection="data">
      {{ data.item | dig('name') }}
    </template>

    <template v-slot:item="data">
      <v-list-item-avatar>
        <v-icon>mdi-cart</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ data.item | dig('name') }}
        </v-list-item-title>
        <v-list-item-subtitle> </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import actsAsAnAutocomplete from '@/mixins/actsAsAnAutocomplete'

import GetOffers from '@/domain/useCases/offers/GetOffers'

export default {
  name: 'OfferSelect',
  mixins: [actsAsAnAutocomplete],
  data() {
    return {
      actsAsAnAutocomplete: {
        resource: 'offers',
        get: GetOffers
      }
    }
  }
}
</script>
