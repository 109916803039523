/**
 * Used in views that save a new entity.
 *
 * Requires:
 *  - data.entity:            the entity that will be saved;
 *
 *  - data.actsAsACreatePage: options for this mixin:
 *    - entity:   String, name used for a single entity in the adapters (e.g. as a key for a result object);
 *    - resource: String, name used for the resource in the adapters (e.g. when building a request URI);
 *    - create:   Function, function to use when trying to save the resource;
 *    - validate: Array, optional, array of ref names for the forms composing this view;
 *    - getId:    Function, optional, a function that accepts an entity and returns it's id value;
 *    - noRedirect: if true do not redirect to the created entity. Usefull for saving entity while creating another one
 *
 * Provides:
 *  - data.errors:  where errors from use-cases will be stored;
 *
 *  - this.user: the currently logged-in User from the vuex store;
 *
 *  - this.save():  saves the entity and either loads the view page for entity,
 *                  or populates data.errors and notifications;
 *
 *  - this.leave(): loads the show page for this entity;
 */
import { get } from 'vuex-pathify'
import handlesErrors from './handlesErrors'

export default {
  mixins: [handlesErrors],
  data() {
    return { errors: {} }
  },
  computed: { user: get('user') },
  methods: {
    option(name) {
      return this.actsAsACreatePage[name]
    },
    validate() {
      const forms = this.option('validate') || ['form']
      if (!forms) return true

      return forms.reduce((finalVerdict, validatorRefName) => {
        const ref = this.$refs[validatorRefName]
        if (ref) finalVerdict = ref.validate() && finalVerdict
        return finalVerdict
      }, true)
    },
    save() {
      if (!this.validate()) return Promise.reject()
      const useCase = this.option('create')
      const resource = this.option('resource')

      return new useCase(this.user, this.entity).call().then(result => {
        if (result.error) {
          this.errors = this.$_handleError(result)
          return Promise.reject(this.errors)
        } else {
          const entity = result[this.option('entity')]

          const id = this.option('getId')
            ? this.option('getId')(entity)
            : entity.id

          if (!this.option('noRedirect'))
            this.$router.push(`/${resource}/${id}`)
          else return Promise.resolve(id)
        }
      })
    },
    leave() {
      this.$router.push(`/${this.option('resource')}`)
    }
  }
}
