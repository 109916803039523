<template>
  <v-form ref="form" @submit.prevent>
    <v-container>
      <v-row>
        <v-col cols="12" lg="6">
          <TextField
            :label="$t('entities.job.detail.name')"
            v-model="entity.detail.name"
            required
            :error="errors | dig('detail', 'name')"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import actsAsAForm from '@/mixins/actsAsAForm'
import { TextField } from '@/components/common/form'
import JobAndDetail from '@/domain/entities/JobAndDetail'

export default {
  name: 'JobDetailForm',
  mixins: [actsAsAForm],
  components: { TextField },
  props: { value: JobAndDetail, errors: Object }
}
</script>

<style scoped>
td {
  padding: 0.9em 0.4em 0.9em 0.4em !important;
}
td.value {
  width: 90px !important;
}
td.actions,
td.checkbox {
  text-align: center;
  width: 120px !important;
}
td.delete {
  width: 20px !important;
}
</style>
