import Base from './Base'
import Lecturer from '@/domain/entities/Lecturer'
import PersonAndCompanyFormatter from './PersonAndCompany'
import Decimal from '@/lib/Decimal'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null

    const entity = this.fieldNamesFromResponse(resource)

    entity.lecturer = PersonAndCompanyFormatter.fromResponseResource(
      entity.lecturer
    )
    entity.fee = Decimal.fromBE(entity.fee).forUI()

    return new Lecturer(entity)
  }

  static toRequestResource(entity) {
    if (!entity) return null

    const resource = this.fieldNamesForRequest(entity)

    resource.lecturer = PersonAndCompanyFormatter.toRequestResource(
      resource.lecturer
    )
    resource.fee = Decimal.fromUI(resource.fee).forBE()
    return resource
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    if (!resource) return null

    const { id, lecturer, fee } = resource

    const result = {
      id: id,
      lecturer: lecturer,
      fee: fee
    }

    this.formatInternalValuesFromResponse(resource, result)

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(entity, removeEmpty = false) {
    if (!entity) return null

    const { id, lecturer, fee } = entity

    const result = {
      id: id,
      lecturer: lecturer,
      fee: fee
    }

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }
}
