import Base from './Base'
import Payment from '@/domain/entities/Payment'
import Decimal from '@/lib/Decimal'
import { isEmpty } from '@/lib/Utils'

import PersonAndCompanyFormatter from './PersonAndCompany'
import BankAccountFormatter from './BankAccount'
import PaymentCardFormatter from './PaymentCard'
import PaymentCostCenterDetailFormatter from './PaymentCostCenterDetail'
import PaymentJobDetailFormatter from './PaymentJobDetail'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null
    const entity = this.fieldNamesFromResponse(resource)

    entity.supplier = PersonAndCompanyFormatter.fromResponseResource(
      entity.supplier
    )
    entity.bankAccount = BankAccountFormatter.fromResponseResource(
      entity.bankAccount
    )
    entity.paymentCard = PaymentCardFormatter.fromResponseResource(
      entity.paymentCard
    )

    entity.untaxedPrice = Decimal.fromBE(entity.untaxedPrice).forUI()
    entity.vatAmount = Decimal.fromBE(entity.vatAmount).forUI()

    entity.costCenterDetails = PaymentCostCenterDetailFormatter.fromResponseResourcesList(
      entity.costCenterDetails
    )

    entity.jobDetails = PaymentJobDetailFormatter.fromResponseResourcesList(
      entity.jobDetails
    )

    let invoice = entity.invoice
    if (invoice && process.env.VUE_APP_TEST)
      invoice = `http://localhost/${resource.invoice}`
    entity.invoice = invoice

    return new Payment(entity)
  }

  static toRequestResource(entity) {
    if (!entity) return null
    const resource = this.fieldNamesForRequest(entity)

    ;['supplier', 'bankAccount', 'paymentCard'].forEach(field => {
      const objectWithIdOnly = Base.toResourceIdObject(resource[field])
      if (objectWithIdOnly) resource[field] = objectWithIdOnly
    })

    resource.untaxedPrice = Decimal.fromUI(resource.untaxedPrice).forBE()
    resource.vatAmount = Decimal.fromUI(resource.vatAmount).forBE()

    resource.costCenterDetails = PaymentCostCenterDetailFormatter.toRequestResourcesList(
      entity.costCenterDetails
    )

    resource.jobDetails = PaymentJobDetailFormatter.toRequestResourcesList(
      entity.jobDetails
    )

    return this.fieldNamesForRequest(resource)
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    if (!resource) return null
    let {
      id,
      invoPayment,
      supplier,
      type,
      date,
      duoDate,
      paymentDate,
      bankAccount,
      paymentCard,
      untaxedPrice,
      vatAmount,
      description,
      notes,
      invoice,
      paymentCostCenterRels,
      paymentJobDetRels
    } = resource

    const result = {
      id,
      invoPayment,
      supplier,
      type,
      date,
      duoDate,
      paymentDate,
      bankAccount,
      paymentCard,
      untaxedPrice,
      vatAmount,
      description,
      notes,
      invoice,
      costCenterDetails: paymentCostCenterRels,
      jobDetails: paymentJobDetRels
    }

    this.formatInternalValuesFromResponse(resource, result)

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(entity, removeEmpty = false) {
    if (!entity) return null

    let {
      id,
      invoPayment,
      supplier,
      type,
      date,
      duoDate,
      paymentDate,
      bankAccount,
      paymentCard,
      creditCardType,
      creditCardOwner,
      untaxedPrice,
      vatAmount,
      description,
      costCenterDetails,
      jobDetails,
      notes
    } = entity

    const result = {
      id,
      invoPayment,
      supplier,
      type,
      date,
      duoDate,
      paymentDate,
      bankAccount,
      paymentCard,
      creditCardType,
      creditCardOwner,
      untaxedPrice,
      vatAmount,
      description,
      paymentCostCenterRels: costCenterDetails,
      paymentJobDetRels: jobDetails,
      notes
    }

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static formatFilterOptions(filter) {
    if (isEmpty(filter)) return null
    let result = super.formatFilterOptions(filter) || {}
    let expandedResult = {}
    expandedResult = {
      paymentStatus: filter.paymentStatus,
      supplier: this.toResourceIdObject(filter.supplier)
    }

    return this.removeEmptyAttributes(Object.assign(result, expandedResult))
  }

  static fromResponseErrors(errors, resource = null) {
    const fieldErrors = resource ? errors[resource] : errors.invoPurchase

    const extraMessages = []

    const {
      fields: paymentCostCenterDetailFields,
      extraMessages: paymentCostCenterDetailExtraMessages
    } = PaymentCostCenterDetailFormatter.fromResponseErrorsList(
      errors.paymentCostCenterRels
    )

    const {
      fields: paymentJobDetailFields,
      extraMessages: paymentJobDetailExtraMessages
    } = PaymentJobDetailFormatter.fromResponseErrorsList(
      errors.paymentJobDetRels
    )

    let paymentFieldErrors =
      this.fieldNamesFromResponse(fieldErrors, true) || {}
    paymentFieldErrors.costCenterDetails = paymentCostCenterDetailFields
    paymentFieldErrors.jobDetails = paymentJobDetailFields

    extraMessages.push(paymentCostCenterDetailExtraMessages)
    extraMessages.push(paymentJobDetailExtraMessages)

    return {
      fields: paymentFieldErrors,
      extraMessages
    }
  }
}
