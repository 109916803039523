/**
 * Provides behaviours for views that edit an entity.
 *
 * Expects:
 *  - this.id:              the id for the current entity, usually a prop provided by the router;
 *  - data.actsAsAShowPage: options for this mixin:
 *    - entity:             String, name used for a single entity in the adapters (e.g. as a key for a result object);
 *    - resource:           String, name used for the resource in the adapters (e.g. when building a request URI);
 *    - get:                use-case Function to use when fetching the entity's data;
 *    - getMultipleIds:     optional Boolean value, if set to true this.id will be
 *                          expected to be an array of ids (used to create a new Invoice from
 *                          multiple Purchases);
 *    - getCallback:        optional Function that is called before returning the
 *                          entity fetched with the 'get' use-case so that the entity itself can be
 *                          modified as needed before being returned;
 *    - onGetErrorGoBack:   if true, if there is an error when calling the Get use-case,
 *                          go back to the previous page.
 *    - validate:           Array, optional, array of ref names for the forms composing this view;
 *
 *   - update (maybe edit ?): use-case Function, if defined it is used to update the entity in the BE;
 *    - create:             use-case Function, if 'update' is not defined this is used to
 *                          save the form data as a new entity in the BE;
 *    - getId:              optional Function that given an entity returns its id;
 *
 * Provides:
 *  - this.user:       the currently logged-in User from the vuex store;
 *
 *  - data.errors:     an Object where to put error information;
 *
 *  - this.gotoEdit(): loads the edit page for the current entity
 *
 *  - this.gotoList(): loads the show page for the current entity;
 */

import { get } from 'vuex-pathify'
import handlesErrors from './handlesErrors'

export default {
  mixins: [handlesErrors],
  data() {
    return { errors: {} }
  },
  computed: { user: get('user') },
  methods: {
    option(name) {
      return this.actsAsAnEditPage[name] || null
    },
    validate() {
      const forms = this.option('validate') || ['form']
      if (!forms) return true

      return forms.reduce((finalVerdict, validatorRefName) => {
        const ref = this.$refs[validatorRefName]
        if (ref) finalVerdict = ref.validate() && finalVerdict
        return finalVerdict
      }, true)
    },
    save() {
      if (!this.validate()) return

      const resource = this.option('resource')
      // use-case options should be either "edit" or "create"
      const useCaseCreate = this.option('create')
      const useCaseEdit = this.option('edit')

      const useCase = useCaseEdit
        ? new useCaseEdit(this.user, this.id, this.entity)
        : new useCaseCreate(this.user, this.entity)

      useCase.call().then(result => {
        if (result.error) {
          this.errors = this.$_handleError(result)
        } else {
          const entity = result[this.option('entity')]

          const id = this.option('getId')
            ? this.option('getId')(entity)
            : entity.id
          this.$router.push(`/${resource}/${id}`)
        }
      })
    },
    leave() {
      this.$router.push(`/${this.option('resource')}`)
    },
    back() {
      this.$router.go(-1)
    }
  },
  created() {
    const entityName = this.option('entity')
    const useCase = this.option('get')
    const idArgument = this.option('getMultipleIds') ? this.ids : this.id
    const callback = this.option('getCallback')

    new useCase(this.user).call(idArgument).then(result => {
      if (result.error) {
        const goBack = this.option('onGetErrorGoBack')
        this.$_handleError(result, goBack)
      } else {
        this.entity = callback
          ? callback(result[entityName])
          : result[entityName]
      }
    })
  }
}
