<template>
  <v-autocomplete
    :class="_class"
    :label="$t('entities.invoice.job.name')"
    :items="items"
    :clearable="clearable"
    v-model="item"
    @input="onChange"
    return-object
    item-text="name"
    item-value="id"
    :no-data-text="$t('common.autocomplete.no-data')"
    :rules="_rules"
    :required="required"
    :outlined="readonly || outlined"
    :filled="readonly"
    :hide-details="hideDetails"
    dense
  >
    <template v-slot:selection="data">
      {{ data.item.name }}
    </template>

    <template v-slot:item="data">
      <v-list-item-content :class="{ inactive: !data.item.isActive() }">
        <v-list-item-title>
          {{ data.item.name }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import Error from '@/lib/Error'
import actsAsAnAutocomplete from '@/mixins/actsAsAnAutocomplete'
import GetJobs from '@/domain/useCases/jobs/GetJobs'
import { dig } from '@/lib/Utils'
import Job from '@/domain/entities/Job'

export default {
  name: 'JobSelect',
  mixins: [actsAsAnAutocomplete],
  props: {
    jobs: Array
  },
  computed: {
    jobDetails() {
      return dig(this.item, 'details') || []
    }
  },
  methods: {
    init() {
      if (this.jobs && this.jobs.length) {
        this.items = this.jobs.map(job => new Job(job))
        return
      }

      new GetJobs(this.user).call().then(result => {
        if (result.error) {
          new Error({
            message: 'common.autocomplete.error-loading'
          }).toAlert()
          console.info(`Error loading job for autocomplete:`, result)
        } else this.items = result.jobs
      })
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    jobs() {
      this.init()
    }
  }
}
</script>

<style>
.inactive {
  color: gray;
}
</style>
