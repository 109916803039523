<template>
  <v-simple-table class="table" dense>
    <template v-slot:default>
      <thead class="text-left">
        <th>
          {{ $t('entities.customerContract.detail.description') }}
        </th>
        <th>{{ $t('entities.customerContract.detail.value') }}</th>
        <th>{{ $t('entities.customerContract.detail.max') }}</th>
        <th>{{ $t('entities.customerContract.detail.notes') }}</th>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ item.description }}</td>
          <td class="value">{{ item.value }}</td>
          <td class="value">{{ item.max }}</td>
          <td class="notes">{{ item.notes }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: 'CustomerContractDetailsSimpleTable',
  props: { items: Array }
}
</script>

<style scoped>
td.value {
  width: 90px !important;
}
td.notes {
  width: 240px !important;
}
</style>
