import {
  EditionsListPage,
  NewEditionPage,
  EditEditionPage,
  ShowEditionPage
} from '@/views/editions/index.js'

export default [
  {
    path: '/editions',
    name: 'EditionsPage',
    component: EditionsListPage,
    meta: {
      title: 'menu.long.resources.editions'
    }
  },
  {
    path: '/editions/new',
    name: 'NewEditionPage',
    component: NewEditionPage,
    meta: {
      title: 'menu.long.resources.new-edition'
    }
  },
  {
    path: '/editions/:id/edit',
    name: 'EditEditionPage',
    component: EditEditionPage,
    props: true,
    meta: {
      title: 'menu.long.resources.edit-edition'
    }
  },
  {
    path: '/editions/:id',
    name: 'ShowEditionPage',
    component: ShowEditionPage,
    props: true,
    meta: {
      title: 'menu.long.resources.show-edition'
    }
  }
]
