import validateUser from '@/lib/validateUser'
import invoicePreviewAdapter from '@/adapters/backoffice/invoices/preview'

export default class {
  user = null

  constructor(user) {
    this.user = user
  }

  async call(entity) {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    return await invoicePreviewAdapter(this.user.token, entity)
  }
}
