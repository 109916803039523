import axios from '@/axios.js'

import CustomerContractFormatter from '../formatters/CustomerContract'
import resourceErrorsToResponse from '../formatters/resourceErrorsToResponse'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, id, data) {
  const headers = { Authorization: `Bearer ${token}` }
  const payload = {
    custContract: CustomerContractFormatter.toRequestResource(data)
  }

  if (!payload || !id) {
    return {
      error: true,
      errorData: { message: 'errors.resource.invalid' }
    }
  }

  try {
    const { data } = await axios.put(`/api/v1/custContracts/${id}`, payload, {
      headers
    })
    const customerContract = CustomerContractFormatter.fromResponseResource(
      data.custContract
    )
    return { error: false, customerContract }
  } catch (e) {
    return (
      resourceErrorsToResponse(e, CustomerContractFormatter, 'custContract') ||
      exceptionToResponse(e)
    )
  }
}
