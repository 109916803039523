<template>
  <v-form ref="form" @submit.prevent>
    <v-container>
      <v-row>
        <v-col cols="12" lg="6">
          <TextField
            :label="$t('entities.costCenter.name')"
            v-model="entity.name"
            :error="errors.name"
          />
        </v-col>
        <v-col cols="12" lg="3">
          <DateField
            :label="$t('common.fields.startDate')"
            v-model="entity.startDate"
            :error="errors.startDate"
          />
        </v-col>
        <v-col cols="12" lg="3">
          <DateField
            :label="$t('common.fields.endDate')"
            v-model="entity.endDate"
            :error="errors.endDate"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <Textarea
            :label="$t('entities.costCenter.notes')"
            v-model="entity.notes"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              {{ $t('entities.costCenter.details') }}
            </v-card-title>
            <DetailsTable v-model="entity.details" :errors="errors.details" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import actsAsAForm from '@/mixins/actsAsAForm'

import { TextField, Textarea, DateField } from '@/components/common/form'

import CostCenter from '@/domain/entities/CostCenter'
import DetailsTable from './CostCenterDetailsEditableTable.vue'

export default {
  name: 'CostCenterForm',
  mixins: [actsAsAForm],
  components: {
    TextField,
    Textarea,
    DateField,
    DetailsTable
  },
  props: {
    value: CostCenter,
    errors: Object
  }
}
</script>
