import { dig } from '@/lib/Utils'

import validateUser from '@/lib/validateUser'
import beneficiaryContractsListAdapter from '@/adapters/backoffice/customers/getBeneficiaryContracts'

export default class {
  user = null

  constructor(user) {
    this.user = user
  }

  async call(beneficiary) {
    const beneficiaryId = dig(beneficiary, 'id')
    if (!beneficiaryId) return []

    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    return await beneficiaryContractsListAdapter(this.user.token, beneficiaryId)
  }
}
