<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12" lg="9">
        <PersonAndCompaniesForm
          v-if="entity"
          ref="form"
          as="customer"
          v-model="entity"
          :errors="errors"
          @remove-company-at="removePersonCompanyAt"
        />
      </v-col>

      <v-col cols="12" lg="3" order="first" order-lg="last">
        <PersonSummary v-if="entity.person" :entity="entity.person" isCustomer>
          <template #avatar>
            <ImageUpload v-model="entity.person.photo">
              <div slot="activator">
                <v-avatar
                  size="128px"
                  v-if="!entity.person.photo"
                  class="grey lighten-3 elevation-6 mt-n12 px-2"
                >
                  <small>{{ $t('common.avatar.add') }}</small>
                </v-avatar>
                <v-avatar
                  v-else
                  size="128px"
                  class="grey lighten-3 elevation-6 mt-n12 px-2 mb-3"
                  v-ripple
                >
                  <v-img
                    contain
                    v-if="entity.person.photo.imageURL"
                    :src="entity.person.photo.imageURL"
                  />
                  <v-img contain v-else :src="entity.person.photo" />
                </v-avatar>
              </div>
            </ImageUpload>

            <div v-if="entity.person.photo">
              <v-btn class="warning" @click="entity.person.photo = null">{{
                $t('common.avatar.remove')
              }}</v-btn>
            </div>
          </template>

          <v-divider />

          <SummaryCommandBanner
            icon="mdi-cash-plus"
            appendText="+"
            :text="$t('common.personCompany.add-empty.customer')"
            @click="addCustomer"
          />

          <SummaryCommandBanner
            icon="mdi-cash-minus"
            appendText="+"
            :text="$t('common.personCompany.add-empty.supplier')"
            @click="addSupplier"
          />

          <template #controls>
            <v-row>
              <v-col cols="3" md="6">
                <v-btn color="success" width="100%" @click="save()">
                  {{ $t('form.customers.new.submit') }}
                </v-btn>
              </v-col>
              <v-col cols="3" md="6">
                <v-btn color="warning" width="100%" @click="leave()">
                  {{ $t('common.leave') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </PersonSummary>
      </v-col>
    </v-row>

    <v-dialog v-model="removePersonCompanyConfirmDialog" max-width="400px">
      <RemovePersonCompanyDialog
        @yesClick="removePersonCompany"
        @noClick="cancelRemovePersonCompany"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import actsAsAnEditPage from '@/mixins/actsAsAnEditPage'
import addCustomerSupplier from '@/mixins/addCustomerSupplier'

import PersonAndCompaniesForm from '@/components/personCompanies/PersonAndCompaniesForm.vue'
import ImageUpload from '@/components/common/ImageUpload.vue'
import PersonSummary from '@/components/people/PersonSummary'
import SummaryCommandBanner from '@/components/common/SummaryCommandBanner.vue'
import RemovePersonCompanyDialog from '@/components/personCompanies/RemovePersonCompanyDialog.vue'

import PersonAndCompanies from '@/domain/entities/PersonAndCompanies'
import GetCustomer from '@/domain/useCases/customers/GetCustomer'
import EditCustomer from '@/domain/useCases/customers/EditCustomer'

export default {
  name: 'EditCustomerPage',
  mixins: [actsAsAnEditPage, addCustomerSupplier],
  components: {
    PersonAndCompaniesForm,
    ImageUpload,
    PersonSummary,
    SummaryCommandBanner,
    RemovePersonCompanyDialog
  },
  props: { id: String },
  data() {
    return {
      actsAsAnEditPage: {
        resource: 'customers',
        entity: 'customer',
        getId: entity => entity.person.id,
        get: GetCustomer,
        edit: EditCustomer
      },
      entity: PersonAndCompanies.newCustomer()
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
