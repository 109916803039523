<template>
  <div>
    <EditionsList
      :loading="loading"
      :items="items"
      :page="page"
      :totalItems="totalItems"
      @refresh="refresh"
      @delete="deleteEntity"
      @goto-new="gotoNew"
      @goto-edit="gotoEdit"
      @goto-show="gotoShow"
    />

    <v-dialog v-model="deleteDialog" max-width="400px">
      <ConfirmDialog
        :message="$t('form.editions.delete.confirm-text')"
        :yesText="$t('common.delete.yes')"
        :noText="$t('common.delete.no')"
        @yesClick="deleteConfirmed"
        @noClick="deleteCanceled"
      />
    </v-dialog>
  </div>
</template>

<script>
import actsAsAListPage from '@/mixins/actsAsAListPage'

import EditionsList from '@/components/editions/EditionsList'
import ConfirmDialog from '@/components/common/ConfirmDialog.vue'

import GetEditions from '@/domain/useCases/editions/GetEditions'
import DeleteEdition from '@/domain/useCases/editions/DeleteEdition'

export default {
  name: 'EditionsListPage',
  mixins: [actsAsAListPage],
  components: { EditionsList, ConfirmDialog },
  data() {
    return {
      actsAsAListPage: {
        resource: 'editions',
        get: GetEditions,
        delete: DeleteEdition
      }
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
