<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="1">
        <CardTextField
          :label="$t('entities.customerContract.code')"
          :value="entity.code"
        />
      </v-col>

      <v-col cols="12" lg="2">
        <CardDateField
          type="number"
          :label="$t('entities.customerContract.version')"
          :value="entity.version"
        />
      </v-col>

      <v-col cols="12" lg="2">
        <CardDateField
          :label="$t('entities.customerContract.validFrom')"
          :value="entity.validFrom"
        />
      </v-col>

      <v-col cols="12" lg="2">
        <CardDateField
          :label="$t('entities.customerContract.validTo')"
          :value="entity.validTo"
        />
      </v-col>

      <v-col cols="12" lg="2">
        <CardTextField
          :label="$t('entities.customerContract.endReason')"
          :value="entity.endReason | dig('name')"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="6">
        <CardTextField
          :label="$t('entities.customerContract.contract.name')"
          :value="contractName"
        />
      </v-col>

      <v-col cols="12" lg="6">
        <CardTextField
          :label="$t('entities.customerContract.previousContract.name')"
          :value="previousContractName"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="2">
        <CardTextField
          :label="$t('entities.customerContract.buyer')"
          :value="buyerFullName"
        />
      </v-col>

      <v-col cols="12" lg="2">
        <CardTextField
          :label="$t('entities.customerContract.beneficiary')"
          :value="beneficiaryFullName"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="2">
        <CardDateField
          :label="$t('entities.customerContract.purchaseDate')"
          :value="purchaseDate"
        />
      </v-col>

      <v-col cols="12" lg="2">
        <CardTextField
          type="number"
          :label="$t('entities.contract.duration')"
          :value="contractDuration"
        />
      </v-col>

      <v-col cols="12" lg="2">
        <CardYesNoField
          :label="$t('entities.customerContract.autoRenewal')"
          :value="entity.autoRenewal"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="12">
        <CardDateField
          :label="$t('entities.customerContract.cancellationDate')"
          :value="entity.cancellationDate"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="12">
        <CardTextarea
          :label="$t('entities.customerContract.notes')"
          :value="entity.notes"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            {{ $t('entities.customerContract.details') }}
          </v-card-title>
          <DetailsTable :items="entity.details" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  CardTextField,
  CardDateField,
  CardTextarea,
  CardYesNoField
} from '@/components/common/card'
import DetailsTable from '@/components/customerContracts/CustomerContractDetailsSimpleTable.vue'

import CustomerContract from '@/domain/entities/CustomerContract'
import { dig } from '@/lib/Utils'

export default {
  name: 'CustomerContractCard',
  components: {
    CardTextField,
    CardTextarea,
    CardDateField,
    CardYesNoField,
    DetailsTable
  },
  props: { entity: CustomerContract },
  computed: {
    previousContractName() {
      return dig(this.entity.previousContract, 'name')
    },
    contractName() {
      return dig(this.entity.contract, 'name')
    },
    buyerFullName() {
      let person = dig(this.entity.buyer, 'person')
      return person ? person.getFullName() : ''
    },
    beneficiaryFullName() {
      let person = dig(this.entity.beneficiary, 'person')
      return person ? person.getFullName() : ''
    },
    purchaseDate() {
      return dig(this.entity.purchase, 'purchaseDate')
    },
    contractDuration() {
      return dig(this.entity.contract, 'duration')
    }
  }
}
</script>
