<template>
  <app-card class="mt-4 text-center purchase-data-menu">
    <v-card-text class="mt-0 pt-0 text-left">
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="headline" v-if="entity.offer">
            {{ entity.offer.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ $t('entities.purchase.customer') }}:
            {{ customerFormatted }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ $t('entities.purchase.purchaseDate') }}:
            {{ entity.purchaseDate }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ $t('entities.purchase.finalPrice') }}:
            {{ entity.finalPrice | append('€') }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>

    <slot />

    <v-card-text>
      <slot name="controls" />
    </v-card-text>
  </app-card>
</template>

<script>
import Purchase from '@/domain/entities/Purchase'
import { formatPersonAndCompany } from '@/adapters/backoffice/formatters/interface/Common'

export default {
  name: 'purchaseSummary',
  props: { entity: Purchase },
  computed: {
    customerFormatted() {
      return formatPersonAndCompany(this.entity.customer)
    }
  }
}
</script>

<style scoped>
.purchase-data-menu {
  position: sticky;
  top: 50px;
}
</style>
