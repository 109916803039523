import Contract from './Contract'
import ContractReason from './ContractReason'
import PersonAndCompany from './PersonAndCompany'
import Purchase from './Purchase'

export default class {
  static new() {
    return new this({
      contract: Contract.new(),
      buyer: PersonAndCompany.new(),
      beneficiary: PersonAndCompany.new(),
      purchase: Purchase.new(),
      endReason: ContractReason.new()
    })
  }

  static default() {
    return new this()
  }

  constructor({
    id = null,
    details = null,
    code = null,
    version = null,
    previousContract = null,
    endReason = null,
    buyer = null,
    contract = null,
    beneficiary = null,
    purchase = null,
    partnership = null,
    autoRenewal = null,
    notes = null,

    validFrom = null,
    validTo = null,
    cancellationDate = null,
    creationDate = null,
    startDate = null,
    lastModified = null,
    lastModifiedBy = null
  } = {}) {
    this.id = id
    this.details = details

    this.code = code
    this.version = version
    this.previousContract = previousContract
    this.endReason = endReason
    this.buyer = buyer
    this.contract = contract
    this.beneficiary = beneficiary
    this.purchase = purchase
    this.partnership = partnership
    this.autoRenewal = autoRenewal
    this.cancellationDate = cancellationDate
    this.notes = notes

    this.validFrom = validFrom
    this.validTo = validTo
    this.creationDate = creationDate
    this.startDate = startDate
    this.lastModified = lastModified
    this.lastModifiedBy = lastModifiedBy
  }
}
