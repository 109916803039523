<template>
  <v-autocomplete
    :class="_class"
    :label="label"
    :items="items"
    :clearable="clearable"
    v-model="item"
    return-object
    item-text="type"
    @input="onChange"
    :no-data-text="$t('common.autocomplete.no-data')"
    :rules="_rules"
    :required="required"
    :outlined="readonly || outlined"
    :filled="readonly"
    :hide-details="hideDetails"
    dense
  >
    <template v-slot:selection="data">
      {{ formatForUI(data.item) }}
    </template>

    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>
          {{ data.item.type }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ data.item.owner }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import Error from '@/lib/Error'
import validators from '@/domain/validations/rules'
import actsAsAnAutocomplete from '@/mixins/actsAsAnAutocomplete'
import GetPaymentCards from '@/domain/useCases/payments/GetPaymentCards'
import PaymentCardFormatter from '@/adapters/backoffice/formatters/PaymentCard'

export default {
  name: 'PaymentCardSelect',
  mixins: [actsAsAnAutocomplete],
  props: {
    rules: Array,
    required: Boolean,
    readonly: Boolean,
    outlined: Boolean,
    hideDetails: Boolean,
    clearable: Boolean
  },
  computed: {
    validates() {
      return validators(this)
    },
    _class() {
      return this.required ? 'required' : ''
    }
  },
  methods: {
    formatForUI: PaymentCardFormatter.formatForUI
  },
  created() {
    new GetPaymentCards(this.user).call().then(result => {
      if (result.error) {
        new Error({
          message: 'common.autocomplete.error-loading'
        }).toAlert()
        console.info(`Error loading payment cards for autocomplete:`, result)
      } else this.items = result.cards
    })
  }
}
</script>
