export default {
  products: {
    title: 'Lista prodotti',
    new: 'Nuovo prodotto'
  },
  editions: {
    title: 'Lista edizioni',
    new: 'Nuova edizione'
  },
  offers: {
    title: 'Lista offerte',
    new: 'Nuova offerta'
  },
  contracts: {
    title: 'Lista contratti',
    new: 'Nuova contratto'
  },
  customerContracts: {
    title: 'Lista istanze di contratto',
    new: 'Nuova istanza di contratto'
  },
  purchases: {
    title: 'Lista acquisti',
    new: 'Nuovo acquisto',
    contractOrOffer: {
      title: 'Contratto/offerta',
      contract: 'Contratto',
      offer: 'Offerta',
      name: 'Nome',
      description: 'Descrizione'
    }
  },
  invoices: {
    title: 'Lista fatture'
  },
  payments: {
    title: 'Lista pagamenti',
    new: 'Nuovo pagamento',
    paymentStatusFilter: {
      values: [
        {
          text: 'Tutti',
          value: 'all'
        },
        { text: 'Da pagare', value: 'unpaid' },
        { text: 'Pagato', value: 'paid' }
      ],
      text: 'Stato pagamento'
    }
  },
  costCenters: {
    title: 'Lista centri di costo',
    new: 'Nuovo centro di costo'
  },
  jobs: {
    title: 'Lista commesse',
    new: 'Nuova commessa'
  }
}
