<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12" lg="9">
        <material-card
          icon="mdi-credit-card"
          icon-small
          :title="$t('common.sections.titles.payment')"
          color="primary"
        >
          <PaymentForm ref="form" v-model="entity" :errors="errors" />
        </material-card>
      </v-col>
      <v-col cols="12" lg="3" order="first" order-lg="last">
        <PaymentSummary :entity="entity">
          <v-divider />
          <template #controls>
            <v-row>
              <v-col cols="3" md="6">
                <v-btn color="success" width="100%" @click="save()">
                  {{ $t('form.customers.new.submit') }}
                </v-btn>
              </v-col>
              <v-col cols="3" md="6">
                <v-btn color="warning" width="100%" @click="leave()">
                  {{ $t('common.leave') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </PaymentSummary>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsACreatePage from '@/mixins/actsAsACreatePage'

import PaymentForm from '@/components/payments/PaymentForm.vue'
import PaymentSummary from '@/components/payments/PaymentSummary.vue'

import Payment from '@/domain/entities/Payment'
import CreatePayment from '@/domain/useCases/payments/CreatePayment'

export default {
  name: 'NewPaymentPage',
  mixins: [actsAsACreatePage],
  components: { PaymentForm, PaymentSummary },
  data() {
    return {
      actsAsACreatePage: {
        entity: 'payment',
        resource: 'payments',
        create: CreatePayment
      },
      entity: Payment.default()
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
