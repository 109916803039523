<template>
  <div>
    <!-- slot for parent component to activate the file changer -->
    <div @click="launchFilePicker()" :class="disabled ? '' : 'active'">
      <slot name="activator"></slot>
    </div>

    <!-- image input: style is set to hidden and assigned a ref so that it can be triggered -->
    <input
      type="file"
      ref="file"
      :name="uploadFieldName"
      @change="onFileChange($event.target.name, $event.target.files)"
      style="display:none"
    />

    <!-- error dialog displays any potential errors -->
    <v-dialog v-model="errorDialog" max-width="400px">
      <NotificationDialog
        :message="errorText"
        :okText="$t('common.dialog.ok')"
        @okClick="errorDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import NotificationDialog from '@/components/common/NotificationDialog.vue'

export default {
  name: 'ImageUpload',
  components: { NotificationDialog },
  data: () => ({
    errorDialog: null,
    errorText: '',
    uploadFieldName: 'file',
    maxSize: 5
  }),

  props: {
    value: [String, Object],
    disabled: Boolean
  },

  methods: {
    launchFilePicker() {
      if (!this.disabled) this.$refs.file.click()
    },

    onFileChange(fieldName, file) {
      const { maxSize } = this
      let imageFile = file[0] //check if user actually selected a file
      if (file.length > 0) {
        let size = imageFile.size / maxSize / 1024 / 1024
        if (!imageFile.type.match('image.*')) {
          // check whether the upload is an image
          this.errorDialog = true
          this.errorText = this.$t('errors.avatar.image')
        } else if (size > 1) {
          // check whether the size is greater than the size limit
          this.errorDialog = true
          this.errorText = this.$t('errors.avatar.size', {
            size: maxSize
          })
        } else {
          // Append file into FormData & turn file into image URL
          let formData = new FormData()
          let imageURL = URL.createObjectURL(imageFile)
          formData.append(fieldName, imageFile)

          // Emit FormData & image URL to the parent component
          this.$emit('input', { formData, imageURL })
        }
      }
    }
  }
}
</script>

<style scoped>
.active {
  cursor: pointer;
}
</style>
