<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="12">
        <CardTextField
          :label="$t('entities.payment.supplier.fullName')"
          :value="formatPersonAndCompany(entity.supplier)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="3">
        <CardTextField
          :label="$t('entities.payment.type')"
          :value="entity.type | dig('type')"
        />
      </v-col>
      <v-col cols="12" lg="3">
        <CardDateField
          :label="$t('entities.payment.date')"
          :value="entity.date"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="3">
        <CardTextField
          :label="$t('entities.payment.paymentCard')"
          :value="formatPaymentCard(entity.paymentCard)"
        />
      </v-col>
      <v-col cols="12" lg="3">
        <CardTextField
          :label="$t('entities.payment.bankAccount')"
          :value="entity.bankAccount | dig('name')"
        />
      </v-col>
      <v-col cols="12" lg="3">
        <CardDateField
          :label="$t('entities.payment.paymentDate')"
          :value="entity.paymentDate"
        />
      </v-col>
      <v-col cols="12" lg="3">
        <CardDateField
          :label="$t('entities.payment.duoDate')"
          :value="entity.duoDate"
        />
      </v-col>
      <v-col cols="12" lg="3">
        <CardTextField
          :label="$t('entities.payment.untaxedPrice')"
          :value="entity.untaxedPrice"
        />
      </v-col>
      <v-col cols="12" lg="3">
        <CardTextField
          :label="$t('entities.payment.vatAmount')"
          :value="entity.vatAmount"
        />
      </v-col>
      <v-col cols="12" lg="12">
        <CardTextField
          :label="$t('entities.payment.description')"
          :value="entity.description"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6">
        <CardFileField
          :label="$t('entities.payment.invoice')"
          :value="entity.invoice"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <CardTextarea
          :label="$t('entities.payment.notes')"
          :value="entity.notes"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  CardDateField,
  CardTextField,
  CardFileField
} from '@/components/common/card'

import Payment from '@/domain/entities/Payment'
import { formatPersonAndCompany } from '@/adapters/backoffice/formatters/interface/Common'
import CardTextarea from '../common/card/CardTextarea.vue'
import PaymentCardFormatter from '@/adapters/backoffice/formatters/PaymentCard'

export default {
  name: 'PaymentCard',
  components: {
    CardDateField,
    CardTextField,
    CardFileField,
    CardTextarea
  },
  props: { entity: Payment },
  methods: {
    formatPersonAndCompany,
    formatPaymentCard: PaymentCardFormatter.formatForUI
  }
}
</script>
