/**
 * Adds behaviours from three different mixins: actsAsAForm, handlesErrors and
 * hasPlaceholders.
 *
 * Expects:
 *  - data.entity:             the entity that will receive the placeholder values;
 *
 *  - data.errors:             an Object where to put error information;
 *
 *  - this.hasPlacehoders.get: Class of the useCase to use to fetch the  placeholder data;
 *
 * Provides:
 *  - the combined functionalities from actsAsAForm, handlesErrors and hasPlaceholders;
 *
 *  - this.user: the currently logged-in User from the vuex store;
 */
import { get } from 'vuex-pathify'
import actsAsAForm from './actsAsAForm'
import handlesErrors from './handlesErrors'
import hasPlaceholders from './hasPlaceholders'

export default {
  mixins: [actsAsAForm, handlesErrors, hasPlaceholders],
  computed: { user: get('user') }
}
