<template>
  <v-select
    :label="label"
    :items="items"
    :value="value"
    multiple
    item-text="role"
    return-object
    chips
    deletable-chips
    small-chips
    :loading="loading"
    :outlined="outlined"
    :hide-details="hideDetails"
    dense
    @input="onChange"
  ></v-select>
</template>

<script>
import { get } from 'vuex-pathify'

import GetCustomerRoles from '@/domain/useCases/customers/GetCustomerRoles'
import GetSupplierRoles from '@/domain/useCases/suppliers/GetSupplierRoles'

export default {
  name: 'PersonCompanyRolesMultiselect',
  props: {
    label: String,
    as: String,
    value: Array,
    outlined: Boolean,
    hideDetails: Boolean
  },
  data() {
    return { items: [], loading: true }
  },
  computed: {
    user: get('user')
  },
  methods: {
    onChange(newValue) {
      this.$emit('input', newValue)
    }
  },
  created() {
    const useCase =
      this.as === 'customer'
        ? new GetCustomerRoles(this.user)
        : new GetSupplierRoles(this.user)

    useCase.call({ itemsPerPage: -1 }).then(result => {
      if (result.error) {
        console.info('Error loading user roles', result.data)
      } else {
        this.items = result.roles
        this.loading = false
      }
    })
  }
}
</script>
