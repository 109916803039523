<template>
  <v-form ref="form" @submit.prevent>
    <v-container>
      <v-row>
        <v-col cols="12" lg="4">
          <CardTextField
            v-if="entity.code"
            :label="$t('entities.invoice.code')"
            :value="entity.code"
          />
          <CardTextField
            v-else
            :label="$t('entities.invoice.code')"
            :value="$t('common.autoassigned')"
          />
        </v-col>
        <v-col cols="12" lg="4">
          <CardTextField
            v-if="entity.version"
            :label="$t('entities.invoice.version')"
            :value="entity.version"
          />
          <CardTextField
            v-else
            :label="$t('entities.invoice.version')"
            :value="$t('common.autoassigned')"
          />
        </v-col>
        <v-col cols="12" lg="4">
          <InvoiceBranchSelect
            :label="$t('entities.invoice.branch')"
            clearable
            v-model="entity.branch"
            :error="errors.branch"
            required
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="2">
          <InvoiceTypeSelect
            :label="$t('entities.invoice.type')"
            v-model="entity.type"
            required
          />
        </v-col>
        <v-col cols="12" lg="2">
          <DateField
            :label="$t('entities.invoice.date')"
            v-model="entity.date"
            :error="errors.date"
            required
          />
        </v-col>
        <v-col cols="12" lg="2">
          <DateField
            :label="$t('entities.invoice.dueDate')"
            v-model="entity.dueDate"
            :error="errors.dueDate"
            required
          />
        </v-col>
        <v-col cols="12" lg="2">
          <DateField
            :label="$t('entities.invoice.paymentDate')"
            v-model="entity.paymentDate"
            :error="errors.paymentDate"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="2">
          <TextField
            :label="$t('entities.invoice.purchaseSourceDescription')"
            v-model="entity.purchaseSourceDescription"
            :error="errors.purchaseSourceDescription"
          />
        </v-col>
        <v-col cols="12" lg="2">
          <TextField
            :label="$t('entities.invoice.paymentSourceDescription')"
            v-model="entity.paymentSourceDescription"
            :error="errors.paymentSourceDescription"
          />
        </v-col>
        <v-col cols="12" lg="2">
          <TextField
            :label="$t('entities.invoice.fiscalType')"
            v-model="entity.fiscalType"
            :error="errors.fiscalType"
          />
        </v-col>
        <v-col cols="12" lg="4">
          <TextField
            :label="$t('entities.invoice.fiscalTypeDescription')"
            v-model="entity.fiscalTypeDescription"
            :error="errors.fiscalTypeDescription"
          />
        </v-col>
        <v-col cols="12" lg="4">
          <TextField
            :label="$t('entities.invoice.fiscalTypeRegsReference')"
            v-model="entity.fiscalTypeRegsReference"
            :error="errors.fiscalTypeRegsReference"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="4">
          <TextField
            :label="$t('entities.invoice.name')"
            v-model="entity.name"
            :error="errors.name"
            required
          />
        </v-col>
        <v-col cols="12" lg="2">
          <TextField
            :label="$t('entities.invoice.vatCode')"
            v-model="entity.vatCode"
            :error="errors.vatCode"
          />
        </v-col>
        <v-col cols="12" lg="2">
          <TextField
            :label="$t('entities.invoice.sdi')"
            v-model="entity.sdi"
            :error="errors.sdi"
            required
          />
        </v-col>
        <v-col cols="12" lg="2">
          <TextField
            type="email"
            :label="$t('entities.invoice.pec')"
            v-model="entity.pec"
            :error="errors.pec"
          />
        </v-col>
        <v-col cols="12" lg="2">
          <TextField
            type="fiscal-code"
            :label="$t('entities.invoice.fiscalCode')"
            v-model="entity.fiscalCode"
            :error="errors.fiscalCode"
          />
        </v-col>
        <v-col cols="12" lg="2">
          <DeductionTypeSelect
            :label="$t('entities.invoice.deductionType')"
            v-model="entity.deductionType"
            :error="errors.deductionType"
          />
        </v-col>
        <v-col cols="12" lg="2">
          <TextField
            type="percentage"
            :label="$t('entities.invoice.deductionRate')"
            v-model="entity.deductionRate"
            :error="errors.deductionRate"
            required
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12">
          <TextField
            :label="$t('entities.invoice.address')"
            v-model="entity.address"
            :error="errors.address"
            required
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="4">
          <BankAccountSelect
            :label="$t('entities.invoice.bankAccount')"
            clearable
            v-model="entity.bankAccount"
            :error="errors.bankAccount"
            @input="bankAccountSelected"
            required
          />
        </v-col>
        <v-col cols="12" lg="4">
          <CardTextField
            :label="$t('entities.invoice.bankAccountName')"
            :value="entity.bankAccountName"
            required
          />
        </v-col>
        <v-col cols="12" lg="4">
          <CardTextField
            :label="$t('entities.invoice.bankAccountIban')"
            :value="entity.bankAccountIban"
            required
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="2">
          <CardTextField
            type="currency"
            :label="$t('entities.invoice.amount')"
            :value="entity.amount"
            :error="errors.amount"
          />
        </v-col>
        <v-col cols="12" lg="2">
          <CardTextField
            type="currency"
            :label="$t('entities.invoice.vatAmount')"
            :value="entity.vatAmount"
            :error="errors.vatAmount"
          />
        </v-col>
        <v-col cols="12" lg="2">
          <CardTextField
            type="currency"
            :label="$t('entities.invoice.taxAmount')"
            :value="entity.taxAmount"
            :error="errors.taxAmount"
          />
        </v-col>
        <v-col cols="12" lg="2">
          <CardTextField
            type="currency"
            :label="$t('entities.invoice.stampAmount')"
            :value="entity.stampAmount"
            :error="errors.stampAmount"
          />
        </v-col>
        <v-col cols="12" lg="2">
          <CardTextField
            type="currency"
            :label="$t('entities.invoice.totalAmount')"
            :value="entity.totalAmount"
            :error="errors.totalAmount"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12">
          <Textarea
            :label="$t('entities.invoice.notes')"
            v-model="entity.notes"
            :error="errors.notes"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="2">
          <v-switch
            color="success"
            :label="$t('entities.invoice.issuable')"
            v-model="entity.issuable"
            :error="errors.issuable"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import actsAsAForm from '@/mixins/actsAsAForm'

import { TextField, DateField, Textarea } from '@/components/common/form'
import { CardTextField } from '@/components/common/card'

import Invoice from '@/domain/entities/Invoice'
import InvoiceTypeSelect from './InvoiceTypeSelect.vue'
import BankAccountSelect from '@/components/invoices/BankAccountSelect.vue'
import DeductionTypeSelect from '@/components/invoices/DeductionTypeSelect.vue'
import InvoiceBranchSelect from '@/components/invoices/InvoiceBranchSelect.vue'

import { dig } from '@/lib/Utils'

export default {
  name: 'InvoiceForm',
  mixins: [actsAsAForm],
  components: {
    TextField,
    DateField,
    Textarea,
    CardTextField,
    BankAccountSelect,
    DeductionTypeSelect,
    InvoiceTypeSelect,
    InvoiceBranchSelect
  },
  props: {
    value: Invoice,
    errors: Object
  },
  mounted: function() {
    this.entity.dueDate = this.entity.dueDate || this.entity.date
  },
  methods: {
    bankAccountSelected() {
      this.entity.bankAccountIban = dig(this.entity.bankAccount, 'iban')
      this.entity.bankAccountName = dig(this.entity.bankAccount, 'name')
    }
  }
}
</script>
