/**
 * Used in entity form components
 *
 * Requires:
 *
 *   - data.entity:     the entity used as the form's data source;
 *
 *   - this.validate(): function to validate the form's content;
 */
export default {
  data() {
    return { entity: this.value }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    }
  },
  watch: {
    value() {
      this.entity = this.value
    }
  }
}
