<template>
  <v-card>
    <v-card-title v-if="title">{{ title }}</v-card-title>
    <v-card-text v-if="message">
      {{ message }}
    </v-card-text>
    <v-card-actions>
      <v-btn color="blue darken-1" text @click="$emit('yesClick')">
        {{ yesText }}</v-btn
      >
      <v-btn color="blue darken-1" text @click="$emit('noClick')">
        {{ noText }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
export default {
  name: 'ConfirmDialog',
  props: { title: String, message: String, yesText: String, noText: String }
}
</script>
