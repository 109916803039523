import InvoiceFormatter from '../formatters/Invoice'
import axios from '@/axios.js'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, entity) {
  try {
    const payload = { invoPurchase: InvoiceFormatter.toRequestResource(entity) }
    // eslint-disable-next-line no-unused-vars
    const { data } = await axios.post(
      `/api/v1/invoPurchases/${entity.id}/save`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob'
      }
    )

    return { error: false }
  } catch (e) {
    return exceptionToResponse(e)
  }
}
