<template>
  <v-dialog v-model="showModal" @input="clearEntityIfOpened">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on">
        {{ $t('form.suppliers.new.title') }}
      </v-btn>
    </template>

    <v-card>
      <PersonAndCompaniesForm
        v-if="entity"
        ref="form"
        as="supplier"
        v-model="entity"
        :errors="errors"
        @remove-company-at="removePersonCompanyAt"
      />
      <v-card-actions>
        <v-btn color="primary" @click="saveEntity">
          {{ $t('form.suppliers.new.submit') }}
        </v-btn>
        <v-btn color="warning" min-width="150" @click="leave()">
          {{ $t('common.leave') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import actsAsACreatePage from '@/mixins/actsAsACreatePage'
import addCustomerSupplier from '@/mixins/addCustomerSupplier'

import PersonAndCompaniesForm from '@/components/personCompanies/PersonAndCompaniesForm.vue'
import PersonAndCompanies from '@/domain/entities/PersonAndCompanies'
import CreateSupplier from '@/domain/useCases/suppliers/CreateSupplier'
import GetSupplier from '@/domain/useCases/suppliers/GetSupplier'
import { get } from 'vuex-pathify'

export default {
  name: 'CreateSupplierModal',
  mixins: [actsAsACreatePage, addCustomerSupplier],
  components: {
    PersonAndCompaniesForm
  },
  computed: {
    user: get('user')
  },
  data() {
    return {
      showModal: false,
      actsAsACreatePage: {
        resource: 'suppliers',
        entity: 'supplier',
        getId: entity => entity.person.id,
        create: CreateSupplier,
        noRedirect: true
      },
      entity: PersonAndCompanies.newSupplier()
    }
  },
  methods: {
    clearEntityIfOpened() {
      if (this.showModal) this.entity = PersonAndCompanies.newSupplier()
    },
    leave() {
      this.showModal = false
    },
    saveEntity() {
      this.save().then(id =>
        new GetSupplier(this.user).call(id).then(result => {
          this.entity = result[this.actsAsACreatePage.entity]
          this.showModal = false
          this.$emit('saved', this.entity)
        })
      )
    }
  }
}
</script>
