<template>
  <v-form ref="form" @submit.prevent>
    <v-container>
      <v-row>
        <v-col cols="12" lg="12">
          <SupplierSelect
            :label="$t('entities.payment.supplier.fullName')"
            v-model="entity.supplier"
            required
            :error="errors.supplier"
          />
          <CreateSupplierModal @saved="onSupplierCreated" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="3">
          <PaymentInvoiceTypeSelect
            :label="$t('entities.payment.type')"
            clearable
            v-model="entity.type"
            :error="errors.type"
            required
          />
        </v-col>
        <v-col cols="12" lg="3">
          <DateField
            :label="$t('entities.payment.date')"
            v-model="entity.date"
            :error="errors.date"
            required
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="3">
          <PaymentCardSelect
            :label="$t('entities.payment.paymentCard')"
            clearable
            v-model="entity.paymentCard"
            :error="errors.paymentCard"
            @input="paymentCardChanged"
          />
        </v-col>
        <v-col cols="12" lg="4">
          <BankAccountSelect
            :label="$t('entities.payment.bankAccount')"
            clearable
            v-model="entity.bankAccount"
            :error="errors.bankAccount"
            required
            @input="bankAccountChanged"
          />
        </v-col>
        <v-col cols="12" lg="3">
          <DateField
            :label="$t('entities.payment.paymentDate')"
            v-model="entity.paymentDate"
            :error="errors.paymentDate"
          />
        </v-col>
        <v-col cols="12" lg="4">
          <DateField
            :label="$t('entities.payment.duoDate')"
            v-model="entity.duoDate"
            :error="errors.duoDate"
            required
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="2">
          <TextField
            type="currency"
            :label="$t('entities.payment.untaxedPrice')"
            v-model="entity.untaxedPrice"
            required
          />
        </v-col>
        <v-col cols="12" lg="2">
          <TextField
            type="currency"
            :label="$t('entities.payment.vatAmount')"
            v-model="entity.vatAmount"
            required
          />
        </v-col>
        <v-col cols="12" lg="8">
          <TextField
            :label="$t('entities.payment.description')"
            v-model="entity.description"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6">
          <FileField
            :label="$t('entities.payment.invoice')"
            v-model="entity.invoice"
            max-size="5"
            :extensions="['pdf']"
            :error="errors.invoice"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <Textarea
            :label="$t('entities.payment.notes')"
            v-model="entity.notes"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import actsAsAForm from '@/mixins/actsAsAForm'

import {
  DateField,
  TextField,
  Textarea,
  FileField
} from '@/components/common/form'

import SupplierSelect from '@/components/personCompanies/SupplierSelect.vue'
import CreateSupplierModal from '@/components/personCompanies/CreateSupplierModal.vue'
import BankAccountSelect from '@/components/invoices/BankAccountSelect.vue'
import PaymentInvoiceTypeSelect from '@/components/payments/PaymentInvoiceTypeSelect.vue'
import PaymentCardSelect from '@/components/payments/PaymentCardSelect.vue'

import Payment from '@/domain/entities/Payment'
import PersonAndCompany from '@/domain/entities/PersonAndCompany'
import { dig } from '@/lib/Utils'

export default {
  name: 'PaymentForm',
  mixins: [actsAsAForm],
  components: {
    BankAccountSelect,
    CreateSupplierModal,
    DateField,
    FileField,
    PaymentCardSelect,
    PaymentInvoiceTypeSelect,
    SupplierSelect,
    TextField,
    Textarea
  },
  props: {
    value: Payment,
    errors: Object
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    onSupplierCreated(supplierCreated) {
      const personCompany = supplierCreated.personCompanies[0]
      const { id, company, position, roles } = personCompany
      this.entity.supplier = PersonAndCompany.newSupplier({
        id,
        person: supplierCreated.person,
        company,
        position,
        roles
      })
    },
    paymentCardChanged() {
      const cardBankAccount = dig(this.entity, 'paymentCard', 'bankAccount')
      this.entity.bankAccount = cardBankAccount
    },
    bankAccountChanged() {
      this.entity.paymentCard = null
    }
  },
  watch: {}
}
</script>
