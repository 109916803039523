<template>
  <div>
    <PersonCompaniesList
      as="customer"
      :loading="loading"
      :items="items"
      :page="page"
      :totalItems="totalItems"
      @refresh="refresh"
      @delete="deleteEntity"
      @goto-new="gotoNew"
      @goto-edit="gotoEdit"
      @goto-show="gotoShow"
    />

    <v-dialog v-model="deleteDialog" max-width="400px">
      <ConfirmDialog
        :title="$t('common.customer.delete.title')"
        :message="$t('common.customer.delete.confirm-text')"
        :yesText="$t('common.delete.yes')"
        :noText="$t('common.delete.no')"
        @yesClick="deleteConfirmed"
        @noClick="deleteCanceled"
      />
    </v-dialog>
  </div>
</template>

<script>
import actsAsAListPage from '@/mixins/actsAsAListPage'

import PersonCompaniesList from '@/components/personCompanies/PersonCompaniesList.vue'
import ConfirmDialog from '@/components/common/ConfirmDialog.vue'

import GetCustomers from '@/domain/useCases/customers/GetCustomers'
import DeleteCustomer from '@/domain/useCases/customers/DeleteCustomer'

export default {
  name: 'CustomersListPage',
  mixins: [actsAsAListPage],
  data() {
    return {
      actsAsAListPage: {
        resource: 'customers',
        get: GetCustomers,
        delete: DeleteCustomer
      }
    }
  },
  components: { PersonCompaniesList, ConfirmDialog },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
