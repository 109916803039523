<template>
  <v-form ref="form">
    <material-card
      v-for="(item, index) in items"
      :key="index"
      :title="$t('entities.invoice.details')"
      icon="mdi-credit-card-search"
      icon-small
      color="primary"
    >
      <v-container>
        <v-row>
          <v-col cols="12" lg="12">
            <CardTextField
              :value="item.offerName"
              :label="$t('entities.invoice.detail.offerName')"
            />
          </v-col>
          <v-col cols="12" lg="12">
            <CardTextarea
              :label="$t('entities.invoice.detail.description')"
              :value="item.description"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="2">
            <CardDateField
              :value="item.date"
              :label="$t('entities.invoice.detail.date')"
            />
          </v-col>
          <v-col cols="12" lg="2">
            <CardTextField
              :value="item.contractNumber"
              :label="$t('entities.invoice.detail.contractNumber')"
            />
          </v-col>
          <v-col cols="12" lg="2">
            <CardTextField
              type="currency"
              :value="item.amount"
              :label="$t('entities.invoice.detail.amount')"
            />
          </v-col>
          <v-col cols="12" lg="2">
            <CardTextField
              type="currency"
              :value="item.discount"
              :label="$t('entities.invoice.detail.discount')"
            />
          </v-col>
          <v-col cols="12" lg="2">
            <CardTextField
              type="currency"
              :value="item.vatAmount"
              :label="$t('entities.invoice.detail.vatAmount')"
            />
          </v-col>
          <v-col cols="12" lg="2">
            <CardTextField
              type="currency"
              :value="item.vatPerc"
              :label="$t('entities.invoice.detail.vatPerc')"
            />
          </v-col>
        </v-row>
      </v-container>
    </material-card>
  </v-form>
</template>

<script>
import {
  CardTextarea,
  CardTextField,
  CardDateField
} from '@/components/common/card'

export default {
  name: 'InvoiceDetailsCard',
  components: { CardTextarea, CardTextField, CardDateField },
  props: { items: Array },
  computed: {
    details() {
      return this.items || []
    }
  }
}
</script>
