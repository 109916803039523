import Base from './Base'
import Invoice from '@/domain/entities/Invoice'
import BankAccountFormatter from './BankAccount'
import InvoiceBranchFormatter from './InvoiceBranch'
import InvoiceDetailFormatter from './InvoiceDetail'
import InvoiceCostCenterDetailFormatter from './InvoiceCostCenterDetail'
import InvoiceJobDetailFormatter from './InvoiceJobDetail'
import { isString, isObject } from '@/lib/Utils'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null

    const entity = this.fieldNamesFromResponse(resource)

    this.formatDecimalsFromResponseResource(
      entity,
      'amount',
      'vatAmount',
      'taxAmount',
      'stampAmount',
      'totalAmount',
      'deductionRate'
    )

    entity.bankAccount = BankAccountFormatter.fromResponseResource(
      entity.bankAccount
    )

    entity.branch = InvoiceBranchFormatter.fromResponseResource(entity.branch)

    entity.details = InvoiceDetailFormatter.fromResponseResourcesList(
      entity.details
    )

    entity.costCenterDetails = InvoiceCostCenterDetailFormatter.fromResponseResourcesList(
      entity.costCenterDetails
    )

    entity.jobDetails = InvoiceJobDetailFormatter.fromResponseResourcesList(
      entity.jobDetails
    )

    return new Invoice(entity)
  }

  static toRequestResource(entity) {
    if (!entity) return null

    const resource = Object.assign({}, entity)

    this.formatDecimalsForRequestResource(
      resource,
      'amount',
      'vatAmount',
      'taxAmount',
      'stampAmount',
      'totalAmount',
      'aliquotaRitenuta'
    )

    resource.bankAccount = BankAccountFormatter.toResourceIdObject(
      resource.bankAccount
    )

    resource.branch = InvoiceBranchFormatter.toResourceIdObject(resource.branch)

    resource.details = InvoiceDetailFormatter.toRequestResourcesList(
      entity.details
    )

    resource.costCenterDetails = InvoiceCostCenterDetailFormatter.toRequestResourcesList(
      entity.costCenterDetails
    )

    resource.jobDetails = InvoiceJobDetailFormatter.toRequestResourcesList(
      entity.jobDetails
    )
    return this.fieldNamesForRequest(resource)
  }

  static fromResponseErrors(errors, resource = null) {
    const fieldErrors = resource ? errors[resource] : errors.invoPurchase

    const extraMessages = []

    const invoiceErrors = errors['invoPurchase']

    if (isString(invoiceErrors))
      extraMessages.push(`errors.invoice.${invoiceErrors}`)

    const purchaseErrors = errors['purchases']
    if (isObject(purchaseErrors)) {
      for (const index in purchaseErrors)
        extraMessages.push([
          `errors.invoice.purchase.${purchaseErrors[index]}`,
          { i: Number(index) + 1 }
        ])
    }

    const {
      fields: invoiceCostCenterDetailFields,
      extraMessages: invoiceCostCenterDetailExtraMessages
    } = InvoiceCostCenterDetailFormatter.fromResponseErrorsList(
      errors.invoPurchaseCostCenterRels
    )

    const {
      fields: invoiceJobDetailFields,
      extraMessages: invoiceJobDetailExtraMessages
    } = InvoiceJobDetailFormatter.fromResponseErrorsList(
      errors.invoPurchaseJobDetRels
    )

    let invoiceFieldErrors =
      this.fieldNamesFromResponse(fieldErrors, true) || {}
    invoiceFieldErrors.costCenterDetails = invoiceCostCenterDetailFields
    invoiceFieldErrors.jobDetails = invoiceJobDetailFields

    extraMessages.push(invoiceCostCenterDetailExtraMessages)
    extraMessages.push(invoiceJobDetailExtraMessages)

    return {
      fields: invoiceFieldErrors,
      extraMessages
    }
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    if (!resource) return null

    let {
      id,
      invoNum,
      invoVers,
      invoType,
      branch,
      invoDate,
      invoDueDate,
      invoPaymentDate,
      canPartiallyAmend,
      canAmend,
      canEdit,
      canSend,

      sdiSendDate,
      sdiInvoNum,
      sdiMessageCode,
      sdiCheck,
      sdiDate,

      invoName,
      invoVatNum,
      invoFcNum,
      invoAddress,
      invoSdi,
      invoPec,
      invoAmount,
      invoVatAmount,
      invoTaxAmount,
      invoStampAmount,
      invoTotalAmount,
      invoPaymentSource,

      notes,

      flagOk,
      naturaFatturaCodice,
      naturaFatturaDescr,
      riferimentoNormativo,

      bankAccount, // this is actually an id...
      bankAccountName,
      bankAccountIban,
      purchaseSourceDescr,
      tipoRitenuta,
      aliquotaRitenuta,

      invoPurchaseRels,
      invoPurchaseCostCenterRels,
      invoPurchaseJobDetRels
    } = resource

    const result = {
      id,
      code: invoNum,
      version: invoVers,
      type: invoType,
      branch,
      date: invoDate,
      dueDate: invoDueDate,
      paymentDate: invoPaymentDate,
      canPartiallyAmend,
      canAmend,
      canEdit,
      canSend,

      sdiInvoiceNumber: sdiInvoNum,
      sdiSubmissionDate: sdiSendDate,
      sdiResponseDate: sdiDate,
      sdiCheckResult: sdiCheck,
      sdiResponseCode: sdiMessageCode,

      name: invoName,
      vatCode: invoVatNum,
      fiscalCode: invoFcNum,
      address: invoAddress,
      sdi: invoSdi,
      pec: invoPec,
      amount: invoAmount,
      vatAmount: invoVatAmount,
      taxAmount: invoTaxAmount,
      stampAmount: invoStampAmount,
      totalAmount: invoTotalAmount,

      notes,

      issuable: flagOk,
      fiscalType: naturaFatturaCodice,
      fiscalTypeDescription: naturaFatturaDescr,
      fiscalTypeRegsReference: riferimentoNormativo,

      bankAccount, // this is actually an id...
      bankAccountName,
      bankAccountIban,
      purchaseSourceDescription: purchaseSourceDescr,
      paymentSourceDescription: invoPaymentSource,
      deductionType: tipoRitenuta,
      deductionRate: aliquotaRitenuta,

      details: invoPurchaseRels,
      costCenterDetails: invoPurchaseCostCenterRels,
      jobDetails: invoPurchaseJobDetRels
    }

    this.formatInternalValuesFromResponse(resource, result)
    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(entity, removeEmpty = false) {
    if (!entity) return null

    let {
      id,
      code,
      version,
      type,
      branch,
      date,
      dueDate,
      paymentDate,

      sdiInvoiceNumber,
      sdiSubmissionDate,
      sdiResponseDate,
      sdiCheckResult,
      sdiResponseCode,

      name,
      vatCode,
      fiscalCode,
      address,
      sdi,
      pec,
      amount,
      vatAmount,
      taxAmount,
      stampAmount,
      totalAmount,
      paymentSourceDescription,

      notes,

      issuable,
      fiscalType,
      fiscalTypeDescription,
      fiscalTypeRegsReference,

      bankAccount,
      /// These are not considered by the BE, ony bankAccount is
      //bankAccountName,
      //bankAccountDescription,
      purchaseSourceDescription,
      deductionType,
      deductionRate,

      details,
      costCenterDetails,
      jobDetails
    } = entity

    const result = {
      id,
      invoNum: code,
      invoVers: version,
      invoType: type,
      branch,
      invoDate: date,
      invoDueDate: dueDate,
      invoPaymentDate: paymentDate,

      sdiInvoNum: sdiInvoiceNumber,
      sdiSendDate: sdiSubmissionDate,
      sdiDate: sdiResponseDate,
      sdiCheck: sdiCheckResult,
      sdiMessageCode: sdiResponseCode,

      invoName: name,
      invoVatNum: vatCode,
      invoFcNum: fiscalCode,
      invoAddress: address,
      invoSdi: sdi,
      invoPec: pec,
      invoAmount: amount,
      invoVatAmount: vatAmount,
      invoTaxAmount: taxAmount,
      invoStampAmount: stampAmount,
      invoTotalAmount: totalAmount,
      invoPaymentSource: paymentSourceDescription,

      notes,

      flagOk: issuable,
      naturaFatturaCodice: fiscalType,
      naturaFatturaDescr: fiscalTypeDescription,
      riferimentoNormativo: fiscalTypeRegsReference,

      bankAccount,
      /// These are not considered by the BE, ony bankAccount is
      //bankAccountName: bankAccountName,
      //bankAccountDescr: bankAccountDescription,
      purchaseSourceDescr: purchaseSourceDescription,
      tipoRitenuta: deductionType,
      aliquotaRitenuta: deductionRate,

      invoPurchaseRels: details,
      invoPurchaseCostCenterRels: costCenterDetails,
      invoPurchaseJobDetRels: jobDetails
    }

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }
}
