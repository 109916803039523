<template>
  <div>
    <v-tabs v-model="tab" fixed-tabs>
      <v-tab key="search">{{ $t('common.contract.search') }}</v-tab>
      <v-tab key="create">{{ $t('common.contract.create') }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="search">
        <div class="tab-item-content">
          <v-container>
            <ContractSelect
              ref="autocomplete"
              v-model="selectedContract"
              required
            />
            <ContractCard v-if="selectedContract" :entity="selectedContract" />
          </v-container>
        </div>
      </v-tab-item>

      <v-tab-item key="create">
        <div class="tab-item-content">
          <ContractForm ref="form" v-model="newContract" :errors="errors" />
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { get } from 'vuex-pathify'
import handlesErrors from '@/mixins/handlesErrors.js'

import ContractForm from './ContractForm.vue'
import ContractSelect from './ContractSelect.vue'
import ContractCard from './ContractCard.vue'

import Contract from '@/domain/entities/Contract'

export default {
  name: 'ContractSelectOrCreate',
  mixins: [handlesErrors],
  components: { ContractForm, ContractSelect, ContractCard },
  props: { value: Contract },
  data() {
    return {
      tab: 0,
      newContract: Contract.default(),
      selectedContract: this.value,
      contract: null,
      errors: {}
    }
  },
  computed: { user: get('user') },
  methods: {
    validate() {
      switch (this.tab) {
        case 0:
          return this.$refs.autocomplete.validate()
        case 1:
          return this.$refs.form.validate()
      }
      return false
    },
    reset() {
      this.contract = null
      this.newContract = Contract.default()
      this.selectedContract = null
    }
  },
  watch: {
    tab() {
      switch (this.tab) {
        case 0:
          this.contract = this.selectedContract
          break
        case 1:
          this.contract = this.newContract
          break
        default:
          this.contract = null
      }
    },
    selectedContract() {
      if (this.selectedContract && this.selectedContract.id) {
        this.contract = this.selectedContract
      }
    },
    contract: {
      handler() {
        this.$emit('input', this.contract)
      },
      deep: true
    }
  }
}
</script>
