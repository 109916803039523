import axios from '@/axios.js'

import PurchaseFormatter from '../formatters/Purchase'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, id) {
  try {
    const { data } = await axios.get(`/api/v1/purchases/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    })

    const purchase = PurchaseFormatter.fromResponseResource(data.purchase)

    return { error: false, purchase }
  } catch (e) {
    return exceptionToResponse(e)
  }
}
