import CostCenterDetail from '@/domain/entities/CostCenterDetail'
import Base from './Base'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null
    const entity = this.fieldNamesFromResponse(resource)
    return new CostCenterDetail(entity)
  }

  static toRequestResource(entity) {
    if (!entity) return null
    return this.fieldNamesForRequest(entity)
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    const { id, name } = resource

    const result = {
      id,
      name
    }

    this.formatInternalValuesFromResponse(resource, result)

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(entity, removeEmpty = false) {
    const { id, name } = entity

    const result = {
      id,
      name
    }

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }
}
