import Vue from 'vue'
import Vuex from 'vuex'
import pathify from '@/plugins/store/vuex-pathify'

import app from './modules/app'

Vue.use(Vuex)

const state = {
  user: null
}
const actions = {
  getUser: ({ state }) => {
    if (state.user) return state.user

    let user
    const local = localStorage.getItem('user@mmo-admin')

    if (!local) return null

    try {
      user = JSON.parse(local)
    } catch (e) {
      user = null
    }

    if (user) state.user = user
    return user
  },
  setUser: ({ state }, user) => {
    state.user = user
    localStorage.setItem('user@mmo-admin', JSON.stringify(user))
  }
}

export default new Vuex.Store({
  state,
  actions,
  modules: { app },
  plugins: [pathify.plugin]
})
