import axios from '@/axios.js'
import exceptionToResponse from '../../formatters/exceptionToResponse'

export default async function(token, id, invoiceDocument) {
  if (!invoiceDocument) return null

  const headers = { Authorization: `Bearer ${token}` }
  const payload = new FormData()
  payload.append('file', invoiceDocument)

  try {
    const { data } = await axios.post(`/api/v1/payments/${id}/file`, payload, {
      headers
    })

    return { error: false, invoiceLink: data.path }
  } catch (e) {
    return exceptionToResponse(e)
  }
}
