import Base from './Base'
import { isEmpty, isObject } from '@/lib/Utils'
import Decimal from '@/lib/Decimal'
import Product from '@/domain/entities/Product'
import ProductCategoryFormatter from './ProductCategory'
import ProductTypeFormatter from './ProductType'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null

    const entity = this.fieldNamesFromResponse(resource)

    entity.category = ProductCategoryFormatter.fromResponseResource(
      entity.category
    )
    entity.type = ProductTypeFormatter.fromResponseResource(entity.type)
    entity.price = Decimal.fromBE(entity.price).forUI()
    entity.language = entity.language ? entity.language.toUpperCase() : ''

    return new Product(entity)
  }

  static toRequestResource(product) {
    if (!product) return null

    const resource = this.fieldNamesForRequest(product)

    resource.prodCategory = ProductCategoryFormatter.toResourceIdObject(
      product.category
    )
    resource.prodType = ProductCategoryFormatter.toResourceIdObject(
      product.type
    )
    resource.listPrice = Decimal.fromUI(product.price).forBE()
    resource.languageUppercase = product.language
      ? product.language.toUpperCase()
      : null

    return resource
  }

  static formatPlaceholdersResource(resource) {
    if (isEmpty(resource)) return null
    const entity = this.fieldNamesFromResponse(resource, true)
    if (entity.category)
      entity.category = ProductCategoryFormatter.fromResponseResource(
        entity.category
      )
    if (entity.type)
      entity.type = ProductTypeFormatter.fromResponseResource(entity.type)
    if (entity.price) entity.price = Decimal.fromBE(entity.price).forUI()

    return entity
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    if (!resource) return null

    const {
      id,
      prodCode,
      creationDate,
      expiryDate,
      maxAttendees,
      minAttendees,
      notes,
      listPrice,
      prodLanguage,
      durationDays,
      prodDesc,
      durationHours,
      prodRef,
      prodCategory,
      prodName,
      prodType
    } = resource

    const result = {
      id,
      name: prodName,
      code: prodCode,
      category: prodCategory,
      type: prodType,
      price: listPrice,
      language: prodLanguage,
      description: prodDesc,
      fromDate: creationDate,
      toDate: expiryDate,
      durationHours,
      durationDays,
      prerequisites: prodRef,
      minAttendees,
      maxAttendees,
      notes
    }

    this.formatInternalValuesFromResponse(resource, result)

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(entity, removeEmpty = false) {
    if (!entity) return null

    const {
      id,
      name,
      code,
      category,
      type,
      description,
      price,
      language,
      fromDate,
      toDate,
      durationHours,
      durationDays,
      prerequisites,
      minAttendees,
      maxAttendees,
      notes,
      startDate,
      endDate
    } = entity

    const result = {
      id,
      prodName: name,
      prodCode: code,
      prodCategory: isObject(category)
        ? ProductCategoryFormatter.fieldNamesForRequest(category, removeEmpty)
        : category,
      prodType: isObject(type)
        ? ProductTypeFormatter.fieldNamesForRequest(type, removeEmpty)
        : type,
      listPrice: price,
      prodLanguage: language,
      prodDesc: description,
      durationDays,
      durationHours,
      prodRef: prerequisites,
      minAttendees,
      maxAttendees,
      creationDate: fromDate,
      expiryDate: toDate,
      notes,
      startDate,
      endDate
    }

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }
}
