import Person from './Person'
import PersonCompanies from './PersonCompanies'
export default class PersonAndCompanies {
  static newCustomer() {
    return new this({
      person: new Person(),
      personCompanies: [PersonCompanies.newCustomerPersonCompany()]
    })
  }

  static newSupplier() {
    return new this({
      person: new Person(),
      personCompanies: [PersonCompanies.newSupplierPersonCompany()]
    })
  }

  static defaultCustomer() {
    return this.newCustomer()
  }

  static defaultSupplier() {
    return this.newSupplier()
  }

  constructor({ person = null, personCompanies = [] } = {}) {
    this.person = person
    this.personCompanies = personCompanies
  }
}
