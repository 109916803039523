/**
 * Utility function to validate authentication data used in REST requests.
 */
import Error from './Error'

export default function(user) {
  if (!user || !user.token)
    return new Error({
      message: 'errors.auth.unauthorized',
      status: 401
    })
  return true
}
