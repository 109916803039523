import validateUser from '@/lib/validateUser'
import suppliersListAdapter from '@/adapters/backoffice/suppliers/list'

export default class {
  user = null

  constructor(user) {
    this.user = user
  }

  async call(options) {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    return await suppliersListAdapter(this.user.token, options)
  }
}
