export default class {
  constructor({
    id = null,
    type = null,
    owner = null,
    bankAccount = null
  } = {}) {
    this.id = id
    this.type = type
    this.owner = owner
    this.bankAccount = bankAccount
  }
}
