<!-- 
  Allows to pick one or more customer/supplier.
  Do not use directly. See CustomerSelect and SupplierSelect.
-->
<template>
  <v-autocomplete
    :class="_class"
    :label="label"
    v-model="item"
    :items="items"
    return-object
    item-value="id"
    :search-input.sync="searchword"
    cache-items
    clearable
    no-filter
    :multiple="multiple"
    :chips="multiple"
    :no-data-text="noDataText"
    :loading="loading"
    :rules="_rules"
    :required="required"
    :error-messages="error"
    :dense="!multiple"
    :hint="hint"
    @input="onChange"
  >
    <template v-slot:selection="data">
      {{ data.item | dig('person', 'name') }}
      {{ data.item | dig('person', 'surname') }}
      ({{ data.item | dig('company', 'invoiceName') }})
    </template>

    <template v-slot:item="data">
      <v-list-item-avatar>
        <img v-if="data.item.person.photo" :src="data.item.person.photo" />
        <v-icon v-else>mdi-account-details</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ data.item | dig('person', 'name') }}
          {{ data.item | dig('person', 'surname') }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ data.item | dig('company', 'invoiceName') }}
          {{ data.item | dig('company', 'fiscalCode') }}
          {{ data.item | dig('company', 'vatCode') }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { isArray } from '@/lib/Utils'
import actsAsAnAutocomplete from '@/mixins/actsAsAnAutocomplete'

import PersonAndCompany from '@/domain/entities/PersonAndCompany'
import GetCustomers from '@/domain/useCases/customers/GetCustomers'
import GetSuppliers from '@/domain/useCases/suppliers/GetSuppliers'

// Search for Customers or Suppliers NOT both
export default {
  name: 'PersonAndCompanySelect',
  mixins: [actsAsAnAutocomplete],
  props: { multiple: Boolean, supplier: Boolean },
  data() {
    return {
      actsAsAnAutocomplete: {
        resource: this.supplier ? 'suppliers' : 'customers',
        get: this.supplier ? GetSuppliers : GetCustomers,
        formatter: 'toPersonAndCompanyList'
      }
    }
  },
  methods: {
    toPersonAndCompanyList(personAndCompaniesList) {
      const constructor = this.supplier
        ? PersonAndCompany.newSupplier
        : PersonAndCompany.newCustomer
      const personAndCompanyList = personAndCompaniesList.reduce(
        (items, customer) => {
          customer.personCompanies.forEach(personCompany => {
            const { id, company, position, roles } = personCompany
            items.push(
              constructor({
                id,
                person: customer.person,
                company,
                position,
                roles
              })
            )
          })
          return items
        },
        []
      )
      return personAndCompanyList
    },
    remove(item) {
      if (!this.multiple || !isArray(this.value)) return
      const newValue = this.value.filter(element => {
        element.id === item.id
      })
      this.$emit('input', newValue)
    }
  }
}
</script>
