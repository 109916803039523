import preparePurchaseCustomerContractAdapter from '@/adapters/backoffice/purchases/prepareCustomerContract'
import Error from '@/lib/Error'

export default class {
  user = null

  constructor(user) {
    this.user = user
  }

  async call(id) {
    if (!this.user || !this.user.token)
      return new Error({ message: 'errors.auth.unauthorized' })

    return await preparePurchaseCustomerContractAdapter(this.user.token, id)
  }
}
