import axios from '@/axios.js'

import EditionFormatter from '../formatters/Edition'
import resourceErrorsToResponse from '../formatters/resourceErrorsToResponse'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, id, data) {
  const headers = { Authorization: `Bearer ${token}` }
  const payload = { edition: EditionFormatter.toRequestResource(data) }

  if (!data) {
    return {
      error: true,
      errorData: { message: 'errors.resource.invalid' }
    }
  }

  try {
    const { data } = await axios.put(`/api/v1/editions/${id}`, payload, {
      headers
    })
    const edition = EditionFormatter.fromResponseResource(data.edition)
    return { error: false, edition }
  } catch (e) {
    return (
      resourceErrorsToResponse(e, EditionFormatter) || exceptionToResponse(e)
    )
  }
}
