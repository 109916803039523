<template>
  <v-form ref="form" @submit.prevent="authSubmitted()">
    <v-text-field
      v-model="email"
      type="email"
      :label="$t('form.login.email')"
      prepend-icon="mdi-account"
      required
    ></v-text-field>

    <v-text-field
      v-model="password"
      :append-icon="!showPassword ? 'mdi-eye' : 'mdi-eye-off'"
      :type="showPassword ? 'text' : 'password'"
      @click:append="showPassword = !showPassword"
      :label="$t('form.login.password')"
      prepend-icon="mdi-lock"
      required
    ></v-text-field>

    <div class="text-center">
      <v-btn color="primary" type="submit">{{ $t('form.login.submit') }}</v-btn>
    </div>
  </v-form>
</template>

<script>
export default {
  name: 'LoginForm',
  props: {
    onLoginRequest: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      email: 'adm@makemoneyorganization.com',
      password: null,
      showPassword: false
    }
  },
  methods: {
    authSubmitted() {
      this.email = this.email.trim()
      this.password = this.password.trim()
      if (this.email !== '' && this.password !== '') {
        this.onLoginRequest(this.email, this.password)
      }
    }
  }
}
</script>

<style scoped>
button[type='submit'] {
  width: 70%;
  margin: auto;
}
</style>
