import Base from './Base'
import Vat from '@/domain/entities/Vat'
import Decimal from '@/lib/Decimal'
import { isEmpty } from '@/lib/Utils'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null

    const entity = this.fieldNamesFromResponse(resource)

    const value = Decimal.fromBE(entity.value)
    entity.value = value.forUI()

    return new Vat(entity)
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    if (!resource) return null

    let { id, vatValue } = resource
    const value = isEmpty(vatValue) ? null : String(vatValue)
    const result = { id, value }

    this.formatInternalValuesFromResponse(resource, result)
    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(entity, removeEmpty = false) {
    if (!entity) return null

    let { id, value } = entity

    const vatValue = isEmpty(value) ? null : Number(value)

    const result = { id, vatValue }

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }
}
