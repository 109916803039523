import axios from '@/axios.js'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, id) {
  try {
    await axios.delete(`/api/v1/offers/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return { error: false }
  } catch (e) {
    return exceptionToResponse(e)
  }
}
