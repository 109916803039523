import axios from '@/axios.js'
import exceptionToResponse from '../formatters/exceptionToResponse'
import ProductPriceList from '../formatters/ProductPriceList'

export default async function(token, id) {
  try {
    const { data } = await axios.get(`/api/v1/products/${id}/priceLists`, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return {
      error: false,
      productPriceList: ProductPriceList.fromResponseResourcesList(data.results)
    }
  } catch (e) {
    return exceptionToResponse(e)
  }
}
