import axios from '@/axios.js'

import InvoiceFormatter from '../formatters/Invoice'
import exceptionToResponse from '../formatters/exceptionToResponse'
import resourceErrorsToResponse from '../formatters/resourceErrorsToResponse'

export default async function(token, id) {
  try {
    await axios.delete(`/api/v1/invoPurchases/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return { error: false }
  } catch (e) {
    return (
      resourceErrorsToResponse(e, InvoiceFormatter, 'invoPurchase') ||
      exceptionToResponse(e)
    )
  }
}
