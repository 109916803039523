<template>
  <v-form ref="form" @submit.prevent>
    <v-container>
      <v-row>
        <v-col cols="12" lg="12">
          <TextField
            :label="$t('entities.product.name')"
            v-model="entity.name"
            required
            :errors="errors.name"
          />
        </v-col>

        <v-col cols="12" lg="4">
          <TextField
            :label="$t('entities.product.code')"
            v-model="entity.code"
            required
            :errors="errors.code"
          />
        </v-col>

        <v-col cols="12" lg="2">
          <LanguageSelect
            :label="$t('entities.product.language')"
            v-model="entity.language"
            :errors="errors.language"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <ProductCategorySelect
            :label="$t('entities.product.category')"
            v-model="entity.category"
            required
            :errors="errors.category"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <ProductTypeSelect
            :label="$t('entities.product.type')"
            v-model="entity.type"
            required
            :errors="errors.type"
          />
        </v-col>

        <v-col cols="12" lg="12">
          <Textarea
            :label="$t('entities.product.description')"
            v-model="entity.description"
            required
            :errors="errors.description"
          />
        </v-col>

        <v-col cols="12" lg="2">
          <TextField
            type="currency"
            :label="$t('entities.product.price')"
            v-model="entity.price"
            required
            :errors="errors.price"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <TextField
            type="number"
            min="1"
            :label="$t('entities.product.minAttendees')"
            v-model="entity.minAttendees"
            required
            :errors="errors.minAttendees"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <TextField
            type="number"
            :min="0"
            :label="$t('entities.product.maxAttendees')"
            v-model="entity.maxAttendees"
            required
            :errors="errors.maxAttendees"
          />
        </v-col>

        <v-col cols="12" lg="2">
          <TextField
            type="number"
            min="0"
            :label="$t('entities.product.durationHours')"
            v-model="entity.durationHours"
            required
            :errors="errors.durationHours"
          />
        </v-col>

        <v-col cols="12" lg="2">
          <TextField
            type="number"
            min="0"
            :label="$t('entities.product.durationDays')"
            v-model="entity.durationDays"
            required
            :errors="errors.durationDays"
          />
        </v-col>

        <v-col cols="12" lg="12">
          <Textarea
            :label="$t('entities.product.prerequisites')"
            v-model="entity.prerequisites"
            :errors="errors.prerequisites"
          />
        </v-col>

        <v-col cols="12">
          <Textarea
            :label="$t('entities.product.notes')"
            v-model="entity.notes"
            :errors="errors.notes"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import actsAsAForm from '@/mixins/actsAsAForm'

import { TextField, Textarea } from '@/components/common/form'
import ProductTypeSelect from '@/components/products/ProductTypeSelect.vue'
import ProductCategorySelect from '@/components/products/ProductCategorySelect.vue'
import LanguageSelect from '@/components/common/LanguageSelect.vue'

import Product from '@/domain/entities/Product'
import Error from '@/lib/Error'

export default {
  name: 'ProductForm',
  mixins: [actsAsAForm],
  components: {
    ProductTypeSelect,
    ProductCategorySelect,
    LanguageSelect,
    TextField,
    Textarea
  },
  props: { value: Product, errors: Object },
  methods: {
    validate() {
      const formValid = this.$refs.form.validate()
      let formReallyValid = true
      const { maxAttendees: max, minAttendees: min } = this.entity

      if (max < min) {
        new Error({
          message: this.$t('max-and-min-attendees-not-congruent')
        }).saveAlert(this)
        formReallyValid = false
      }

      return formValid && formReallyValid
    }
  }
}
</script>
