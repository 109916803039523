<template>
  <app-card class="mt-4 text-center person-data-menu">
    <slot name="avatar" />
    <v-card-text class="mt-0 pt-0 text-left">
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="headline">
            {{ entity.name }}
            {{ entity.surname }}
          </v-list-item-title>
          <v-list-item-title class="headline" v-if="isCustomer">
            <v-chip
              v-if="inCoaching"
              class="ma-2"
              color="green"
              text-color="white"
            >
              {{ $t('common.customer.active-coaching') }}
            </v-chip>
            <v-chip v-else class="ma-2" color="red" text-color="white">
              {{ $t('common.customer.inactive-coaching') }}
            </v-chip>
          </v-list-item-title>
          <v-list-item-subtitle v-if="entity.company">
            {{ entity.company }}
            <span v-if="entity.role" v-html="`(${entity.role})`" />
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="entity.email" class="less-height">
        <v-list-item-icon class="less-vertical-margin mr-1">
          <v-icon small>mdi-email</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle class="text-left">{{
          entity.email
        }}</v-list-item-subtitle>
      </v-list-item>

      <v-list-item v-if="entity.phone" class="less-height">
        <v-list-item-icon class="less-vertical-margin mr-1">
          <v-icon small>mdi-phone-classic</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle class="text-left">{{
          entity.phone
        }}</v-list-item-subtitle>
      </v-list-item>

      <v-list-item v-if="entity.mobile" class="less-height">
        <v-list-item-icon class="less-vertical-margin mr-1">
          <v-icon small>mdi-cellphone</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle class="text-left">{{
          entity.mobile
        }}</v-list-item-subtitle>
      </v-list-item>
    </v-card-text>

    <v-card-text v-if="entity.notes" class="pt-0 text-left text--secondary">
      <h3>{{ $t('entities.person.notes') }}</h3>
      {{ entity.notes | truncate(200) }}
    </v-card-text>

    <v-card-text
      v-if="entity.intolerances"
      class="pt-0 text-left text--secondary"
    >
      <h3>{{ $t('entities.person.intolerances') }}</h3>
      {{ entity.intolerances | truncate(200) }}
    </v-card-text>

    <slot />

    <v-card-text>
      <slot name="controls" />
    </v-card-text>
  </app-card>
</template>

<script>
import Person from '@/domain/entities/Person'

export default {
  name: 'PersonSummary',
  props: { entity: Person, isCustomer: Boolean },
  computed: {
    inCoaching: function() {
      return (
        this.entity.coachingContracts &&
        this.entity.coachingContracts.length > 0
      )
    }
  }
}
</script>

<style scoped>
.person-data-menu {
  position: sticky;
  top: 50px;
}

.less-height {
  line-height: 24px !important;
  max-height: 24px !important;
  min-height: 24px !important;
}

.less-vertical-margin {
  margin: auto 0 !important;
}
</style>
