import { dig } from '@/lib/Utils'

export function formatPersonAndCompany(personAndCompany) {
  if (!personAndCompany) return ''

  const name = dig(personAndCompany, 'person', 'name') || ''
  const surname = dig(personAndCompany, 'person', 'surname') || ''
  const company = dig(personAndCompany, 'company', 'invoiceName') || ''

  let result = `${name} ${surname}`
  if (company) result += ` (${company})`
  return result
}
