<template>
  <v-form ref="form" @submit.prevent>
    <v-container>
      <v-row>
        <v-col cols="12" lg="3">
          <TextField
            type="currency"
            :label="$t('entities.offer.editionsTotalPrice')"
            v-model="entity.editionsTotalPrice"
            readonly
            :error="errors.editionsTotalPrice"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <TextField
            type="currency"
            :label="$t('entities.offer.offerPrice')"
            :value="entity.offerPrice"
            readonly
            :error="errors.offerPrice"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <TextField
            type="currency"
            :label="$t('entities.offer.deposit')"
            v-model="entity.deposit"
            required
            :error="errors.deposit"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <TextField
            type="number"
            :label="$t('entities.offer.paymentDelay')"
            v-model="entity.paymentDelay"
            required
            :error="errors.paymentDelay"
          />
        </v-col>

        <v-col cols="12" lg="2">
          <TextField
            type="number"
            :label="$t('entities.offer.installmentsNumber')"
            v-model="entity.installmentsNumber"
            required
            :error="errors.installmentsNumber"
          />
        </v-col>

        <v-col cols="12" lg="2">
          <TextField
            type="currency"
            :label="$t('entities.offer.installmentsAmountEach')"
            v-model="entity.installmentsAmountEach"
            required
            :error="errors.installmentsAmountEach"
          />
        </v-col>

        <v-col cols="12" lg="2">
          <TextField
            type="currency"
            :label="$t('entities.offer.installmentsAmountExtra')"
            v-model="entity.installmentsAmountExtra"
            required
            :error="errors.installmentsAmountExtra"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="2">
          <v-checkbox
            :label="$t('entities.offer.standardDiscountApplicable')"
            v-model="entity.standardDiscountApplicable"
            :error="errors.standardDiscountApplicable"
          />
        </v-col>

        <v-col cols="12" lg="2">
          <v-checkbox
            :label="$t('entities.offer.discountOverride')"
            v-model="entity.discountOverride"
            :error="errors.discountOverride"
          />
        </v-col>
      </v-row>

      <v-row v-if="showDiscounts">
        <v-col>
          <v-card>
            <v-card-title>
              {{ $t('entities.offer.contractOverrides.title') }}
            </v-card-title>
            <OverridesTable
              v-model="entity"
              :errors="errors.contractOverrides"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Decimal from '@/lib/Decimal'
import ActsAsAForm from '@/mixins/actsAsAForm'
import { TextField } from '@/components/common/form'
import OverridesTable from '@/components/offers/OfferContractOverridesEditableTable'

import Offer from '@/domain/entities/Offer'

export default {
  name: 'OfferPricesForm',
  mixins: [ActsAsAForm],
  components: { TextField, OverridesTable },
  props: {
    value: Offer,
    errors: Object
  },
  computed: {
    showDiscounts() {
      return (
        this.entity.standardDiscountApplicable || this.entity.discountOverride
      )
    }
  },
  methods: {
    updateFinalPrice() {
      // finalPrice = deposit + extra + (installmentsNumber - delay)*installmentsAmount
      const installmentsNumberDecimal =
        Decimal.fromUI(this.entity.installmentsNumber) || Decimal.zero()
      const installmentsAmountEachDecimal =
        Decimal.fromUI(this.entity.installmentsAmountEach) || Decimal.zero()

      const finalPrice = Decimal.sumFromUI(
        this.entity.deposit,
        installmentsAmountEachDecimal
          .multiply(
            installmentsNumberDecimal.subtract(
              Decimal.fromUI(this.entity.paymentDelay) || Decimal.zero()
            )
          )
          .forUI(),
        this.entity.installmentsAmountExtra
      )
      this.entity.offerPrice = finalPrice
    }
  },
  watch: {
    'entity.standardDiscountApplicable': function() {
      if (this.entity.standardDiscountApplicable)
        this.entity.discountOverride = false
    },
    'entity.discountOverride': function() {
      if (this.entity.discountOverride)
        this.entity.standardDiscountApplicable = false
    },
    'entity.deposit': function() {
      this.updateFinalPrice()
    },
    'entity.installmentsAmountEach': function() {
      this.updateFinalPrice()
    },
    'entity.installmentsNumber': function() {
      this.updateFinalPrice()
    },
    'entity.installmentsAmountExtra': function() {
      this.updateFinalPrice()
    },
    'entity.paymentDelay': function() {
      this.updateFinalPrice()
    }
  }
}
</script>
