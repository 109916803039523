import Vue from 'vue'
import VueI18n from 'vue-i18n'

import it from '../locales/it.js'

Vue.use(VueI18n)

let locale = process.env.VUE_APP_I18N_LOCALE
let fallback = process.env.VUE_APP_I18N_FALLBACK_LOCALE
let silentTranslationWarn = false
let messages = { it }

export default new VueI18n({
  locale: locale || 'it',
  fallbackLocale: fallback || 'it',
  messages,
  silentTranslationWarn
})
