import Base from './Base'
import PaymentCard from '@/domain/entities/PaymentCard'
import BankAccountFormatter from './BankAccount'
export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null

    const entity = this.fieldNamesFromResponse(resource)
    entity.bankAccount = BankAccountFormatter.fromResponseResource(
      resource.bankAccount
    )
    return new PaymentCard(entity)
  }

  static toRequestResource(entity) {
    if (!entity) return null

    const resource = this.fieldNamesForRequest(entity)

    resource.bankAccount = BankAccountFormatter.toRequestResource(
      entity.bankAccount
    )
    return resource
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    if (!resource) return null

    const { id, type, owner, bankAccount } = resource
    const result = { id, type, owner, bankAccount }

    this.formatInternalValuesFromResponse(resource, result)

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(entity, removeEmpty = false) {
    if (!entity) return null

    const { id, type, owner, bankAccount } = entity
    const result = { id, type, owner, bankAccount }

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static formatForUI(entity) {
    if (!entity) return null
    const type = entity.type ? `${entity.type} - ` : ''
    return `${type} ${entity.owner}`
  }
}
