import {
  CustomerContractsListPage,
  NewCustomerContractPage,
  EditCustomerContractPage,
  ShowCustomerContractPage
} from '@/views/customerContracts/index.js'

export default [
  {
    path: '/customerContracts',
    name: 'CustomerContractsPage',
    component: CustomerContractsListPage,
    meta: {
      title: 'menu.long.resources.customerContracts'
    }
  },
  {
    path: '/customerContracts/new',
    name: 'NewCustomerContractPage',
    component: NewCustomerContractPage,
    props: true,
    meta: {
      title: 'menu.long.resources.new-customerContract'
    }
  },
  {
    path: '/customerContracts/:id/edit',
    name: 'EditCustomerContractPage',
    component: EditCustomerContractPage,
    props: true,
    meta: {
      title: 'menu.long.resources.edit-customerContract'
    }
  },
  {
    path: '/customerContracts/:id',
    name: 'ShowCustomerContractPage',
    component: ShowCustomerContractPage,
    props: true,
    meta: {
      title: 'menu.long.resources.show-customerContract'
    }
  }
]
