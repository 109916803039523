<template>
  <material-card
    icon="mdi-card-account-details"
    icon-small
    color="primary"
    :title="$t('common.sections.titles.person')"
  >
    <v-container>
      <v-row>
        <v-col cols="12" lg="4">
          <CardTextField
            :label="$t('entities.person.name')"
            :value="person.name"
          />
        </v-col>

        <v-col cols="12" lg="4">
          <CardTextField
            :label="$t('entities.person.surname')"
            :value="person.surname"
          />
        </v-col>

        <v-col cols="12" lg="4">
          <CardTextField
            :label="$t('entities.person.fiscalCode')"
            :value="person.fiscalCode"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <CardDateField
            :label="$t('entities.person.birthDate')"
            :value="person.birthDate"
            readonly
          />
        </v-col>

        <v-col cols="12" lg="3">
          <CardTextField
            :label="$t('entities.person.email')"
            :value="person.email"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <CardTextField
            :label="$t('entities.person.mobile')"
            :value="person.mobile"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <CardTextField
            :label="$t('entities.person.phone')"
            :value="person.phone"
          />
        </v-col>

        <v-col cols="12">
          <CardTextField
            :label="$t('entities.person.address1')"
            :value="person.address1"
          />
        </v-col>

        <v-col cols="12">
          <CardTextField
            :label="$t('entities.person.address2')"
            :value="person.address2"
          />
        </v-col>

        <v-col cols="12" lg="2">
          <CardTextField
            :label="$t('entities.person.postalCode')"
            :value="person.postalCode"
            outlined
            filled
          />
        </v-col>

        <v-col cols="12" lg="3">
          <CardTextField
            :label="$t('entities.person.city')"
            :value="person.city"
          />
        </v-col>

        <v-col cols="12" lg="2">
          <CardTextField
            :label="$t('entities.person.county')"
            :value="person.county"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <CardTextField
            :label="$t('entities.person.region')"
            :value="person.region"
          />
        </v-col>

        <v-col cols="12" lg="2">
          <CardTextField
            :label="$t('entities.person.nation')"
            :value="nation"
          />
        </v-col>

        <v-col cols="12">
          <CardTextarea
            :label="$t('entities.person.notes')"
            :value="person.notes"
          />
        </v-col>

        <v-col cols="12">
          <CardTextarea
            :label="$t('entities.person.intolerances')"
            :value="person.intolerances"
          />
        </v-col>

        <v-col cols="12" lg="2">
          <CardYesNoField
            :value="person.active"
            :label="$t('entities.person.active')"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <CardDateField
            :label="$t('entities.person.startDate')"
            :value="person.startDate"
            readonly
          />
        </v-col>

        <v-col cols="12" lg="3">
          <CardDateField
            :label="$t('entities.person.endDate')"
            :value="person.endDate"
            readonly
          />
        </v-col>

        <v-col cols="12" lg="3">
          <CardDateField
            :value="person.lastModified"
            :label="$t('common.fields.lastModified')"
            show-time
          />
        </v-col>

        <v-col cols="12" lg="3">
          <CardTextField
            :label="$t('common.fields.lastModifiedBy')"
            :value="person.lastModifiedBy"
          />
        </v-col>
      </v-row>
    </v-container>
  </material-card>
</template>

<script>
import { get } from 'vuex-pathify'

import {
  CardTextField,
  CardDateField,
  CardTextarea,
  CardYesNoField
} from '@/components/common/card'
import Person from '@/domain/entities/Person'

export default {
  name: 'PersonCard',
  components: {
    CardTextField,
    CardDateField,
    CardTextarea,
    CardYesNoField
  },
  props: { person: { type: Person } },
  computed: {
    nations: get('app/nations'),
    nation() {
      const nation = this.person.nation
      if (!nation) return ''

      const nationConfig = this.nations.find(n => n.value === nation)
      return nationConfig.text || ''
    }
  }
}
</script>
