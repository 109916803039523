import validateUser from '@/lib/validateUser'
import customerContractCreateAdapter from '@/adapters/backoffice/customerContracts/create'

export default class {
  user = null
  customerContract = null

  constructor(user, customerContract) {
    this.user = user
    this.customerContract = customerContract
  }

  async call() {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    if (!this.customerContract)
      return {
        error: true,
        errorData: { message: 'errors.resource.invalid' }
      }

    if (this.customerContract.id)
      return {
        error: true,
        errorData: { message: 'errors.resource.existing' }
      }

    return await customerContractCreateAdapter(
      this.user.token,
      this.customerContract
    )
  }
}
