<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12" lg="9">
        <material-card
          icon="mdi-archive"
          icon-small
          :title="$t('common.sections.titles.product')"
          color="primary"
        >
          <ProductForm ref="form" v-model="entity" :errors="errors" />
        </material-card>
      </v-col>
      <v-col cols="12" lg="3" order="first" order-lg="last">
        <ProductSummary :entity="entity">
          <v-divider />
          <template #controls>
            <v-row>
              <v-col cols="3" md="6">
                <v-btn color="success" width="100%" @click="save()">
                  {{ $t('common.form.save') }}
                </v-btn>
              </v-col>
              <v-col cols="3" md="6">
                <v-btn width="100%" color="warning" @click="leave()">
                  {{ $t('common.leave') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </ProductSummary>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsAnEditPage from '@/mixins/actsAsAnEditPage'

import ProductForm from '@/components/products/ProductForm.vue'
import ProductSummary from '@/components/products/ProductSummary'

import Product from '@/domain/entities/Product'
import GetProduct from '@/domain/useCases/products/GetProduct'
import EditProduct from '@/domain/useCases/products/EditProduct'

export default {
  name: 'EditProductPage',
  mixins: [actsAsAnEditPage],
  components: { ProductForm, ProductSummary },
  props: { id: String },
  data() {
    return {
      actsAsAnEditPage: {
        resource: 'products',
        entity: 'product',
        get: GetProduct,
        edit: EditProduct
      },
      entity: Product.default()
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
