import purchaseGetAdapter from '@/adapters/backoffice/purchases/get'

export default class {
  user = null

  constructor(user) {
    this.user = user
  }

  async call(id) {
    if (!this.user || !this.user.token)
      return {
        error: true,
        errorData: { message: 'errors.auth.unauthorized' }
      }

    return await purchaseGetAdapter(this.user.token, id)
  }
}
