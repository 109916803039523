/**
 * Adds basic behaviours for list components: sorting, paging and filtering
 * options among other things.
 *
 * Expects:
 *  - data.page: should change whenever the current page changes (page as in results paging);
 *
 * Provides:
 *  - data.options:              Object, should be used in the list's data-tables prop with the same name
 *
 *  - this.searchbarSubmitted(): should be called when new search filters are requested,
 *                               e.g. by submitting the searchbar form;
 *
 *  - data.errors:               an Object where to put error information;
 *
 * Emits:
 *  - @refresh(listOptions): emitted when the list options or search filters change,
 *                           should be handled by the view containing this component;
 *                           listOptions is created by adding a "filters" entry to data.options
 */
export default {
  data() {
    return {
      options: { sortBy: ['lastModified'], sortDesc: [false] },
      footerOptions: {
        itemsPerPageOptions: [25, 50, 100]
      },
      listOptions: {}
    }
  },
  methods: {
    searchbarSubmitted(filters) {
      this.updateListOptions(filters)
      this.$emit('refresh', this.listOptions)
    },
    updateListOptions(filters) {
      this.listOptions = Object.assign({}, this.options, { filters })
    }
  },
  watch: {
    options() {
      this.updateListOptions(this.listOptions.filters)
      this.$emit('refresh', this.listOptions)
    },
    page() {
      this.options.page = this.page
    }
  }
}
