import Base from './Base'
import ProductCategory from '@/domain/entities/ProductCategory'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null
    const entity = this.fieldNamesFromResponse(resource)
    return new ProductCategory(entity)
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    if (!resource) return null
    const { id, category } = resource
    const result = { id, code: category }
    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(entity, removeEmpty = false) {
    if (!entity) return null
    const { id, code } = entity
    const result = { id, category: code }
    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }
}
