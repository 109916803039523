var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('material-card',{attrs:{"icon":"mdi-cart","icon-small":"","title":_vm.$t('list.offers.title'),"color":"primary"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"footer-props":_vm.footerOptions,"server-items-length":_vm.totalItems,"loading":_vm.loading,"no-data-text":_vm.$t('common.list.no-data')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"11"}},[_c('Searchbar',{on:{"submit":_vm.searchbarSubmitted}})],1),_c('v-col',{staticClass:"pt-0 pl-5",attrs:{"cols":"1"}},[_c('RoundButton',{attrs:{"icon":"mdi-plus","alt":_vm.$t('list.offers.new')},on:{"click":function($event){return _vm.$emit('goto-new')}}})],1)],1)],1)]},proxy:true},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("yesNo")(item.active))+" ")]}},{key:"item.fromDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.fromDate))+" ")]}},{key:"item.toDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.toDate))+" ")]}},{key:"item.offerPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("append")(item.offerPrice,'€'))+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"height":"100px","overflow-y":"scroll","max-width":"350px"}},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.creationDate",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("dateTime")(item.creationDate)))])]}},{key:"item.lastModified",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("dateTime")(item.lastModified)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions"},[_c('v-icon',{staticClass:"mr-2 show-product-button",on:{"click":function($event){return _vm.$emit('goto-show', item.id)}}},[_vm._v("mdi-eye")]),_c('v-icon',{staticClass:"mr-2 edit-product-button",on:{"click":function($event){return _vm.$emit('goto-edit', item.id)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{staticClass:"delete-product-button",on:{"click":function($event){return _vm.$emit('delete', item.id)}}},[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }