<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12" lg="9">
        <material-card
          icon="mdi-archive"
          icon-small
          color="primary"
          :title="$t('common.sections.titles.product')"
        >
          <ProductCard :entity="entity" />
        </material-card>
      </v-col>
      <v-col cols="12" lg="3" order="first" order-lg="last">
        <ProductSummary :entity="entity">
          <v-divider />
          <SummaryCommandBanner
            icon="mdi-view-list"
            :text="$t('common.back-to-list')"
            @click="gotoList"
          />
          <SummaryCommandBanner
            icon="mdi-pencil"
            :text="$t('common.product.edit')"
            @click="gotoEdit"
          />
        </ProductSummary>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsAShowPage from '@/mixins/actsAsAShowPage'

import ProductCard from '@/components/products/ProductCard'
import ProductSummary from '@/components/products/ProductSummary.vue'
import SummaryCommandBanner from '@/components/common/SummaryCommandBanner.vue'

import Product from '@/domain/entities/Product'
import GetProduct from '@/domain/useCases/products/GetProduct'

export default {
  name: 'ShowProductPage',
  mixins: [actsAsAShowPage],
  components: { ProductCard, ProductSummary, SummaryCommandBanner },
  props: { id: String },
  data() {
    return {
      actsAsAShowPage: {
        resource: 'products',
        entity: 'product',
        get: GetProduct
      },
      entity: Product.default()
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
