import {
  OffersListPage,
  NewOfferPage,
  EditOfferPage,
  ShowOfferPage
} from '@/views/offers/index.js'

export default [
  {
    path: '/offers',
    name: 'OffersPage',
    component: OffersListPage,
    meta: {
      title: 'menu.long.resources.offers'
    }
  },
  {
    path: '/offers/new',
    name: 'NewOfferPage',
    component: NewOfferPage,
    meta: {
      title: 'menu.long.resources.new-offer'
    }
  },
  {
    path: '/offers/:id/edit',
    name: 'EditOfferPage',
    component: EditOfferPage,
    props: true,
    meta: {
      title: 'menu.long.resources.edit-offer'
    }
  },
  {
    path: '/offers/:id',
    name: 'ShowOfferPage',
    component: ShowOfferPage,
    props: true,
    meta: {
      title: 'menu.long.resources.show-offer'
    }
  }
]
