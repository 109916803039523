import validateUser from '@/lib/validateUser'
import Error from '@/lib/Error'
import editionCreateAdapter from '@/adapters/backoffice/editions/create'

export default class {
  user = null
  edition = null

  constructor(user, edition) {
    this.user = user
    this.edition = edition
  }

  async call() {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    if (!this.edition) return new Error({ message: 'errors.resource.invalid' })

    if (this.edition.id)
      return new Error({ message: 'errors.resource.existing' })

    return await editionCreateAdapter(this.user.token, this.edition)
  }
}
