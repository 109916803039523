export default class {
  constructor({
    id = null,
    type = null,
    location = null,
    fee = null,
    hourlyFee = null
  } = {}) {
    this.id = id
    this.location = location
    this.fee = fee
    this.type = type
    this.hourlyFee = hourlyFee
  }
}
