<template>
  <PersonAndCompanySelect
    supplier
    v-bind="$attrs"
    @input="val => $emit('input', val)"
  />
</template>

<script>
import PersonAndCompanySelect from './PersonAndCompanySelect.vue'

export default {
  name: 'SupplierSelect',
  components: { PersonAndCompanySelect },
  props: { multiple: Boolean }
}
</script>
