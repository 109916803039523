import axios from '@/axios.js'

import ListOptionsFormatter from '../formatters/ListOptions'
import CompanyFormatter from '../formatters/Company'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, listOptions) {
  try {
    const options = ListOptionsFormatter.toRequestOptions(
      listOptions,
      CompanyFormatter
    )
    const response = await axios.get('/api/v1/companies', {
      params: options,
      headers: { Authorization: `Bearer ${token}` }
    })

    const { results, totalItems, page } = response.data
    const companies = CompanyFormatter.fromResponseResourcesList(results)

    return { error: false, companies, totalItems, page }
  } catch (e) {
    return exceptionToResponse(e)
  }
}
