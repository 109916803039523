import { today, momentForBE } from '@/lib/Utils'
import moment from 'moment'
import Contract from './Contract'
import Product from './Product'

export default class {
  static new() {
    return new this({ genericProduct: {} })
  }

  static default() {
    return new this({ fromDate: today() })
  }

  static fromContract(contract) {
    const edition = this.newContractEdition()
    edition.genericProduct = new Contract(contract)
    edition.genericProductType = 'contract'
    return edition.copyValuesFromContract(contract)
  }

  static fromProduct(product) {
    const edition = this.newProductEdition()
    edition.genericProduct = new Product(product)
    edition.genericProductType = 'product'
    return edition.copyValuesFromProduct(product)
  }

  static newContractEdition() {
    return new this({
      fromDate: today(),
      genericProduct: Contract.new(),
      genericProductType: 'contract',
      active: false,
      showOnCalendar: false
    })
  }

  static newProductEdition() {
    return new this({
      fromDate: today(),
      genericProduct: Product.new(),
      genericProductType: 'product',
      active: false,
      showOnCalendar: false
    })
  }

  constructor({
    id = null,
    active = null,
    genericProduct = null,
    genericProductType = null,
    fromDate = null,
    toDate = null,
    durationDays = null,
    durationHours = null,
    lecturers = [],
    showOnCalendar = null,
    price = null,
    place = null,
    placeDescription = null,
    maxAttendees = null,
    minAttendees = null,
    language = null,
    notes = null,

    creationDate = null,
    startDate = null,
    lastModified = null,
    lastModifiedBy = null
  } = {}) {
    this.id = id
    this.genericProduct = genericProduct
    this.genericProductType = genericProductType
    this.fromDate = fromDate
    this.toDate = toDate
    this.durationDays = durationDays
    this.durationHours = durationHours
    this.active = active
    this.lecturers = lecturers
    this.showOnCalendar = showOnCalendar
    this.price = price
    this.place = place
    this.placeDescription = placeDescription
    this.maxAttendees = maxAttendees
    this.minAttendees = minAttendees
    this.language = language
    this.notes = notes

    this.creationDate = creationDate
    this.startDate = startDate
    this.lastModified = lastModified
    this.lastModifiedBy = lastModifiedBy
  }

  copyValuesFromContract(contract) {
    this.price = this.price || contract.price
    this.fromDate = this.fromDate || contract.validFrom
    this.toDate = this.toDate || contract.validTo
    return this
  }

  copyValuesFromProduct(product) {
    this.price = this.price || product.price
    this.fromDate = this.fromDate || product.validFrom
    this.toDate = this.toDate || product.validTo
    this.durationDays = this.durationDays || product.durationDays
    this.durationHours = this.durationHours || product.durationHours
    this.maxAttendees = this.maxAttendees || product.maxAttendees
    this.minAttendees = this.minAttendees || product.minAttendees
    return this
  }

  isBoundToProduct() {
    return this.genericProductType == 'product'
  }

  isBoundToContract() {
    return this.genericProductType == 'contract'
  }

  calculateToDate() {
    if (this.isBoundToProduct()) {
      return (
        this.toDate ||
        momentForBE(
          moment(this.fromDate).add(this.genericProduct.durationDays, 'd')
        )
      )
    }
  }
}
