import {
  InvoicesListPage,
  ShowInvoicePage,
  AmendEditInvoicePage,
  PartialAmendInvoicePage,
  EditInvoiceCostCenters,
  ShowInvoiceCostCenters,
  EditInvoiceJobs,
  ShowInvoiceJobs
} from '@/views/invoices/index.js'

export default [
  {
    path: '/invoices',
    name: 'InvoicesPage',
    component: InvoicesListPage,
    meta: {
      title: 'menu.long.resources.invoices'
    }
  },
  {
    path: '/invoices/purchase/:purchase',
    name: 'InvoicesPageByPurchase',
    component: InvoicesListPage,
    props: true,
    meta: {
      title: 'menu.long.resources.invoices'
    }
  },
  {
    path: '/invoices/:id',
    name: 'ShowInvoicePage',
    component: ShowInvoicePage,
    props: true,
    meta: {
      title: 'menu.long.resources.show-invoice'
    }
  },
  {
    path: '/invoices/:id/edit',
    name: 'EditInvoicePage',
    component: AmendEditInvoicePage,
    props: route => ({ id: route.params.id, edit: true }),
    meta: {
      title: 'menu.long.resources.edit-invoice'
    }
  },
  {
    path: '/invoices/:id/amend',
    name: 'AmendInvoicePage',
    component: AmendEditInvoicePage,
    props: route => ({ id: route.params.id, amend: true }),
    meta: {
      title: 'menu.long.resources.amend-invoice'
    }
  },
  {
    path: '/invoices/:id/partial-amend',
    name: 'PartialAmendInvoicePage',
    component: PartialAmendInvoicePage,
    props: true,
    meta: {
      title: 'menu.long.resources.partial-amend-invoice'
    }
  },
  {
    path: '/invoices/:id/edit/cost-centers',
    name: 'EditInvoiceCostCenters',
    component: EditInvoiceCostCenters,
    props: true,
    meta: {
      title: 'menu.long.resources.edit-invoice-cost-centers'
    }
  },
  {
    path: '/invoices/:id/show/cost-centers',
    name: 'ShowInvoiceCostCenters',
    component: ShowInvoiceCostCenters,
    props: true,
    meta: {
      title: 'menu.long.resources.show-invoice-cost-centers'
    }
  },
  {
    path: '/invoices/:id/edit/jobs',
    name: 'EditInvoiceJobs',
    component: EditInvoiceJobs,
    props: true,
    meta: {
      title: 'menu.long.resources.edit-invoice-jobs'
    }
  },
  {
    path: '/invoices/:id/show/jobs',
    name: 'ShowInvoiceJobs',
    component: ShowInvoiceJobs,
    props: true,
    meta: {
      title: 'menu.long.resources.show-invoice-jobs'
    }
  }
]
