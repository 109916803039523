import PaymentCostCenterDetail from '@/domain/entities/PaymentCostCenterDetail'
import Base from './Base'
import CostCenterFormatter from './CostCenter'
import CostCenterDetailFormatter from './CostCenterDetail'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null
    const entity = this.fieldNamesFromResponse(resource)

    this.formatDecimalsFromResponseResource(entity, 'amount', 'percentage')

    entity.costCenter = CostCenterFormatter.fromResponseResource(
      entity.costCenter
    )
    entity.costCenterDetail = CostCenterDetailFormatter.fromResponseResource(
      entity.costCenterDetail
    )

    return new PaymentCostCenterDetail(entity)
  }

  static toRequestResource(entity) {
    if (!entity) return null
    const resource = Object.assign({}, entity)
    this.formatDecimalsForRequestResource(resource, 'amount', 'percentage')
    resource.costCenter = CostCenterFormatter.toRequestResource(
      entity.costCenter
    )
    resource.costCenterDetail = CostCenterDetailFormatter.toRequestResource(
      entity.costCenterDetail
    )

    return this.fieldNamesForRequest(resource)
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    const {
      id,
      amount,
      percentage,
      costCenter,
      costCenterDet,
      notes
    } = resource

    const result = {
      id,
      amount,
      percentage,
      costCenter,
      costCenterDetail: costCenterDet,
      notes
    }

    this.formatInternalValuesFromResponse(resource, result)

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(entity, removeEmpty = false) {
    const {
      id,
      amount,
      percentage,
      costCenter,
      costCenterDetail,
      notes
    } = entity

    const result = {
      id,
      amount,
      percentage,
      costCenter,
      costCenterDet: costCenterDetail,
      notes
    }

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }
}
