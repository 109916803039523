<template>
  <v-expansion-panels inset>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <span class="text-h4">
          {{ $t('common.list.search.title') }}
        </span>
        <template v-slot:actions>
          <v-icon color="primary"> mdi-chevron-down </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form
          ref="form"
          @submit.prevent="submitted"
          class="list-searchbar mx-0"
        >
          <v-row dense>
            <v-col cols="2">
              <SelectField
                :label="$t('entities.edition.genericProductType')"
                v-model="filters.genericProductType"
                :items="genericProductTypes"
                hide-details
                outlined
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                :label="$t('entities.edition.genericProduct')"
                v-model="filters.genericProduct.name"
                hide-details
                outlined
                dense
              />
            </v-col>

            <v-col cols="2">
              <ProductCategorySelect
                :label="$t('entities.product.category')"
                v-model="filters.genericProduct.category"
                hide-details
                outlined
              />
            </v-col>

            <v-col cols="2">
              <ProductTypeSelect
                :label="$t('entities.product.type')"
                v-model="filters.genericProduct.type"
                hide-details
                outlined
              />
            </v-col>

            <v-col cols="2">
              <LanguageSelect
                :label="$t('entities.product.language')"
                v-model="filters.genericProduct.language"
                hide-details
                outlined
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                :label="$t('entities.edition.placeDescription')"
                v-model="filters.placeDescription"
                hide-details
                outlined
                dense
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                :label="$t('entities.edition.place')"
                v-model="filters.place"
                hide-details
                outlined
                dense
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="auto">
              <v-btn color="primary" outlined @click="reset()">
                <v-icon left dark> mdi-undo-variant </v-icon>
                {{ $t('common.list.search.reset') }}
              </v-btn>
            </v-col>
            <v-col cols="2">
              <v-btn type="submit" color="primary" dark>
                <v-icon left dark> mdi-magnify </v-icon>
                {{ $t('common.list.search.submit') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { SelectField } from '@/components/common/form'
import ProductTypeSelect from '@/components/products/ProductTypeSelect.vue'
import ProductCategorySelect from '@/components/products/ProductCategorySelect.vue'
import LanguageSelect from '@/components/common/LanguageSelect.vue'

import Edition from '@/domain/entities/Edition'

export default {
  name: 'EditionSearchbar',
  components: {
    SelectField,
    ProductTypeSelect,
    ProductCategorySelect,
    LanguageSelect
  },
  data() {
    return {
      filters: Edition.new()
    }
  },
  computed: {
    genericProductTypes() {
      return [
        {
          value: 'contract',
          text: this.$t('entities.edition.genericProductTypes.contract')
        },
        {
          value: 'product',
          text: this.$t('entities.edition.genericProductTypes.product')
        }
      ]
    }
  },
  methods: {
    reset() {
      this.filters = Edition.new()
    },
    submitted() {
      this.$emit('submit', this.filters)
    }
  }
}
</script>
