import axios from '@/axios.js'

import ListOptionsFormatter from '../formatters/ListOptions'
import PersonAndCompaniesFormatter from '../formatters/PersonAndCompanies'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, listOptions) {
  try {
    const options = ListOptionsFormatter.toRequestOptions(
      listOptions,
      PersonAndCompaniesFormatter
    )
    const { data } = await axios.get('/api/v1/userCompanies/customer', {
      headers: { Authorization: `Bearer ${token}` },
      params: options
    })

    const { results, totalItems, page } = data
    const customers = PersonAndCompaniesFormatter.fromResponseResourcesList(
      results
    )

    return { error: false, customers, totalItems, page }
  } catch (e) {
    return exceptionToResponse(e)
  }
}
