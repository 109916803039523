import axios from '@/axios.js'

import ContractFormatter from '../formatters/Contract'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, userId) {
  try {
    const headers = { Authorization: `Bearer ${token}` }
    /// TODO: this should be passed through the list options' formatter

    const params = {
      page: 1,
      itemsPerPage: 'all',
      filter: {}
    }

    const { data } = await axios.get(
      `/api/v1/custContracts/coaching/user/${userId}`,
      {
        params,
        headers
      }
    )

    const { results, totalItems, page } = data
    /// TODO: formatter for this?

    const contracts = results.map(customerContract =>
      ContractFormatter.fromResponseResource(customerContract.contract)
    )

    return { error: false, contracts, totalItems, page }
  } catch (e) {
    return exceptionToResponse(e)
  }
}
