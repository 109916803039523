import axios from '@/axios.js'
import exceptionToResponse from '../../formatters/exceptionToResponse'

export default async function(token, id, signatureFile) {
  if (!signatureFile) return null

  const headers = { Authorization: `Bearer ${token}` }
  const payload = new FormData()
  payload.append('file', signatureFile)

  try {
    const { data } = await axios.post(
      `/api/v1/purchases/${id}/signatureFile`,
      payload,
      { headers }
    )

    return { error: false, signatureFile: data.path }
  } catch (e) {
    return exceptionToResponse(e)
  }
}
