import axios from '@/axios.js'
import { isFile } from '@/lib/Utils'

import PurchaseFormatter from '../formatters/Purchase'
import resourceErrorsToResponse from '../formatters/resourceErrorsToResponse'
import exceptionToResponse from '../formatters/exceptionToResponse'

import updateSignatureFile from './signatureFile/update'
import deleteSignatureFile from './signatureFile/delete'

export default async function(token, id, entity) {
  const signatureFile = entity.signatureFile

  const headers = { Authorization: `Bearer ${token}` }
  const payload = { purchase: PurchaseFormatter.toRequestResource(entity) }

  try {
    const { data } = await axios.put(`/api/v1/purchases/${id}`, payload, {
      headers
    })

    const purchase = PurchaseFormatter.fromResponseResource(data.purchase)
    const purchaseId = purchase.id

    if (!signatureFile || isFile(signatureFile)) {
      deleteSignatureFile(token, purchaseId)

      if (signatureFile) {
        const uploadResponse = await updateSignatureFile(
          token,
          purchaseId,
          signatureFile
        )

        if (uploadResponse.error) {
          uploadResponse.message = 'entities.purchase.error.signed-document'
          return uploadResponse
        } else {
          purchase.signatureFile = uploadResponse.signatureFile
        }
      }
    }
    return { error: false, purchase }
  } catch (e) {
    return (
      resourceErrorsToResponse(e, PurchaseFormatter, 'purchase') ||
      exceptionToResponse(e)
    )
  }
}
