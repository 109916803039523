import axios from '@/axios.js'

import PaymentFormatter from '../formatters/Payment'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, id) {
  try {
    const { data } = await axios.get(`/api/v1/payments/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    })

    const payment = PaymentFormatter.fromResponseResource(data.payment)

    return { error: false, payment }
  } catch (e) {
    return exceptionToResponse(e)
  }
}
