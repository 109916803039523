<template>
  <ConfirmDialog
    :title="$t('common.personCompany.remove.title')"
    :message="$t('common.personCompany.remove.confirm-text')"
    :yesText="$t('common.yes')"
    :noText="$t('common.no')"
    @yesClick="$emit('yesClick')"
    @noClick="$emit('noClick')"
  />
</template>

<script>
import ConfirmDialog from '@/components/common/ConfirmDialog.vue'

export default {
  name: 'RemovePersonCompanyDialog',
  components: {
    ConfirmDialog
  }
}
</script>
