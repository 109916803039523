<template>
  <v-autocomplete
    :class="_class"
    :label="$t('entities.invoice.job.detail.name')"
    :items="items"
    :clearable="clearable"
    v-model="item"
    @input="onChange"
    return-object
    item-text="name"
    item-value="id"
    :no-data-text="$t('common.autocomplete.no-data')"
    :rules="_rules"
    :required="required"
    :outlined="readonly || outlined"
    :filled="readonly"
    :hide-details="hideDetails"
    dense
  >
    <template v-slot:selection="data">
      {{ data.item | dig('detail', 'name') }}
    </template>

    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>
          {{ data.item | dig('detail', 'name') }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import actsAsAnAutocomplete from '@/mixins/actsAsAnAutocomplete'
import Job from '@/domain/entities/Job'
import GetJob from '@/domain/useCases/jobs/GetJob'
import Error from '@/lib/Error'

export default {
  name: 'JobAndDetailSelect',
  mixins: [actsAsAnAutocomplete],
  props: {
    job: Job
  },
  methods: {
    refreshJobAndDetails(job) {
      if (!job || !job.id) {
        this.items = []
        return
      }

      new GetJob(this.user).call(job.id).then(result => {
        if (result.error) {
          this.items = []
          new Error({
            message: 'common.autocomplete.error-loading'
          }).toAlert()
          console.info(`Error loading job for autocomplete:`, result)
        } else this.items = result.job.jobAndDetails
      })
    }
  },
  watch: {
    job(newVal) {
      this.refreshJobAndDetails(newVal)
      this.item = null
    }
  },
  mounted() {
    this.refreshJobAndDetails(this.job)
  }
}
</script>
