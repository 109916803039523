<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="2">
        <CardTextField
          :label="$t('entities.personCompany.position')"
          :value="entity.position"
        />
      </v-col>
      <v-col cols="12" lg="10">
        <CardTextField
          :label="$t('entities.personCompany.roles')"
          :value="roles | join"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { CardTextField } from '@/components/common/card'

import PersonCompanies from '@/domain/entities/PersonCompanies'

export default {
  name: 'PersonCompanyRolesAndPositionForm',
  props: { entity: PersonCompanies },
  components: { CardTextField },
  computed: {
    roles() {
      if (!this.entity.roles) return ''
      return this.entity.roles.map(role => role.role)
    }
  }
}
</script>
