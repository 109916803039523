<template>
  <v-expansion-panels inset>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <span class="text-h4">
          {{ $t('common.list.search.title') }}
        </span>
        <template v-slot:actions>
          <v-icon color="primary"> mdi-chevron-down </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form
          ref="form"
          @submit.prevent="submitted"
          class="list-searchbar mx-0"
        >
          <v-row dense>
            <v-col cols="2">
              <v-select
                :label="$t('common.active.title')"
                v-model="filters.person.active"
                :items="activeSelectItems"
                hide-details
                outlined
                dense
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                :label="$t('entities.person.name')"
                v-model="filters.person.name"
                hide-details
                outlined
                dense
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                :label="$t('entities.person.surname')"
                v-model="filters.person.surname"
                outlined
                hide-details
                dense
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                :label="$t('entities.person.fiscalCode')"
                v-model="filters.person.fiscalCode"
                outlined
                hide-details
                dense
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                :label="$t('entities.person.email')"
                v-model="filters.person.email"
                outlined
                hide-details
                dense
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="2">
              <v-text-field
                :label="$t('entities.personCompany.company')"
                v-model="filters.personCompanies.company.invoiceName"
                outlined
                hide-details
                dense
              />
            </v-col>

            <v-col cols="4">
              <PersonCompanyRolesMultiselect
                :as="as"
                :label="$t('entities.personCompany.roles')"
                v-model="filters.personCompanies.roles"
                outlined
                hide-details
              />
            </v-col>

            <v-col cols="2">
              <PersonCompanyPositionSelect
                :label="$t('entities.personCompany.position')"
                v-model="filters.personCompanies.position"
                outlined
                hide-details
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                :label="$t('entities.company.vatCode')"
                v-model="filters.personCompanies.company.vatCode"
                outlined
                hide-details
                dense
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                :label="$t('entities.company.invoicePec')"
                v-model="filters.personCompanies.company.invoicePec"
                outlined
                hide-details
                dense
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="auto">
              <v-btn color="primary" outlined @click="reset()">
                <v-icon left dark> mdi-undo-variant </v-icon>
                {{ $t('common.list.search.reset') }}
              </v-btn>
            </v-col>
            <v-col cols="2">
              <v-btn type="submit" color="primary" dark>
                <v-icon left dark> mdi-magnify </v-icon>
                {{ $t('common.list.search.submit') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import PersonCompanyRolesMultiselect from '@/components/personCompanies/PersonCompanyRolesMultiselect.vue'
import PersonCompanyPositionSelect from '@/components/personCompanies/PersonCompanyPositionSelect.vue'

import Person from '@/domain/entities/Person'
import PersonCompanies from '@/domain/entities/PersonCompanies'

export default {
  name: 'PersonCompaniesSearchbar',
  components: { PersonCompanyRolesMultiselect, PersonCompanyPositionSelect },
  props: { as: String },
  data() {
    return {
      filters: {
        person: Person.new(),
        personCompanies: PersonCompanies.new()
      },
      activeSelectItems: [
        {
          text: this.$t('common.active.all'),
          value: null
        },
        {
          text: this.$t('common.active.true'),
          value: true
        },
        {
          text: this.$t('common.active.false'),
          value: false
        }
      ]
    }
  },
  methods: {
    reset() {
      this.filters = PersonCompanies.new()
    },
    submitted() {
      this.$emit('submit', this.filters)
    }
  }
}
</script>
