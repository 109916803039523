import Base from './Base'
import { isEmpty, isString } from '@/lib/Utils'
import JobFormatter from './Job'
import JobDetailFormatter from './JobDetail'
import JobAndDetail from '@/domain/entities/JobAndDetail'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null
    const entity = this.fieldNamesFromResponse(resource)

    entity.job = JobFormatter.fromResponseResource(resource.job)
    entity.detail = JobDetailFormatter.fromResponseResource(resource.jobDet)

    return new JobAndDetail(entity)
  }

  static toRequestResource(entity) {
    if (!entity) return null
    const resource = this.fieldNamesForRequest(entity)
    resource.job = JobFormatter.toRequestResource(entity.job)
    resource.jobDet = JobDetailFormatter.toRequestResource(entity.detail)
    return resource
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    if (!resource) return null
    let { id } = resource

    const result = {
      id
    }

    this.formatInternalValuesFromResponse(resource, result)

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(entity, removeEmpty = false) {
    if (!entity) return null

    let { id } = entity

    const result = {
      id
    }

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static formatFilterOptions(filter) {
    if (isEmpty(filter)) return null
    let result = super.formatFilterOptions(filter) || {}
    let expandedResult = {}
    expandedResult = {
      name: filter.name
    }

    return this.removeEmptyAttributes(Object.assign(result, expandedResult))
  }

  static fromResponseErrors(errors, resource = null) {
    const fieldErrors = resource ? errors[resource] : errors.invoPurchase

    const extraMessages = []

    const detsErrors = errors['jobDetRels']

    if (isString(detsErrors))
      extraMessages.push(`errors.job.details.${detsErrors}`)

    return {
      fields: this.fieldNamesFromResponse(fieldErrors, true),
      extraMessages
    }
  }
}
