<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="2">
        <CardYesNoField
          color="success"
          :label="$t('entities.offer.active')"
          v-model="entity.active"
        />
      </v-col>

      <v-col cols="12" lg="5">
        <CardDateField
          :label="$t('entities.offer.fromDate')"
          v-model="entity.fromDate"
          required
        />
      </v-col>

      <v-col cols="12" lg="5">
        <CardDateField
          :label="$t('entities.offer.toDate')"
          v-model="entity.toDate"
          required
        />
      </v-col>

      <v-col cols="12" lg="12">
        <CardTextField
          :label="$t('entities.offer.name')"
          v-model="entity.name"
          required
        />
      </v-col>

      <v-col cols="12" lg="12">
        <CardTextarea
          :label="$t('entities.offer.description')"
          v-model="entity.description"
          required
        />
      </v-col>

      <v-col cols="12">
        <CardTextarea
          :label="$t('entities.offer.notes')"
          v-model="entity.notes"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  CardYesNoField,
  CardDateField,
  CardTextField,
  CardTextarea
} from '@/components/common/card'

import Offer from '@/domain/entities/Offer'

export default {
  name: 'OfferCard',
  components: { CardYesNoField, CardDateField, CardTextField, CardTextarea },
  props: { entity: Offer }
}
</script>
