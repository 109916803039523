import Base from './Base'
import PersonCompanyRole from '@/domain/entities/PersonCompanyRole'
export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null
    return new PersonCompanyRole(this.fieldNamesFromResponse(resource))
  }

  static toRequestResource(entity) {
    if (!entity) return null
    return this.fieldNamesForRequest(entity)
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    if (!resource) return null

    const { id, type, role, descr } = resource
    const result = { id, type, role, description: descr }

    this.formatInternalValuesFromResponse(resource, result)

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(entity, removeEmpty = false) {
    if (!entity) return null

    const { id, type, role, description } = entity
    const result = { id, type, role, descr: description }

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }
}
