<template>
  <v-container v-if="entity" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" lg="9">
        <PersonAndCompaniesCard :entity="entity" as="customer" />
      </v-col>

      <v-col cols="12" lg="3" order="first" order-lg="last">
        <PersonSummary v-if="entity.person" :entity="entity.person" isCustomer>
          <template #avatar>
            <v-img
              contain
              v-if="entity.person.photo"
              :src="entity.person.photo"
              class="rounded-circle-custom elevation-6 mt-n12 d-inline-block"
              width="128"
            />
          </template>

          <SummaryCommandBanner
            icon="mdi-view-list"
            :text="$t('common.back-to-list')"
            @click="gotoList()"
          />
          <SummaryCommandBanner
            icon="mdi-pencil"
            :text="$t('common.customer.edit')"
            @click="gotoEdit()"
          />
        </PersonSummary>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsAShowPage from '@/mixins/actsAsAShowPage'

import PersonAndCompaniesCard from '@/components/personCompanies/PersonAndCompaniesCard.vue'
import PersonSummary from '@/components/people/PersonSummary.vue'
import SummaryCommandBanner from '@/components/common/SummaryCommandBanner.vue'

import PersonAndCompanies from '@/domain/entities/PersonAndCompanies'
import GetCustomer from '@/domain/useCases/customers/GetCustomer'

export default {
  name: 'ShowCustomerPage',
  mixins: [actsAsAShowPage],
  components: { PersonAndCompaniesCard, PersonSummary, SummaryCommandBanner },
  props: { id: String },
  data() {
    return {
      actsAsAShowPage: {
        resource: 'customers',
        entity: 'customer',
        get: GetCustomer
      },
      entity: PersonAndCompanies.newCustomer()
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
