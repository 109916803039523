<template>
  <v-data-table v-if="items.length" :headers="headers" :items="items" dense>
    <template v-slot:[`item.discount`]="{ item }">
      <span v-if="overridesEnabled" class="text-decoration-line-through">
        {{ item.discount | percentage }}
      </span>
      <span v-else>{{ item.discount | percentage }}</span>
    </template>

    <template v-slot:[`item._discount`]="{ item, index }">
      <span v-if="overridesEnabled">
        <span v-if="overrides[index].discountType === 'percentage'">
          {{ overrides[index].discountPercentage | percentage }}
        </span>
        <span v-if="overrides[index].discountType === 'value'">
          {{ overrides[index].discountValue | append('€') }}
        </span>
      </span>
    </template>

    <template v-slot:[`item._discountedValue`]="{ item, index }">
      {{ discountedValue(index) }}
    </template>
  </v-data-table>
</template>

<script>
import { get } from 'vuex-pathify'
import Decimal from '@/lib/Decimal'

import Offer from '@/domain/entities/Offer'
import GetContracts from '@/domain/useCases/contracts/GetContracts'
import OfferContractOverride from '@/domain/entities/OfferContractOverride'

export default {
  name: 'OfferContractsOverridesCard',
  props: { entity: Offer },
  data() {
    return {
      headers: [
        {
          text: this.$t('entities.contract.name'),
          value: 'name'
        },
        {
          text: this.$t('entities.contract.version'),
          value: 'version'
        },
        {
          text: this.$t('entities.contract.code'),
          value: 'code'
        },
        {
          text: this.$t('entities.contract.discount'),
          value: 'discount'
        },
        {
          text: this.$t('entities.offer.contractOverrides.discount'),
          value: '_discount'
        },
        {
          text: this.$t('common.discounted-value'),
          value: '_discountedValue'
        }
      ],
      overrides: this.entity.contractOverrides,
      items: []
    }
  },
  computed: {
    user: get('user'),
    overridesEnabled() {
      return this.entity.discountOverride
    }
  },
  methods: {
    discountOverridden(index) {
      return this.overridesEnabled && this.overrides[index]._active
    },
    discountedValue(index) {
      const standardDiscountApplicable = this.entity.standardDiscountApplicable
      const overridesEnabled = this.entity.discountOverride
      const isOverridden = this.overrides[index]._active

      if (standardDiscountApplicable)
        return this.calculateDiscountedValue(this.items[index].discount, true)

      if (!overridesEnabled || !isOverridden) return this.entity.offerPrice

      const overriddenType = this.overrides[index].discountType

      if (overriddenType === 'percentage')
        return this.calculateDiscountedValue(
          this.overrides[index].discountPercentage,
          true
        )
      else
        return this.calculateDiscountedValue(
          this.overrides[index].discountValue,
          false
        )
    },
    calculateDiscountedValue(discount, isPercentage) {
      const discountDecimal = Decimal.fromUI(discount)

      if (isPercentage)
        return Decimal.fromUI(this.entity.offerPrice)
          .discount(discountDecimal)
          .forUI()
      else
        return Decimal.fromUI(this.entity.offerPrice)
          .subtract(discountDecimal)
          .forUI()
    }
  },
  mounted() {
    new GetContracts(this.user)
      .call({ page: 1, itemsPerPage: -1 })
      .then(result => {
        if (result.error) this.$_handleError(result)
        else {
          this.items = result.contracts
          this.overrides = this.items.map(contract => {
            let override = this.entity.contractOverrides.find(
              o => o.contract.id === contract.id
            )
            if (!override)
              override = OfferContractOverride.fromContract(contract)

            override._active = !!override.id
            override.discountValue = override.discountValue || '0,00'
            override.discountPercentage = override.discountPercentage || '0'
            return override
          })
        }
      })
  }
}
</script>
