<template>
  <div :class="_class">
    <v-menu
      class="_class"
      v-if="!readonly"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateFormatted"
          :label="label"
          :hint="$t('common.date.hint')"
          prepend-icon="mdi-calendar"
          clearable
          v-bind="attrs"
          v-on="on"
          @keydown.space="menu = !menu"
          :rules="_rules"
          :error-messages="_error"
          :required="required"
          dense
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        no-title
        @change="dateChanged()"
        :disabled="readonly"
      ></v-date-picker>
    </v-menu>

    <CardDateField
      v-else
      :value="dateFormatted"
      :label="label"
      :show-time="showTime"
    />
  </div>
</template>

<script>
import CardDateField from '@/components/common/card/CardDateField.vue'

import moment from 'moment'
import validators from '@/domain/validations/rules'
import { dateForUI, dateTimeForUI } from '@/lib/Utils'

export default {
  name: 'DateField',
  components: { CardDateField },
  props: {
    label: String,
    value: String,
    showTime: Boolean, // TODO: implementation when not read-only
    rules: Array,
    error: String,
    required: Boolean,
    readonly: Boolean,
    filled: { type: Boolean, default: true },
    outlined: { type: Boolean, default: true }
  },
  data() {
    return {
      date: this.value,
      dateFormatted: this.formatDate(this.value),
      menu: false
    }
  },
  computed: {
    validates() {
      return validators(this)
    },
    _class() {
      return this.required ? 'required' : ''
    },
    _rules() {
      const _rules = this.rules || []
      if (this.required) _rules.push(this.validates.presence)
      _rules.push(this.validates.date)
      return _rules
    },
    _error() {
      return this.error ? this.$t(`errors.field.${this.error}`) : []
    }
  },
  methods: {
    dateChanged() {
      this.menu = false
      this.$emit('input', this.date)
    },
    formatDate(date) {
      return this.showTime ? dateTimeForUI(date) : dateForUI(date)
    },
    parseDate(date) {
      if (!date) return null
      const timeFormat = this.showTime ? ' HH:mm:ss' : ''
      return moment
        .utc(date, `DD/MM/YYYY${timeFormat}`)
        .format(`YYYY-MM-DD${timeFormat}`)
    }
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date)
      this.$emit('input', this.date)
    },
    dateFormatted(value) {
      if (!value) this.date = null
      if (this.validates.date(value) === true) this.date = this.parseDate(value)
    },
    value(value) {
      this.dateFormatted = this.formatDate(value)
    }
  }
}
</script>
