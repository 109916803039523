import Base from './Base'
import { isEmpty, isString } from '@/lib/Utils'
import JobAndDetailFormatter from './JobAndDetail'
import Job from '@/domain/entities/Job'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null
    const entity = this.fieldNamesFromResponse(resource)

    entity.jobAndDetails = JobAndDetailFormatter.fromResponseResourcesList(
      resource.jobDetRels
    )

    return new Job(entity)
  }

  static toRequestResource(entity) {
    if (!entity) return null
    const resource = this.fieldNamesForRequest(entity)
    resource.jobDetRels = JobAndDetailFormatter.toRequestResourcesList(
      entity.jobAndDetails
    )
    return resource
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    if (!resource) return null
    let { id, name, notes, startDate, endDate } = resource

    const result = {
      id,
      name,
      notes,
      startDate,
      endDate
    }

    this.formatInternalValuesFromResponse(resource, result)

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(entity, removeEmpty = false) {
    if (!entity) return null

    let { id, name, notes, startDate, endDate } = entity

    const result = {
      id,
      name,
      notes,
      startDate,
      endDate
    }

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static formatFilterOptions(filter) {
    if (isEmpty(filter)) return null
    let result = super.formatFilterOptions(filter) || {}
    let expandedResult = {}
    expandedResult = {
      name: filter.name
    }

    return this.removeEmptyAttributes(Object.assign(result, expandedResult))
  }

  static fromResponseErrors(errors, resource = null) {
    const fieldErrors = resource ? errors[resource] : errors.invoPurchase

    const extraMessages = []

    const detsErrors = errors['jobDetRels']

    if (isString(detsErrors))
      extraMessages.push(`errors.jobDetRel.details.${detsErrors}`)

    return {
      fields: this.fieldNamesFromResponse(fieldErrors, true),
      extraMessages
    }
  }
}
