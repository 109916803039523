import Base from './Base'
import Offer from '@/domain/entities/Offer'

import EditionFormatter from './Edition'
import ContractOverrideFormatter from './OfferContractOverride'
import { isString } from '@/lib/Utils'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null
    const entity = this.fieldNamesFromResponse(resource)

    this.formatDecimalsFromResponseResource(
      entity,
      'editionsTotalPrice',
      'offerPrice',
      'deposit',
      'installmentsAmountEach',
      'installmentsAmountExtra'
    )

    entity.editions = EditionFormatter.fromResponseResourcesList(
      resource.editions
    )
    entity.contractOverrides = ContractOverrideFormatter.fromResponseResourcesList(
      resource.contractOverrides
    )

    return new Offer(entity)
  }

  static toRequestResource(entity) {
    if (!entity) return null
    const resource = this.fieldNamesForRequest(entity)

    this.formatDecimalsForRequestResource(
      resource,
      'offerCommercialPrice',
      'offerNetPrice',
      'deposit',
      'installmentAmount',
      'extraInstallmentAmount'
    )

    resource.editions = EditionFormatter.toRequestResourcesList(entity.editions)
    resource.contractOverrides = ContractOverrideFormatter.toRequestResourcesList(
      entity.contractOverrides
    )

    if (!entity.discountOverride) {
      resource.contractOverrides = []
    }

    return resource
  }

  static fromResponseErrors(errors) {
    const extraMessages = []

    const fields = this.fieldNamesFromResponse(errors.offer || errors, true)
    const { offerPrice, installmentsNumber, paymentDelay, editions } = fields

    if (offerPrice) {
      switch (true) {
        case offerPrice.startsWith('out-of-range'):
          fields.offerPrice = 'offer-net-price-over-base-price'
          break
        case offerPrice.startsWith('greater-than'):
          fields.offerPrice = 'offer-net-price-different-from-expected'
          break
      }
    }
    if (installmentsNumber && installmentsNumber.startsWith('out-of-range'))
      fields.installmentsNumber = 'between.1-12'
    if (paymentDelay && paymentDelay.startsWith('out-of-range'))
      fields.paymentDelay = 'between.1-12'

    if (isString(editions) && editions === 'required')
      extraMessages.push('errors.offer.editions-required')

    return { fields, extraMessages }
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    if (!resource) return null

    const {
      id,
      flagActive,
      offerName,
      offerDescr,
      validStartDate,
      validEndDate,
      offerCommercialPrice,
      offerNetPrice,
      flagStandardDiscount,
      flagOverrideDiscount,
      deposit,
      deferredPayment,
      numberOfInstallments,
      installmentAmount,
      extraInstallmentAmount,
      notes,
      editions,
      contractOverrides
    } = resource

    const result = {
      id,
      active: flagActive,
      name: offerName,
      description: offerDescr,
      fromDate: validStartDate,
      toDate: validEndDate,
      editionsTotalPrice: offerCommercialPrice,
      offerPrice: offerNetPrice,
      standardDiscountApplicable: flagStandardDiscount,
      discountOverride: flagOverrideDiscount,
      deposit,
      paymentDelay: deferredPayment,
      installmentsNumber: numberOfInstallments,
      installmentsAmountEach: installmentAmount,
      installmentsAmountExtra: extraInstallmentAmount,
      notes,
      editions,
      contractOverrides
    }

    this.formatInternalValuesFromResponse(resource, result)

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(entity, removeEmpty = false) {
    if (!entity) return null

    const {
      id,
      active,
      name,
      description,
      fromDate,
      toDate,
      editionsTotalPrice,
      offerPrice,
      standardDiscountApplicable,
      discountOverride,
      deposit,
      paymentDelay,
      installmentsNumber,
      installmentsAmountEach,
      installmentsAmountExtra,
      notes,
      editions,
      contractOverrides
    } = entity

    const result = {
      id,
      flagActive: active,
      offerName: name,
      offerDescr: description,
      validStartDate: fromDate,
      validEndDate: toDate,
      offerCommercialPrice: editionsTotalPrice,
      offerNetPrice: offerPrice,
      flagStandardDiscount: standardDiscountApplicable,
      flagOverrideDiscount: discountOverride,
      deposit,
      deferredPayment: paymentDelay,
      numberOfInstallments: installmentsNumber,
      installmentAmount: installmentsAmountEach,
      extraInstallmentAmount: installmentsAmountExtra,
      notes,
      editions,
      contractOverrides
    }

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }
}
