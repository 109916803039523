import Person from './Person'
import Company from './Company'
export default class PersonAndCompany {
  static new() {
    return new PersonAndCompany({
      person: new Person(),
      company: new Company()
    })
  }

  static default() {
    return PersonAndCompany.new()
  }

  static newCustomer({ id, person, company, position, roles }) {
    return new PersonAndCompany({
      id,
      person,
      company,
      type: 'customer',
      position,
      roles
    })
  }

  static newSupplier({ id, person, company, position, roles }) {
    return new PersonAndCompany({
      id,
      person,
      company,
      type: 'supplier',
      position,
      roles
    })
  }

  constructor({
    id = null,
    person = null,
    company = null,
    type = null,
    position = null,
    roles = [],
    startDate = null,
    endDate = null
  } = {}) {
    this.id = id
    this.person = person
    this.company = company // Instance of Company
    this.type = type // "customer" or "supplier"
    this.position = position
    this.roles = roles
    this.startDate = startDate
    this.endDate = endDate
  }
}
