export default class {
  static new() {
    return new this()
  }

  static default() {
    return new this()
  }

  constructor({
    id = null,
    fiscalCode = null,
    vatCode = null,
    iban = null,
    invoiceName = null,
    invoiceAddress = null,
    invoiceNation = null,
    invoiceRegion = null,
    invoiceCounty = null,
    invoiceCity = null,
    invoicePostalCode = null,
    invoicePec = null,
    invoiceSdi = null,
    invoiceCig = null,
    invoiceCup = null,
    notes = null,

    creationDate = null,
    startDate = null,
    endDate = null,
    lastModified = null,
    lastModifiedBy = null
  } = {}) {
    this.id = id
    this.fiscalCode = fiscalCode
    this.vatCode = vatCode
    this.iban = iban
    this.invoiceName = invoiceName
    this.invoiceAddress = invoiceAddress
    this.invoiceNation = invoiceNation
    this.invoiceRegion = invoiceRegion
    this.invoiceCounty = invoiceCounty
    this.invoiceCity = invoiceCity
    this.invoicePostalCode = invoicePostalCode
    this.invoicePec = invoicePec
    this.invoiceSdi = invoiceSdi
    this.invoiceCig = invoiceCig
    this.invoiceCup = invoiceCup
    this.notes = notes

    this.creationDate = creationDate
    this.startDate = startDate
    this.endDate = endDate
    this.lastModified = lastModified
    this.lastModifiedBy = lastModifiedBy
  }
}
