export default class {
  static new() {
    return new this()
  }

  static default() {
    return new this()
  }

  constructor({
    id = null,
    lecturer = null,
    fee = null,

    creationDate = null,
    startDate = null,
    lastModified = null,
    lastModifiedBy = null
  } = {}) {
    this.id = id
    this.lecturer = lecturer
    this.fee = fee

    this.creationDate = creationDate
    this.startDate = startDate
    this.lastModified = lastModified
    this.lastModifiedBy = lastModifiedBy
  }
}
