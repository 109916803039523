import validateUser from '@/lib/validateUser'
import paymentJobsAdapter from '@/adapters/backoffice/payments/updateJobs'

export default class {
  user = null
  id = null
  payment = null

  constructor(user, id, payment) {
    this.user = user
    this.id = id
    this.payment = payment
  }

  async call() {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    return await paymentJobsAdapter(this.user.token, this.id, this.payment)
  }
}
