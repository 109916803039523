<template>
  <v-autocomplete
    :class="_class"
    @change="changed"
    v-model="nation"
    :items="nations"
    :label="label"
    :rules="_rules"
    :required="required"
    :outlined="readonly"
    :filled="readonly"
    :no-data-text="$t('common.autocomplete.no-data')"
    dense
  ></v-autocomplete>
</template>

<script>
import { get } from 'vuex-pathify'

import validators from '@/domain/validations/rules'

export default {
  name: 'NationSelect',
  props: {
    label: String,
    value: String,
    rules: Array,
    required: Boolean,
    readonly: Boolean
  },
  data() {
    return { nation: this.value }
  },
  computed: {
    nations: get('app/nations'),
    validates() {
      return validators(this)
    },
    _class() {
      return this.required ? 'required' : ''
    },
    _rules() {
      const _rules = this.rules || []
      if (this.required) _rules.push(this.validates.presence)
      return _rules
    }
  },
  methods: {
    changed() {
      this.$emit('input', this.nation)
    }
  },
  watch: {
    value() {
      this.nation = this.value || ''
    }
  }
}
</script>

<style>
.required label.v-label::after {
  content: ' *';
}
</style>
