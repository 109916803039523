export default class {
  static new() {
    return new this()
  }

  static default() {
    return new this({
      active: false
    })
  }

  constructor({
    id = null,
    active = null,
    name = null,
    surname = null,
    fiscalCode = null,
    phone = null,
    mobile = null,
    email = null,
    notes = null,
    intolerances = null,
    photo = null,
    nation = null,
    birthDate = null,
    region = null,
    county = null,
    city = null,
    postalCode = null,
    address1 = null,
    address2 = null,

    creationDate = null,
    startDate = null,
    endDate = null,
    lastModified = null,
    lastModifiedBy = null
  } = {}) {
    this.id = id
    this.active = active
    this.name = name
    this.surname = surname
    this.fiscalCode = fiscalCode
    this.phone = phone
    this.mobile = mobile
    this.email = email
    this.notes = notes
    this.intolerances = intolerances
    this.photo = photo
    this.nation = nation
    this.birthDate = birthDate
    this.region = region
    this.county = county
    this.city = city
    this.postalCode = postalCode
    this.address1 = address1
    this.address2 = address2

    this.creationDate = creationDate
    this.startDate = startDate
    this.endDate = endDate
    this.lastModified = lastModified
    this.lastModifiedBy = lastModifiedBy
  }

  getFullName() {
    return this.name + ' ' + this.surname
  }
}
