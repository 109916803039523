<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12" lg="9">
        <material-card
          icon="mdi-package-variant"
          icon-small
          color="primary"
          :title="$t('common.sections.titles.invoice')"
        >
          <InvoiceCard :entity="entity" />
          <InvoiceJobsForm
            ref="form"
            v-model="entity"
            :errors="errors.jobDetails"
          />
          <InvoiceDetailsCard :items="entity.details" />
        </material-card>
      </v-col>
      <v-col cols="12" lg="3" order="first" order-lg="last">
        <InvoiceSummary :entity="entity">
          <v-divider />
          <template #controls>
            <v-row>
              <v-col cols="3" md="6">
                <v-btn color="success" width="100%" @click="save()">
                  {{ $t('form.customers.new.submit') }}
                </v-btn>
              </v-col>
              <v-col cols="3" md="6">
                <v-btn color="warning" width="100%" @click="leave()">
                  {{ $t('common.leave') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </InvoiceSummary>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsAnEditPage from '@/mixins/actsAsAnEditPage'

import InvoiceCard from '@/components/invoices/InvoiceCard.vue'
import InvoiceDetailsCard from '@/components/invoices/InvoiceDetailsCard.vue'
import InvoiceJobsForm from '@/components/invoices/InvoiceJobsForm.vue'
import InvoiceSummary from '@/components/invoices/InvoiceSummary.vue'

import Invoice from '@/domain/entities/Invoice'
import GetInvoice from '@/domain/useCases/invoices/GetInvoice'
import EditInvoiceJobs from '@/domain/useCases/invoices/EditInvoiceJobs'

export default {
  name: 'EditInvoiceJobsPage',
  mixins: [actsAsAnEditPage],
  components: {
    InvoiceCard,
    InvoiceDetailsCard,
    InvoiceJobsForm,
    InvoiceSummary
  },
  props: { id: String },
  data() {
    return {
      actsAsAnEditPage: {
        entity: 'invoice',
        resource: 'invoices',
        get: GetInvoice,
        edit: EditInvoiceJobs,
        validate: ['form']
      },
      entity: Invoice.default()
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
