<template>
  <div>
    <v-tabs v-model="tab" fixed-tabs>
      <v-tab key="product">{{ $t('entities.names.product') }}</v-tab>
      <v-tab key="contract">{{ $t('entities.names.contract') }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="product">
        <div class="tab-item-content">
          <v-container>
            <ProductSelect
              ref="productSelect"
              v-model="selectedProduct"
              :required="_required('product')"
            />
          </v-container>
        </div>
      </v-tab-item>

      <v-tab-item key="contract">
        <div class="tab-item-content">
          <v-container>
            <ContractSelect
              ref="contractSelect"
              v-model="selectedContract"
              :required="_required('contract')"
            />
          </v-container>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import ContractSelect from '../contracts/ContractSelect.vue'
import ProductSelect from '../products/ProductSelect.vue'

import Contract from '@/domain/entities/Contract'
import Product from '@/domain/entities/Product'

export default {
  name: 'EditionGenericProductSelect',
  components: { ContractSelect, ProductSelect },
  props: { value: [Contract, Product], type: String, required: Boolean },
  data() {
    return {
      tab: 0,
      selectedProduct: null,
      selectedContract: null,
      item: this.value,
      itemType: this.type || 'product'
    }
  },
  methods: {
    _required(type) {
      return this.tab === 1 ? type === 'contract' : type === 'product'
    }
  },
  watch: {
    tab() {
      switch (this.tab) {
        case 0:
          this.item = this.selectedProduct
          this.itemType = 'product'
          break
        case 1:
          this.item = this.selectedContract
          this.itemType = 'contract'
          break
        default:
          this.item = null
          this.itemType = null
      }

      this.$emit('input', this.item)
      this.$emit('update:type', this.itemType)
    },
    selectedProduct() {
      if (this.selectedProduct && this.selectedProduct.id) {
        this.itemType = 'product'
        this.item = this.selectedProduct
        this.$emit('input', this.item)
        this.$emit('update:type', this.itemType)
      }
    },
    selectedContract() {
      if (this.selectedContract && this.selectedContract.id) {
        this.itemType = 'contract'
        this.item = this.selectedContract
        this.$emit('input', this.item)
        this.$emit('update:type', this.itemType)
      }
    }
  }
}
</script>
