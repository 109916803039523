import Base from './Base'
import InvoiceType from '@/domain/entities/PaymentInvoiceType'
export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null
    return new InvoiceType(this.fieldNamesFromResponse(resource))
  }

  static toRequestResource(entity) {
    if (!entity) return null
    return this.fieldNamesForRequest(entity)
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    if (!resource) return null

    const { id, type, position } = resource
    const result = { id, type, position }

    this.formatInternalValuesFromResponse(resource, result)

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(entity, removeEmpty = false) {
    if (!entity) return null

    const { id, type, position } = entity
    const result = { id, type, position }

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }
}
