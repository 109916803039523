<template>
  <v-app>
    <AppBar :page-title="pageTitle" />
    <Drawer />
    <v-main>
      <v-container fluid>
        <FlashAlert />
        <router-view :key="$route.path" />
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
export default {
  name: 'DefaultLayout',
  props: ['pageTitle'],
  components: {
    AppBar: () =>
      import(
        /* webpackChunkName: "default-app-bar" */
        '@/components/theme/layout/AppBar'
      ),
    Drawer: () =>
      import(
        /* webpackChunkName: "default-drawer" */
        '@/components/theme/layout/Drawer'
      ),
    FlashAlert: () =>
      import(
        /* webpackChunkName: "default-flash-alert" */
        '@/components/common/FlashAlert.vue'
      ),
    Footer: () =>
      import(
        /* webpackChunkName: "default-footer" */
        '@/components/theme/layout/Footer'
      )
  }
}
</script>
