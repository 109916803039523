import axios from '@/axios.js'

import ProductFormatter from '../formatters/Product'
import resourceErrorsToResponse from '../formatters/resourceErrorsToResponse'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, resource) {
  const headers = { Authorization: `Bearer ${token}` }
  const payload = { product: ProductFormatter.toRequestResource(resource) }

  try {
    const { data } = await axios.post(`/api/v1/products`, payload, { headers })

    const product = ProductFormatter.fromResponseResource(data.product)
    return { error: false, product }
  } catch (e) {
    return (
      resourceErrorsToResponse(e, ProductFormatter) || exceptionToResponse(e)
    )
  }
}
