<template>
  <v-form ref="form">
    <material-card
      icon="mdi-package-variant"
      iconSmall
      color="primary"
      :title="$t('entities.payment.job.name')"
    >
      <div v-for="(jobDetail, index) in jobDetails" :key="index">
        <PaymentJobCard :value="jobDetail" />
        <hr />
      </div>
    </material-card>
  </v-form>
</template>

<script>
import Payment from '@/domain/entities/Payment'
import PaymentJobCard from './PaymentJobCard.vue'
import { dig } from '@/lib/Utils'

export default {
  name: 'PaymentJobsCard',
  components: {
    PaymentJobCard
  },
  props: {
    value: Payment,
    errors: Object
  },
  computed: {
    jobDetails() {
      return dig(this.value, 'jobDetails') || []
    }
  }
}
</script>
