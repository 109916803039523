<template>
  <v-simple-table class="table lecturers-table" dense>
    <template v-slot:default>
      <thead class="text-left">
        <th>{{ $t('entities.edition.lecturer.lecturer') }}</th>
        <th>{{ $t('entities.edition.lecturer.fee') }}</th>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ formatLecturer(item.lecturer) }}</td>
          <td class="value">{{ item.fee }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { dig } from '@/lib/Utils'

export default {
  name: 'EditionDetailsSimpleTable',
  props: { items: Array },
  methods: {
    formatLecturer(lecturer) {
      const name = dig(lecturer, 'person', 'name')
      const surname = dig(lecturer, 'person', 'surname')
      const company = dig(lecturer, 'company', 'invoiceName')
      return `${name} ${surname} (${company})`
    }
  }
}
</script>

<style>
.lecturers-table table {
  width: auto !important;
}
</style>

<style scoped>
td {
  width: 250px !important;
}
</style>
