<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="1">
          <v-icon class="mt-3 ml-2 mr-1">mdi-paperclip</v-icon>
        </v-col>
        <v-col cols="9" class="pl-0 pr-0">
          <CardTextField :label="this.label" :value="file.name()" />
        </v-col>
        <v-col v-if="file.url()" cols="1" class="pt-5">
          <v-btn :href="file.url()" target="_blank" color="primary" small>
            <v-icon>mdi-cloud-download</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import CardTextField from './CardTextField.vue'

import FileValue from '@/lib/FileValue'

export default {
  name: 'CardFileField',
  components: { CardTextField },
  props: {
    label: String,
    value: String
  },
  computed: {
    file() {
      // Forced to be a String, so this never becomes an empty file (see the
      // FileValue class)
      return FileValue.from(this.value || '')
    }
  }
}
</script>
