export default class {
  static new() {
    return new this()
  }

  static default() {
    return this.new()
  }

  constructor({ id = null, name = null, description = null } = {}) {
    this.id = id
    this.name = name
    this.description = description
  }
}
