<template>
  <CardTextField :label="label" :value="dateValue" />
</template>

<script>
import CardTextField from './CardTextField.vue'
import { dateForUI, dateTimeForUI } from '@/lib/Utils'

export default {
  name: 'CardDateField',
  components: { CardTextField },
  props: { label: String, value: String, showTime: Boolean },
  computed: {
    dateValue() {
      return this.showTime ? dateTimeForUI(this.value) : dateForUI(this.value)
    }
  }
}
</script>
