import Vue from 'vue'

const filenameFilter = function(value) {
  let path
  try {
    path = value.trim()
  } catch (e) {
    path = ''
  }
  return path ? path.substring(path.lastIndexOf('/') + 1) : ''
}

Vue.filter('filename', filenameFilter)

export default filenameFilter
