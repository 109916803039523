import PaymentJobDetail from '@/domain/entities/PaymentJobDetail'
import Base from './Base'
import JobAndDetailFormatter from './JobAndDetail'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null
    const entity = this.fieldNamesFromResponse(resource)

    this.formatDecimalsFromResponseResource(entity, 'amount', 'percentage')

    entity.jobAndDetail = JobAndDetailFormatter.fromResponseResource(
      entity.jobAndDetail
    )

    return new PaymentJobDetail(entity)
  }

  static toRequestResource(entity) {
    if (!entity) return null
    const resource = Object.assign({}, entity)
    this.formatDecimalsForRequestResource(resource, 'amount', 'percentage')

    resource.jobAndDetail = JobAndDetailFormatter.toRequestResource(
      entity.jobAndDetail
    )

    return this.fieldNamesForRequest(resource)
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    const { id, amount, percentage, jobDetRel, notes } = resource

    const result = {
      id,
      amount,
      percentage,
      jobAndDetail: jobDetRel,
      notes
    }

    this.formatInternalValuesFromResponse(resource, result)

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(entity, removeEmpty = false) {
    const { id, amount, percentage, jobAndDetail, notes } = entity

    const result = {
      id,
      amount,
      percentage,
      jobDetRel: jobAndDetail,
      notes
    }

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }
}
