import validateUser from '@/lib/validateUser'
import Error from '@/lib/Error'
import productCreateAdapter from '@/adapters/backoffice/products/create'

export default class {
  user = null
  product = null

  constructor(user, product) {
    this.user = user
    this.product = product
  }

  async call() {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    if (!this.product) return new Error({ message: 'errors.resource.invalid' })

    if (this.product.id)
      return new Error({ message: 'errors.resource.existing' })

    return await productCreateAdapter(this.user.token, this.product)
  }
}
