import {
  ContractsListPage,
  NewContractPage,
  EditContractPage,
  ShowContractPage
} from '@/views/contracts/index.js'

export default [
  {
    path: '/contracts',
    name: 'ContractsPage',
    component: ContractsListPage,
    meta: {
      title: 'menu.long.resources.contracts'
    }
  },
  {
    path: '/contracts/new',
    name: 'NewContractPage',
    component: NewContractPage,
    meta: {
      title: 'menu.long.resources.new-contract'
    }
  },
  {
    path: '/contracts/:id/edit',
    name: 'EditContractPage',
    component: EditContractPage,
    props: true,
    meta: {
      title: 'menu.long.resources.edit-contract'
    }
  },
  {
    path: '/contracts/:id',
    name: 'ShowContractPage',
    component: ShowContractPage,
    props: true,
    meta: {
      title: 'menu.long.resources.show-contract'
    }
  }
]
