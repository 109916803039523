<template>
  <v-form ref="form" @submit.prevent v-if="costCenters">
    <material-card
      color="primary"
      :title="$t('entities.invoice.costCenter.name')"
    >
      <v-col>
        <v-btn color="secondary" @click="addNew">
          {{ $t('common.invoice.costCenterDetail.add') }}
        </v-btn>
      </v-col>
      <div v-for="(costCenterDetail, index) in costCenterDetails" :key="index">
        <InvoiceCostCenterForm
          :costCenters="costCenters"
          v-model="entity.costCenterDetails[index]"
          :errors="errorsAtIndex(index)"
          :invoice="value"
          @remove="removeAt"
        />
        <hr />
      </div>
    </material-card>
  </v-form>
</template>

<script>
import Invoice from '@/domain/entities/Invoice'
import InvoiceCostCenterDetail from '@/domain/entities/InvoiceCostCenterDetail'
import actsAsAForm from '@/mixins/actsAsAForm'
import InvoiceCostCenterForm from './InvoiceCostCenterForm.vue'
import { dig } from '@/lib/Utils'
import { get } from 'vuex-pathify'
import GetCostCenters from '@/domain/useCases/costCenters/GetCostCenters'

export default {
  name: 'InvoiceCostCentersForm',
  mixins: [actsAsAForm],
  components: {
    InvoiceCostCenterForm
  },
  props: {
    value: Invoice,
    errors: Array
  },
  data() {
    return { costCenters: [] }
  },
  computed: {
    user: get('user'),
    costCenterDetails() {
      return dig(this.value, 'costCenterDetails') || []
    }
  },
  methods: {
    addNew() {
      this.entity.costCenterDetails.push(
        new InvoiceCostCenterDetail({
          invoice: this.value
        })
      )
      this.$emit('input', this.entity)
    },
    removeAt(idx) {
      this.entity.costCenterDetails = this.entity.costCenterDetails.filter(
        (_el, _idx) => _idx !== idx
      )
    },
    errorsAtIndex(idx) {
      return dig(this.errors, idx) || {}
    }
  },
  mounted() {
    new GetCostCenters(this.user).call().then(result => {
      if (result.error) {
        new Error({
          message: 'common.autocomplete.error-loading'
        }).toAlert()
        console.info(`Error loading job for autocomplete:`, result)
      } else this.costCenters = result.costCenters
    })
  }
}
</script>
