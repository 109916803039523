import validateUser from '@/lib/validateUser'
import Error from '@/lib/Error'
import customerUpdateAdapter from '@/adapters/backoffice/customers/update'

export default class {
  user = null
  id = null
  customer = null

  constructor(user, id, customer) {
    this.user = user
    this.id = id
    this.customer = customer
  }

  async call() {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    if (!this.customer || !this.id)
      return new Error({ message: 'errors.resource.invalid' })

    if (
      !this.customer.personCompanies ||
      !this.customer.personCompanies.find(pc => pc.type === 'customer')
    )
      return new Error({ message: 'common.personCompany.required' })

    return await customerUpdateAdapter(this.user.token, this.id, this.customer)
  }
}
