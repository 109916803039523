export default class {
  static new() {
    return new this()
  }

  static default() {
    return new this()
  }

  constructor({
    id = null,
    description = null,
    value = null,
    max = null,
    notes = null,

    creationDate = null,
    startDate = null,
    lastModified = null,
    lastModifiedBy = null
  } = {}) {
    this.id = id
    this.description = description
    this.value = value
    this.max = max
    this.notes = notes

    this.creationDate = creationDate
    this.startDate = startDate
    this.lastModified = lastModified
    this.lastModifiedBy = lastModifiedBy
  }
}
