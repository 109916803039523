import Base from './Base'
import CostCenter from '@/domain/entities/CostCenter'
import { isEmpty, isString } from '@/lib/Utils'
import CostCenterDetailFormatter from './CostCenterDetail'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null
    const entity = this.fieldNamesFromResponse(resource)

    entity.details = CostCenterDetailFormatter.fromResponseResourcesList(
      resource.costCenterDets
    )

    return new CostCenter(entity)
  }

  static toRequestResource(entity) {
    if (!entity) return null
    const resource = this.fieldNamesForRequest(entity)
    resource.costCenterDets = CostCenterDetailFormatter.toRequestResourcesList(
      entity.details
    )
    return resource
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    if (!resource) return null
    let { id, name, notes, startDate, endDate } = resource

    const result = {
      id,
      name,
      notes,
      startDate,
      endDate
    }

    this.formatInternalValuesFromResponse(resource, result)

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(entity, removeEmpty = false) {
    if (!entity) return null

    let { id, name, notes, startDate, endDate } = entity

    const result = {
      id,
      name,
      notes,
      startDate,
      endDate
    }

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static formatFilterOptions(filter) {
    if (isEmpty(filter)) return null
    let result = super.formatFilterOptions(filter) || {}
    let expandedResult = {}
    expandedResult = {
      name: filter.name
    }

    return this.removeEmptyAttributes(Object.assign(result, expandedResult))
  }

  static fromResponseErrors(errors, resource = null) {
    const fieldErrors = resource ? errors[resource] : errors.invoPurchase

    const extraMessages = []

    const detsErrors = errors['costCenterDets']

    if (isString(detsErrors))
      extraMessages.push(`errors.costCenter.details.${detsErrors}`)

    return {
      fields: this.fieldNamesFromResponse(fieldErrors, true),
      extraMessages
    }
  }
}
