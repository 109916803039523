import moment from 'moment'

export default class {
  static new() {
    return new this()
  }

  static default() {
    return new this({ fromDate: moment(Date.now()).format('YYYY-MM-DD') })
  }

  constructor({
    id = null,
    code = null,
    name = null,
    category = null,
    type = null,
    price = null,
    language = null,
    description = null,
    durationHours = null,
    durationDays = null,
    prerequisites = null,
    minAttendees = null,
    maxAttendees = null,
    notes = null,

    creationDate = null,
    startDate = null,
    lastModified = null,
    lastModifiedBy = null
  } = {}) {
    this.id = id
    this.code = code
    this.name = name
    this.category = category
    this.type = type
    this.price = price
    this.language = language
    this.description = description
    this.durationHours = durationHours
    this.durationDays = durationDays
    this.prerequisites = prerequisites
    this.minAttendees = minAttendees
    this.maxAttendees = maxAttendees
    this.notes = notes

    this.creationDate = creationDate
    this.startDate = startDate
    this.lastModified = lastModified
    this.lastModifiedBy = lastModifiedBy
  }
}
