var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"purchases-list","fluid":"","tag":"section"}},[_c('material-card',{attrs:{"icon":"mdi-credit-card-plus","icon-small":"","title":_vm.$t('list.purchases.title'),"color":"primary"}},[_c('v-data-table',{attrs:{"show-select":"","headers":_vm.headers,"items":_vm.itemsWithSelectSupport,"options":_vm.options,"footer-props":_vm.footerOptions,"server-items-length":_vm.totalItems,"loading":_vm.loading,"no-data-text":_vm.$t('common.list.no-data')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{staticClass:"mx-1"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"auto"}},[(_vm.selected.length)?_c('v-btn',{staticClass:"mt-3",attrs:{"small":"","color":"primary"},on:{"click":function($event){_vm.$emit(
                    'create-invoice',
                    _vm.selected.map(function (purchase) { return purchase.id; })
                  )}}},[_vm._v(_vm._s(_vm.$t('common.purchase.create-invoices')))]):_vm._e()],1),_c('v-col',[_c('Searchbar',{on:{"submit":_vm.searchbarSubmitted}})],1),_c('v-col',{staticClass:"pt-0 pl-5",attrs:{"cols":"1"}},[_c('RoundButton',{attrs:{"icon":"mdi-plus","alt":_vm.$t('list.purchases.new')},on:{"click":function($event){return _vm.$emit('goto-new')}}})],1)],1)],1)]},proxy:true},{key:"item.invoiceOptions",fn:function(ref){
                  var item = ref.item;
return [(_vm.isInvoiceable(item))?_c('v-btn',{attrs:{"small":"","color":"success"},on:{"click":function($event){return _vm.$emit('create-invoice', item.id)}}},[_vm._v(_vm._s(_vm.$t('common.purchase.create-invoice')))]):_vm._e(),(_vm.hasInvoice(item))?_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.$emit('show-invoices', item.id)}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-receipt")]),_vm._v(_vm._s(_vm.$t('common.purchase.show-invoice')))],1):_vm._e()]}},{key:"item.purchaseDate",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.purchaseDate))+" ")]}},{key:"item.purchaseStatus",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"text-center"},[(_vm.isValid(item))?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-checkbox-marked-circle")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close-circle")])],1)]}},{key:"item.contractOrOffer",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t( ("list.purchases.contractOrOffer." + (item.offer ? 'offer' : 'contract')) ))+" ")]}},{key:"item.finalPrice",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("append")(item.finalPrice,'€'))+" ")]}},{key:"item.contractOrOfferName",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item[item.offer ? 'offer' : 'contract'].name)+" ")]}},{key:"item.contractOrOfferDescription",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item[item.offer ? 'offer' : 'contract'].description)+" ")]}},{key:"item.creationDate",fn:function(ref){
                  var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("dateTime")(item.creationDate)))])]}},{key:"item.lastModified",fn:function(ref){
                  var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("dateTime")(item.lastModified)))])]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"actions"},[_c('v-icon',{staticClass:"mr-2 show-purchase-button",on:{"click":function($event){return _vm.$emit('goto-show', item.id)}}},[_vm._v("mdi-eye")]),_c('v-icon',{staticClass:"mr-2 edit-purchase-button",on:{"click":function($event){return _vm.$emit('goto-edit', item.id)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{staticClass:"delete-purchase-button",on:{"click":function($event){return _vm.$emit('delete', item.id)}}},[_vm._v("mdi-delete")])],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }