<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12" lg="9">
        <material-card
          icon="mdi-package-variant"
          icon-small
          :title="$t('common.sections.titles.costCenter')"
          color="primary"
        >
          <CostCenterForm ref="form" v-model="entity" :errors="errors" />
        </material-card>
      </v-col>
      <v-col cols="12" lg="3" order="first" order-lg="last">
        <CostCenterSummary :entity="entity">
          <v-divider />
          <template #controls>
            <v-row>
              <v-col cols="3" md="6">
                <v-btn color="success" width="100%" @click="save()">
                  {{ $t('form.customers.new.submit') }}
                </v-btn>
              </v-col>
              <v-col cols="3" md="6">
                <v-btn color="warning" width="100%" @click="leave()">
                  {{ $t('common.leave') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </CostCenterSummary>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsACreatePage from '@/mixins/actsAsACreatePage'

import CostCenterForm from '@/components/costCenters/CostCenterForm.vue'
import CostCenterSummary from '@/components/costCenters/CostCenterSummary.vue'

import CostCenter from '@/domain/entities/CostCenter'
import CreateCostCenter from '@/domain/useCases/costCenters/CreateCostCenter'

export default {
  name: 'NewCostCenterPage',
  mixins: [actsAsACreatePage],
  components: { CostCenterForm, CostCenterSummary },
  data() {
    return {
      actsAsACreatePage: {
        entity: 'costCenter',
        resource: 'costCenters',
        create: CreateCostCenter
      },
      entity: CostCenter.default()
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
