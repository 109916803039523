<template>
  <v-form v-if="entity" ref="form" @submit.prevent>
    <v-container>
      <v-row>
        <v-col cols="12" lg="12">
          <TextField
            :label="$t('entities.contract.name')"
            v-model="entity.name"
            required
            :error="errors.name"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="2">
          <v-switch
            color="success"
            :label="$t('entities.contract.active')"
            v-model="entity.active"
            :error="errors.active"
          />
        </v-col>

        <v-col cols="12" lg="2">
          <TextField
            :label="$t('entities.contract.code')"
            v-model="entity.code"
            required
            :error="errors.code"
          />
        </v-col>

        <v-col cols="12" lg="2">
          <TextField
            type="number"
            :label="$t('entities.contract.version')"
            v-model="entity.version"
            required
            :error="errors.version"
          />
        </v-col>

        <v-col cols="12" lg="2">
          <ContractTypeSelect
            :label="$t('entities.contract.type')"
            v-model="entity.type"
            required
            :error="errors.type"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <DateField
            :label="$t('entities.contract.validFrom')"
            v-model="entity.validFrom"
            required
            :error="errors.validFrom"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <DateField
            :label="$t('entities.contract.validTo')"
            v-model="entity.validTo"
            required
            :error="errors.validTo"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="2">
          <TextField
            type="currency"
            :label="$t('entities.contract.price')"
            v-model="entity.price"
            required
            :error="errors.price"
          />
        </v-col>

        <v-col cols="12" lg="2">
          <TextField
            type="percentage"
            :label="$t('entities.contract.discount')"
            v-model="entity.discount"
            required
            :error="errors.discount"
          />
        </v-col>

        <v-col cols="12" lg="2">
          <TextField
            type="number"
            :label="$t('entities.contract.duration')"
            v-model="entity.duration"
            :error="errors.duration"
          />
        </v-col>

        <v-col cols="12" lg="2">
          <v-checkbox
            :label="$t('entities.contract.autoRenewal')"
            v-model="entity.autoRenewal"
            :error="errors.autoRenewal"
          />
        </v-col>

        <v-col cols="12" lg="2">
          <TextField
            type="number"
            :label="$t('entities.contract.renewalStep')"
            v-model="entity.renewalStep"
            :error="errors.renewalStep"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="12">
          <Textarea
            :label="$t('entities.contract.description')"
            v-model="entity.description"
            :error="errors.description"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="12">
          <FileField
            :label="$t('entities.contract.pdf')"
            v-model="entity.pdf"
            max-size="5"
            :extensions="['pdf']"
            :error="errors.pdf"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card>
            <material-alert
              v-if="!nDetails"
              color="warning"
              icon="mdi-alert-outline"
            >
              {{ $t('errors.contract.details.required') }}
            </material-alert>
            <v-card-title>
              {{ $t('entities.contract.details') }}
            </v-card-title>
            <DetailsTable v-model="entity.details" :errors="errors.details" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import actsAsAForm from '@/mixins/actsAsAForm'

import {
  TextField,
  DateField,
  Textarea,
  FileField
} from '@/components/common/form'
import ContractTypeSelect from './ContractTypeSelect.vue'
import DetailsTable from './ContractDetailsEditableTable.vue'

import Contract from '@/domain/entities/Contract'
import Error from '@/lib/Error'

export default {
  name: 'ContractForm',
  mixins: [actsAsAForm],
  components: {
    ContractTypeSelect,
    TextField,
    DateField,
    Textarea,
    FileField,
    DetailsTable
  },
  props: { value: Contract, errors: Object },
  computed: {
    nDetails() {
      return this.entity.details ? this.entity.details.length : 0
    }
  },
  methods: {
    validate() {
      const valid = this.$refs.form.validate()
      let validDetails = false

      if (this.nDetails > 0) validDetails = true
      else
        new Error({
          message: 'errors.contract.details.required'
        }).saveAlert(this)

      return valid && validDetails
    }
  }
}
</script>
