<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12" lg="9">
        <material-card
          icon="mdi-package-variant"
          icon-small
          color="primary"
          :title="$t('common.sections.titles.payment')"
        >
          <PaymentCard :entity="entity" />
          <PaymentCostCentersForm
            ref="form"
            v-model="entity"
            :errors="errors.costCenterDetails"
          />
        </material-card>
      </v-col>
      <v-col cols="12" lg="3" order="first" order-lg="last">
        <PaymentSummary :entity="entity">
          <v-divider />
          <template #controls>
            <v-row>
              <v-col cols="3" md="6">
                <v-btn color="success" width="100%" @click="save()">
                  {{ $t('form.customers.new.submit') }}
                </v-btn>
              </v-col>
              <v-col cols="3" md="6">
                <v-btn color="warning" width="100%" @click="leave()">
                  {{ $t('common.leave') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </PaymentSummary>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsAnEditPage from '@/mixins/actsAsAnEditPage'

import PaymentCard from '@/components/payments/PaymentCard.vue'
import PaymentCostCentersForm from '@/components/payments/PaymentCostCentersForm.vue'
import PaymentSummary from '@/components/payments/PaymentSummary.vue'

import Payment from '@/domain/entities/Payment'
import GetPayment from '@/domain/useCases/payments/GetPayment'
import EditPaymentCostCenters from '@/domain/useCases/payments/EditPaymentCostCenters'
import { get } from 'vuex-pathify'

export default {
  name: 'EditPaymentCostCentersPage',
  mixins: [actsAsAnEditPage],
  components: {
    PaymentCard,
    PaymentCostCentersForm,
    PaymentSummary
  },
  props: { id: String },
  data() {
    return {
      actsAsAnEditPage: {
        entity: 'payment',
        resource: 'payments',
        get: GetPayment,
        edit: EditPaymentCostCenters,
        validate: ['form']
      },
      entity: Payment.default()
    }
  },
  computed: {
    user: get('user')
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
