<template>
  <div>
    <div v-if="fileValue.isFileFormObject()" cols="12" lg="6">
      <v-file-input
        class="_class"
        show-size
        :label="label"
        :value="fileValue.file"
        :rules="_rules"
        :required="required"
        :messages="_messages"
        :error-messages="errors"
        @change="fileChanged"
      />
    </div>
    <div v-else cols="1 pt-6">
      <v-row>
        <v-col cols="10">
          <TextField
            :label="label"
            :value="fileValue.name()"
            :required="required"
            clearable
            readonly
            outlined
            filled
            @input="fileChanged"
          >
            <template #prepend>
              <v-icon>mdi-paperclip</v-icon>
            </template>
          </TextField>
        </v-col>
        <v-col cols="2">
          <v-btn :href="fileValue.url()" target="_blank" color="primary" small>
            <v-icon>mdi-cloud-download</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import FileValue from '@/lib/FileValue'
import validators from '@/domain/validations/rules'

import { TextField } from '@/components/common/form'

export default {
  name: 'FileField',
  components: { TextField },
  props: {
    label: String,
    value: [String, File],
    required: Boolean,
    maxSize: String, // in MB
    extensions: Array,
    errors: Object
  },
  data() {
    return {
      fileValue: FileValue.from(this.value)
    }
  },
  computed: {
    _class() {
      return this.required ? 'required' : ''
    },
    _messages() {
      const _messages = []
      if (this.extensions)
        _messages.push(
          this.$t('common.file.allowed.extensions', {
            extensions: this.extensions
          })
        )

      if (this.maxSize)
        _messages.push(
          this.$t('common.file.allowed.size', { size: this.maxSize })
        )

      // If I give the input an array of messages, only one is shown...
      return _messages.join(', ')
    },
    _rules() {
      const _rules = []

      if (this.required) _rules.push(this.validates.presence)
      if (this.extensions)
        _rules.push(this.validates.fileExtensionIn(this.extensions))
      if (this.maxSize) _rules.push(this.validates.fileSize(this.maxSize))

      return _rules
    },
    validates() {
      return validators(this)
    }
  },
  methods: {
    fileChanged(newValue) {
      this.$emit('input', newValue)
    }
  },
  watch: {
    value() {
      this.fileValue = FileValue.from(this.value)
    }
  }
}
</script>
