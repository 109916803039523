import {
  CostCentersListPage,
  NewCostCenterPage,
  EditCostCenterPage,
  ShowCostCenterPage
} from '@/views/costCenters/index.js'

export default [
  {
    path: '/costCenters',
    name: 'CostCentersPage',
    component: CostCentersListPage,
    meta: {
      title: 'menu.long.resources.cost-centers'
    }
  },
  {
    path: '/costCenters/new',
    name: 'NewCostCenterPage',
    component: NewCostCenterPage,
    meta: {
      title: 'menu.long.resources.new-cost-center'
    }
  },
  {
    path: '/costCenters/:id/edit',
    name: 'EditCostCenterPage',
    component: EditCostCenterPage,
    props: true,
    meta: {
      title: 'menu.long.resources.edit-cost-center'
    }
  },
  {
    path: '/costCenters/:id',
    name: 'ShowCostCenterPage',
    component: ShowCostCenterPage,
    props: true,
    meta: {
      title: 'menu.long.resources.show-cost-center'
    }
  }
]
