<template>
  <v-autocomplete
    class="_class"
    @change="changed"
    v-model="language"
    :items="languages"
    :label="label"
    :rules="_rules"
    :required="required"
    :outlined="readonly || outlined"
    :filled="readonly"
    :hide-details="hideDetails"
    :no-data-text="$t('common.autocomplete.no-data')"
    dense
  ></v-autocomplete>
</template>

<script>
import { get } from 'vuex-pathify'

import validators from '@/domain/validations/rules'

export default {
  name: 'LanguageSelect',
  props: {
    label: String,
    value: String,
    rules: Array,
    required: Boolean,
    readonly: Boolean,
    outlined: Boolean,
    hideDetails: Boolean
  },
  data() {
    return { language: '' }
  },
  computed: {
    languages: get('app/languages'),
    validates() {
      return validators(this)
    },
    _class() {
      return this.required ? 'required' : ''
    },
    _rules() {
      const _rules = this.rules || []
      if (this.required) _rules.push(this.validates.presence)
      return _rules
    }
  },
  methods: {
    changed() {
      this.$emit('input', this.language)
    }
  },
  watch: {
    value() {
      this.language = this.value || ''
    }
  }
}
</script>
