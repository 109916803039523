import entities from './it/entities.js'
import errors from './it/errors.js'
import list from './it/list.js'
import menu from './it/menu.js'
import procedures from './it/procedures.js'
import { dateForUI } from '@/lib/Utils'

export default {
  app: {
    title: 'MMO Amministrazione'
  },
  entities,
  errors,
  list,
  menu,
  procedures,

  payment: {
    code: {
      mp01: 'contanti',
      mp02: 'assegno',
      mp03: 'assegno circolare',
      mp04: 'contanti presso Tesoreria',
      mp05: 'bonifico',
      mp06: 'vaglia cambiario',
      mp07: 'bollettino bancario',
      mp08: 'carta di pagamento',
      mp09: 'RID',
      mp10: 'RID utenze',
      mp11: 'RID veloce',
      mp12: 'RIBA',
      mp13: 'MAV',
      mp14: 'quietanza erario',
      mp15: 'giroconto su conti di contabilità speciale',
      mp16: 'domiciliazione bancaria',
      mp17: 'domiciliazione postale',
      mp18: 'bollettino di c/c postale',
      mp19: 'SEPA Direct Debit',
      mp20: 'SEPA Direct Debit CORE',
      mp21: 'SEPA Direct Debit B2B',
      mp22: 'Trattenuta su somme già riscosse'
    }
  },
  common: {
    active: {
      title: 'Stato',
      true: 'Attivi',
      false: 'Non attivi',
      all: 'Tutti'
    },
    autoassigned: 'Generato automaticamente',
    yes: 'Sì',
    no: 'No',
    ok: 'Ok',
    delete: {
      yes: 'Sì, cancellare',
      no: 'No, non cancellare'
    },
    leave: 'Annulla',
    version: 'V. {version}',
    'back-to-list': 'Torna alla lista',
    'fill-unknown': 'Riempi campi obbligatori con dati "segnaposto"',
    date: { hint: 'GG/MM/YYYY' },
    months: 'Mesi',
    next: 'Continua',
    finalize: 'Completa',
    cancel: 'Annulla',
    help: 'Aiuto',
    relationDetails: 'Dati addizionali',
    'discounted-value': 'Prezzo finale',
    fields: {
      creationDate: 'Data di creazione',
      lastModified: 'Ultima modifica',
      lastModifiedBy: 'Modificato da',
      startDate: 'Data inizio',
      endDate: 'Data fine'
    },
    form: {
      save: 'Salva'
    },
    sections: {
      titles: {
        person: 'Dati anagrafici',
        customer: 'Dati di fatturazione cliente',
        supplier: 'Dati di fatturazione fornitore',
        company: 'Ragione sociale',
        product: 'Prodotto',
        edition: 'Edizione',
        offer: 'Offerta',
        contract: 'Contratto',
        customerContract: 'Istanza di contratto',
        purchase: 'Acquisto',
        invoice: 'Fattura',
        payment: 'Pagamento',
        costCenter: 'Centro di costo',
        job: 'Commesse'
      }
    },
    avatar: {
      add: 'Fare click per caricare una foto',
      remove: 'Rimuovi foto',
      missing: 'Foto mancante'
    },
    list: {
      search: {
        title: 'Filtri di ricerca',
        submit: 'Cerca',
        reset: 'Svuota'
      },
      'no-data': 'Nessun risultato trovato',
      'n-selected': ({ named }) => {
        const n = Number(named('n') || 0)
        return n != 1 ? `${n} elementi selezionati` : '1 elemento selezionato'
      }
    },
    autocomplete: {
      'no-data': 'Nessun risultato trovato',
      'at-least-n-chars':
        'Scrivere almeno {n} caratteri per iniziare la ricerca',
      'error-loading': "Errore caricando valori per l'autocompletamento",
      empty: 'Nessuna selezione'
    },
    companies: {
      search: 'Cerca tra le ragioni sociali esistenti',
      create: 'Crea una nuova ragione sociale',
      submit: 'Aggiungi',
      cancel: 'Annulla'
    },
    customer: {
      'active-coaching': 'In coaching',
      'inactive-coaching': 'Non in coaching',
      show: 'Dettaglio cliente',
      edit: 'Modifica cliente',
      'copy-data': 'Copia dati anagrafici nei dati fiscali',
      delete: {
        title: 'Cancellare questo cliente?',
        'confirm-text': 'Il cliente verrà rimosso dal sistema, continuare?'
      }
    },
    supplier: {
      show: 'Dettaglio fornitore',
      edit: 'Modifica fornitore',
      'copy-data': 'Copia dati anagrafici nei dati fiscali',
      delete: {
        title: 'Cancellare questo fornitore?',
        'confirm-text': 'Il fornitore verrà rimosso dal sistema, continuare?'
      }
    },
    file: {
      allowed: {
        extensions: ({ named }) => {
          const extensions = named('extensions')
          const extensionsString = extensions.join(', ')
          if (extensions.length > 1)
            return `estensioni permesse: ${extensionsString}`
          else return `estensione permessa: ${extensionsString}`
        },
        size: 'dimensione massima file: {size} MB'
      }
    },
    contract: {
      create: 'Crea un nuovo contratto',
      edit: 'Modifica questo contratto',
      search: 'Cerca tra i contratti esistenti',
      detail: {
        add: 'Aggiungi dettaglio',
        addBelow: 'Aggungi sotto',
        moveUp: 'Sposta in alto',
        moveDown: 'Sposta in basso',
        remove: 'Rimuovi dettaglio'
      },
      delete: {
        title: 'Cancellare questo contratto?',
        'confirm-text': 'Il contratto verrà rimosso dal sistema, continuare?'
      }
    },
    customerContract: {
      create: 'Crea nuova istanza di contratto',
      edit: 'Modifica questa istanza di contratto',
      search: 'Cerca tra le istanze di contratto esistenti',
      detail: {
        add: 'Aggiungi dettaglio',
        addBelow: 'Aggungi sotto',
        moveUp: 'Sposta in alto',
        moveDown: 'Sposta in basso',
        remove: 'Rimuovi dettaglio'
      },
      delete: {
        title: 'Cancellare questa istanza di contratto?',
        'confirm-text': 'Verrà rimosso dal sistema, continuare?'
      },
      buyer: {
        name: 'Nome acquirente',
        surname: 'Cognome acquirente'
      }
    },
    personCompany: {
      required: {
        customer: 'Dati fiscali cliente richiesti',
        supplier: 'Dati fiscali fornitore richiesti'
      },
      edit: {
        customer: 'Dati fiscali cliente',
        supplier: 'Dati fiscali fornitore'
      },
      remove: {
        title: 'Rimuovere questa ragione sociale?',
        'confirm-text':
          'La modifica non verrà salvata finchè non verrà premito il tasto "salva"'
      },
      list: {
        customer: 'Lista clienti',
        supplier: 'Lista fornitori'
      },
      new: {
        customer: 'Nuovo cliente',
        supplier: 'Nuovo fornitore'
      },
      search: {
        customer: 'Cerca tra i clienti',
        supplier: 'Cerca tra i fornitori'
      },
      'add-empty': {
        customer: 'Aggiungi nuova scheda dati fiscali cliente',
        supplier: 'Aggiungi nuova scheda dati fiscali fornitore'
      },
      'none-selected': 'Nessuna persona selezionata'
    },
    product: {
      search: 'Cerca tra i prodotti esistenti',
      show: 'Dettaglio prodotto',
      edit: 'Modifica prodotto',
      delete: {
        title: 'Cancellare questo prodotto?',
        'confirm-text': 'Il prodotto verrà rimosso dal sistema, continuare?'
      },
      productList: 'Listino prodotto'
    },
    edition: {
      create: 'Crea una nuova edizione',
      edit: 'Modifica queste edizione',
      show: "Dettaglio dell'edizione",
      'selected-generic-product': 'Prodotto selezionato',
      'remove-generic-product': 'Rimuovi prodotto',
      offers: {
        title: 'Offerte di cui questa edizione fa parte',
        description: 'Gestisci associazioni con offerte',
        'add-title': 'Inserisci edizione in offerte',
        'no-offers':
          'Questa edizione non è ancora associato con alcuna offerta.',
        link: ({ named }) => {
          const n = Number(named('n') || 0)
          return n == 1 ? "Aggiungi all'offerta" : 'Aggiungi alle offerte'
        },
        unlink: ({ named }) => {
          const n = Number(named('n') || 0)
          return n == 1 ? "Rimuovi dall'offerta" : 'Rimuovi dalle offerte'
        }
      },
      lecturer: {
        add: 'Aggiungi relatore',
        addBelow: 'Aggungi sotto',
        moveUp: 'Sposta in alto',
        moveDown: 'Sposta in basso',
        remove: 'Rimuovi relatore'
      },
      search: {
        lecturer: 'Cerca relatore tra i fornitori esistenti'
      }
    },
    offer: {
      edit: 'Modifica questa offerta',
      delete: {
        'confirm-text': 'Cancellare questa offerta?'
      },
      editions: {
        'no-editions': 'Questa offerta non ha edizioni',
        'add-title': 'Aggiungi edizioni a questa offerta',
        link: ({ named }) => {
          const n = Number(named('n') || 0)
          return n == 1 ? 'Aggiungi prodotto' : 'Aggiungi prodotti'
        },
        unlink: ({ named }) => {
          const n = Number(named('n') || 0)
          return n == 1 ? 'Rimuovi prodotto' : 'Rimuovi prodotti'
        }
      },
      products: {
        title: 'Prodotti parte di questa offerta',
        description: 'Gestisci associazioni con prodotti',
        link: ({ named }) => {
          const n = Number(named('n') || 0)
          return n == 1 ? 'Aggiungi prodotto' : 'Aggiungi prodotti'
        },
        unlink: ({ named }) => {
          const n = Number(named('n') || 0)
          return n == 1 ? 'Rimuovi prodotto' : 'Rimuovi prodotti'
        }
      }
    },
    purchase: {
      show: "Dettagli dell'acquisto",
      edit: 'Modifica questo acquisto',
      delete: {
        title: 'Cancellare questo acquisto?',
        'confirm-text': "L'acquisto verrà rimosso dal sistema, continuare?"
      },
      'contract-number-autoassigned': 'Il N. contratto è auto-assegnato',
      'invoice-options': 'Fatture',
      'create-invoice': 'Crea fattura',
      'create-customer-contract': 'Crea istanza di contratto',
      'create-invoices': 'Fattura selezionati',
      'show-invoice': 'Mostra'
    },
    invoice: {
      branch: {
        search: 'Ramo fattura'
      },
      show: 'Dettagli della fattura',
      details: {
        'no-details': 'Questa fattura non ha dettagli'
      },
      'partial-amend': 'Storno parziale fattura',
      amend: 'Storno totale fattura',
      edit: 'Modifica fattura',
      send: { title: 'Invia al SDI', 'confirm-text': 'Confermare?' },
      preview: 'Anteprima / Salva',
      saveConfirmed: 'Fattura salvata sul server',
      summary: {
        title: ({ named }) => {
          // named should be an Invoice instance
          const date = dateForUI(named('date'))
          const code = named('code')
          const version = named('version')
          return code && version
            ? `Fattura N° ${named('code')} V.${named('version')} del ${date}`
            : ''
        },
        subtitle: ({ named }) => {
          const amount = `${named('amount')} €`
          return `${named('name')}: ${amount}`
        }
      },
      costCenterDetail: {
        add: 'Aggiungi centro di costo',
        edit: 'Modifica centri di costo'
      },
      jobDetail: {
        add: 'Aggiungi commesse',
        edit: 'Modifica commesse'
      }
    },
    offerOrContract: {
      search: 'Cerca offerte e contratti'
    },
    payment: {
      search: 'Cerca tra i pagamenti',
      show: 'Dettaglio pagamento',
      edit: 'Modifica pagamento',
      delete: {
        title: 'Cancellare questo pagamento?',
        'confirm-text': 'Il pagamento verrà rimosso dal sistema, continuare?'
      },
      costCenterDetail: {
        add: 'Aggiungi centro di costo',
        edit: 'Modifica centri di costo'
      },
      jobDetail: {
        add: 'Aggiungi commesse',
        edit: 'Modifica commesse'
      }
    },
    dialog: {
      ok: 'Ok'
    },
    costCenter: {
      search: 'Cerca tra i centro di costo',
      show: 'Dettaglio centro di costo',
      edit: 'Modifica centro di costo',
      delete: {
        title: 'Cancellare questo centro di costo?',
        'confirm-text':
          'Il centro di costo verrà rimosso dal sistema, continuare?'
      },
      detail: {
        add: 'Aggiungi dettaglio',
        addBelow: 'Aggungi sotto',
        moveUp: 'Sposta in alto',
        moveDown: 'Sposta in basso',
        remove: 'Rimuovi dettaglio'
      }
    },
    job: {
      search: 'Cerca tra le commesse',
      show: 'Dettaglio commessa',
      edit: 'Modifica commessa',
      delete: {
        title: 'Cancellare questa commessa?',
        'confirm-text': 'La commessa verrà rimossa dal sistema, continuare?'
      },
      detail: {
        search: 'Cerca sotto commesse',
        create: 'Crea sotto commessa',
        add: 'Aggiungi dettaglio',
        addBelow: 'Aggungi sotto',
        moveUp: 'Sposta in alto',
        moveDown: 'Sposta in basso',
        remove: 'Rimuovi dettaglio'
      }
    }
  },
  form: {
    login: {
      email: 'E-Mail',
      password: 'Password',
      submit: 'Entra'
    },
    customers: {
      new: {
        title: 'Inserisci nuovo cliente',
        submit: 'Salva'
      },
      edit: {
        title: 'Modifica cliente',
        submit: 'Salva'
      },
      show: {
        title: 'Dettaglio cliente'
      },
      delete: {
        'confirm-text': 'Cancellare cliente?'
      },
      error: {
        photo:
          'Errore nel salvataggio della foto, ma gli altri dati utente sono stati salvati correttamente'
      },
      company: {
        remove: {
          title: 'Rimuovi questa ragione sociale',
          'confirm-text': 'Rimuovere questa ragione sociale?',
          yes: 'Sì',
          no: 'No'
        }
      }
    },
    suppliers: {
      new: {
        title: 'Inserisci nuovo fornitore',
        submit: 'Salva'
      },
      edit: {
        title: 'Modifica fornitore',
        submit: 'Salva'
      },
      show: {
        title: 'Dettaglio fornitore'
      },
      delete: {
        'confirm-text': 'Cancellare fornitore?'
      },
      error: {
        photo:
          'Errore nel salvataggio della foto, ma gli altri dati utente sono stati salvati correttamente'
      },
      company: {
        remove: {
          title: 'Rimuovi questa ragione sociale',
          'confirm-text': 'Rimuovere questa ragione sociale?',
          yes: 'Sì',
          no: 'No'
        }
      }
    },
    companies: {
      new: {
        submit: 'Salva'
      },
      error: {
        'vat-or-fiscal-code-required':
          'Una azienda deve avere partita IVA o codice fiscale'
      }
    },
    products: {
      contractOverrides: {
        discountOverridden: 'Sconto sovrascritto da prodotto',
        discountNotOverridden: 'Sconto non sovrascritto da prodotto',
        reset: 'Rimuovi la sovrascrittura'
      },
      new: {
        title: 'Inserisci nuovo prodotto',
        submit: 'Salva'
      },
      edit: {
        title: 'Modifica prodotto',
        submit: 'Salva'
      },
      show: {
        title: 'Dettaglio prodotto'
      },
      delete: {
        confirm: 'Cancellare prodotto?'
      }
    },
    editions: {
      new: {
        title: 'Inserisci nuova edizione',
        submit: 'Salva'
      },
      edit: {
        title: 'Modifica edizione',
        submit: 'Salva'
      },
      show: {
        title: 'Dettaglio edizione'
      },
      delete: {
        'confirm-text': 'Cancellare edizione?'
      }
    },
    offers: {
      new: {
        title: 'Inserisci nuova offerta',
        submit: 'Salva'
      },
      edit: {
        title: 'Modifica offerta',
        submit: 'Salva'
      },
      show: {
        title: 'Dettaglio offerta'
      }
    },
    contracts: {
      new: {
        title: 'Inserisci nuovo contratto',
        submit: 'Salva'
      },
      edit: {
        title: 'Modifica contratto',
        submit: 'Salva'
      },
      show: {
        title: 'Dettaglio contratto'
      },
      delete: {
        'confirm-text': 'Cancellare contratto?'
      },
      offer: {
        quantity: 'Quantità',
        discountMonths: 'Durata sconto',
        hasSpecialDiscount: 'Sconto non-standard',
        discount: 'Sconto',
        update: 'Cambia'
      }
    },
    purchases: {
      'signature-file-upload': 'Carica documento firmato',
      new: {
        title: 'Inserisci nuovo acquisto',
        submit: 'Salva'
      },
      edit: {
        title: 'Modifica acquisto',
        submit: 'Salva'
      },
      show: {
        title: 'Dettaglio acquisto',
        cancel: 'Chiudi'
      },
      delete: {
        'confirm-text': 'Cancellare acquisto?'
      },
      error: {
        'signed-document':
          'Errore nel salvataggio della documento firmato, ma gli altri dati sono stati salvati correttamente'
      },
      customer: {
        title: 'Dati cliente'
      },
      customer2: {
        title: 'Dati cliente addizionale'
      },
      offerOrContract: {
        title: 'Offerta o contratto'
      }
    },
    costCenters: {
      remove: {
        title: 'Rimuovi centro di costo',
        'confirm-text': 'Rimuovere questo centro di costo?',
        yes: 'Sì',
        no: 'No'
      }
    },
    jobs: {
      remove: {
        title: 'Rimuovi commessa',
        'confirm-text': 'Rimuovere questa commessa',
        yes: 'Sì',
        no: 'No'
      }
    }
  }
}
