<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12" lg="9">
        <material-card
          icon="mdi-file-document-multiple"
          icon-small
          :title="$t('common.sections.titles.customerContract')"
          color="primary"
        >
          <CustomerContractForm ref="form" v-model="entity" :errors="errors" />
        </material-card>
      </v-col>
      <v-col cols="12" lg="3" order="first" order-lg="last">
        <CustomerContractSummary :entity="entity">
          <v-divider />
          <template #controls>
            <v-row>
              <v-col cols="3" md="6">
                <v-btn color="success" width="100%" @click="save()">
                  {{ $t('common.form.save') }}
                </v-btn>
              </v-col>
              <v-col cols="3" md="6">
                <v-btn color="warning" width="100%" @click="leave()">
                  {{ $t('common.leave') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </CustomerContractSummary>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsAnEditPage from '@/mixins/actsAsAnEditPage'
import CustomerContractForm from '@/components/customerContracts/CustomerContractForm.vue'
import CustomerContractSummary from '@/components/customerContracts/CustomerContractSummary.vue'

import CustomerContract from '@/domain/entities/CustomerContract'
import CreateCustomerContract from '@/domain/useCases/customerContracts/CreateCustomerContract'
import PrepareCustomerContract from '@/domain/useCases/customerContracts/PreparePurchaseCustomerContract'

export default {
  name: 'NewCustomerContractPage',
  mixins: [actsAsAnEditPage],
  props: { id: String },
  components: { CustomerContractForm, CustomerContractSummary },
  data() {
    return {
      actsAsAnEditPage: {
        entity: 'customerContract',
        resource: 'customerContracts',
        create: CreateCustomerContract,
        get: PrepareCustomerContract,
        onGetErrorGoBack: true,
        validate: ['form']
      },
      entity: CustomerContract.default()
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
