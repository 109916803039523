<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12" lg="9">
        <material-card
          icon="mdi-receipt"
          icon-small
          :title="$t('common.sections.titles.invoice')"
          color="primary"
        >
          <InvoiceForm ref="form" v-model="entity" :errors="errors" />
        </material-card>

        <InvoiceDetailsForm
          ref="detailsForm"
          v-model="entity.details"
          :errors="errors.details || {}"
        />

        <p v-if="!entity.details.length" class="text--primary">
          <material-alert color="warning" icon="mdi-alert-outline">
            {{ $t('common.invoice.details.no-details') }}
          </material-alert>
        </p>
      </v-col>
      <v-col cols="12" lg="3" order="first" order-lg="last">
        <InvoiceSummary :entity="entity">
          <v-divider />
          <template #controls>
            <v-row>
              <v-col cols="3" md="6">
                <v-btn color="success" width="100%" @click="saveWithDetails()">
                  {{ $t('common.form.save') }}
                </v-btn>
              </v-col>
              <v-col cols="3" md="6">
                <v-btn color="warning" width="100%" @click="back()">
                  {{ $t('common.leave') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </InvoiceSummary>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsAnEditPage from '@/mixins/actsAsAnEditPage'

import Invoice from '@/domain/entities/Invoice'
import GetInvoice from '@/domain/useCases/invoices/GetInvoice'
import EditInvoice from '@/domain/useCases/invoices/EditInvoice'
import AmendInvoice from '@/domain/useCases/invoices/AmendInvoice'

import InvoiceForm from '@/components/invoices/InvoiceForm.vue'
import InvoiceDetailsForm from '@/components/invoices/InvoiceDetailsForm.vue'
import InvoiceSummary from '@/components/invoices/InvoiceSummary.vue'

export default {
  name: 'AmendEditInvoicePage',
  mixins: [actsAsAnEditPage],
  components: {
    InvoiceForm,
    InvoiceDetailsForm,
    InvoiceSummary
  },
  props: { id: String, edit: Boolean, amend: Boolean },
  data() {
    return {
      actsAsAnEditPage: {
        entity: 'invoice',
        resource: 'invoices',
        get: GetInvoice,
        edit: this.edit ? EditInvoice : this.amend ? AmendInvoice : null,
        validate: ['form', 'detailsForm']
      },
      entity: Invoice.default()
    }
  },
  methods: {
    saveWithDetails() {
      if (this.entity.details.length) this.save()
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
