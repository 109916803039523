<template>
  <v-form v-if="entity" ref="form" @submit.prevent>
    <v-container>
      <v-row>
        <v-col cols="12" lg="12">
          <ContractSelect
            :label="$t('entities.customerContract.contract.name')"
            clearable
            v-model="entity.contract"
            :error="errors.contract"
            required
          />
        </v-col>
        <v-col cols="12" lg="12">
          <ContractSelect
            :label="$t('entities.customerContract.previousContract.name')"
            clearable
            v-model="entity.previousContract"
            :error="errors.previousContract"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg>
          <CardTextField
            v-if="entity.code"
            :label="$t('entities.customerContract.code')"
            :value="entity.code"
          />
          <CardTextField
            v-else
            :label="$t('entities.customerContract.code')"
            :value="$t('common.autoassigned')"
          />
        </v-col>

        <v-col cols="12" lg>
          <CardTextField
            v-if="entity.version"
            :label="$t('entities.customerContract.version')"
            :value="entity.version"
          />
          <CardTextField
            v-else
            :label="$t('entities.customerContract.version')"
            :value="$t('common.autoassigned')"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6">
          <CustomerSelect
            :label="$t('entities.customerContract.buyer')"
            clearable
            v-model="entity.buyer"
            :error="errors.buyer"
            required
          />
        </v-col>

        <v-col cols="12" lg="6">
          <CustomerSelect
            :label="$t('entities.customerContract.beneficiary')"
            clearable
            v-model="entity.beneficiary"
            :error="errors.beneficiary"
            required
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="3">
          <DateField
            :label="$t('entities.customerContract.validFrom')"
            v-model="entity.validFrom"
            required
            :error="errors.validFrom"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <DateField
            :label="$t('entities.customerContract.validTo')"
            v-model="entity.validTo"
            required
            :error="errors.validTo"
          />
        </v-col>

        <v-col cols="12" lg="2">
          <ContractReasonSelect
            :label="$t('entities.customerContract.endReason')"
            clearable
            v-model="entity.endReason"
            :error="errors.endReason"
            required
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="2">
          <v-checkbox
            :label="$t('entities.customerContract.autoRenewal')"
            v-model="entity.autoRenewal"
            :error="errors.autoRenewal"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="12">
          <DateField
            :label="$t('entities.customerContract.cancellationDate')"
            v-model="entity.cancellationDate"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="12">
          <Textarea
            :label="$t('entities.customerContract.notes')"
            v-model="entity.notes"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="12">
          <FileField
            :label="$t('entities.customerContract.pdf')"
            v-model="entity.pdf"
            max-size="5"
            :extensions="['pdf']"
            :error="errors.pdf"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card>
            <material-alert
              v-if="!nDetails"
              color="warning"
              icon="mdi-alert-outline"
            >
              {{ $t('errors.customerContract.details.required') }}
            </material-alert>
            <v-card-title>
              {{ $t('entities.customerContract.details') }}
            </v-card-title>
            <DetailsTable v-model="entity.details" :errors="errors.details" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import actsAsAForm from '@/mixins/actsAsAForm'

import { DateField, Textarea, FileField } from '@/components/common/form'
import { CardTextField } from '@/components/common/card'

import ContractSelect from '@/components/contracts/ContractSelect.vue'
import ContractReasonSelect from '@/components/customerContracts/ContractReasonSelect.vue'
import CustomerSelect from '@/components/personCompanies/CustomerSelect.vue'
import DetailsTable from './CustomerContractDetailsEditableTable.vue'

import CustomerContract from '@/domain/entities/CustomerContract'
import Error from '@/lib/Error'
import { dig } from '@/lib/Utils'

export default {
  name: 'CustomerContractForm',
  mixins: [actsAsAForm],
  components: {
    CardTextField,
    DateField,
    Textarea,
    FileField,
    DetailsTable,
    ContractSelect,
    ContractReasonSelect,
    CustomerSelect
  },
  props: { value: CustomerContract, errors: Object },
  computed: {
    nDetails() {
      return this.entity.details ? this.entity.details.length : 0
    },
    buyerFullName() {
      let person = dig(this.entity.buyer, 'person')
      return person ? person.getFullName() : ''
    },
    beneficiaryFullName() {
      let person = dig(this.entity.beneficiary, 'person')
      return person ? person.getFullName() : ''
    }
  },
  methods: {
    validate() {
      const valid = this.$refs.form.validate()
      let validDetails = false

      if (this.nDetails > 0) validDetails = true
      else
        new Error({
          message: 'errors.customerContract.details.required'
        }).saveAlert(this)

      return valid && validDetails
    }
  }
}
</script>
