<template>
  <app-card class="mt-4 text-center payment-data-menu">
    <v-card-text class="mt-0 pt-0 text-left">
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-subtitle>
            {{ $t('entities.payment.supplier.fullName') }}
            {{ supplierFormatted }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ $t('entities.payment.untaxedPrice') }}
            {{ entity.untaxedPrice }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>

    <slot />

    <v-card-text>
      <slot name="controls" />
    </v-card-text>
  </app-card>
</template>

<script>
import Payment from '@/domain/entities/Payment'
import { formatPersonAndCompany } from '@/adapters/backoffice/formatters/interface/Common'

export default {
  name: 'paymentSummary',
  props: { entity: Payment },
  computed: {
    supplierFormatted() {
      return formatPersonAndCompany(this.entity.supplier)
    }
  }
}
</script>

<style scoped>
.payment-data-menu {
  position: sticky;
  top: 50px;
}
</style>
