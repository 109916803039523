<template>
  <div>
    <PersonCard v-if="entity.person" :person="entity.person" />

    <material-card
      v-for="(personCompany, index) in entity.personCompanies"
      :key="index"
      :icon="personCompanyIcon(personCompany)"
      :title="personCompanyTitle(personCompany)"
      :color="personCompanyColor(personCompany)"
    >
      <v-container>
        <v-row>
          <v-col cols="12" lg="3">
            <CardDateField
              :label="$t('entities.company.startDate')"
              :value="personCompany.startDate"
            />
          </v-col>
          <v-col cols="12" lg="3">
            <CardDateField
              :label="$t('entities.company.endDate')"
              :value="personCompany.endDate"
            />
          </v-col>
        </v-row>
      </v-container>
      <RolesAndPositionCard :entity="personCompany" />
      <CompanyCard :company="personCompany.company" />
    </material-card>
  </div>
</template>

<script>
import CardDateField from '@/components/common/card/CardDateField.vue'
import PersonCard from '@/components/people/PersonCard.vue'
import RolesAndPositionCard from '@/components/personCompanies/PersonCompanyRolesAndPositionCard.vue'
import CompanyCard from '@/components/companies/CompanyCard.vue'

import PersonAndCompanies from '@/domain/entities/PersonAndCompanies'

export default {
  name: 'PersonAndCompaniesCard',
  components: {
    CardDateField,
    PersonCard,
    RolesAndPositionCard,
    CompanyCard
  },
  props: { entity: PersonAndCompanies, as: String },
  methods: {
    personCompanyIcon(personCompany) {
      return personCompany.type === 'customer'
        ? 'mdi-cash-plus'
        : 'mdi-cash-minus'
    },
    personCompanyTitle(personCompany) {
      return this.$t(`common.sections.titles.${personCompany.type}`)
    },
    personCompanyColor(personCompany) {
      return personCompany.type == 'customer' ? 'primary' : 'indigo'
    }
  }
}
</script>

<style scoped>
.person-data-menu {
  position: sticky;
  top: 93px;
}
</style>
