<template>
  <LoginLayout>
    <h1 id="error404">404 Not found</h1>
    <v-btn color="primary" href="/dashboard">Dashboard</v-btn>
  </LoginLayout>
</template>

<script>
import LoginLayout from '@/layouts/LoginLayout.vue'

export default {
  name: '404Page',
  components: { LoginLayout }
}
</script>

<style>
#error404 {
  margin: 1em;
}
</style>
