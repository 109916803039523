<template>
  <v-form ref="form">
    <material-card
      icon="mdi-package-variant"
      iconSmall
      color="primary"
      :title="$t('entities.invoice.costCenter.name')"
    >
      <div v-for="(costCenterDetail, index) in costCenterDetails" :key="index">
        <InvoiceCostCenterCard :value="costCenterDetail" />
        <hr />
      </div>
    </material-card>
  </v-form>
</template>

<script>
import Invoice from '@/domain/entities/Invoice'
import InvoiceCostCenterCard from './InvoiceCostCenterCard.vue'
import { dig } from '@/lib/Utils'

export default {
  name: 'InvoiceCostCentersCard',
  components: {
    InvoiceCostCenterCard
  },
  props: {
    value: Invoice,
    errors: Object
  },
  computed: {
    costCenterDetails() {
      return dig(this.value, 'costCenterDetails') || []
    }
  }
}
</script>
