import validateUser from '@/lib/validateUser'
import customerPlaceholdersGetAdapter from '@/adapters/backoffice/customers/getPlaceholders'
import supplierPlaceholdersGetAdapter from '@/adapters/backoffice/suppliers/getPlaceholders'

export default class {
  user = null
  as = null

  constructor(user, as) {
    this.user = user
    this.as = as
  }

  async call(entity = null) {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    let placeholders
    if (this.as === 'customer') {
      const response = await customerPlaceholdersGetAdapter(this.user.token)
      placeholders = response.customer
    } else {
      const response = await supplierPlaceholdersGetAdapter(this.user.token)
      placeholders = response.supplier
    }

    if (placeholders && entity) {
      const { person: personPlaceholders } = placeholders
      for (const field in personPlaceholders) {
        entity.person[field] = entity.person[field] || personPlaceholders[field]
      }

      const personCompanies = placeholders.personCompanies
      if (
        personCompanies &&
        personCompanies.company &&
        entity.personCompanies
      ) {
        entity.personCompanies.map(personCompany => {
          for (const field in personCompanies.company) {
            personCompany.company[field] =
              personCompany.company[field] || personCompanies.company[field]
          }
          return personCompany
        })
      }
    }
    return placeholders
  }
}
