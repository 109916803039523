export default class {
  static new() {
    return new this()
  }

  static default() {
    return this.new()
  }

  constructor({ id = null, description = null, notes = null } = {}) {
    this.id = id
    this.description = description
    this.notes = notes
  }
}
