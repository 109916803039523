import axios from '@/axios.js'

import ProductFormatter from '../formatters/Product'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, id) {
  try {
    const { data } = await axios.get(`/api/v1/products/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    })

    const product = ProductFormatter.fromResponseResource(data.product)

    return { error: false, product }
  } catch (e) {
    return exceptionToResponse(e)
  }
}
