import validateUser from '@/lib/validateUser'
import Error from '@/lib/Error'
import jobCreateAdapter from '@/adapters/backoffice/jobs/create'

export default class {
  user = null
  job = null

  constructor(user, job) {
    this.user = user
    this.job = job
  }

  async call() {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    if (!this.job) return new Error({ message: 'errors.resource.invalid' })
    if (this.job.id)
      return new Error({
        message: 'errors.resource.existing'
      })

    return await jobCreateAdapter(this.user.token, this.job)
  }
}
