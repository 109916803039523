<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12" lg="9">
        <material-card
          icon="mdi-calendar"
          icon-small
          :title="$t('common.sections.titles.edition')"
          color="primary"
        >
          <EditionForm ref="form" v-model="entity" :errors="errors" />
        </material-card>
      </v-col>
      <v-col cols="12" lg="3" order="first" order-lg="last">
        <EditionSummary :entity="entity">
          <v-divider class="my-2" />
          <template #controls>
            <v-row>
              <v-col cols="3" md="6">
                <v-btn color="success" width="100%" @click="save()">
                  {{ $t('form.customers.new.submit') }}
                </v-btn>
              </v-col>
              <v-col cols="3" md="6">
                <v-btn color="warning" width="100%" @click="leave()">
                  {{ $t('common.leave') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </EditionSummary>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsACreatePage from '@/mixins/actsAsACreatePage'

import EditionForm from '@/components/editions/EditionForm.vue'
import EditionSummary from '@/components/editions/EditionSummary.vue'

import Edition from '@/domain/entities/Edition'
import CreateEdition from '@/domain/useCases/editions/CreateEdition'

export default {
  name: 'NewEditionPage',
  mixins: [actsAsACreatePage],
  components: { EditionForm, EditionSummary },
  data() {
    return {
      actsAsACreatePage: {
        entity: 'edition',
        resource: 'editions',
        create: CreateEdition
      },
      entity: Edition.default()
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
