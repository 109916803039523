<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
import '@/styles/overrides.sass'

export default {
  name: 'App',
  metaInfo() {
    const titleTemplate = `${this.$t('app.title')} | %s`
    const locale = this.$i18n.locale

    return {
      titleTemplate,
      htmlAttrs: { lang: locale },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' }
      ]
    }
  }
}
</script>
