<template>
  <v-data-table v-if="items.length" :headers="headers" :items="items" dense>
    <template v-slot:[`item.contract.discount`]="{ item }">
      <span v-if="overridesEnabled" class="text-decoration-line-through">
        {{ item.contract.discount | percentage }}
      </span>
      <span v-else>{{ item.contract.discount | percentage }}</span>
    </template>

    <template v-slot:[`item._overridden`]="{ item }">
      <v-checkbox
        color="primary"
        :disabled="!overridesEnabled"
        v-model="item._active"
        @change="overrideChanged(item)"
      />
    </template>

    <template v-slot:[`item.discountType`]="{ item }">
      <v-select
        :items="[
          {
            value: 'percentage',
            text: $t(
              'entities.offer.contractOverrides.discountTypes.percentage'
            )
          },
          {
            value: 'value',
            text: $t('entities.offer.contractOverrides.discountTypes.value')
          }
        ]"
        v-model="item.discountType"
        :disabled="!discountOverridden(item)"
        dense
        @input="overrideChanged(item)"
      ></v-select>
    </template>

    <template v-slot:[`item.discount`]="{ item }">
      <TextField
        type="percentage"
        v-if="item.discountType === 'percentage'"
        v-model="item.discountPercentage"
        :disabled="!discountOverridden(item)"
        @input="overrideChanged(item)"
      />
      <TextField
        type="currency"
        v-if="item.discountType === 'value'"
        v-model="item.discountValue"
        :disabled="!discountOverridden(item)"
        :rules="[positiveDiscountedValueRule]"
        @input="overrideChanged(item)"
      />
    </template>

    <template v-slot:[`item._discountedValue`]="{ item }">
      {{ item._discountedValue | append('€') }}
    </template>
  </v-data-table>
</template>

<script>
import { get } from 'vuex-pathify'
import Decimal from '@/lib/Decimal'
import handlesErrors from '@/mixins/handlesErrors'

import { TextField } from '@/components/common/form'

import Offer from '@/domain/entities/Offer'
import GetContracts from '@/domain/useCases/contracts/GetContracts'
import OfferContractOverride from '@/domain/entities/OfferContractOverride'

export default {
  name: 'OfferContractOverridesEditableTable',
  mixins: [handlesErrors],
  components: { TextField },
  props: { value: Offer, errors: Object },
  data() {
    return {
      headers: [
        // items is an Array of overrides
        {
          text: this.$t('entities.contract.name'),
          value: 'contract.name'
        },
        {
          text: this.$t('entities.contract.version'),
          value: 'contract.version'
        },
        {
          text: this.$t('entities.contract.code'),
          value: 'contract.code'
        },
        {
          text: this.$t('entities.contract.discount'),
          value: 'contract.discount'
        },
        {
          text: this.$t('entities.offer.contractOverrides.overridden'),
          value: '_overridden'
        },
        {
          text: this.$t('entities.offer.contractOverrides.discountType'),
          value: 'discountType'
        },
        {
          text: this.$t('entities.offer.contractOverrides.discount'),
          value: 'discount'
        },
        {
          text: this.$t('common.discounted-value'),
          value: '_discountedValue'
        }
      ],
      items: [] // COPY of offer.contractOverrides
    }
  },
  computed: {
    user: get('user'),
    offer() {
      return this.value
    },
    overridesEnabled() {
      return this.offer.discountOverride
    }
  },
  methods: {
    overrideChanged(override) {
      this.offer.contractOverrides = this.activeOverrides()
      override._discountedValue = this.calculateDiscountedValue(override)
      this.$emit('input', this.offer)
    },
    activeOverrides() {
      return this.items.filter(override => override._active)
    },
    positiveDiscountedValueRule(value) {
      const isPositive = Decimal.fromUI(this.offer.offerPrice)
        .subtract(Decimal.fromUI(value), { forcePositive: false })
        .isPositive()

      return (
        isPositive || this.$t('errors.offer.contractOverride.negative-price')
      )
    },
    discountOverridden(override) {
      return this.overridesEnabled && override._active
    },
    calculateDiscountedValue(override) {
      let value
      switch (true) {
        case this.discountOverridden(override):
          value = this.offer.calculateDiscountedPrice(override.contract)
          break
        case this.overridesEnabled:
          value = Decimal.zeroForUI()
          break
        default:
          value = override.contract.calculateDiscountedPrice(
            this.offer.offerPrice
          )
      }
      return value
    },
    calculateAllDiscountedValue() {
      this.items.forEach(override => {
        override._discountedValue = this.calculateDiscountedValue(override)
      })
    }
  },
  watch: {
    'value.offerPrice': function() {
      this.calculateAllDiscountedValue()
    },
    overridesEnabled: function() {
      this.calculateAllDiscountedValue()
    }
  },
  mounted() {
    new GetContracts(this.user)
      .call({ page: 1, itemsPerPage: -1 })
      .then(result => {
        if (result.error) this.$_handleError(result)
        else {
          this.items = result.contracts.map(contract => {
            const zero = 0 // No decimal are allowed. Can't use zeroForUI()

            const override = this.offer.contractOverrides.find(
              o => o.contract.id === contract.id
            )
            const overrideCopy = override
              ? new OfferContractOverride(override)
              : OfferContractOverride.fromContract(contract)

            overrideCopy._active = !!overrideCopy.id
            overrideCopy.discountValue = overrideCopy.discountValue || zero

            overrideCopy.discountPercentage =
              overrideCopy.discountPercentage || zero

            overrideCopy._discountedValue = this.calculateDiscountedValue(
              overrideCopy
            )

            return overrideCopy
          })
        }
      })
  }
}
</script>
