import axios from '@/axios.js'

import InvoiceFormatter from '../formatters/Invoice'
import InvoiceJobDetailsFormatter from '../formatters/InvoiceJobDetail'
import resourceErrorsToResponse from '../formatters/resourceErrorsToResponse'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, id, data) {
  const payload = {
    invoPurchaseJobDetRels: InvoiceJobDetailsFormatter.toRequestResourcesList(
      data.jobDetails
    )
  }

  if (!payload || !id) {
    return {
      error: true,
      errorData: { message: 'errors.resource.invalid' }
    }
  }

  try {
    const { data } = await axios.put(
      `/api/v1/invoPurchases/${id}/jobs`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )

    const invoice = InvoiceFormatter.fromResponseResource(data.invoPurchase)
    return { error: false, invoice }
  } catch (e) {
    return (
      resourceErrorsToResponse(e, InvoiceFormatter) || exceptionToResponse(e)
    )
  }
}
