<template>
  <v-autocomplete
    :class="_class"
    :label="label"
    :items="productCategories"
    v-model="productCategory"
    return-object
    @change="changed"
    :no-data-text="$t('common.autocomplete.no-data')"
    :rules="_rules"
    :required="required"
    :outlined="readonly || outlined"
    :filled="readonly"
    :hide-details="hideDetails"
    dense
  ></v-autocomplete>
</template>

<script>
import { get } from 'vuex-pathify'
import validators from '@/domain/validations/rules'

import GetProductCategories from '@/domain/useCases/products/GetProductCategories'

export default {
  name: 'ProductCategorySelect',
  props: {
    label: String,
    value: Object,
    rules: Array,
    required: Boolean,
    readonly: Boolean,
    outlined: Boolean,
    hideDetails: Boolean
  },
  computed: {
    user: get('user'),
    validates() {
      return validators(this)
    },
    _class() {
      return this.required ? 'required' : ''
    },
    _rules() {
      const _rules = this.rules || []
      if (this.required) _rules.push(this.validates.presence)
      return _rules
    }
  },
  data() {
    return {
      productCategory: null,
      productCategories: []
    }
  },
  methods: {
    translateCode(code) {
      return this.$t(`entities.product.categories.${code}`)
    },
    changed() {
      this.$emit('input', this.productCategory)
    }
  },
  created() {
    new GetProductCategories(this.user).call().then(result => {
      if (result.error) {
        new Error({
          message: 'common.autocomplete.error-loading'
        }).toAlert()
        console.info(`Error loading payment sources for autocomplete:`, result)
      } else {
        this.productCategories = result.productCategories.map(productCategory =>
          Object.assign(productCategory, {
            text: this.translateCode(productCategory.code)
          })
        )
      }
    })
  },
  watch: {
    value() {
      this.productCategory = this.value
      if (!this.productCategory.text)
        this.productCategory.text = this.translateCode(
          this.productCategory.code
        )
    }
  }
}
</script>
