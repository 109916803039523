<template>
  <div>
    <v-form ref="form">
      <material-card
        v-for="(entity, index) in entities"
        :key="index"
        :title="$t('entities.invoice.details')"
      >
        <v-container>
          <v-row>
            <v-col cols="12" lg="12">
              <CardTextField
                :value="entity.offerName"
                :label="$t('entities.invoice.detail.offerName')"
              />
            </v-col>
            <v-col cols="12" lg="12">
              <Textarea
                v-model="entity.description"
                :label="$t('entities.invoice.detail.description')"
                :error="errors.description"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="2">
              <CardDateField
                :value="entity.date"
                :label="$t('entities.invoice.detail.date')"
              />
            </v-col>
            <v-col cols="12" lg="2">
              <CardTextField
                :value="entity.contractNumber"
                :label="$t('entities.invoice.detail.contractNumber')"
              />
            </v-col>
            <v-col cols="12" lg="2">
              <CardTextField
                type="currency"
                :value="entity.amount"
                :label="$t('entities.invoice.detail.amount')"
              />
            </v-col>
            <v-col cols="12" lg="2">
              <CardTextField
                type="currency"
                :value="entity.discount"
                :label="$t('entities.invoice.detail.discount')"
              />
            </v-col>
            <v-col cols="12" lg="2">
              <CardTextField
                type="currency"
                :value="entity.vatAmount"
                :label="$t('entities.invoice.detail.vatAmount')"
              />
            </v-col>
            <v-col cols="12" lg="2">
              <CardTextField
                type="currency"
                :value="entity.vatPerc"
                :label="$t('entities.invoice.detail.vatPerc')"
              />
            </v-col>
          </v-row>
        </v-container>
      </material-card>
    </v-form>
  </div>
</template>

<script>
import actsAsAForm from '@/mixins/actsAsAForm'
import { Textarea } from '@/components/common/form'
import { CardTextField, CardDateField } from '@/components/common/card'

export default {
  name: 'InvoiceDetailsForm',
  mixins: [actsAsAForm],
  components: { Textarea, CardTextField, CardDateField },
  props: {
    value: Array,
    errors: Object
  },
  computed: {
    entities() {
      return this.entity || []
    }
  }
}
</script>
