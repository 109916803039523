<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12" lg="9">
        <material-card
          icon="mdi-receipt"
          icon-small
          :title="$t('common.sections.titles.invoice')"
          color="primary"
        >
          <InvoicePartialAmendForm
            ref="form"
            v-model="entity"
            :errors="errors"
          />

          <InvoiceDetailsCard :items="entity.details" />
        </material-card>
      </v-col>
      <v-col cols="12" lg="3" order="first" order-lg="last">
        <InvoiceSummary :entity="entity">
          <v-divider />
          <template #controls>
            <v-row>
              <v-col cols="3" md="6">
                <v-btn color="success" width="100%" @click="save()">
                  {{ $t('form.customers.new.submit') }}
                </v-btn>
              </v-col>
              <v-col cols="3" md="6">
                <v-btn color="warning" width="100%" @click="leave()">
                  {{ $t('common.leave') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </InvoiceSummary>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsAnEditPage from '@/mixins/actsAsAnEditPage'

import Invoice from '@/domain/entities/Invoice'
import GetInvoice from '@/domain/useCases/invoices/GetInvoice'
import PartialAmendInvoice from '@/domain/useCases/invoices/PartialAmendInvoice'

import InvoicePartialAmendForm from '@/components/invoices/InvoicePartialAmendForm.vue'
import InvoiceDetailsCard from '@/components/invoices/InvoiceDetailsCard.vue'
import InvoiceSummary from '@/components/invoices/InvoiceSummary.vue'

export default {
  name: 'PartialAmendInvoice',
  mixins: [actsAsAnEditPage],
  components: {
    InvoicePartialAmendForm,
    InvoiceDetailsCard,
    InvoiceSummary
  },
  props: { id: String, edit: Boolean, amend: Boolean },
  data() {
    return {
      actsAsAnEditPage: {
        entity: 'invoice',
        resource: 'invoices',
        get: GetInvoice,
        edit: PartialAmendInvoice,
        validate: ['form']
      },
      entity: Invoice.default()
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
