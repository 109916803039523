import axios from '@/axios.js'
import Error from '@/lib/Error'

import CustomerContractFormatter from '../formatters/CustomerContract'
import resourceErrorsToResponse from '../formatters/resourceErrorsToResponse'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, customerContract) {
  const payload = {
    custContract: CustomerContractFormatter.toRequestResource(customerContract)
  }

  if (!payload.custContract) {
    return new Error({ message: 'errors.resource.invalid' })
  }

  try {
    const { data } = await axios.post('/api/v1/custContracts', payload, {
      headers: { Authorization: `Bearer ${token}` }
    })

    const customerContract = CustomerContractFormatter.fromResponseResource(
      data.custContract
    )
    return { error: false, customerContract }
  } catch (e) {
    return (
      resourceErrorsToResponse(
        e,
        CustomerContractFormatter,
        'customerContracts'
      ) || exceptionToResponse(e)
    )
  }
}
