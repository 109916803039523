<template>
  <v-container id="customers-list" fluid tag="section">
    <material-card
      :icon="icon"
      icon-small
      :title="translate('list')"
      color="primary"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :footer-props="footerOptions"
        :server-items-length="totalItems"
        show-expand
        expand-icon="mdi-chevron-down"
        item-key="person.id"
        :loading="loading"
        :no-data-text="$t('common.list.no-data')"
      >
        <template v-slot:top>
          <v-container>
            <v-row dense>
              <v-col cols="11">
                <Searchbar @submit="searchbarSubmitted" :as="as" />
              </v-col>
              <v-col cols="1" class="pt-0 pl-5">
                <RoundButton
                  :icon="`mdi-plus`"
                  :alt="translate('new')"
                  @click="$emit('goto-new')"
                />
              </v-col>
            </v-row>
          </v-container>
        </template>

        <template v-slot:[`item.person.active`]="{ item }">
          {{ item.person.active | yesNo }}
        </template>

        <template v-slot:[`item.company`]="{ item }">
          {{ firstPrimaryTypeCompanyName(item) }}
        </template>

        <template v-slot:[`item.roles`]="{ item }">
          {{ firstPrimaryTypeCompanyRoles(item) }}
        </template>

        <template v-slot:[`item.person.email`]="{ item }">
          <a v-if="item.person.email" :href="`mailto:${item.person.email}`">{{
            item.person.email
          }}</a>
        </template>

        <template v-slot:[`item.person.mobile`]="{ item }">
          <a v-if="item.person.mobile" :href="`tel:${item.person.mobile}`">{{
            item.person.mobile
          }}</a>
        </template>

        <template v-slot:[`item.person.phone`]="{ item }">
          <a v-if="item.person.phone" :href="`tel:${item.person.phone}`">{{
            item.person.phone
          }}</a>
        </template>

        <template v-slot:[`item.creationDate`]="{ item }">
          <small>{{ item.person.creationDate | dateTime }}</small>
        </template>

        <template v-slot:[`item.lastModified`]="{ item }">
          <small>{{ item.person.lastModified | dateTime }}</small>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="actions">
            <v-icon
              class="mr-1 show-customer-button"
              @click="$emit('goto-show', item.person.id)"
              >mdi-eye</v-icon
            >
            <v-icon
              class="mr-1 edit-customer-button"
              @click="$emit('goto-edit', item.person.id)"
              >mdi-pencil</v-icon
            >
            <v-icon
              class="delete-customer-button"
              @click="$emit('delete', item.person.id)"
              >mdi-delete</v-icon
            >
          </div>
        </template>

        <template v-slot:expanded-item="{ item, headers }">
          <td :colspan="headers.length">
            <v-card class="mb-3 mt-2 mx-2">
              <v-card-title class="pb-1">{{
                primaryTypeCompaniesTitle
              }}</v-card-title>
              <v-card-text>
                <CompaniesSummary
                  :as="primaryType"
                  :personCompanies="primaryTypeCompanies(item)"
                />
              </v-card-text>
              <v-card-title
                v-if="secondaryTypeCompanies(item).length"
                class="py-1"
                >{{ secondaryTypeCompaniesTitle }}</v-card-title
              >
              <v-card-text v-if="secondaryTypeCompanies(item).length">
                <CompaniesSummary
                  as="secondaryType"
                  :personCompanies="secondaryTypeCompanies(item)"
                />
              </v-card-text>
            </v-card>
          </td>
        </template>
      </v-data-table>
    </material-card>
  </v-container>
</template>

<script>
import actsAsAList from '@/mixins/actsAsAList'

import Searchbar from './PersonCompaniesSearchbar.vue'
import CompaniesSummary from './PersonCompaniesSimpleTable.vue'
import RoundButton from '@/components/common/RoundButton.vue'

export default {
  name: 'PersonCompaniesList',
  mixins: [actsAsAList],
  components: {
    Searchbar,
    RoundButton,
    CompaniesSummary
  },
  props: {
    as: String,
    loading: Boolean,
    items: Array,
    page: Number,
    totalItems: Number
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('entities.person.active'),
          value: 'person.active'
        },
        {
          text: this.$t('entities.person.name'),
          value: 'person.name'
        },
        {
          text: this.$t('entities.person.surname'),
          value: 'person.surname'
        },
        {
          text: this.$t('entities.person.fiscalCode'),
          value: 'person.fiscalCode'
        },
        {
          sortable: false,
          text: this.$t('entities.personCompany.company'),
          value: 'company'
        },
        {
          sortable: false,
          text: this.$t('entities.personCompany.roles'),
          value: 'roles'
        },
        {
          text: this.$t('entities.person.email'),
          value: 'person.email'
        },
        {
          text: this.$t('entities.person.mobile'),
          value: 'person.mobile'
        },
        {
          text: this.$t('entities.person.phone'),
          value: 'person.phone'
        },
        {
          text: this.$t('common.fields.creationDate'),
          value: 'person.creationDate'
        },
        {
          text: this.$t('common.fields.lastModified'),
          value: 'person.lastModified'
        },
        { text: '', value: 'actions', sortable: false }
      ]
    }
  },
  computed: {
    icon() {
      return this.as === 'customer'
        ? 'mdi-account-details'
        : 'mdi-account-details-outline'
    },
    primaryType() {
      return this.as
    },
    secondaryType() {
      return this.as === 'customer' ? 'supplier' : 'customer'
    },
    primaryTypeCompaniesTitle() {
      return this.$t(`common.personCompany.list.${this.primaryType}`)
    },
    secondaryTypeCompaniesTitle() {
      return this.$t(`common.personCompanies.list.${this.secondaryType}`)
    }
  },
  methods: {
    filterPersonCompaniesByType(personAndCompanies, type) {
      if (!personAndCompanies) return []
      return personAndCompanies.personCompanies.filter(
        personCompanies => personCompanies.type == type
      )
    },
    firstPrimaryTypeCompanyName(item) {
      const { company } = this.filterPersonCompaniesByType(
        item,
        this.primaryType
      )[0]
      return company.invoiceName || ''
    },
    firstPrimaryTypeCompanyRoles(item) {
      const { roles } = this.filterPersonCompaniesByType(
        item,
        this.primaryType
      )[0]
      return roles ? roles.map(r => r.role).join(',') : ''
    },
    primaryTypeCompanies(item) {
      if (!item.personCompanies) return []
      return item.personCompanies.filter(
        personCompanies => personCompanies.type === this.primaryType
      )
    },
    secondaryTypeCompanies(item) {
      if (!item.personCompanies) return []
      return item.personCompanies.filter(
        personCompanies => personCompanies.type === this.secondaryType
      )
    },
    translate(label) {
      return this.$t(`common.personCompany.${label}.${this.as}`)
    }
  }
}
</script>

<style scoped>
.actions {
  min-width: 100px;
}
</style>
