<template>
  <v-expansion-panels inset>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <span class="text-h4">
          {{ $t('common.list.search.title') }}
        </span>
        <template v-slot:actions>
          <v-icon color="primary"> mdi-chevron-down </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form
          ref="form"
          @submit.prevent="submitted"
          class="list-searchbar mx-0"
        >
          <v-row dense>
            <v-col cols="2">
              <v-text-field
                :label="$t('entities.product.code')"
                v-model="filters.code"
                hide-details
                outlined
                dense
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                :label="$t('entities.product.name')"
                v-model="filters.name"
                hide-details
                outlined
                dense
              />
            </v-col>

            <v-col cols="2">
              <ProductCategorySelect
                :label="$t('entities.product.category')"
                v-model="filters.category"
                hide-details
                outlined
              />
            </v-col>

            <v-col cols="2">
              <ProductTypeSelect
                :label="$t('entities.product.type')"
                v-model="filters.type"
                hide-details
                outlined
              />
            </v-col>

            <v-col cols="2">
              <LanguageSelect
                :label="$t('entities.product.language')"
                v-model="filters.language"
                hide-details
                outlined
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="auto">
              <v-btn color="primary" outlined @click="reset()">
                <v-icon left dark> mdi-undo-variant </v-icon>
                {{ $t('common.list.search.reset') }}
              </v-btn>
            </v-col>
            <v-col cols="2">
              <v-btn type="submit" color="primary" dark>
                <v-icon left dark> mdi-magnify </v-icon>
                {{ $t('common.list.search.submit') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import ProductTypeSelect from '@/components/products/ProductTypeSelect.vue'
import ProductCategorySelect from '@/components/products/ProductCategorySelect.vue'
import LanguageSelect from '@/components/common/LanguageSelect.vue'

import Product from '@/domain/entities/Product'

export default {
  name: 'ProductSearchbar',
  components: { ProductTypeSelect, ProductCategorySelect, LanguageSelect },
  props: { as: String },
  data() {
    return { filters: Product.new() }
  },
  methods: {
    reset() {
      this.filters = Product.new()
    },
    submitted() {
      this.$emit('submit', this.filters)
    }
  }
}
</script>
