import {
  CustomersListPage,
  NewCustomerPage,
  EditCustomerPage,
  ShowCustomerPage
} from '@/views/customers/index.js'

export default [
  {
    path: '/customers',
    name: 'CustomersPage',
    component: CustomersListPage,
    meta: {
      title: 'menu.long.resources.customers'
    }
  },
  {
    path: '/customers/new',
    name: 'NewCustomerPage',
    component: NewCustomerPage,
    meta: {
      title: 'menu.long.resources.new-customer'
    }
  },
  {
    path: '/customers/:id/edit',
    name: 'EditCustomerPage',
    component: EditCustomerPage,
    props: true,
    meta: {
      title: 'menu.long.resources.edit-customer'
    }
  },
  {
    path: '/customers/:id',
    name: 'ShowCustomerPage',
    component: ShowCustomerPage,
    props: true,
    meta: {
      title: 'menu.long.resources.show-customer'
    }
  }
]
