/**
 * Provides common behaviours for views that show a list of entities.
 *
 * Expects:
 *  - data.actsAsAListPage, options for this mixin:
 *    - resource: String, name used for the resource in the adapters (e.g. when building the request URI);
 *    - get:      Use-case Function to use to populate the list, e.g. GetCustomers;
 *    - delete:   Use-case Function to use to delete an element from the list;
 *
 *  - data.errors: an Object where to put error information;
 *
 * Provides:
 *  - this.refresh(options):  loads/reloads the list, options is what a vuetify
 *                            data-table would expect for paging ordering, etc;
 *                            also reads data.listOptions for field search
 *                            options;
 *
 *  - this.deleteEntity(id):  initiates the deletion process for an entity, sets deleteDialog to true (see below);
 *
 *  - data.deleteDialog:      when set to true a delete dialog should be shown, the view should have a dialog with
 *                            its v-model set to this; if the dialog is confirmed confirmed then the view should
 *                            call deleteConfirmed(), otherwise it should call deleteCanceled();
 *
 *  - this.deleteConfirmed(): performs the entity deletion, should be called by
                              the confirmation dialog after user confirmation;
 *
 *  - this.deleteCanceled():  sets deleteDialog to false, should be called by
 *                            the confirmation dialog if the user chooses to
 *                            cancel the operation;
 *
 *  - this.gotoNew():         loads the new entity page;
 *
 *  - this.gotoEdit(id):      loads the edit page for the requested entity;
 *
 *  - this.gotoShow(id):      loads the show page for the requested entity;
 *
 */
import { get } from 'vuex-pathify'
import handlesErrors from './handlesErrors'

export default {
  mixins: [handlesErrors],
  data() {
    return {
      listOptions: {},
      loading: false,
      items: [],
      page: 1,
      totalItems: 0,

      deleteDialog: false,
      deleteId: null
    }
  },
  computed: {
    user: get('user')
  },
  methods: {
    option(name) {
      return this.actsAsAListPage[name]
    },
    refresh(options = null) {
      this.loading = true
      if (options) this.listOptions = options
      const useCase = this.option('get')

      new useCase(this.user).call(this.listOptions).then(result => {
        if (result.error) {
          /// Note: no field errors expected for list requests.
          this.$_handleError(result)
        } else {
          this.items = result[this.option('resource')]
          this.page = result.page
          this.totalItems = result.totalItems
        }
        this.loading = false
      })
    },
    deleteEntity(id) {
      this.deleteId = id
      this.deleteDialog = true
    },
    deleteConfirmed() {
      const id = this.deleteId
      if (id) {
        this.loading = true
        const useCase = this.option('delete')
        new useCase(this.user, id).call().then(result => {
          if (result.error) {
            this.$_handleError(result)
            this.loading = false
          } else {
            this.refresh()
          }
        })
      }
      this.deleteDialog = false
    },
    deleteCanceled() {
      this.deleteId = null
      this.deleteDialog = false
    },
    gotoNew() {
      this.$router.push(`/${this.option('resource')}/new`)
    },
    gotoEdit(id) {
      this.$router.push(`/${this.option('resource')}/${id}/edit`)
    },
    gotoShow(id) {
      this.$router.push(`/${this.option('resource')}/${id}`)
    }
  }
}
