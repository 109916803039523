// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ripple from 'vuetify/lib/directives/ripple'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify, { directives: { ripple } })
export default new Vuetify({
  breakpoint: { mobileBreakpoint: 960 },
  icons: {
    values: { expand: 'mdi-chevron-down' }
  },
  theme: {
    themes: {
      light: {
        primary: '#4791db',
        secondary: colors.blueGrey,
        accent: '#4791db',
        info: '#64b5f6',
        success: '#81c784',
        warning: '#ffb74d',
        error: '#e57373'
      },
      dark: {
        primary: '#115293',
        secondary: '#9a0036',
        accent: '#115293',
        info: '#1976d2',
        success: '#388e3c',
        warning: '#f57c00',
        error: '#d32f2f'
      }
    }
  }
})
