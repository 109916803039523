import axios from '@/axios.js'

import EditionFormatter from '../formatters/Edition'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, id) {
  try {
    const { data } = await axios.get(`/api/v1/editions/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    })

    if (data.edition.product) {
      const productId = data.edition.product.id

      const {
        data: { results }
      } = await axios.get(`/api/v1/products/${productId}/contractOverrides`, {
        headers: { Authorization: `Bearer ${token}` }
      })

      data.edition.product.contractOverrides = results
    }

    const edition = EditionFormatter.fromResponseResource(data.edition)

    return { error: false, edition }
  } catch (e) {
    return exceptionToResponse(e)
  }
}
