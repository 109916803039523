<template>
  <div>
    <v-tabs v-model="tab" fixed-tabs>
      <v-tab key="create" v-if="!selectedItemExists">{{
        $t('common.job.detail.create')
      }}</v-tab>
      <v-tab key="search">{{ $t('common.job.detail.search') }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="create" v-if="!selectedItemExists">
        <div class="tab-item-content">
          <JobDetailForm v-model="entity" />
        </div>
      </v-tab-item>
      <v-tab-item key="search">
        <div class="tab-item-content">
          <v-container>
            <JobDetailSelect @input="jobDetailSelected" clearable />
          </v-container>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import actsAsAForm from '@/mixins/actsAsAForm'
import JobDetailSelect from './JobDetailSelect.vue'
import JobDetailForm from './JobDetailForm.vue'
import JobAndDetail from '@/domain/entities/JobAndDetail'
import { dig } from '@/lib/Utils'

export default {
  name: 'JobDetailSelectOrCreate',
  mixins: [actsAsAForm],
  components: {
    JobDetailSelect,
    JobDetailForm
  },
  props: {
    value: JobAndDetail,
    errors: Object,
    icon: String
  },
  data() {
    return {
      tab: null
    }
  },
  computed: {
    selectedItemExists() {
      return dig(this.value, 'detail', 'id')
    }
  },
  methods: {
    jobDetailSelected(jobDetail) {
      const jobAndDetail = jobDetail
        ? new JobAndDetail({
            detail: jobDetail
          })
        : JobAndDetail.new()
      this.$emit('input', jobAndDetail)
    }
  }
}
</script>

<style scoped>
.tab-item-content {
  margin-top: 12px;
  min-height: 100px;
}
</style>
