import PurchaseTerm from '@/domain/entities/PurchaseTerm'
import Base from './Base'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null

    const entity = this.fieldNamesFromResponse(resource)
    return new PurchaseTerm(entity)
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    if (!resource) return null
    const { id, code } = resource
    const result = { id, code }
    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(entity, removeEmpty = false) {
    if (!entity) return null
    const { id, code } = entity
    const result = { id, code }
    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }
}
