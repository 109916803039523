<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="12">
        <CardTextField
          :label="$t('entities.contract.name')"
          :value="entity.name"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="2">
        <CardYesNoField
          color="success"
          :label="$t('entities.contract.active')"
          :value="entity.active"
        />
      </v-col>

      <v-col cols="12" lg="2">
        <CardTextField
          :label="$t('entities.contract.code')"
          :value="entity.code"
        />
      </v-col>

      <v-col cols="12" lg="2">
        <CardTextField
          type="number"
          :label="$t('entities.contract.version')"
          :value="entity.version"
        />
      </v-col>

      <v-col cols="12" lg="2">
        <CardTextField
          :label="$t('entities.contract.type')"
          :value="entity.type"
        />
      </v-col>

      <v-col cols="12" lg="3">
        <CardDateField
          :label="$t('entities.contract.validFrom')"
          :value="entity.validFrom"
        />
      </v-col>

      <v-col cols="12" lg="3">
        <CardDateField
          :label="$t('entities.contract.validTo')"
          :value="entity.validTo"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="2">
        <CardTextField
          type="currency"
          :label="$t('entities.contract.price')"
          :value="entity.price"
        />
      </v-col>

      <v-col cols="12" lg="2">
        <CardTextField
          type="percentage"
          :label="$t('entities.contract.discount')"
          :value="entity.discount"
        />
      </v-col>

      <v-col cols="12" lg="2">
        <CardTextField
          type="number"
          :label="$t('entities.contract.duration')"
          :value="entity.duration"
        />
      </v-col>

      <v-col cols="12" lg="2">
        <CardYesNoField
          :label="$t('entities.contract.autoRenewal')"
          :value="entity.autoRenewal"
        />
      </v-col>

      <v-col cols="12" lg="3">
        <CardTextField
          type="number"
          :label="$t('entities.contract.renewalStep')"
          :value="entity.renewalStep"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="12">
        <CardTextarea
          :label="$t('entities.contract.description')"
          :value="entity.description"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="12">
        <CardFileField
          :label="$t('entities.contract.pdf')"
          :value="entity.pdf"
          max-size="5"
          :extensions="['pdf']"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            {{ $t('entities.contract.details') }}
          </v-card-title>
          <DetailsTable :items="entity.details" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  CardTextField,
  CardDateField,
  CardTextarea,
  CardYesNoField,
  CardFileField
} from '@/components/common/card'
import DetailsTable from '@/components/contracts/ContractDetailsSimpleTable.vue'

import Contract from '@/domain/entities/Contract'

export default {
  name: 'ContractCard',
  components: {
    CardTextField,
    CardTextarea,
    CardDateField,
    CardYesNoField,
    CardFileField,
    DetailsTable
  },
  props: { entity: Contract }
}
</script>
