export default class {
  constructor({
    id = null,
    type = null,
    role = null,
    description = null
  } = {}) {
    this.id = id
    this.role = role
    this.type = type
    this.description = description
  }
}
