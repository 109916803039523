<template>
  <div :class="_class">
    <v-text-field
      :type="_type"
      :label="label"
      :value="value"
      :rules="_rules"
      :required="required"
      :hint="hint"
      :error-messages="_error"
      :messages="messages"
      :disabled="disabled"
      :readonly="readonly"
      :outlined="readonly"
      :prefix="_prefix"
      :suffix="suffix"
      :reverse="_reverse"
      :counter="_counter"
      :clearable="clearable"
      @input="onChange"
      @blur="onBlur"
      dense
    />
  </div>
</template>

<script>
import { isEmpty } from '@/lib/Utils'
import validators from '@/domain/validations/rules'
import Decimal from '@/lib/Decimal'

export default {
  name: 'TextField',
  props: {
    label: String,
    value: null, // Accept any type of value
    type: String,
    rules: Array,
    required: Boolean,
    hint: String,
    error: String,
    disabled: Boolean,
    readonly: Boolean,
    prefix: String,
    suffix: String,
    counter: Number,
    min: [String, Number],
    messages: String,
    clearable: Boolean
  },
  computed: {
    validates() {
      return validators(this)
    },
    _class() {
      const classes = []
      if (this.required) classes.push('required')
      if (this.type === 'fiscal-code') classes.push('fiscal-code')
      return classes
    },
    _type() {
      const type = this.type
      switch (type) {
        case 'fiscal-code':
        case 'percentage':
        case 'currency':
        case 'text':
        case 'number':
          return 'text'
        default:
          return type
      }
    },
    _prefix() {
      switch (this.type) {
        case 'currency':
          return '€'
        case 'percentage':
          return '%'
        default:
          return this.suffix
      }
    },
    _reverse() {
      switch (this.type) {
        case 'currency':
        case 'percentage':
          return false
      }
      return false
    },
    _rules() {
      const _rules = this.rules || []
      switch (this.type) {
        case 'email':
          _rules.push(this.validates.email)
          break
        case 'number':
          _rules.push(this.validates.positiveInteger)
          if (this.min) _rules.push(this.validates.greaterOrEqualTo(this.min))
          break
        case 'currency':
          _rules.push(this.validates.currency)
          break
        case 'any-currency':
          _rules.push(this.validates.anyCurrency)
          break
        case 'percentage':
          _rules.push(this.validates.percent)
          break
      }
      if (this.required) _rules.push(this.validates.presence)
      if (this.min) _rules.push(this.validates.greaterOrEqualTo(this.min))

      return _rules
    },
    _error() {
      return this.error ? this.$t(`errors.field.${this.error}`) : []
    },
    _counter() {
      return null
    }
  },
  methods: {
    onChange(newValue) {
      this.$emit('input', newValue)
    },
    onBlur(e) {
      const newValue = e.target.value
      if (!isEmpty(newValue))
        switch (this.type) {
          case 'currency':
            e.stopPropagation()
            this.$nextTick(function() {
              this.$emit('input', Decimal.fromUI(newValue).forUI())
            })
            break
          case 'fiscal-code':
            e.stopPropagation()
            if (this.model) {
              this.$nextTick(function() {
                this.$emit('input', String(newValue).toUpperCase())
              })
            }
            break
        }
    }
  }
}
</script>
