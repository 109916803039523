import axios from '@/axios.js'

import ProductCategoryFormatter from '../formatters/ProductCategory'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token) {
  try {
    const { data } = await axios.get('/api/v1/products/categories', {
      headers: { Authorization: `Bearer ${token}` }
    })

    return {
      error: false,
      productCategories: ProductCategoryFormatter.fromResponseResourcesList(
        data.results
      )
    }
  } catch (e) {
    return exceptionToResponse(e)
  }
}
