import Decimal from '@/lib/Decimal'
export default class {
  static new() {
    return new this()
  }

  static default() {
    return new this({ editionsTotalPrice: '0,00' })
  }

  static fromEditions(editions) {
    const offer = this.default()
    offer.editions = editions
    offer.recalculateEditionTotals()

    return offer
  }

  constructor({
    id = null,
    active = null,
    name = null,
    description = null,
    fromDate = null,
    toDate = null,
    editionsTotalPrice = null,
    offerPrice = null,
    standardDiscountApplicable = null, // Boolean
    discountOverride = null, // Boolean
    deposit = null,
    paymentDelay = null,
    installmentsNumber = null,
    installmentsAmountEach = null,
    installmentsAmountExtra = null,
    notes = null,

    editions = [], // Array of Edition objects
    contractOverrides = [], // Array of OfferContractOverride objects

    creationDate = null,
    startDate = null,
    lastModified = null,
    lastModifiedBy = null
  } = {}) {
    this.id = id
    this.active = active
    this.name = name
    this.description = description
    this.fromDate = fromDate
    this.toDate = toDate
    this.editionsTotalPrice = editionsTotalPrice
    this.offerPrice = offerPrice
    this.standardDiscountApplicable = standardDiscountApplicable
    this.discountOverride = discountOverride
    this.deposit = deposit
    this.paymentDelay = paymentDelay
    this.installmentsNumber = installmentsNumber
    this.installmentsAmountEach = installmentsAmountEach
    this.installmentsAmountExtra = installmentsAmountExtra
    this.notes = notes

    this.editions = editions
    this.contractOverrides = contractOverrides

    this.startDate = startDate
    this.creationDate = creationDate
    this.lastModified = lastModified
    this.lastModifiedBy = lastModifiedBy
  }

  recalculateEditionTotals() {
    const editionPrices = this.editions.map(
      edition => edition.price || Decimal.zeroForUI()
    )
    this.editionsTotalPrice = Decimal.sumFromUI(...editionPrices)
    if (!this.offerPrice) this.offerPrice = this.editionsTotalPrice

    return this
  }

  hasDiscounts() {
    return this.standardDiscountApplicable || this.discountOverride
  }

  calculateDiscount(contract, price = null) {
    const zero = Decimal.zeroForUI()
    price = price ?? this.offerPrice

    if (!this.hasDiscounts() || !contract) return zero

    if (this.standardDiscountApplicable)
      return contract.calculateDiscount(price)

    const overridden = this.contractOverrides.find(
      override => override.contract.id === contract.id
    )
    return overridden ? overridden.calculateDiscount(price) : zero
  }

  calculateDiscountedPrice(contract, price = null) {
    price = price ?? this.offerPrice
    const discount = this.calculateDiscount(contract, price)

    return Decimal.fromUI(price)
      .subtract(Decimal.fromUI(discount))
      .forUI()
  }
}
