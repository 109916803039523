<template>
  <PersonAndCompanySelect v-bind="$attrs" @input="val => $emit('input', val)" />
</template>

<script>
import PersonAndCompanySelect from './PersonAndCompanySelect.vue'

export default {
  name: 'CustomerSelect',
  components: { PersonAndCompanySelect },
  props: { multiple: Boolean }
}
</script>
