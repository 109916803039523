<template>
  <v-simple-table class="table" dense>
    <template v-slot:default>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ item.detail.name }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: 'JobDetailsSimpleTable',
  props: { items: Array }
}
</script>

<style scoped>
td.value {
  width: 90px !important;
}
td.checkbox {
  width: 120px !important;
}
</style>
