/**
 * Miscellaneous utility functions waiting to be reorganized in a sensible
 * manner.
 */
import moment from 'moment'

const isArray = function(variable) {
  return _isArray(variable)
}
const isObject = function(variable) {
  return !!variable && !_isArray(variable) && 'object' === typeof variable
}
const isString = function(variable) {
  return variable === '' || (!!variable && 'string' === typeof variable)
}
const isFile = function(variable) {
  if (!variable) return false

  const className = dig(variable, 'constructor', 'name')
  return className === 'File'
}
const isFunction = function(variable) {
  return variable && {}.toString.call(variable) === '[object Function]'
}
const isEmpty = function(value) {
  if (value === undefined || value === null || value === '') return true
  if (isObject(value)) return Object.keys(value).length === 0
  if (isArray(value)) return value.length === 0

  return false
}

const dig = function(arrayOrObject, ...path) {
  if (!arrayOrObject || isEmpty(path)) return null

  let current = arrayOrObject
  if (!current[path[0]]) return null

  path.forEach(field => {
    if (current[field]) current = current[field]
    else {
      current = null
      return
    }
  })

  return current
}

const deepClone = function(object) {
  return JSON.parse(JSON.stringify(object))
}

const dateForUI = function(date) {
  if (!date) return ''
  const momentDate = moment.utc(date, 'YYYY-MM-DD')
  return momentDate.isValid() ? momentDate.local().format('DD/MM/YYYY') : ''
}

const momentForBE = function(momentDate) {
  return momentDate.isValid() ? momentDate.format('YYYY-MM-DD') : ''
}

const dateTimeForUI = function(dateTime) {
  if (!dateTime) return ''
  const momentDate = moment.utc(dateTime, 'YYYY-MM-DD HH:mm:ss')
  return momentDate.isValid()
    ? momentDate.local().format('DD/MM/YYYY HH:mm:ss')
    : ''
}

const today = function() {
  return moment().format('YYYY-MM-DD')
}

export {
  isArray,
  isObject,
  isString,
  isFile,
  isFunction,
  isEmpty,
  dig,
  deepClone,
  dateForUI,
  dateTimeForUI,
  momentForBE,
  today
}

function _isArray(variable) {
  if (!variable) return false
  // Cannot use dig here because dig uses isArray() through isEmpty().
  if (!variable.constructor || !variable.constructor.name) return false
  return variable.constructor.name === 'Array'
}
