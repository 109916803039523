<template>
  <v-autocomplete
    :class="_class"
    :label="label"
    :items="items"
    :clearable="clearable"
    v-model="item"
    return-object
    item-text="name"
    @input="onChange"
    :no-data-text="$t('common.autocomplete.no-data')"
    :rules="_rules"
    :required="required"
    :outlined="readonly || outlined"
    :filled="readonly"
    :hide-details="hideDetails"
    dense
  >
    <template v-slot:selection="data">
      {{ data.item.name }}
    </template>

    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>
          {{ data.item.name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ data.item.iban }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import Error from '@/lib/Error'
import actsAsAnAutocomplete from '@/mixins/actsAsAnAutocomplete'

import GetBankAccounts from '@/domain/useCases/invoices/GetBankAccounts'

export default {
  name: 'BankAccountSelect',
  mixins: [actsAsAnAutocomplete],
  created() {
    new GetBankAccounts(this.user).call().then(result => {
      if (result.error) {
        new Error({
          message: 'common.autocomplete.error-loading'
        }).toAlert()
        console.info(`Error loading bank accounts for autocomplete:`, result)
      } else this.items = result.bankAccounts
    })
  }
}
</script>
