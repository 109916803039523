<template>
  <v-container fluid tag="section">
    <material-card
      icon="mdi-file-document-multiple"
      icon-small
      :title="$t('list.customerContracts.title')"
      color="primary"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :footer-props="footerOptions"
        :server-items-length="totalItems"
        :loading="loading"
        :no-data-text="$t('common.list.no-data')"
      >
        <template v-slot:top>
          <v-container>
            <v-row dense>
              <v-col cols="11">
                <Searchbar @submit="searchbarSubmitted" />
              </v-col>
              <v-col cols="1" class="d-none pt-0 pl-5">
                <RoundButton
                  icon="mdi-plus"
                  :alt="$t('list.customerContracts.new')"
                  @click="$emit('goto-new')"
                />
              </v-col>
            </v-row>
          </v-container>
        </template>

        <template v-slot:[`item.buyer`]="{ item }">
          {{ getBuyerFullName(item) }}
        </template>

        <template v-slot:[`item.contractName`]="{ item }">
          {{ item.contract.name }}
        </template>

        <template v-slot:[`item.creationDate`]="{ item }">
          <small>{{ item.creationDate | dateTime }}</small>
        </template>

        <template v-slot:[`item.lastModified`]="{ item }">
          <small>{{ item.lastModified | dateTime }}</small>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="actions">
            <v-icon
              class="mr-2 show-customerContract-button"
              @click="$emit('goto-show', item.id)"
              >mdi-eye</v-icon
            >
            <v-icon
              class="mr-2 edit-customerContract-button"
              @click="$emit('goto-edit', item.id)"
              >mdi-pencil</v-icon
            >
            <v-icon
              class="delete-customerContract-button"
              @click="$emit('delete', item.id)"
              >mdi-delete</v-icon
            >
          </div>
        </template>
      </v-data-table>
    </material-card>
  </v-container>
</template>

<script>
import actsAsAList from '@/mixins/actsAsAList'

import Searchbar from './CustomerContractSearchbar.vue'
import RoundButton from '@/components/common/RoundButton.vue'
import { dig } from '@/lib/Utils'

export default {
  name: 'CustomerContractsList',
  mixins: [actsAsAList],
  components: { Searchbar, RoundButton },
  props: {
    loading: Boolean,
    items: Array,
    page: Number,
    totalItems: Number
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('entities.customerContract.buyer'),
          value: 'buyer'
        },
        {
          text: this.$t('entities.customerContract.contract.name'),
          value: 'contractName'
        },
        {
          text: this.$t('entities.customerContract.code'),
          value: 'code'
        },
        {
          text: this.$t('entities.customerContract.version'),
          value: 'version'
        },
        {
          text: this.$t('common.fields.creationDate'),
          value: 'creationDate'
        },
        {
          text: this.$t('common.fields.lastModified'),
          value: 'lastModified'
        },
        { text: '', value: 'actions' }
      ]
    }
  },
  methods: {
    getBuyerFullName(item) {
      let person = dig(item.buyer, 'person')
      return person ? person.getFullName() : ''
    }
  }
}
</script>

<style scoped>
.actions {
  min-width: 100px;
}
</style>
