<template>
  <SelectField
    :label="label"
    :value="value"
    return-object
    :items="items"
    item-text="name"
    :customer="customer"
    :required="required"
    :error="error"
    @input="onChange"
    :loading="loading"
  />
</template>

<script>
import { get } from 'vuex-pathify'
import Error from '@/lib/Error'
import SelectField from '@/components/common/form/SelectField'

import GetBeneficiaryContracts from '@/domain/useCases/contracts/GetBeneficiaryContracts'

export default {
  name: 'BeneficiaryContractSelect',
  components: { SelectField },
  props: {
    label: String,
    value: Object,
    customer: Object,
    required: Boolean,
    error: String
  },
  data() {
    return { items: [], loading: false }
  },
  computed: {
    user: get('user')
  },
  methods: {
    onChange(newValue) {
      this.$emit('input', newValue)
    }
  },
  watch: {
    customer() {
      this.loading = true
      this.onChange(null)
      if (!this.customer) {
        this.items = []
      } else {
        new GetBeneficiaryContracts(this.user)
          .call(this.customer)
          .then(result => {
            if (result.error) {
              new Error({
                message: 'common.autocomplete.error-loading'
              }).toAlert()
              console.info(
                `Error loading customer contracts information for select:`,
                result
              )
            } else {
              this.items = result.contracts
              this.loading = false
            }
          })
      }
    }
  }
}
</script>
