import axios from '@/axios.js'

import ContractFormatter from '../formatters/Contract'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, id) {
  try {
    const { data } = await axios.get(`/api/v1/contracts/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    })

    const contract = ContractFormatter.fromResponseResource(data.contract)

    return { error: false, contract }
  } catch (e) {
    return exceptionToResponse(e)
  }
}
