<template>
  <div>
    <InvoicesList
      :loading="loading"
      :items="items"
      :page="page"
      :totalItems="totalItems"
      @refresh="customRefresh"
      @goto-show="gotoShow"
      @delete="deleteEntity"
    />
    <v-dialog v-if="false" v-model="deleteDialog" max-width="400px">
      <ConfirmDialog
        :title="$t('common.invoice.delete.title')"
        :message="$t('common.invoice.delete.confirm-text')"
        :yesText="$t('common.delete.yes')"
        :noText="$t('common.delete.no')"
        @yesClick="deleteConfirmed"
        @noClick="deleteCanceled"
      />
    </v-dialog>
  </div>
</template>

<script>
import actsAsAListPage from '@/mixins/actsAsAListPage'

import InvoicesList from '@/components/invoices/InvoicesList'

import GetInvoices from '@/domain/useCases/invoices/GetInvoices'
import DeleteInvoice from '@/domain/useCases/invoices/DeleteInvoice'

import ConfirmDialog from '@/components/common/ConfirmDialog.vue'

export default {
  name: 'InvoicesListPage',
  mixins: [actsAsAListPage],
  components: { InvoicesList, ConfirmDialog },
  props: { purchase: String },
  data() {
    return {
      actsAsAListPage: {
        resource: 'invoices',
        get: GetInvoices,
        delete: DeleteInvoice
      }
    }
  },
  methods: {
    customRefresh(listOptions) {
      let tmpListOptions = this.purchase ? { purchase: this.purchase } : {}
      listOptions = Object.assign(listOptions, tmpListOptions)
      this.refresh(listOptions)
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
