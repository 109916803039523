import Base from './Base'
import OfferContractOverride from '@/domain/entities/OfferContractOverride'
import ContractFormatter from './Contract'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null
    const entity = this.fieldNamesFromResponse(resource)
    entity.discountType = resource.discountValue ? 'value' : 'percentage'
    this.formatDecimalsFromResponseResource(
      entity,
      'discountPercentage',
      'discountValue'
    )

    entity.contract = ContractFormatter.fromResponseResource(entity.contract)

    return new OfferContractOverride(entity)
  }

  static toRequestResource(entity) {
    if (!entity) return null
    const resource = this.fieldNamesForRequest(entity)

    if (entity.discountType === 'percentage') {
      delete resource.discountValue
    } else {
      delete resource.discountPercentage
    }

    this.formatDecimalsForRequestResource(
      resource,
      'discountPercentage',
      'discountValue'
    )

    resource.contract = ContractFormatter.toRequestResource(resource.contract)

    return resource
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    if (!resource) return null
    const { id, contract, discountPercentage, discountValue, notes } = resource

    const result = {
      id,
      contract,
      discountPercentage,
      discountValue,
      notes
    }

    this.formatInternalValuesFromResponse(resource, result)

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(entity, removeEmpty = false) {
    if (!entity) return null

    const { id, contract, discountPercentage, discountValue, notes } = entity

    const result = {
      id,
      contract,
      discountPercentage,
      discountValue,
      notes
    }

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }
}
