var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('entities.offer.editions'))+" ")]),_c('v-card-text',[(_vm.offerEditions.length || _vm.offerEditionsLoading)?_c('v-data-table',{attrs:{"show-select":"","disable-sort":"","headers":_vm.headers,"items":_vm.offerEditions,"loading":_vm.offerEditionsLoading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.offerEditionsSelected.length)?_c('v-toolbar',{staticClass:"mx-auto",attrs:{"flat":"","color":"info","dark":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t('common.list.n-selected', { n: _vm.offerEditionsSelected.length })))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.unlinkEditions()}}},[_c('v-icon',{attrs:{"small":"","left":"","dark":""}},[_vm._v("mdi-delete")]),_vm._v(" "+_vm._s(_vm.$t('common.offer.editions.unlink', { n: _vm.offerEditionsSelected.length }))+" ")],1)],1):_c('v-toolbar',{staticClass:"mx-auto",attrs:{"flat":""}})]},proxy:true},{key:"item.genericProductType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.translateProductType(item.genericProductType))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("append")(item.price,'€'))+" ")]}},{key:"item.fromDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.fromDate))+" ")]}},{key:"item.toDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.toDate))+" ")]}}],null,true),model:{value:(_vm.offerEditionsSelected),callback:function ($$v) {_vm.offerEditionsSelected=$$v},expression:"offerEditionsSelected"}}):_c('p',{staticClass:"text--primary"},[_c('material-alert',{attrs:{"color":"warning","icon":"mdi-alert-outline"}},[_vm._v(" "+_vm._s(_vm.$t('common.offer.editions.no-editions'))+" ")])],1)],1)],1),_c('v-card',{staticClass:"mt-2"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('common.offer.editions.add-title'))+" ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"show-select":"","headers":_vm.headers,"items":_vm.editions,"options":_vm.editionsOptions,"server-items-length":_vm.editionsTotalItems,"loading":_vm.editionsLoading,"no-data-text":_vm.$t('common.list.no-data')},on:{"update:options":function($event){_vm.editionsOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.editionsSelected.length)?_c('v-toolbar',{staticClass:"mx-0",attrs:{"color":"info","dark":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t('common.list.n-selected', { n: _vm.editionsSelected.length })))]),_c('v-spacer'),_c('v-btn',{attrs:{"right":"","color":"success"},on:{"click":function($event){return _vm.linkEditions()}}},[_c('v-icon',{attrs:{"small":"","left":"","dark":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t('common.offer.editions.link', { n: _vm.editionsSelected.length }))+" ")],1)],1):_vm._e()]},proxy:true},{key:"item.genericProductType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.translateProductType(item.genericProductType))+" ")]}},{key:"item.fromDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.fromDate))+" ")]}},{key:"item.toDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.toDate))+" ")]}}],null,true),model:{value:(_vm.editionsSelected),callback:function ($$v) {_vm.editionsSelected=$$v},expression:"editionsSelected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }