import Base from './Base'
import Decimal from '@/lib/Decimal'
import ContractDetailFormatter from './ContractDetail'
import Contract from '@/domain/entities/Contract'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null

    const entity = this.fieldNamesFromResponse(resource)
    const price = Decimal.fromBE(entity.price)
    entity.price = price ? price.forUI() : null

    entity.details = ContractDetailFormatter.fromResponseResourcesList(
      resource.contractDets
    )

    this.formatDecimalsFromResponseResource(entity, 'discount')

    return new Contract(entity)
  }

  static toRequestResource(contract) {
    if (!contract) return null

    const resource = this.fieldNamesForRequest(contract)
    const contractPrice = Decimal.fromUI(resource.contractPrice)
    resource.contractPrice = contractPrice ? contractPrice.forBE() : null

    resource.contractDets = ContractDetailFormatter.toRequestResourcesList(
      contract.details
    )
    this.formatDecimalsForRequestResource(resource, 'standardDiscountPerc')
    return resource
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    const {
      id,
      contractLabelCode,
      contractVersionNum,
      contractType,
      contractName,
      contractDesc,
      validStartDate,
      validEndDate,
      contractDuration,
      contractAutoRenewal,
      contractRenewalStep,
      flagActive,
      contractPrice,
      standardDiscountPerc,
      pdfLink
    } = resource

    const result = {
      id,
      active: flagActive,
      code: contractLabelCode,
      version: contractVersionNum,
      type: contractType,
      name: contractName,
      description: contractDesc,
      validFrom: validStartDate,
      validTo: validEndDate,
      price: contractPrice,
      discount: standardDiscountPerc,
      duration: contractDuration,
      autoRenewal: contractAutoRenewal,
      renewalStep: contractRenewalStep,
      pdf: pdfLink
    }

    this.formatInternalValuesFromResponse(resource, result)

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(contract, removeEmpty = false) {
    if (!contract) return null

    const {
      id,
      active,
      code,
      version,
      type,
      name,
      description,
      validFrom,
      validTo,
      price,
      discount,
      duration,
      autoRenewal,
      renewalStep
    } = contract

    const result = {
      id,
      flagActive: active,
      contractLabelCode: code,
      contractVersionNum: version,
      contractType: type,
      contractName: name,
      contractDesc: description,
      validStartDate: validFrom,
      validEndDate: validTo,
      contractPrice: price,
      standardDiscountPerc: discount,
      contractDuration: duration,
      contractAutoRenewal: autoRenewal,
      contractRenewalStep: renewalStep
    }

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }
}
