/**
 * Provides behaviours for views that show read-only details about an entity.
 *
 * Expects:
 *  - this.id:              the id for the current entity, usually a prop provided by the router;
 *  - data.actsAsAShowPage: options for this mixin:
 *    - entity:   String, name used for a single entity in the adapters (e.g. as a key for a result object);
 *    - resource: String, name used for the resource in the adapters (e.g. when building a request URI);
 *    - get:      use-case Function to use when fetching the entity's data;
 *
 *
 * Provides:
 *  - this.user: the currently logged-in User from the vuex store;
 *
 *  - data.errors:          an Object where to put error information;
 *
 *  - this.gotoEdit():      loads the edit page for the current entity
 *
 *  - this.gotoList():      loads the show page for the current entity;
 */

import { get } from 'vuex-pathify'
import handlesErrors from './handlesErrors'

export default {
  mixins: [handlesErrors],
  computed: {
    user: get('user')
  },
  methods: {
    option(name) {
      return this.actsAsAShowPage[name]
    },
    gotoEdit() {
      this.$router.push(`/${this.option('resource')}/${this.id}/edit`)
    },
    gotoList() {
      this.$router.push(`/${this.option('resource')}`)
    }
  },
  created() {
    const entityName = this.option('entity')
    const useCase = this.option('get')
    new useCase(this.user).call(this.id).then(result => {
      if (result.error) {
        /// Note: no field errors expected for get requests.
        this.$_handleError(result)
      } else {
        this.entity = result[entityName]
      }
    })
  }
}
