<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12" lg="9">
        <material-card
          icon="mdi-file-document-multiple"
          icon-small
          color="primary"
          :title="$t('common.sections.titles.customerContract')"
        >
          <CustomerContractCard :entity="entity" />
        </material-card>
      </v-col>
      <v-col cols="12" lg="3" order="first" order-lg="last">
        <CustomerContractSummary :entity="entity">
          <v-divider />

          <SummaryCommandBanner
            icon="mdi-view-list"
            :text="$t('common.back-to-list')"
            @click="gotoList"
          />
          <SummaryCommandBanner
            icon="mdi-pencil"
            :text="$t('common.customerContract.edit')"
            @click="gotoEdit"
          />
        </CustomerContractSummary>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsAShowPage from '@/mixins/actsAsAShowPage'

import CustomerContractCard from '@/components/customerContracts/CustomerContractCard'
import CustomerContractSummary from '@/components/customerContracts/CustomerContractSummary.vue'
import SummaryCommandBanner from '@/components/common/SummaryCommandBanner.vue'

import CustomerContract from '@/domain/entities/CustomerContract'
import GetCustomerContract from '@/domain/useCases/customerContracts/GetCustomerContract'

export default {
  name: 'ShowCustomerContractPage',
  mixins: [actsAsAShowPage],
  components: {
    CustomerContractCard,
    CustomerContractSummary,
    SummaryCommandBanner
  },
  props: {
    id: String
  },
  data() {
    return {
      actsAsAShowPage: {
        resource: 'customerContracts',
        entity: 'customerContract',
        get: GetCustomerContract
      },
      entity: CustomerContract.default()
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
