<template>
  <v-container fluid tag="section" v-if="showForm">
    <v-row>
      <v-col cols="12" lg="9">
        <material-card
          icon="mdi-receipt"
          icon-small
          :title="$t('common.sections.titles.invoice')"
          color="primary"
        >
          <InvoiceForm
            ref="form"
            v-if="showForm"
            v-model="entity"
            :errors="errors"
          />
        </material-card>

        <InvoiceDetailsForm
          ref="detailsForm"
          v-if="showForm"
          v-model="entity.details"
          :errors="errors.details || {}"
        />

        <p v-if="!entity.details.length" class="text--primary">
          <material-alert color="warning" icon="mdi-alert-outline">
            {{ $t('common.invoice.details.no-details') }}
          </material-alert>
        </p>
      </v-col>
      <v-col cols="12" lg="3" order="first" order-lg="last">
        <InvoiceSummary :entity="entity">
          <v-divider />
          <template #controls>
            <v-row>
              <v-col cols="3" md="6">
                <v-btn color="success" width="100%" @click="save()">
                  {{ $t('form.customers.new.submit') }}
                </v-btn>
              </v-col>
              <v-col cols="3" md="6">
                <v-btn color="warning" width="100%" @click="leave()">
                  {{ $t('common.leave') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </InvoiceSummary>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsAnEditPage from '@/mixins/actsAsAnEditPage'
import { dig } from '@/lib/Utils'

import InvoiceForm from '@/components/invoices/InvoiceForm.vue'
import InvoiceDetailsForm from '@/components/invoices/InvoiceDetailsForm.vue'
import InvoiceSummary from '@/components/invoices/InvoiceSummary.vue'

import Invoice from '@/domain/entities/Invoice'
import PreparePurchasesInvoice from '@/domain/useCases/purchases/PreparePurchasesInvoice'
import CreateInvoice from '@/domain/useCases/invoices/CreateInvoice'

export default {
  name: 'NewPurchaseInvoicePage',
  mixins: [actsAsAnEditPage],
  components: { InvoiceForm, InvoiceDetailsForm, InvoiceSummary },
  props: { ids: Array }, // Array of Purchase ids
  data() {
    return {
      showForm: false,
      actsAsAnEditPage: {
        entity: 'invoice',
        resource: 'invoices',
        getMultipleIds: true,
        get: PreparePurchasesInvoice,
        onGetErrorGoBack: true,
        getCallback: this.invoicePrepared,
        create: CreateInvoice,
        validate: ['form', 'detailsForm']
      },
      entity: Invoice.new()
    }
  },
  methods: {
    invoicePrepared(entity) {
      const details = dig(entity, 'details')
      if (details)
        details.forEach(detail => {
          detail.description = this.$t(
            'entities.invoice.detail.default-description',
            detail
          )
        })

      this.showForm = true
      return entity
    },
    saveWithDetails() {
      if (this.entity.details.length) this.save()
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
