<template>
  <div>
    <v-tabs v-model="tab" fixed-tabs>
      <v-tab key="create">{{ $t('common.companies.create') }}</v-tab>
      <v-tab key="search">{{ $t('common.companies.search') }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="create">
        <div class="tab-item-content">
          <CompanyForm v-model="newItem" ref="form" :as="as" :errors="errors" />
        </div>
      </v-tab-item>
      <v-tab-item key="search">
        <div class="tab-item-content">
          <v-container>
            <v-autocomplete
              :label="$t('common.companies.search')"
              v-model="autocompleteSelectedItem"
              :items="autocompleteItems"
              item-value="id"
              return-object
              :search-input.sync="autocompleteSearchword"
              clearable
              no-filter
              :no-data-text="noDataText"
              :loading="autocompleteLoading"
              :error-messages="autocompleteErrors"
              dense
            >
              <template v-slot:selection="data">
                {{ data.item.invoiceName }}
              </template>

              <template v-slot:item="data">
                <v-list-item-avatar>
                  <v-icon>mdi-card-bulleted</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.invoiceName"
                  ></v-list-item-title>
                  <v-list-item-subtitle v-if="fiscalOrVatCode(data.item)">
                    {{ fiscalOrVatCode(data.item) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-container>

          <CompanyCard v-if="selectedCompany.id" :company="selectedCompany" />
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { get } from 'vuex-pathify'

import CompanyCard from '@/components/companies/CompanyCard.vue'
import CompanyForm from './CompanyForm.vue'

import Error from '@/lib/Error'
import Company from '@/domain/entities/Company'
import GetCompanies from '@/domain/useCases/companies/GetCompanies'

export default {
  name: 'PersonCompanySelectOrCreate',
  components: { CompanyCard, CompanyForm },
  props: {
    as: String,
    value: Company,
    errors: Object,
    icon: String
  },
  data() {
    return {
      tab: null,

      /// These are Companies, not PersonCompanies
      newItem: this.value, /// Company (not PersonCompany)

      autocompleteLoading: false,
      autocompleteItems: [], /// [Company] (not PersonCompany)
      autocompleteSelectedItem: null, /// Company (not PersonCompany)
      autocompleteSearchword: '',
      autocompleteErrors: []
    }
  },
  computed: {
    user: get('user'),
    autocompleteMinChars: get('app/autocompleteMinChars'),
    hasActions() {
      return !this.disableActions
    },
    submitButtonDisabled() {
      return (
        this.tab === null ||
        (!this.createTabSelected && !this.selectedCompany.id)
      )
    },
    noDataText() {
      const text = this.search || ''
      if (text.length < this.autocompleteMinChars)
        return this.$t('common.autocomplete.at-least-n-chars', {
          n: this.autocompleteMinChars
        })

      return this.$t('common.autocomplete.no-data')
    },
    createTabSelected() {
      return this.tab === 0
    },
    selectedCompany() {
      return this.autocompleteSelectedItem
        ? this.autocompleteSelectedItem
        : new Company()
    }
  },
  methods: {
    validate() {
      if (this.createTabSelected) {
        return this.$refs.form.validate()
      } else {
        const company = this.autocompleteSelectedItem
        if (!company || !company.id) {
          this.autocompleteErrors = [this.$t('errors.field.required')]
          return false
        } else {
          this.autocomplateErrors = []
          return true
        }
      }
    },
    fiscalOrVatCode(item) {
      if (!item.fiscalCode && !item.vatCode) return ''
      return item.fiscalCode ? item.fiscalCode : item.vatCode
    }
  },
  watch: {
    createTabSelected() {
      if (this.createTabSelected) {
        this.$emit('input', this.newItem)
      } else {
        this.$emit('input', this.autocompleteSelectedItem || new Company())
      }
    },
    autocompleteSelectedItem: function() {
      if (this.selectedCompany) this.$emit('input', this.selectedCompany)
    },
    async autocompleteSearchword(text) {
      if (text !== null) {
        if (text.length < this.autocompleteMinChars) return
        if (this.autocompleteLoading) return

        this.autocompleteLoading = true

        this.autocompleteItems = []
        if (this.autocompleteSelectedItem && this.autocompleteSelectedItem.id)
          this.autocompleteItems = [this.autocompleteSelectedItem]

        new GetCompanies(this.user)
          .call({ fullText: text, itemsPerPage: 10, page: 1 })
          .then(result => {
            if (result.error) {
              new Error({
                message: 'common.autocomplete.error-loading'
              }).toAlert()
              console.info('Error loading companies for autocomplete:', result)
            } else {
              this.autocompleteItems = [
                ...this.autocompleteItems,
                ...result.companies
              ]
              this.autocompleteLoading = false
            }
          })
      }
    }
  }
}
</script>

<style scoped>
.tab-item-content {
  margin-top: 12px;
  min-height: 600px;
}
</style>
