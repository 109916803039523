import Base from './Base'
import InvoiceDetail from '@/domain/entities/InvoiceDetail'
import PurchaseFormatter from '@/adapters/backoffice/formatters/Purchase'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null

    const entity = this.fieldNamesFromResponse(resource)

    this.formatDecimalsFromResponseResource(
      entity,
      'amount',
      'discount',
      'vatAmount',
      'vatPerc'
    )

    entity.purchase = PurchaseFormatter.fromResponseIdObject(entity.purchase)

    return new InvoiceDetail(entity)
  }

  static toRequestResource(entity) {
    if (!entity) return null

    const resource = Object.assign({}, entity)

    this.formatDecimalsForRequestResource(
      resource,
      'amount',
      'discount',
      'vatAmount',
      'vatPerc'
    )

    resource.purchase = PurchaseFormatter.toResourceIdObject(entity.purchase)
    return this.fieldNamesForRequest(resource)
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    if (!resource) return null

    let {
      id,
      purchaseDate,
      contractName,
      contractNumber,
      offerName,
      offerDescr,
      offerNetPrice,
      finalDiscount,
      invoVatAmount,
      invoVatPerc,
      purchase
    } = resource

    const result = {
      id,
      date: purchaseDate,
      contractName,
      contractNumber,
      offerName,
      description: offerDescr,
      amount: offerNetPrice,
      vatAmount: invoVatAmount,
      discount: finalDiscount,
      vatPerc: invoVatPerc,
      purchase
    }

    this.formatInternalValuesFromResponse(resource, result)
    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(entity, removeEmpty = false) {
    if (!entity) return null

    let {
      id,
      date,
      contractName,
      contractNumber,
      offerName,
      description,
      amount,
      vatAmount,
      discount,
      vatPerc,
      purchase
    } = entity

    const result = {
      id,
      purchaseDate: date,
      contractName,
      contractNumber,
      offerName,
      offerDescr: description,
      offerNetPrice: amount,
      invoVatAmount: vatAmount,
      finalDiscount: discount,
      invoVatPerc: vatPerc,
      purchase
    }

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }
}
