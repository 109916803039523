<template>
  <v-container id="purchases-list" fluid tag="section">
    <material-card
      icon="mdi-credit-card-plus"
      icon-small
      :title="$t('list.purchases.title')"
      color="primary"
    >
      <v-data-table
        show-select
        v-model="selected"
        :headers="headers"
        :items="itemsWithSelectSupport"
        :options.sync="options"
        :footer-props="footerOptions"
        :server-items-length="totalItems"
        :loading="loading"
        :no-data-text="$t('common.list.no-data')"
      >
        <template v-slot:top>
          <v-container class="mx-1">
            <v-row dense>
              <v-col cols="auto">
                <v-btn
                  class="mt-3"
                  v-if="selected.length"
                  small
                  color="primary"
                  @click="
                    $emit(
                      'create-invoice',
                      selected.map(purchase => purchase.id)
                    )
                  "
                  >{{ $t('common.purchase.create-invoices') }}</v-btn
                >
              </v-col>
              <v-col>
                <Searchbar @submit="searchbarSubmitted" />
              </v-col>
              <v-col cols="1" class="pt-0 pl-5">
                <RoundButton
                  :icon="`mdi-plus`"
                  :alt="$t('list.purchases.new')"
                  @click="$emit('goto-new')"
                />
              </v-col>
            </v-row>
          </v-container>
        </template>

        <template v-slot:[`item.invoiceOptions`]="{ item }">
          <v-btn
            v-if="isInvoiceable(item)"
            small
            color="success"
            @click="$emit('create-invoice', item.id)"
            >{{ $t('common.purchase.create-invoice') }}</v-btn
          >
          <v-btn
            v-if="hasInvoice(item)"
            color="primary"
            small
            @click="$emit('show-invoices', item.id)"
            ><v-icon class="mr-1">mdi-receipt</v-icon
            >{{ $t('common.purchase.show-invoice') }}</v-btn
          >
        </template>

        <template v-slot:[`item.purchaseDate`]="{ item }">
          {{ item.purchaseDate | date }}
        </template>

        <template v-slot:[`item.purchaseStatus`]="{ item }">
          <div class="text-center">
            <v-icon v-if="isValid(item)" color="success"
              >mdi-checkbox-marked-circle</v-icon
            >
            <v-icon v-else color="error">mdi-close-circle</v-icon>
          </div>
        </template>

        <template v-slot:[`item.contractOrOffer`]="{ item }">
          {{
            $t(
              `list.purchases.contractOrOffer.${
                item.offer ? 'offer' : 'contract'
              }`
            )
          }}
        </template>

        <template v-slot:[`item.finalPrice`]="{ item }">
          {{ item.finalPrice | append('€') }}
        </template>

        <template v-slot:[`item.contractOrOfferName`]="{ item }">
          {{ item[item.offer ? 'offer' : 'contract'].name }}
        </template>

        <template v-slot:[`item.contractOrOfferDescription`]="{ item }">
          {{ item[item.offer ? 'offer' : 'contract'].description }}
        </template>

        <template v-slot:[`item.creationDate`]="{ item }">
          <small>{{ item.creationDate | dateTime }}</small>
        </template>

        <template v-slot:[`item.lastModified`]="{ item }">
          <small>{{ item.lastModified | dateTime }}</small>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="actions">
            <v-icon
              class="mr-2 show-purchase-button"
              @click="$emit('goto-show', item.id)"
              >mdi-eye</v-icon
            >
            <v-icon
              class="mr-2 edit-purchase-button"
              @click="$emit('goto-edit', item.id)"
              >mdi-pencil</v-icon
            >
            <v-icon
              class="delete-purchase-button"
              @click="$emit('delete', item.id)"
              >mdi-delete</v-icon
            >
          </div>
        </template>
      </v-data-table>
    </material-card>
  </v-container>
</template>

<script>
import actsAsAList from '@/mixins/actsAsAList'

import Searchbar from '@/components/purchases/PurchaseSearchbar.vue'
import RoundButton from '@/components/common/RoundButton.vue'

export default {
  name: 'PurchasesList',
  mixins: [actsAsAList],
  components: { Searchbar, RoundButton },
  props: {
    loading: Boolean,
    items: Array,
    page: Number,
    totalItems: Number
  },
  data() {
    return {
      selected: [],
      headers: [
        {
          text: this.$t('common.purchase.invoice-options'),
          value: 'invoiceOptions',
          sortable: false
        },
        {
          text: this.$t('entities.purchase.purchaseStatus'),
          value: 'purchaseStatus'
        },
        {
          text: this.$t('entities.purchase.contractNumber'),
          value: 'contractNumber'
        },
        {
          text: this.$t('entities.purchase.purchaseDate'),
          value: 'purchaseDate'
        },
        {
          text: this.$t('entities.purchase.quantity'),
          value: 'quantity'
        },
        {
          text: this.$t('entities.purchase.finalPrice'),
          value: 'finalPrice'
        },
        {
          text: this.$t('entities.purchase.purchase-customer.name'),
          value: 'customer.person.name'
        },
        {
          text: this.$t('entities.purchase.purchase-customer.surname'),
          value: 'customer.person.surname'
        },
        {
          text: this.$t('entities.purchase.purchase-beneficiary.name'),
          value: 'beneficiary.person.name'
        },
        {
          text: this.$t('entities.purchase.purchase-beneficiary.surname'),
          value: 'beneficiary.person.surname'
        },
        {
          text: this.$t('entities.purchase.offer'),
          value: 'offer.name'
        },
        {
          text: this.$t('common.fields.creationDate'),
          value: 'creationDate'
        },
        {
          text: this.$t('common.fields.lastModified'),
          value: 'lastModified'
        },

        { text: '', value: 'actions', sortable: false }
      ]
    }
  },
  computed: {
    itemsWithSelectSupport() {
      return this.items.map(purchase =>
        Object.assign(purchase, { isSelectable: this.isInvoiceable(purchase) })
      )
    }
  },
  methods: {
    isValid(purchase) {
      return purchase && purchase.isValid()
    },
    isInvoiceable(purchase) {
      return purchase && purchase.isInvoiceable()
    },
    hasInvoice(purchase) {
      return purchase && purchase.hasInvoice()
    }
  }
}
</script>

<style scoped>
.actions {
  min-width: 100px;
}
</style>
