<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="12">
        <CardTextField
          :label="$t('common.edition.selected-generic-product')"
          :value="entity.genericProduct ? entity.genericProduct.name : ''"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="3">
        <CardYesNoField
          :label="$t('entities.edition.active')"
          :value="entity.active"
        />
      </v-col>

      <v-col cols="12" lg="2">
        <CardTextField
          :label="$t('entities.edition.language')"
          :value="entity.language"
        />
      </v-col>

      <v-col cols="12" lg="3">
        <CardTextField
          type="currency"
          :label="$t('entities.edition.price')"
          :value="entity.price"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="3">
        <CardYesNoField
          :label="$t('entities.edition.showOnCalendar')"
          :value="entity.showOnCalendar"
        />
      </v-col>

      <v-col cols="12" lg="3">
        <CardDateField
          :label="$t('entities.edition.fromDate')"
          :value="entity.fromDate"
        />
      </v-col>

      <v-col cols="12" lg="3">
        <CardDateField
          :label="$t('entities.edition.toDate')"
          :value="entity.toDate"
        />
      </v-col>

      <v-col cols="12" lg="2">
        <CardTextField
          :label="$t('entities.edition.durationHours')"
          :value="entity.durationHours"
        />
      </v-col>

      <v-col cols="12" lg="2">
        <CardTextField
          :label="$t('entities.edition.durationDays')"
          :value="entity.durationDays"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="2">
        <CardTextField
          :label="$t('entities.edition.placeDescription')"
          :value="entity.placeDescription"
        />
      </v-col>

      <v-col cols="12" lg="6">
        <CardTextField
          :label="$t('entities.edition.place')"
          :value="entity.place"
        />
      </v-col>

      <v-col cols="12" lg="3">
        <CardTextField
          :label="$t('entities.edition.minAttendees')"
          :value="entity.minAttendees"
        />
      </v-col>

      <v-col cols="12" lg="3">
        <CardTextField
          :label="$t('entities.edition.maxAttendees')"
          :value="entity.maxAttendees"
        />
      </v-col>

      <v-col cols="12">
        <CardTextarea
          :label="$t('entities.edition.notes')"
          :value="entity.notes"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            {{ $t('entities.edition.lecturers') }}
          </v-card-title>
          <EditionLecturersTable :items="entity.lecturers" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  CardTextField,
  CardYesNoField,
  CardDateField,
  CardTextarea
} from '@/components/common/card'
import EditionLecturersTable from './EditionLecturersSimpleTable.vue'

import Edition from '@/domain/entities/Edition'

export default {
  name: 'EditionCard',
  components: {
    CardTextField,
    CardYesNoField,
    CardDateField,
    CardTextarea,
    EditionLecturersTable
  },
  props: { entity: Edition },
  computed: {}
}
</script>
