import InvoiceFormatter from '../formatters/Invoice'
import axios from '@/axios.js'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, entity) {
  try {
    const payload = { invoPurchase: InvoiceFormatter.toRequestResource(entity) }
    const { data } = await axios.post(
      `/api/v1/invoPurchases/preview`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob'
      }
    )

    return {
      error: false,
      preview: new Blob([data], { type: 'application/pdf' })
    }
  } catch (e) {
    return exceptionToResponse(e)
  }
}
