import axios from '@/axios.js'

import ListOptionsFormatter from '../formatters/ListOptions'
import CostCenterFormatter from '../formatters/CostCenter'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, listOptions) {
  try {
    const options = ListOptionsFormatter.toRequestOptions(
      listOptions,
      CostCenterFormatter
    )
    const { data } = await axios.get('/api/v1/costCenters', {
      params: options,
      headers: { Authorization: `Bearer ${token}` }
    })

    const { results, totalItems, page } = data
    const costCenters = CostCenterFormatter.fromResponseResourcesList(results)

    return { error: false, costCenters, totalItems, page }
  } catch (e) {
    return exceptionToResponse(e)
  }
}
