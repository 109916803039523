<template>
  <v-autocomplete
    :class="_class"
    :label="label"
    :items="productTypes"
    v-model="productType"
    return-object
    @change="changed"
    :no-data-text="$t('common.autocomplete.no-data')"
    :rules="_rules"
    :required="required"
    :outlined="readonly || outlined"
    :filled="readonly"
    :hide-details="hideDetails"
    dense
  ></v-autocomplete>
</template>

<script>
import { get } from 'vuex-pathify'
import validators from '@/domain/validations/rules'

import GetProductTypes from '@/domain/useCases/products/GetProductTypes'

export default {
  name: 'ProductTypeSelect',
  props: {
    label: String,
    value: Object,
    rules: Array,
    required: Boolean,
    readonly: Boolean,
    outlined: Boolean,
    hideDetails: Boolean
  },
  computed: {
    user: get('user'),
    validates() {
      return validators(this)
    },
    _class() {
      return this.required ? 'required' : ''
    },
    _rules() {
      const _rules = this.rules || []
      if (this.required) _rules.push(this.validates.presence)
      return _rules
    }
  },
  data() {
    return {
      productType: null,
      productTypes: []
    }
  },
  methods: {
    translateCode(code) {
      return this.$t(`entities.product.types.${code}`)
    },
    changed() {
      this.$emit('input', this.productType)
    }
  },
  created() {
    new GetProductTypes(this.user)
      .call()
      .then(({ error, errorData, productTypes }) => {
        if (!error)
          this.productTypes = productTypes.map(productType => {
            const { id, code } = productType
            const text = this.translateCode(productType.code)
            return { id, code, text }
          })
        else console.error(errorData)
      })
  },
  watch: {
    value() {
      this.productType = this.value
      if (!this.productType.text)
        this.productType.text = this.translateCode(this.productType.code)
    }
  }
}
</script>
