<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12" lg="9">
        <material-card
          icon="mdi-credit-card"
          icon-small
          color="primary"
          :title="$t('common.sections.titles.purchase')"
        >
          <PurchaseCard :entity="entity" />
        </material-card>
      </v-col>
      <v-col cols="12" lg="3" order="first" order-lg="last">
        <PurchaseSummary :entity="entity">
          <SummaryCommandBanner
            icon="mdi-plus"
            :text="$t('common.purchase.create-customer-contract')"
            @click="createCustomerContract"
          />
          <SummaryCommandBanner
            v-if="invoiceable"
            icon="mdi-receipt"
            :text="$t('common.purchase.create-invoice')"
            @click="createInvoice"
          />
          <v-divider />
          <SummaryCommandBanner
            icon="mdi-view-list"
            :text="$t('common.back-to-list')"
            @click="gotoList"
          />
          <SummaryCommandBanner
            icon="mdi-pencil"
            :text="$t('common.purchase.edit')"
            @click="gotoEdit"
          />
        </PurchaseSummary>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsAShowPage from '@/mixins/actsAsAShowPage'

import PurchaseCard from '@/components/purchases/PurchaseCard.vue'
import PurchaseSummary from '@/components/purchases/PurchaseSummary.vue'
import SummaryCommandBanner from '@/components/common/SummaryCommandBanner.vue'

import Purchase from '@/domain/entities/Purchase'
import GetPurchase from '@/domain/useCases/purchases/GetPurchase'

export default {
  name: 'ShowPurchasePage',
  mixins: [actsAsAShowPage],
  components: { PurchaseCard, PurchaseSummary, SummaryCommandBanner },
  props: { id: String },
  data() {
    return {
      actsAsAShowPage: {
        resource: 'purchases',
        entity: 'purchase',
        get: GetPurchase
      },
      entity: Purchase.default()
    }
  },
  computed: {
    invoiceable() {
      return this.entity.isInvoiceable()
    }
  },
  methods: {
    createInvoice() {
      if (this.entity.isValid())
        this.$router.push({
          name: 'NewPurchaseInvoicePage',
          params: { ids: [this.entity.id] }
        })
    },
    createCustomerContract() {
      if (this.entity.isValid())
        this.$router.push({
          name: 'NewCustomerContractPage',
          params: { id: this.entity.id }
        })
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
