<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12" lg="9">
        <material-card
          icon="mdi-package-variant"
          icon-small
          color="primary"
          :title="$t('common.sections.titles.job')"
        >
          <JobCard :entity="entity" />
        </material-card>
      </v-col>
      <v-col cols="12" lg="3" order="first" order-lg="last">
        <JobSummary :entity="entity">
          <SummaryCommandBanner
            icon="mdi-view-list"
            :text="$t('common.back-to-list')"
            @click="gotoList"
          />
          <SummaryCommandBanner
            icon="mdi-pencil"
            :text="$t('common.job.edit')"
            @click="gotoEdit"
          />
        </JobSummary>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsAShowPage from '@/mixins/actsAsAShowPage'

import JobCard from '@/components/jobs/JobCard.vue'
import JobSummary from '@/components/jobs/JobSummary.vue'
import SummaryCommandBanner from '@/components/common/SummaryCommandBanner.vue'

import Job from '@/domain/entities/Job'
import GetJob from '@/domain/useCases/jobs/GetJob'

export default {
  name: 'ShowJobPage',
  mixins: [actsAsAShowPage],
  components: { JobCard, JobSummary, SummaryCommandBanner },
  props: { id: String },
  data() {
    return {
      actsAsAShowPage: {
        resource: 'jobs',
        entity: 'job',
        get: GetJob
      },
      entity: Job.default()
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
