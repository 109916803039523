/**
 * Used in entity customer supplier form components
 *
 * Requires:
 *
 *   - data.entity:     the entity used as the form's data source;
 */
import { isEmpty } from '@/lib/Utils'
import PersonCompanies from '@/domain/entities/PersonCompanies'

export default {
  data() {
    return {
      nextKey: 1,
      removePersonCompanyIndex: null,
      removePersonCompanyConfirmDialog: false
    }
  },
  methods: {
    addCustomer() {
      this.addPersonCompany(PersonCompanies.newCustomerPersonCompany())
    },
    addSupplier() {
      this.addPersonCompany(PersonCompanies.newSupplierPersonCompany())
    },
    addPersonCompany(personCompany) {
      personCompany._key = this.nextKey++
      this.entity.personCompanies.push(personCompany)
    },
    removePersonCompanyAt(index) {
      if (!isEmpty(index)) {
        this.removePersonCompanyIndex = index
        this.removePersonCompanyConfirmDialog = true
      }
    },
    removePersonCompany() {
      if (!isEmpty(this.removePersonCompanyIndex))
        this.entity.personCompanies.splice(this.removePersonCompanyIndex, 1)

      this.removePersonCompanyConfirmDialog = false
      this.removePersonCompanyIndex = null
    },
    cancelRemovePersonCompany() {
      this.removePersonCompanyConfirmDialog = false
      this.removePersonCompanyIndex = null
    }
  }
}
