/**
 * Models an error with all the relevant information.
 *
 * Can be converted into an Alert or directly saved to the store,
 * although only data consistent with a flash notification will be
 * passwd to the converted object.
 */
import Alert from './Alert'

export default class Error {
  error = true
  rawMessage = null // if not null, this is an already-translated string that should be used instead of message.
  message = '' // message label, will be translated on the interface with $t.
  extraMessages = [] // array of labels with extra information to build the error message.
  fields = null /// Field errors during update/insert.
  status = null /// A REST response status, either a 4xx or 5xx
  data = null /// exception data for logging and such.

  constructor({
    message = '',
    rawMessage = null,
    extraMessages = [],
    fields = null,
    status = 400,
    data = null
  } = {}) {
    this.message = message
    this.rawMessage = rawMessage
    this.extraMessages = extraMessages
    this.fields = fields
    this.status = Number(status)
    this.data = data
  }

  toAlert(showOnNextPage = false) {
    return new Alert({
      type: 'error',
      rawMessage: this.rawMessage,
      message: this.message,
      extraMessages: this.extraMessages,
      showOnNextPage
    })
  }

  saveAlert(vue) {
    this.toAlert().save(vue)
  }
}
