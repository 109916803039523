<template>
  <div>
    <PaymentsList
      :loading="loading"
      :items="items"
      :page="page"
      :totalItems="totalItems"
      @refresh="refresh"
      @delete="deleteEntity"
      @goto-new="gotoNew"
      @goto-edit="gotoEdit"
      @goto-show="gotoShow"
    />
    <v-dialog v-model="deleteDialog" max-width="400px">
      <ConfirmDialog
        :title="$t('common.payment.delete.title')"
        :message="$t('common.payment.delete.confirm-text')"
        :yesText="$t('common.delete.yes')"
        :noText="$t('common.delete.no')"
        @yesClick="deleteConfirmed"
        @noClick="deleteCanceled"
      />
    </v-dialog>
  </div>
</template>

<script>
import actsAsAListPage from '@/mixins/actsAsAListPage'

import PaymentsList from '@/components/payments/PaymentsList'
import ConfirmDialog from '@/components/common/ConfirmDialog.vue'

import GetPayments from '@/domain/useCases/payments/GetPayments'
import DeletePayment from '@/domain/useCases/payments/DeletePayment'

export default {
  name: 'PaymentsListPage',
  mixins: [actsAsAListPage],
  components: { PaymentsList, ConfirmDialog },
  data() {
    return {
      actsAsAListPage: {
        resource: 'payments',
        get: GetPayments,
        delete: DeletePayment
      }
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
