import Base from './Base'
import Edition from '@/domain/entities/Edition'
import ContractFormatter from './Contract'
import ProductFormatter from './Product'
import LecturerFormatter from './Lecturer'
import Decimal from '@/lib/Decimal'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null

    const entity = this.fieldNamesFromResponse(resource)

    switch (entity.genericProductType) {
      case 'contract':
        entity.genericProduct = ContractFormatter.fromResponseResource(
          resource.genericProduct
        )
        break
      case 'product':
        entity.genericProduct = ProductFormatter.fromResponseResource(
          resource.genericProduct
        )
        break
    }
    entity.lecturers = LecturerFormatter.fromResponseResourcesList(
      resource.lecturers
    )
    entity.price = Decimal.fromBE(entity.price).forUI()

    return new Edition(entity)
  }

  static toRequestResource(entity) {
    if (!entity) return null

    const resource = this.fieldNamesForRequest(entity)

    resource.genericProduct = this.toResourceIdObject(entity.genericProduct)

    resource.lecturers = LecturerFormatter.toRequestResourcesList(
      resource.lecturers
    )
    resource.editionPrice = Decimal.fromUI(entity.price).forBE()
    ;[
      ('durationDays', 'durationHours', 'maxAttendees', 'minAttendees')
    ].forEach(field => {
      if (resource[field] && isNaN(resource[field])) resource[field] = null
    })

    return resource
  }

  /// Note: this is the new format for errors.
  /// TODO: remove when this is standardized in the BE.
  static fromResponseErrors(errors) {
    const fields = this.fieldNamesFromResponse(errors.edition || errors, true)
    const { fromDate, toDate } = fields

    if (fromDate && fromDate.startsWith('invalid')) fields.fromDate = 'invalid'
    if (toDate && toDate.startsWith('invalid')) fields.toDate = 'invalid'

    return {
      fields,
      extraMessages: []
    }
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    if (!resource) return null

    const {
      id,
      genericProduct,
      genericProductType,
      courseStartDate,
      courseEndDate,
      durationDays,
      durationHours,
      flagActive,
      lecturers,
      flagShowOnCalendar,
      editionPrice,
      place,
      maxAttendees,
      minAttendees,
      location,
      language,
      notes
    } = resource

    const result = {
      id: id,
      genericProduct,
      genericProductType,
      fromDate: courseStartDate,
      toDate: courseEndDate,
      active: flagActive,
      durationDays,
      durationHours,
      lecturers,
      showOnCalendar: flagShowOnCalendar,
      price: editionPrice,
      place: place,
      placeDescription: location,
      maxAttendees,
      minAttendees,
      language,
      notes
    }

    this.formatInternalValuesFromResponse(resource, result)

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(edition, removeEmpty = false) {
    if (!edition) return null

    const {
      id,
      durationDays,
      durationHours,
      genericProduct,
      genericProductType,
      fromDate,
      toDate,
      active,
      showOnCalendar,
      price,
      place,
      placeDescription,
      language,
      minAttendees,
      maxAttendees,
      notes,
      lecturers
    } = edition

    const result = {
      id: id,
      durationDays,
      durationHours,
      flagActive: active,
      genericProduct,
      genericProductType,
      courseStartDate: fromDate,
      courseEndDate: toDate,
      flagShowOnCalendar: showOnCalendar,
      editionPrice: price,
      place: place,
      location: placeDescription,
      language: language,
      minAttendees,
      maxAttendees,
      notes: notes,
      lecturers
    }

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }
}
