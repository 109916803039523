<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12" lg="9">
        <material-card
          icon="mdi-package-variant"
          icon-small
          :title="$t('common.sections.titles.job')"
          color="primary"
        >
          <JobForm ref="form" v-model="entity" :errors="errors" />
        </material-card>
      </v-col>
      <v-col cols="12" lg="3" order="first" order-lg="last">
        <JobSummary :entity="entity">
          <v-divider />
          <template #controls>
            <v-row>
              <v-col cols="3" md="6">
                <v-btn color="success" width="100%" @click="save()">
                  {{ $t('form.customers.new.submit') }}
                </v-btn>
              </v-col>
              <v-col cols="3" md="6">
                <v-btn color="warning" width="100%" @click="leave()">
                  {{ $t('common.leave') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </JobSummary>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsACreatePage from '@/mixins/actsAsACreatePage'

import JobForm from '@/components/jobs/JobForm.vue'
import JobSummary from '@/components/jobs/JobSummary.vue'

import Job from '@/domain/entities/Job'
import CreateJob from '@/domain/useCases/jobs/CreateJob'

export default {
  name: 'NewJobPage',
  mixins: [actsAsACreatePage],
  components: { JobForm, JobSummary },
  data() {
    return {
      actsAsACreatePage: {
        entity: 'job',
        resource: 'jobs',
        create: CreateJob
      },
      entity: Job.default()
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
