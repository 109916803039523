import { dig } from '@/lib/Utils'

import Base from './Base'
import Purchase from '@/domain/entities/Purchase'
import Decimal from '@/lib/Decimal'

import OfferFormatter from './Offer'
import InvoiceFormatter from './Invoice'
import PersonAndCompanyFormatter from './PersonAndCompany'
import PurchaseSourceFormatter from './PurchaseSource'
import PurchaseTermFormatter from './PurchaseTerm'
import PaymentSourceFormatter from './PaymentSource'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null
    const entity = this.fieldNamesFromResponse(resource)

    entity.invoice = InvoiceFormatter.fromResponseIdObject(entity.invoice)
    entity.customer = PersonAndCompanyFormatter.fromResponseResource(
      entity.customer
    )
    entity.customer2 = PersonAndCompanyFormatter.fromResponseResource(
      entity.customer2
    )
    entity.beneficiary = PersonAndCompanyFormatter.fromResponseResource(
      entity.beneficiary
    )
    entity.offer = OfferFormatter.fromResponseResource(entity.offer)
    entity.purchaseSource = PurchaseSourceFormatter.fromResponseResource(
      entity.purchaseSource
    )
    entity.purchaseTerm = PurchaseTermFormatter.fromResponseResource(
      entity.purchaseTerm
    )
    entity.paymentSource = PaymentSourceFormatter.fromResponseResource(
      entity.paymentSource
    )

    entity.finalPrice = Decimal.fromBE(entity.finalPrice).forUI()
    entity.finalDiscount = Decimal.fromBE(entity.finalDiscount).forUI()
    entity.vatPercent = Decimal.fromBE(entity.vatPercent).forUI()
    entity.vatAmount = Decimal.fromBE(entity.vatAmount).forUI()

    let signatureFile = entity.signatureFile
    if (signatureFile && process.env.VUE_APP_TEST)
      signatureFile = `http://localhost/${resource.signatureFile}`
    entity.signatureFile = signatureFile

    return new Purchase(entity)
  }

  static toRequestResource(entity) {
    if (!entity) return null
    const resource = this.fieldNamesForRequest(entity)

    /// Never needed in request payloads
    delete resource.contractNumber
    delete resource.invoice
    ;[
      'customer',
      'contract',
      'customer2',
      'beneficiary',
      'offer',
      'purchasesource',
      'purchaseterm',
      'paymentsource'
    ].forEach(field => {
      const objectWithIdOnly = Base.toResourceIdObject(resource[field])
      if (objectWithIdOnly) resource[field] = objectWithIdOnly
    })

    resource.finalPrice = Decimal.fromUI(resource.finalPrice).forBE()
    resource.finalDiscount = Decimal.fromUI(resource.finalDiscount).forBE()
    resource.vatPerc = Decimal.fromUI(resource.vatPerc).forBE()
    resource.vatAmount = Decimal.fromUI(resource.vatAmount).forBE()

    return resource
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    if (!resource) return null
    let {
      id,
      invoPurchase,
      customer,
      custContract, // NOTE: this is a CustContract object, but we will just get the attached contract for now
      customer2,
      beneficiary,
      offer,
      quantity,
      purchaseSource,
      purchaseTerm,
      paymentSource,
      //sellerLine, // TODO: needs to be managed as Entity

      contractNumber,
      purchaseDate,
      purchaseStatus,
      finalDiscount,
      finalPrice,
      vatAmount,
      vatPerc,
      flagMandatorySignature,
      flagSigned,
      signatureFile,
      numberOfInstallments,
      paidInstallments,
      notes
    } = resource

    /// TODO: confirm custContract.contract is all we need
    let contract = dig(custContract, 'contract')

    const result = {
      id,
      invoice: invoPurchase,
      customer,
      contract,
      customer2,
      beneficiary,
      offer,
      quantity,
      purchaseSource,
      purchaseTerm,
      paymentSource,
      //sellerLine, // TODO: needs to be managed as Entity

      contractNumber,
      purchaseDate,
      purchaseStatus,
      finalDiscount,
      finalPrice,
      vatAmount,
      vatPercent: vatPerc,
      signatureRequired: flagMandatorySignature,
      signed: flagSigned,
      signatureFile,
      numberOfInstallments,
      paidInstallments,
      notes
    }

    this.formatInternalValuesFromResponse(resource, result)

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(entity, removeEmpty = false) {
    if (!entity) return null

    let {
      id,
      invoice, // Should never be sent except for search filters
      customer,
      contract,
      customer2,
      beneficiary,
      offer,
      quantity,
      //sellerLine, // TODO: needs to be managed as Entity
      purchaseSource,
      purchaseTerm,
      paymentSource,

      contractNumber, // This is generated by the BE, only used for search filters
      purchaseDate,
      purchaseStatus,
      finalDiscount,
      finalPrice,
      vatAmount,
      vatPercent,
      signatureRequired,
      signed,
      //signatureFile, // This is handled sepearately
      numberOfInstallments,
      paidInstallments,
      notes
    } = entity

    const result = {
      id,
      invoice,
      customer,
      contract,
      customer2,
      beneficiary,
      offer,
      quantity,
      //sellerLine, // TODO: needs to be managed as Entity
      purchaseSource,
      purchaseTerm,
      paymentSource,

      contractNumber,
      purchaseDate,
      purchaseStatus,
      finalDiscount,
      finalPrice,
      vatAmount,
      vatPerc: vatPercent,
      flagMandatorySignature: signatureRequired,
      flagSigned: signed,
      //signatureFile, // This is handled sepearately
      numberOfInstallments,
      paidInstallments,
      notes
    }

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }
}
