import axios from '@/axios.js'

import CostCenterFormatter from '../formatters/CostCenter'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, id) {
  try {
    const { data } = await axios.get(`/api/v1/costCenters/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    })

    const costCenter = CostCenterFormatter.fromResponseResource(data.costCenter)

    return { error: false, costCenter }
  } catch (e) {
    return exceptionToResponse(e)
  }
}
