<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12" lg="9">
        <material-card
          icon="mdi-cart"
          icon-small
          :title="$t('common.sections.titles.offer')"
          color="primary"
        >
          <OfferForm ref="form" v-model="entity" :errors="errors" />

          <v-divider />

          <v-container>
            <OfferEditionsForm
              v-if="entity.editions"
              ref="offerEditionsForm"
              v-model="entity"
              :errors="errors.editions"
            />
          </v-container>

          <v-divider />

          <v-container>
            <OfferPricesForm
              v-if="entity"
              ref="offerPricesForm"
              v-model="entity"
              :errors="errors"
            />
          </v-container>
        </material-card>
      </v-col>
      <v-col cols="12" lg="3" order="first" order-lg="last">
        <OfferSummary :entity="entity">
          <v-divider />
          <template #controls>
            <v-row>
              <v-col cols="3" md="6">
                <v-btn color="success" width="100%" @click="save()">
                  {{ $t('form.customers.new.submit') }}
                </v-btn>
              </v-col>
              <v-col cols="3" md="6">
                <v-btn color="warning" width="100%" @click="leave()">
                  {{ $t('common.leave') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </OfferSummary>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsACreatePage from '@/mixins/actsAsACreatePage'

import OfferForm from '@/components/offers/OfferForm.vue'
import OfferPricesForm from '@/components/offers/OfferPricesForm.vue'
import OfferEditionsForm from '@/components/offers/OfferEditionsForm.vue'
import OfferSummary from '@/components/offers/OfferSummary.vue'

import Offer from '@/domain/entities/Offer'
import CreateOffer from '@/domain/useCases/offers/CreateOffer'

export default {
  name: 'NewOfferPage',
  mixins: [actsAsACreatePage],
  components: { OfferForm, OfferPricesForm, OfferEditionsForm, OfferSummary },
  data() {
    return {
      actsAsACreatePage: {
        entity: 'offer',
        resource: 'offers',
        create: CreateOffer,
        validate: ['form', 'offerEditionsForm', 'offerPricesForm']
      },
      entity: Offer.default()
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
