import Decimal from '@/lib/Decimal'

export default class {
  static new() {
    return new this()
  }

  static default() {
    return new this()
  }

  constructor({
    id = null,
    active = null,
    code = null,
    version = null,
    type = null,
    name = null,
    description = null,
    validFrom = null,
    validTo = null,
    price = null,
    discount = null, // This is a percentage discount
    duration = null,
    autoRenewal = null,
    renewalStep = null,
    pdf = null,
    details = [], // Array of ContractDetail

    creationDate = null,
    startDate = null,
    lastModified = null,
    lastModifiedBy = null
  } = {}) {
    this.id = id
    this.active = active
    this.code = code
    this.version = version
    this.type = type
    this.name = name
    this.description = description
    this.validFrom = validFrom
    this.validTo = validTo
    this.price = price
    this.discount = discount
    this.duration = duration
    this.autoRenewal = autoRenewal
    this.renewalStep = renewalStep
    this.pdf = pdf
    this.details = details

    this.creationDate = creationDate
    this.startDate = startDate
    this.lastModified = lastModified
    this.lastModifiedBy = lastModifiedBy
  }

  calculateDiscount(price) {
    let result = Decimal.fromUI(price)
    const percentage = Decimal.fromUI(this.discount)

    if (!result || !percentage) return Decimal.zeroForUI()
    return result.percentage(percentage).forUI()
  }

  calculateDiscountedPrice(price) {
    return Decimal.fromUI(price)
      .subtract(Decimal.fromUI(this.calculateDiscount(price)))
      .forUI()
  }
}
