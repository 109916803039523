import axios from '@/axios.js'
import Error from '@/lib/Error'

import ContractFormatter from '../formatters/Contract'
import resourceErrorsToResponse from '../formatters/resourceErrorsToResponse'
import exceptionToResponse from '../formatters/exceptionToResponse'

import { isFile } from '@/lib/Utils'
import updateSignatureFile from './signatureFile/update'
import deleteSignatureFile from './signatureFile/delete'

export default async function(token, entity) {
  const signFile = entity.pdf
  const payload = { contract: ContractFormatter.toRequestResource(entity) }

  if (!payload.contract) {
    return new Error({ message: 'errors.resource.invalid' })
  }

  try {
    const { data } = await axios.post('/api/v1/contracts', payload, {
      headers: { Authorization: `Bearer ${token}` }
    })

    const contract = ContractFormatter.fromResponseResource(data.contract)
    const contractId = contract.id

    if (!signFile || isFile(signFile)) {
      deleteSignatureFile(token, contractId)

      if (signFile) {
        const uploadResponse = await updateSignatureFile(
          token,
          contractId,
          signFile
        )

        if (uploadResponse.error) {
          uploadResponse.message = 'entities.contract.error.sign-document'
          return uploadResponse
        } else {
          contract.signFile = uploadResponse.signFile
        }
      }
    }

    return { error: false, contract }
  } catch (e) {
    return (
      resourceErrorsToResponse(e, ContractFormatter, 'contracts') ||
      exceptionToResponse(e)
    )
  }
}
