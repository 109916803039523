<template>
  <v-container id="editions-list" fluid tag="section">
    <material-card
      icon="mdi-calendar"
      icon-small
      :title="$t('list.editions.title')"
      color="primary"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :footer-props="footerOptions"
        :server-items-length="totalItems"
        :loading="loading"
        :no-data-text="$t('common.list.no-data')"
      >
        <template v-slot:top>
          <v-container>
            <v-row dense>
              <v-col cols="11">
                <Searchbar @submit="searchbarSubmitted" />
              </v-col>
              <v-col cols="1" class="pt-0 pl-5">
                <RoundButton
                  icon="mdi-plus"
                  :alt="$t('list.editions.new')"
                  @click="$emit('goto-new')"
                />
              </v-col>
            </v-row>
          </v-container>
        </template>

        <template v-slot:[`item.genericProductType`]="{ item }">
          {{ translateGenericProductType(item) }}
        </template>

        <template v-slot:[`item.genericProduct.category`]="{ item }">
          <span v-if="item.genericProduct">
            {{ translateCategory(item) }}
          </span>
        </template>

        <template v-slot:[`item.genericProduct.type`]="{ item }">
          <span v-if="item.genericProduct">
            {{ translateType(item) }}
          </span>
        </template>

        <template v-slot:[`item.fromDate`]="{ item }">
          {{ item.fromDate | date }}
        </template>

        <template v-slot:[`item.toDate`]="{ item }">
          {{ item.toDate | date }}
        </template>

        <template v-slot:[`item.price`]="{ item }">
          {{ item.price | append('€') }}
        </template>

        <template v-slot:[`item.creationDate`]="{ item }">
          <small>{{ item.creationDate | dateTime }}</small>
        </template>

        <template v-slot:[`item.lastModified`]="{ item }">
          <small>{{ item.lastModified | dateTime }}</small>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="actions">
            <v-icon
              class="mr-2 show-edition-button"
              @click="$emit('goto-show', item.id)"
              >mdi-eye</v-icon
            >
            <v-icon
              class="mr-2 edit-edition-button"
              @click="$emit('goto-edit', item.id)"
              >mdi-pencil</v-icon
            >
            <v-icon
              class="delete-edition-button"
              @click="$emit('delete', item.id)"
              >mdi-delete</v-icon
            >
          </div>
        </template>
      </v-data-table>
    </material-card>
  </v-container>
</template>

<script>
import actsAsAList from '@/mixins/actsAsAList'

import RoundButton from '@/components/common/RoundButton.vue'
import Searchbar from './EditionSearchbar.vue'

export default {
  name: 'EditionsList',
  mixins: [actsAsAList],
  components: { Searchbar, RoundButton },
  props: {
    loading: Boolean,
    items: Array,
    page: Number,
    totalItems: Number
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('entities.edition.genericProductType'),
          value: 'genericProductType'
        },
        {
          text: this.$t('entities.edition.genericProduct'),
          value: 'genericProduct.name'
        },
        {
          text: this.$t('entities.product.category'),
          value: 'genericProduct.category'
        },
        {
          text: this.$t('entities.product.type'),
          value: 'genericProduct.type'
        },
        { text: this.$t('entities.edition.language'), value: 'language' },
        { text: this.$t('entities.edition.price'), value: 'price' },
        {
          text: this.$t('entities.edition.fromDate'),
          value: 'fromDate'
        },
        {
          text: this.$t('entities.edition.toDate'),
          value: 'toDate'
        },
        {
          text: this.$t('entities.edition.placeDescription'),
          value: 'placeDescription'
        },
        {
          text: this.$t('entities.edition.place'),
          value: 'place'
        },
        {
          text: this.$t('common.fields.creationDate'),
          value: 'creationDate'
        },
        {
          text: this.$t('common.fields.lastModified'),
          value: 'lastModified'
        },
        { text: '', value: 'actions' }
      ]
    }
  },
  methods: {
    translateGenericProductType(item) {
      if (!item.genericProductType) return ''
      return this.$t(
        `entities.edition.genericProductTypes.${item.genericProductType}`
      )
    },
    translateCategory(item) {
      switch (item.genericProductType) {
        case 'product':
          if (item.genericProduct && item.genericProduct.category) {
            const code = item.genericProduct.category.code
            if (code) return this.$t(`entities.product.categories.${code}`)
          }
          break
      }
      return ''
    },
    translateType(item) {
      switch (item.genericProductType) {
        case 'product':
          if (item.genericProduct && item.genericProduct.type) {
            const code = item.genericProduct.type.code
            if (code) return this.$t(`entities.product.types.${code}`)
          }
          break

        case 'contract':
          if (item.genericProduct && item.genericProduct.type) {
            const code = item.genericProduct.type
            if (code) return this.$t(`entities.contract.types.${code}`)
          }
          break
      }
      return ''
    }
  }
}
</script>

<style scoped>
.actions {
  min-width: 100px;
}
</style>
