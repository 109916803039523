import InvoiceBranch from '@/domain/entities/InvoiceBranch'
import Base from './Base'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null

    const entity = this.fieldNamesFromResponse(resource)
    return new InvoiceBranch(entity)
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    if (!resource) return null
    const { id, descr, notes } = resource
    const result = { id, description: descr, notes }
    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(entity, removeEmpty = false) {
    if (!entity) return null
    const { id, description, notes } = entity
    const result = { id, descr: description, notes }
    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }
}
