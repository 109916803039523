<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="3">
        <CardTextField
          :label="$t('entities.company.invoiceName')"
          :value="company.invoiceName"
        />
      </v-col>

      <v-col cols="12" lg="3">
        <CardTextField
          class="fiscal-code"
          :label="$t('entities.company.fiscalCode')"
          :value="company.fiscalCode"
        />
      </v-col>

      <v-col cols="12" lg="3">
        <CardTextField
          :label="$t('entities.company.vatCode')"
          :value="company.vatCode"
          outlined
          filled
        />
      </v-col>

      <v-col cols="12" lg="3">
        <CardTextField
          :label="$t('entities.company.iban')"
          :value="company.iban"
          outlined
          filled
        />
      </v-col>

      <v-col cols="12">
        <CardTextField
          :label="$t('entities.company.invoiceAddress')"
          :value="company.invoiceAddress"
          outlined
          filled
        />
      </v-col>

      <v-col cols="12" lg="2">
        <CardTextField
          :label="$t('entities.company.invoicePostalCode')"
          :value="company.invoicePostalCode"
        />
      </v-col>

      <v-col cols="12" lg="3">
        <CardTextField
          :label="$t('entities.company.invoiceCity')"
          :value="company.invoiceCity"
        />
      </v-col>

      <v-col cols="12" lg="2">
        <CardTextField
          :label="$t('entities.company.invoiceCounty')"
          :value="company.invoiceCounty"
          outlined
          filled
        />
      </v-col>

      <v-col cols="12" lg="3">
        <CardTextField
          :label="$t('entities.company.invoiceRegion')"
          :value="company.invoiceRegion"
        />
      </v-col>

      <v-col cols="12" lg="2">
        <CardTextField
          :value="company.invoiceNation"
          :label="$t('entities.company.invoiceNation')"
          filled
        />
      </v-col>

      <v-col cols="12" lg="3">
        <CardTextField
          :label="$t('entities.company.invoicePec')"
          :value="company.invoicePec"
          outlined
          filled
        />
      </v-col>
      <v-col cols="12" lg="3">
        <CardTextField
          :label="$t('entities.company.invoiceSdi')"
          :value="company.invoiceSdi"
        />
      </v-col>
      <v-col cols="12" lg="3">
        <CardTextField
          :label="$t('entities.company.invoiceCig')"
          :value="company.invoiceCig"
          :counter="10"
          outlined
          filled
        />
      </v-col>
      <v-col cols="12" lg="3">
        <CardTextField
          :label="$t('entities.company.invoiceCup')"
          :value="company.invoiceCup"
        />
      </v-col>

      <v-col cols="12" lg="4">
        <CardDateField
          :label="$t('entities.company.startDate')"
          :value="company.startDate"
        />
      </v-col>

      <v-col cols="12">
        <CardTextarea
          :label="$t('entities.company.notes')"
          :value="company.notes"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardTextField from '@/components/common/card/CardTextField.vue'
import CardDateField from '@/components/common/card/CardDateField.vue'
import CardTextarea from '@/components/common/card/CardTextarea.vue'

export default {
  name: 'CompanyCard',
  components: { CardTextField, CardTextarea, CardDateField },
  props: { company: Object }
}
</script>
