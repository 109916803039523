import {
  PurchasesListPage,
  NewPurchasePage,
  EditPurchasePage,
  ShowPurchasePage,
  NewPurchaseInvoicePage
} from '@/views/purchases/index.js'

export default [
  {
    path: '/purchases',
    name: 'PurchasesPage',
    component: PurchasesListPage,
    meta: {
      title: 'menu.long.resources.purchases'
    }
  },
  {
    path: '/purchases/new',
    name: 'NewPurchasePage',
    component: NewPurchasePage,
    meta: {
      title: 'menu.long.resources.new-purchase'
    }
  },
  {
    path: '/purchases/:id/edit',
    name: 'EditPurchasePage',
    component: EditPurchasePage,
    props: true,
    meta: {
      title: 'menu.long.resources.edit-purchase'
    }
  },
  {
    path: '/purchases/invoice/new',
    name: 'NewPurchaseInvoicePage',
    component: NewPurchaseInvoicePage,
    props: true,
    meta: {
      title: 'menu.long.resources.create-purchase-invoice'
    }
  },
  {
    path: '/purchases/:id',
    name: 'ShowPurchasePage',
    component: ShowPurchasePage,
    props: true,
    meta: {
      title: 'menu.long.resources.show-purchase'
    }
  }
]
