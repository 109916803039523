<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <CardTextField
          :label="$t('entities.purchase.offer')"
          :value="entity.offer | dig('name')"
        />
      </v-col>
      <v-col cols="12" lg="3">
        <CardTextField
          :label="$t('entities.purchase.quantity')"
          :value="entity.quantity"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <CardTextField
          :label="$t('entities.purchase.customer')"
          :value="formatPersonAndCompany(entity.customer)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="12">
        <CardTextField
          :label="$t('entities.purchase.customerContract')"
          :value="formatContract(entity.contract)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <CardTextField
          :label="$t('entities.purchase.beneficiary')"
          :value="formatPersonAndCompany(entity.beneficiary)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <CardTextField
          :label="$t('entities.purchase.customer2')"
          :value="formatPersonAndCompany(entity.customer2)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="3">
        <CardDateField
          :label="$t('entities.purchase.purchaseDate')"
          :value="entity.purchaseDate"
        />
      </v-col>
      <v-col cols="12" lg="3">
        <CardTextField
          :label="$t('entities.purchase.contractNumber')"
          :value="entity.contractNumber"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="3">
        <CardTextField
          :label="$t('entities.purchase.purchaseSource')"
          :value="entity.purchaseSource | dig('name')"
        />
      </v-col>
      <v-col cols="12" lg="3">
        <CardTextField
          :label="$t('entities.purchase.purchaseStatus')"
          :value="entity.purchaseStatus"
        />
      </v-col>
      <v-col cols="12" lg="3">
        <CardTextField
          :label="$t('entities.purchase.paymentSource')"
          :value="entity.paymentSource | dig('name')"
        />
      </v-col>
      <v-col cols="12" lg="3">
        <CardTextField
          :label="$t('entities.purchase.purchaseTerm')"
          :value="entity.purchaseTerm | dig('code')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="2">
        <CardTextField
          type="currency"
          :label="$t('entities.purchase.finalPrice')"
          :value="entity.finalPrice"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <CardTextField
          type="currency"
          :label="$t('entities.purchase.finalDiscount')"
          :value="entity.finalDiscount"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <CardTextField
          type="currency"
          :label="$t('entities.purchase.untaxedPrice')"
          :value="untaxedPrice"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <CardTextField
          type="percentage"
          :label="$t('entities.purchase.vatPercent')"
          :value="entity.vatPercent"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <CardTextField
          type="currency"
          :label="$t('entities.purchase.vatAmount')"
          :value="entity.vatAmount"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <CardTextField
          type="currency"
          :label="$t('entities.purchase.taxedPrice')"
          :value="taxedPrice"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="2">
        <CardTextField
          :label="$t('entities.purchase.numberOfInstallments')"
          :value="entity.numberOfInstallments"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <CardTextField
          type="number"
          :label="$t('entities.purchase.paidInstallments')"
          :value="entity.paidInstallments"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="4">
        <CardYesNoField
          :label="$t('entities.purchase.signatureRequired')"
          :value="entity.signatureRequired"
        />
      </v-col>
      <v-col cols="12" lg="4">
        <CardYesNoField
          :label="$t('entities.purchase.signed')"
          :value="entity.signed"
        />
      </v-col>
      <v-col cols="12" lg="4">
        <CardFileField
          :label="$t('entities.purchase.signatureFile')"
          :value="entity.signatureFile"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <CardTextarea
          :label="$t('entities.purchase.notes')"
          :value="entity.notes"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  CardDateField,
  CardTextField,
  CardTextarea,
  CardYesNoField,
  CardFileField
} from '@/components/common/card'

import Purchase from '@/domain/entities/Purchase'
import Decimal from '@/lib/Decimal'
import { formatPersonAndCompany } from '@/adapters/backoffice/formatters/interface/Common'

export default {
  name: 'PurchaseCard',
  components: {
    CardDateField,
    CardTextField,
    CardTextarea,
    CardYesNoField,
    CardFileField
  },
  props: { entity: Purchase },
  computed: {
    untaxedPrice() {
      if (!this.entity || !this.entity.finalPrice) return null

      const { finalPrice, finalDiscount } = this.entity

      let result = Decimal.fromUI(finalPrice)
      const discount = Decimal.fromUI(finalDiscount)

      return result.subtract(discount).forUI()
    },
    taxedPrice() {
      if (!this.entity || !this.entity.finalPrice) return null

      let result = Decimal.fromUI(this.untaxedPrice)
      const vat = Decimal.fromUI(this.entity.vatAmount)

      return result.add(vat).forUI()
    }
  },
  methods: {
    formatPersonAndCompany,
    formatContract(contract) {
      if (!contract) return ''
      return contract.name
    }
  }
}
</script>
