<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12" lg="9">
        <material-card
          icon="mdi-file-document-multiple"
          icon-small
          color="primary"
          :title="$t('common.sections.titles.contract')"
        >
          <ContractCard :entity="entity" />
        </material-card>
      </v-col>
      <v-col cols="12" lg="3" order="first" order-lg="last">
        <ContractSummary :entity="entity">
          <v-divider />

          <SummaryCommandBanner
            icon="mdi-view-list"
            :text="$t('common.back-to-list')"
            @click="gotoList"
          />
          <SummaryCommandBanner
            icon="mdi-pencil"
            :text="$t('common.contract.edit')"
            @click="gotoEdit"
          />
        </ContractSummary>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsAShowPage from '@/mixins/actsAsAShowPage'

import ContractCard from '@/components/contracts/ContractCard'
import ContractSummary from '@/components/contracts/ContractSummary.vue'
import SummaryCommandBanner from '@/components/common/SummaryCommandBanner.vue'

import Contract from '@/domain/entities/Contract'
import GetContract from '@/domain/useCases/contracts/GetContract'

export default {
  name: 'ShowContractPage',
  mixins: [actsAsAShowPage],
  components: { ContractCard, ContractSummary, SummaryCommandBanner },
  props: {
    id: String
  },
  data() {
    return {
      actsAsAShowPage: {
        resource: 'contracts',
        entity: 'contract',
        get: GetContract
      },
      entity: Contract.default()
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
