import Decimal from '@/lib/Decimal'

export default class {
  static new() {
    return new this()
  }

  static default() {
    return new this({
      discountType: 'percentage',
      discountPercentage: '0'
    })
  }

  static fromContract(contract) {
    return new this({
      contract,
      discountPercentage: contract.discount,
      discountType: 'percentage'
    })
  }

  constructor({
    id = null,
    contract = null, // Contract object
    discountType = 'percentage', // One of "percentage", "value"
    discountPercentage = null,
    discountValue = null,
    notes = null,

    creationDate = null,
    startDate = null,
    lastModified = null,
    lastModifiedBy = null
  } = {}) {
    this.id = id
    this.contract = contract
    this.discountType = discountType
    this.discountPercentage = discountPercentage
    this.discountValue = discountValue
    this.notes = notes

    this.creationDate = creationDate
    this.startDate = startDate
    this.lastModified = lastModified
    this.lastModifiedBy = lastModifiedBy
  }

  calculateDiscount(price) {
    const zero = Decimal.zeroForUI()
    let result = Decimal.fromUI(price)

    if (!result) return zero

    switch (this.discountType) {
      case 'percentage':
        if (this.discountPercentage)
          return result
            .percentage(Decimal.fromUI(this.discountPercentage))
            .forUI()
        break

      case 'value':
        if (this.discountValue) return this.discountValue
        break
    }

    return zero
  }
}
