import axios from '@/axios.js'

import CustomerContractFormatter from '../formatters/CustomerContract'
import resourceErrorsToResponse from '../formatters/resourceErrorsToResponse'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, id) {
  const headers = { Authorization: `Bearer ${token}` }
  const payload = { purchase: { id } }

  try {
    const { data } = await axios.post(
      `/api/v1/custContracts/getForm`,
      payload,
      { headers }
    )

    const customerContract = CustomerContractFormatter.fromResponseResource(
      data.custContract
    )

    return { error: false, customerContract }
  } catch (e) {
    return (
      resourceErrorsToResponse(e, CustomerContractFormatter, 'custContract') ||
      exceptionToResponse(e)
    )
  }
}
