<template>
  <v-form ref="form" @submit.prevent="submit">
    <v-container>
      <v-row>
        <v-col cols="12" lg="12">
          <v-btn
            small
            v-if="!entity.id"
            color="primary"
            @click="fillEmptyFieldsWithPlaceholders"
          >
            {{ $t('common.fill-unknown') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="3">
          <TextField
            :label="$t('entities.company.invoiceName')"
            v-model="entity.invoiceName"
            required
            :error="errors.invoiceName"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <TextField
            type="fiscal-code"
            :label="$t('entities.company.fiscalCode')"
            v-model="entity.fiscalCode"
            :error="errors.fiscalCode"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <TextField
            :label="$t('entities.company.vatCode')"
            v-model="entity.vatCode"
            :error="errors.vatCode"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <TextField
            :label="$t('entities.company.iban')"
            v-model="entity.iban"
            :required="isSupplier"
            :error="errors.iban"
          />
        </v-col>

        <v-col cols="12">
          <TextField
            :label="$t('entities.company.invoiceAddress')"
            v-model="entity.invoiceAddress"
            required
            :error="errors.invoiceAddress"
          />
        </v-col>

        <v-col cols="12" lg="2">
          <TextField
            :label="$t('entities.company.invoicePostalCode')"
            v-model="entity.invoicePostalCode"
            required
            :error="errors.invoicePostalCode"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <TextField
            :label="$t('entities.company.invoiceCity')"
            v-model="entity.invoiceCity"
            required
            :error="errors.invoiceCity"
          />
        </v-col>

        <v-col cols="12" lg="2">
          <TextField
            :label="$t('entities.company.invoiceCounty')"
            v-model="entity.invoiceCounty"
            required
            :error="errors.invoiceCounty"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <TextField
            :label="$t('entities.company.invoiceRegion')"
            v-model="entity.invoiceRegion"
            :error="errors.invoiceRegion"
          />
        </v-col>

        <v-col cols="12" lg="2">
          <NationSelect
            :label="$t('entities.company.invoiceNation')"
            v-model="entity.invoiceNation"
            required
          ></NationSelect>
        </v-col>

        <v-col cols="12" lg="3">
          <TextField
            type="email"
            :label="$t('entities.company.invoicePec')"
            v-model="entity.invoicePec"
            :error="errors.invoicePec"
          />
        </v-col>
        <v-col cols="12" lg="3">
          <TextField
            :label="$t('entities.company.invoiceSdi')"
            v-model="entity.invoiceSdi"
            :rules="[validates.longExactly(7)]"
            :counter="7"
            :error="errors.invoiceSdi"
          />
        </v-col>
        <v-col cols="12" lg="3">
          <TextField
            :label="$t('entities.company.invoiceCig')"
            v-model="entity.invoiceCig"
            :rules="[validates.longExactly(10)]"
            :counter="10"
            :error="errors.invoiceCig"
          />
        </v-col>
        <v-col cols="12" lg="3">
          <TextField
            :label="$t('entities.company.invoiceCup')"
            v-model="entity.invoiceCup"
            :rules="[validates.longExactly(15)]"
            :counter="15"
            :error="errors.invoiceCup"
          />
        </v-col>

        <v-col cols="12">
          <Textarea
            :label="$t('entities.company.notes')"
            v-model="entity.notes"
            rows="3"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import actsAsAFormWithPlaceholders from '@/mixins/actsAsAFormWithPlaceholders'

import { TextField, Textarea } from '@/components/common/form'
import NationSelect from '@/components/common/NationSelect.vue'

import Company from '@/domain/entities/Company'
import GetCompanyPlaceholders from '@/domain/useCases/companies/GetCompanyPlaceholders'
import validators from '@/domain/validations/rules'

export default {
  name: 'CompanyForm',
  mixins: [actsAsAFormWithPlaceholders],
  components: {
    TextField,
    NationSelect,
    Textarea
  },
  props: {
    value: Company,
    as: String,
    errors: Object
  },
  data() {
    return {
      hasPlaceholders: { get: GetCompanyPlaceholders }
    }
  },
  computed: {
    validates() {
      return validators(this)
    },
    isSupplier() {
      return this.as === 'supplier'
    }
  }
}
</script>
