<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" lg="3">
          <DateField
            :label="$t('entities.company.startDate')"
            v-model="entity.startDate"
          />
        </v-col>
        <v-col cols="12" lg="3">
          <DateField
            :label="$t('entities.company.endDate')"
            v-model="entity.endDate"
          />
        </v-col>
      </v-row>
    </v-container>
    <RolesAndPositionForm v-model="entity" :as="as" />
    <CompanySelectOrCreate
      v-if="newItem"
      ref="form"
      :as="as"
      v-model="entity.company"
      :errors="companyErrors"
    />
    <CompanyForm
      v-else
      ref="form"
      :as="as"
      v-model="entity.company"
      :errors="companyErrors"
    />
  </div>
</template>

<script>
import actsAsAForm from '@/mixins/actsAsAForm'
import DateField from '@/components/common/form/DateField.vue'

import RolesAndPositionForm from '@/components/personCompanies/PersonCompanyRolesAndPositionForm.vue'
import CompanySelectOrCreate from '@/components/companies/CompanySelectOrCreate.vue'
import CompanyForm from '@/components/companies/CompanyForm.vue'

import PersonCompanies from '@/domain/entities/PersonCompanies'
import { dig } from '@/lib/Utils'

export default {
  name: 'PersonCompanyForm',
  mixins: [actsAsAForm],
  components: {
    DateField,
    RolesAndPositionForm,
    CompanySelectOrCreate,
    CompanyForm
  },
  props: {
    as: String,
    value: PersonCompanies,
    newItem: Boolean,
    errors: Object
  },
  computed: {
    companyErrors() {
      return dig(this.errors, 'company') || {}
    },
    icon() {
      return this.as === 'customer' ? 'mdi-cash-plus' : 'mdi-cash-minus'
    },
    companyPositions() {
      return [
        this.$t('entities.personCompany.positions.internal'),
        this.$t('entities.personCompany.positions.external')
      ]
    }
  }
}
</script>
