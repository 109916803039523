import axios from '@/axios.js'

import PersonAndCompaniesFormatter from '../formatters/PersonAndCompanies'
import resourceErrorsToResponse from '../formatters/resourceErrorsToResponse'
import exceptionToResponse from '../formatters/exceptionToResponse'

import updatePersonPhoto from '../person/photo/update'
import deletePersonPhoto from '../person/photo/delete'

export default async function(token, userId, entity) {
  const photo = entity.person.photo

  const headers = { Authorization: `Bearer ${token}` }
  const payload = {
    userCompanies: PersonAndCompaniesFormatter.toRequestResource(entity)
  }

  try {
    const { data } = await axios.put(
      `/api/v1/userCompanies/${userId}`,
      payload,
      { headers }
    )

    const customer = PersonAndCompaniesFormatter.fromResponseResource(data)
    const personId = customer.person.id

    if (!photo) {
      await deletePersonPhoto(token, personId)
      customer.person.photo = null
    } else if (typeof photo === 'object') {
      const { uri } = await updatePersonPhoto(token, personId, photo)
      customer.person.photo = uri
    }
    return { error: false, customer }
  } catch (e) {
    return (
      resourceErrorsToResponse(e, PersonAndCompaniesFormatter) ||
      exceptionToResponse(e)
    )
  }
}
