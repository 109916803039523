import Vue from 'vue'
import { i18n } from '@/plugins'

const yesNoFilter = function(value) {
  return value ? i18n.t('common.yes') : i18n.t('common.no')
}

Vue.filter('yesNo', yesNoFilter)

export default yesNoFilter
