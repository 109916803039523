import Base from './Base'
import ProductType from '@/domain/entities/ProductType'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null
    const entity = this.fieldNamesFromResponse(resource)
    return new ProductType(entity)
  }

  static toRequestResource(entity) {
    if (!entity) return null
    return this.fieldNamesForRequest(entity)
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    if (!resource) return null
    const { id, type } = resource
    const result = { id, code: type }
    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(entity, removeEmpty = false) {
    if (!entity) return null
    const { id, code } = entity
    const result = { id, type: code }
    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }
}
