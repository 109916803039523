import validateUser from '@/lib/validateUser'
import Error from '@/lib/Error'
import offerCreateAdapter from '@/adapters/backoffice/offers/create'

export default class {
  user = null
  offer = null

  constructor(user, offer) {
    this.user = user
    this.offer = offer
  }

  async call() {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    if (!this.offer) return new Error({ message: 'errors.resource.invalid' })

    if (this.offer.id) return new Error({ message: 'errors.resource.existing' })

    return await offerCreateAdapter(this.user.token, this.offer)
  }
}
