import axios from '@/axios.js'

import PaymentFormatter from '../formatters/Payment'
import PaymentJobDetailsFormatter from '../formatters/PaymentJobDetail'
import resourceErrorsToResponse from '../formatters/resourceErrorsToResponse'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, id, data) {
  const payload = {
    paymentJobDetRels: PaymentJobDetailsFormatter.toRequestResourcesList(
      data.jobDetails
    )
  }

  if (!payload || !id) {
    return {
      error: true,
      errorData: { message: 'errors.resource.invalid' }
    }
  }

  try {
    const { data } = await axios.put(`/api/v1/payments/${id}/jobs`, payload, {
      headers: { Authorization: `Bearer ${token}` }
    })

    const payment = PaymentFormatter.fromResponseResource(data.payment)
    return { error: false, payment }
  } catch (e) {
    return (
      resourceErrorsToResponse(e, PaymentFormatter) || exceptionToResponse(e)
    )
  }
}
