import validateUser from '@/lib/validateUser'
import Error from '@/lib/Error'
import purchaseUpdateAdapter from '@/adapters/backoffice/purchases/update'

export default class {
  user = null
  id = null
  purchase = null
  signedFileUpload = null

  constructor(user, id, purchase, signedFileUpload) {
    this.user = user
    this.id = id
    this.purchase = purchase
    this.signedFileUpload = signedFileUpload
  }

  async call() {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    if (!this.purchase || !this.id)
      return new Error({ message: 'errors.resource.invalid' })

    return await purchaseUpdateAdapter(
      this.user.token,
      this.id,
      this.purchase,
      this.signedFileUpload
    )
  }
}
