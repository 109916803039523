import validateUser from '@/lib/validateUser'
import Error from '@/lib/Error'
import paymentUpdateAdapter from '@/adapters/backoffice/payments/update'

export default class {
  user = null
  id = null
  payment = null

  constructor(user, id, payment) {
    this.user = user
    this.id = id
    this.payment = payment
  }

  async call() {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    if (!this.payment || !this.id)
      return new Error({ message: 'errors.resource.invalid' })

    return await paymentUpdateAdapter(this.user.token, this.id, this.payment)
  }
}
