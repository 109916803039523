<template>
  <v-autocomplete
    :class="_class"
    :label="label"
    :items="contractReasons"
    :clearable="clearable"
    v-model="contractReason"
    return-object
    item-text="name"
    @change="changed"
    :no-data-text="$t('common.autocomplete.no-data')"
    :rules="_rules"
    :required="required"
    :outlined="readonly || outlined"
    :filled="readonly"
    :hide-details="hideDetails"
    dense
  >
    <template v-slot:selection="data">
      {{ data.item.name }}
    </template>

    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>
          {{ data.item.name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ data.item.description }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { get } from 'vuex-pathify'
import validators from '@/domain/validations/rules'

import GetContractReasons from '@/domain/useCases/customerContracts/GetContractReasons'

export default {
  name: 'ContractReasonSelect',
  props: {
    label: String,
    value: Object,
    rules: Array,
    required: Boolean,
    readonly: Boolean,
    outlined: Boolean,
    hideDetails: Boolean,
    clearable: Boolean
  },
  computed: {
    user: get('user'),
    validates() {
      return validators(this)
    },
    _class() {
      return this.required ? 'required' : ''
    },
    _rules() {
      const _rules = this.rules || []
      if (this.required) _rules.push(this.validates.presence)
      return _rules
    }
  },
  data() {
    return {
      contractReason: this.value,
      contractReasons: []
    }
  },
  methods: {
    changed() {
      this.$emit('input', this.contractReason)
    }
  },
  created() {
    new GetContractReasons(this.user).call().then(result => {
      if (result.error) {
        new Error({
          message: 'common.autocomplete.error-loading'
        }).toAlert()
        console.info(`Error loading contract reasons for autocomplete:`, result)
      } else this.contractReasons = result.contractReasons
    })
  },
  watch: {
    value() {
      this.contractReason = this.value
    }
  }
}
</script>
