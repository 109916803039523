<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="2">
        <PersonCompanyPositionSelect
          :label="$t('entities.personCompany.position')"
          v-model="entity.position"
        />
      </v-col>
      <v-col cols="12" lg="10">
        <PersonCompanyRolesMultiselect
          :as="as"
          :label="$t('entities.personCompany.roles')"
          v-model="entity.roles"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsAForm from '@/mixins/actsAsAForm'

import PersonCompanyRolesMultiselect from '@/components/personCompanies/PersonCompanyRolesMultiselect.vue'
import PersonCompanyPositionSelect from '@/components/personCompanies/PersonCompanyPositionSelect.vue'

import PersonCompanies from '@/domain/entities/PersonCompanies'

export default {
  name: 'PersonCompanyRolesAndPositionForm',
  mixins: [actsAsAForm],
  props: { value: PersonCompanies, as: String },
  components: { PersonCompanyRolesMultiselect, PersonCompanyPositionSelect },
  methods: {
    validate() {
      return true
    }
  }
}
</script>
