<template>
  <div :class="_class">
    <div class="label">{{ label }}</div>
    <div>
      <div :class="_valueClasses" :alt="value" :title="value">
        {{ value }}{{ suffix }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardTextField',
  props: {
    label: String,
    value: null,
    textarea: Boolean,
    type: String,
    required: Boolean
  },
  computed: {
    _valueClasses() {
      const classes = ['value']
      if (!this.textarea) classes.push('hide-overflow')
      return classes.join(' ')
    },
    _class() {
      const classes = ['container']
      if (this.required) classes.push('required')
      return classes
    },
    suffix() {
      if (!this.value) return ''

      switch (String(this.type)) {
        case 'currency':
          return ' €'
        case 'percentage':
          return ' %'
      }

      return ''
    }
  }
}
</script>

<style scoped>
.container {
  margin-top: 4px;
  margin-bottom: 6px;
  position: relative;
  display: block;
  padding: 12px 4px 4px 8px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.38);
  border-radius: 4px;
}

.label {
  display: block;
  position: absolute;
  top: -10px;
  padding: 0 2px 0 2px;
  background-color: #fff;
  color: #000;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.value {
  color: #000;
  font-size: 16px;
  min-height: 24px;
}

.hide-overflow {
  overflow: hidden;
  overflow-wrap: break-work;
  word-break: normal;
  white-space: nowrap;
}
</style>
