import axios from '@/axios.js'

import InvoiceFormatter from '../formatters/Invoice'
import resourceErrorsToResponse from '../formatters/resourceErrorsToResponse'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, entity) {
  const headers = { Authorization: `Bearer ${token}` }
  const payload = { invoPurchase: InvoiceFormatter.toRequestResource(entity) }

  try {
    const { data } = await axios.post('/api/v1/invoPurchases', payload, {
      headers
    })

    const invoice = InvoiceFormatter.fromResponseResource(data.invoPurchase)

    return { error: false, invoice }
  } catch (e) {
    return (
      resourceErrorsToResponse(e, InvoiceFormatter, 'invoPurchase') ||
      exceptionToResponse(e)
    )
  }
}
