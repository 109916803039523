<template>
  <v-container id="dashboard" fluid tag="section">
    <material-card
      icon="mdi-card-account-details"
      icon-small
      title="Dashboard"
      color="primary"
    >
    </material-card>
  </v-container>
</template>

<script>
import { get } from 'vuex-pathify'

export default {
  name: 'DashboardPage',
  computed: {
    user: get('user')
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
