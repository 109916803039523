<template>
  <v-card>
    <v-card-title> {{ $t('entities.offer.editions') }} </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="entity.editions"
        hide-default-footer
        :no-data-text="$t('common.offer.editions.no-editions')"
      >
        <template v-slot:[`item.genericProductType`]="{ item }">
          {{ translateProductType(item.genericProductType) }}
        </template>

        <template v-slot:[`item.price`]="{ item }">
          {{ item.price | append('€') }}
        </template>

        <template v-slot:[`item.fromDate`]="{ item }">
          {{ item.fromDate | date }}
        </template>

        <template v-slot:[`item.toDate`]="{ item }">
          {{ item.toDate | date }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import Offer from '@/domain/entities/Offer'

export default {
  name: 'OfferEditionsCard',
  props: { entity: Offer },
  data() {
    return {
      headers: [
        {
          text: this.$t('entities.edition.genericProduct'),
          value: 'genericProduct.name'
        },
        {
          text: this.$t('entities.edition.genericProductType'),
          value: 'genericProductType'
        },
        { text: this.$t('entities.edition.price'), value: 'price' },
        { text: this.$t('entities.edition.fromDate'), value: 'fromDate' },
        { text: this.$t('entities.edition.toDate'), value: 'toDate' }
      ]
    }
  },
  methods: {
    translateProductType(type) {
      if (!type) return ''
      return this.$t(`entities.edition.genericProductTypes.${type}`)
    }
  }
}
</script>
