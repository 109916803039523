<template>
  <div>
    <CostCentersList
      :loading="loading"
      :items="items"
      :page="page"
      :totalItems="totalItems"
      @refresh="refresh"
      @delete="deleteEntity"
      @goto-new="gotoNew"
      @goto-edit="gotoEdit"
      @goto-show="gotoShow"
    />
    <v-dialog v-model="deleteDialog" max-width="400px">
      <ConfirmDialog
        :title="$t('common.costCenter.delete.title')"
        :message="$t('common.costCenter.delete.confirm-text')"
        :yesText="$t('common.delete.yes')"
        :noText="$t('common.delete.no')"
        @yesClick="deleteConfirmed"
        @noClick="deleteCanceled"
      />
    </v-dialog>
  </div>
</template>

<script>
import actsAsAListPage from '@/mixins/actsAsAListPage'

import CostCentersList from '@/components/costCenters/CostCentersList'
import ConfirmDialog from '@/components/common/ConfirmDialog.vue'

import GetCostCenters from '@/domain/useCases/costCenters/GetCostCenters'
import DeleteCostCenter from '@/domain/useCases/costCenters/DeleteCostCenter'

export default {
  name: 'CostCentersListPage',
  mixins: [actsAsAListPage],
  components: { CostCentersList, ConfirmDialog },
  data() {
    return {
      actsAsAListPage: {
        resource: 'costCenters',
        get: GetCostCenters,
        delete: DeleteCostCenter
      }
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
