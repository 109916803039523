import validateUser from '@/lib/validateUser'
import productPriceListAdapter from '@/adapters/backoffice/products/getPrices'

export default class {
  user = null

  constructor(user) {
    this.user = user
  }

  async call(productId) {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    return await productPriceListAdapter(this.user.token, productId)
  }
}
