<template>
  <v-simple-table class="table" dense>
    <template v-slot:default>
      <thead class="text-left">
        <th>
          {{ $t('entities.contract.detail.description') }}
        </th>
        <th>{{ $t('entities.contract.detail.value') }}</th>
        <th>{{ $t('entities.contract.detail.bonus') }}</th>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ item.description }}</td>
          <td class="value">{{ item.value }}</td>
          <td class="checkbox">{{ item.bonus | yesNo }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: 'ContractDetailsSimpleTable',
  props: { items: Array }
}
</script>

<style scoped>
td.value {
  width: 90px !important;
}
td.checkbox {
  width: 120px !important;
}
</style>
