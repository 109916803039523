<template>
  <AutocompleteField
    :class="_class"
    :label="_label"
    :value="value"
    return-object
    item-text="name"
    :items="items"
    :hint="hint"
    :no-data-text="$t('common.autocomplete.no-data')"
    :loading="loading"
    :rules="_rules"
    :required="required"
    :error="error"
    dense
    @input="onChange"
  />
</template>

<script>
import { get } from 'vuex-pathify'
import validators from '@/domain/validations/rules'

import { AutocompleteField } from '@/components/common/form'

import GetPaymentSources from '@/domain/useCases/purchases/GetPaymentSources'
import Error from '@/lib/Error'

export default {
  name: 'PaymentSourceSelect',
  components: { AutocompleteField },
  props: {
    label: String,
    value: Object,
    readonly: Boolean,
    hint: String,
    required: Boolean,
    error: String
  },
  data() {
    return {
      items: [],
      loading: true
    }
  },
  computed: {
    user: get('user'),
    _class() {
      return this.required ? 'required' : ''
    },
    _label() {
      return this.label || this.$t('entities.purchase.paymentSource')
    },
    _rules() {
      return this.required ? [validators(this).presence] : []
    }
  },
  methods: {
    onChange(newValue) {
      this.$emit('input', newValue)
    }
  },
  created() {
    new GetPaymentSources(this.user).call().then(result => {
      if (result.error) {
        new Error({
          message: 'common.autocomplete.error-loading'
        }).toAlert()
        console.info(`Error loading payment sources for autocomplete:`, result)
      } else {
        this.items = result.paymentSources || []
        this.loading = false
      }
    })
  }
}
</script>
