/**
 * Support for decimal values, operations and conversions.
 * Used mainly for values in Euros, where control on the precision are VERY
 * important.
 */
import currency from 'currency.js'
import { isObject } from '@/lib/Utils'

export default class Decimal {
  static optionsBE = { symbol: '', separator: '', decimal: '.' }
  static optionsUI = { symbol: '', separator: '.', decimal: ',' }

  raw = null
  value = null

  static isDecimal(value) {
    return isObject(value) && value instanceof Decimal
  }

  static zero() {
    return this.fromBE('0.00')
  }

  static zeroForUI() {
    return this.zero('0.00').forUI()
  }

  static sumFromUI(...values) {
    /// currency object
    const c = values.reduce((sum, value) => {
      return sum.add(this.fromUI(value))
    }, this.fromBE('0.00'))

    return c.forUI()
  }

  static isValid(value) {
    if (!value) return false
    return /^-?\d{1,3}(\.?\d{3})*(,\d{1,2})?$/.test(value)
  }

  static isValidBE(value) {
    if (!value) return false
    return /^-?\d+(\.\d+)?$/.test(value)
  }

  static fromUI(value) {
    if (!this.isValid(value)) return null

    const currencyValue =
      value !== null ? currency(value, this.optionsUI) : null
    return new Decimal(currencyValue, value)
  }

  static fromBE(value) {
    if (!this.isValidBE(value)) return null

    const currencyValue =
      value !== null ? currency(value, this.optionsBE) : null
    return new Decimal(currencyValue, value)
  }

  constructor(currency, raw) {
    this.value = currency !== null ? currency.format(Decimal.optionsBE) : null
    if (raw && String(raw).trim() !== '') this.raw = raw
  }

  getRaw() {
    return this.raw
  }

  forUI() {
    if (!this.value) return this.raw

    const value = currency(this.value).format(Decimal.optionsUI)
    if (Decimal.isValid(value)) return value
    else return this.raw
  }

  forBE() {
    if (!this.value) return this.raw

    const value = currency(this.value).format(Decimal.optionsBE)
    if (Decimal.isValidBE(value)) return value
    else return this.raw
  }

  compare(decimal) {
    this.validateDecimalArgument(decimal)

    const thisNumber = this.toNumber()
    const otherNumber = decimal.toNumber()

    switch (true) {
      case thisNumber == otherNumber:
        return 0
      case thisNumber > otherNumber:
        return 1
      default:
        return -1
    }
  }

  isPositive() {
    return this.isGreaterOrEqualTo(Decimal.zero())
  }

  isGreaterOrEqualTo(value) {
    return this.compare(value) > -1
  }

  add(decimal) {
    if (!decimal) return this
    this.validateDecimalArgument(decimal)

    const c = currency(this.value, Decimal.optionsBE).add(decimal.value)
    this.value = c.format()
    this.raw = this.value
    return this
  }

  subtract(decimal, { forcePositive = true } = {}) {
    if (!decimal) return this
    this.validateDecimalArgument(decimal)

    const c = currency(this.value, Decimal.optionsBE).subtract(decimal.value)
    this.value = c.format()

    if (forcePositive && this.value < 0)
      this.value = currency('0.00', Decimal.optionsBE).format()

    this.raw = this.value
    return this
  }

  multiply(decimal) {
    if (!decimal) return this
    this.validateDecimalArgument(decimal)

    const c = currency(this.value, Decimal.optionsBE).multiply(decimal.value)
    this.value = c.format()
    this.raw = this.value
    return this
  }

  percentage(decimal) {
    if (!decimal) return this
    this.validateDecimalArgument(decimal)

    const c = currency(this.value, Decimal.optionsBE)

    this.value = c
      .multiply(decimal.value)
      .divide('100.00')
      .format()
    this.raw = this.value

    return this
  }

  discount(decimal) {
    if (!decimal) return this
    this.validateDecimalArgument(decimal)

    const c = currency(this.value, Decimal.optionsBE)

    const discount = c.multiply(decimal.value).divide('100.00')
    this.value = c.subtract(discount).format()
    this.raw = this.value
    return this
  }

  tax(decimal) {
    if (!decimal) return this
    this.validateDecimalArgument(decimal)

    const c = currency(this.value, Decimal.optionsBE)

    const discount = c.multiply(decimal.value).divide('100.00')
    this.value = c.add(discount).format()
    this.raw = this.value
    return this
  }

  divide(decimal) {
    if (!decimal) return this
    this.validateDecimalArgument(decimal)

    const c = currency(this.value, Decimal.optionsBE)

    this.value = c.divide(decimal.value).format()
    this.raw = this.value
    return this
  }

  toNumber() {
    const c = currency(this.value, Decimal.optionsBE)
    return c.value
  }

  validateDecimalArgument(value) {
    if (!Decimal.isDecimal(value)) throw 'Decimal argument required'
  }
}
