<template>
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
        <tr>
          <th>{{ $t('entities.company.invoiceName') }}</th>
          <th>{{ $t('entities.company.startDate') }}</th>
          <th>{{ $t('entities.company.endDate') }}</th>
          <th>{{ $t('entities.company.fiscalCode') }}</th>
          <th>{{ $t('entities.company.vatCode') }}</th>
          <th>{{ $t('entities.personCompany.roles') }}</th>
          <th>{{ $t('entities.personCompany.position') }}</th>
          <th>{{ $t('entities.company.invoicePec') }}</th>
          <th>{{ $t('entities.company.invoiceSdi') }}</th>
          <th>{{ $t('entities.company.invoiceCig') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="personCompany in personCompanies"
          :key="personCompany.company.id"
        >
          <td>{{ personCompany.company.invoiceName }}</td>
          <td>{{ personCompany.startDate | date }}</td>
          <td>{{ personCompany.endDate | date }}</td>
          <td>{{ personCompany.company.fiscalCode }}</td>
          <td>{{ personCompany.company.vatCode }}</td>
          <td>{{ roles(personCompany) }}</td>
          <td>{{ position(personCompany) }}</td>
          <td>{{ personCompany.company.invoicePec }}</td>
          <td>{{ personCompany.company.invoiceSdi }}</td>
          <td>{{ personCompany.company.invoiceCig }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: 'PersonCompaniesSimpleTable',
  props: {
    personCompanies: Array
  },
  methods: {
    position(item) {
      if (!item.position) return ''
      return this.$t(`entities.personCompany.positions.${item.position}`)
    },
    roles(item) {
      return item.roles ? item.roles.map(r => r.role).join(',') : ''
    }
  }
}
</script>
