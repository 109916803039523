import validateUser from '@/lib/validateUser'
import contractCreateAdapter from '@/adapters/backoffice/contracts/create'

export default class {
  user = null
  contract = null

  constructor(user, contract) {
    this.user = user
    this.contract = contract
  }

  async call() {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    if (!this.contract)
      return {
        error: true,
        errorData: { message: 'errors.resource.invalid' }
      }

    if (this.contract.id)
      return {
        error: true,
        errorData: { message: 'errors.resource.existing' }
      }

    return await contractCreateAdapter(this.user.token, this.contract)
  }
}
