var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"customers-list","fluid":"","tag":"section"}},[_c('material-card',{attrs:{"icon":_vm.icon,"icon-small":"","title":_vm.translate('list'),"color":"primary"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"footer-props":_vm.footerOptions,"server-items-length":_vm.totalItems,"show-expand":"","expand-icon":"mdi-chevron-down","item-key":"person.id","loading":_vm.loading,"no-data-text":_vm.$t('common.list.no-data')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"11"}},[_c('Searchbar',{attrs:{"as":_vm.as},on:{"submit":_vm.searchbarSubmitted}})],1),_c('v-col',{staticClass:"pt-0 pl-5",attrs:{"cols":"1"}},[_c('RoundButton',{attrs:{"icon":"mdi-plus","alt":_vm.translate('new')},on:{"click":function($event){return _vm.$emit('goto-new')}}})],1)],1)],1)]},proxy:true},{key:"item.person.active",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("yesNo")(item.person.active))+" ")]}},{key:"item.company",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.firstPrimaryTypeCompanyName(item))+" ")]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.firstPrimaryTypeCompanyRoles(item))+" ")]}},{key:"item.person.email",fn:function(ref){
var item = ref.item;
return [(item.person.email)?_c('a',{attrs:{"href":("mailto:" + (item.person.email))}},[_vm._v(_vm._s(item.person.email))]):_vm._e()]}},{key:"item.person.mobile",fn:function(ref){
var item = ref.item;
return [(item.person.mobile)?_c('a',{attrs:{"href":("tel:" + (item.person.mobile))}},[_vm._v(_vm._s(item.person.mobile))]):_vm._e()]}},{key:"item.person.phone",fn:function(ref){
var item = ref.item;
return [(item.person.phone)?_c('a',{attrs:{"href":("tel:" + (item.person.phone))}},[_vm._v(_vm._s(item.person.phone))]):_vm._e()]}},{key:"item.creationDate",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("dateTime")(item.person.creationDate)))])]}},{key:"item.lastModified",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("dateTime")(item.person.lastModified)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions"},[_c('v-icon',{staticClass:"mr-1 show-customer-button",on:{"click":function($event){return _vm.$emit('goto-show', item.person.id)}}},[_vm._v("mdi-eye")]),_c('v-icon',{staticClass:"mr-1 edit-customer-button",on:{"click":function($event){return _vm.$emit('goto-edit', item.person.id)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{staticClass:"delete-customer-button",on:{"click":function($event){return _vm.$emit('delete', item.person.id)}}},[_vm._v("mdi-delete")])],1)]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"mb-3 mt-2 mx-2"},[_c('v-card-title',{staticClass:"pb-1"},[_vm._v(_vm._s(_vm.primaryTypeCompaniesTitle))]),_c('v-card-text',[_c('CompaniesSummary',{attrs:{"as":_vm.primaryType,"personCompanies":_vm.primaryTypeCompanies(item)}})],1),(_vm.secondaryTypeCompanies(item).length)?_c('v-card-title',{staticClass:"py-1"},[_vm._v(_vm._s(_vm.secondaryTypeCompaniesTitle))]):_vm._e(),(_vm.secondaryTypeCompanies(item).length)?_c('v-card-text',[_c('CompaniesSummary',{attrs:{"as":"secondaryType","personCompanies":_vm.secondaryTypeCompanies(item)}})],1):_vm._e()],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }