<template>
  <v-banner :icon-color="iconColor" class="text-body-2 text-left">
    <template v-slot:icon>
      <v-btn :color="iconColor" @click="$emit('click')">
        <v-icon>{{ icon }}</v-icon>
        <span v-if="appendText" class="text-body-1">{{ appendText }}</span>
      </v-btn>
    </template>
    {{ text }}
  </v-banner>
</template>

<script>
export default {
  name: 'SummaryCommandBanner',
  props: {
    icon: { type: String, required: true },
    iconColor: { type: String, default: 'primary' },
    appendText: { type: String, default: '' },
    text: { type: String, required: true }
  }
}
</script>
