<template>
  <v-container id="invoices-list" fluid tag="section">
    <material-card
      icon="mdi-receipt"
      icon-small
      :title="$t('list.invoices.title')"
      color="primary"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :footer-props="footerOptions"
        :server-items-length="totalItems"
        :loading="loading"
        :no-data-text="$t('common.list.no-data')"
      >
        <template v-slot:top>
          <v-container class="mx-1">
            <v-row dense>
              <v-col>
                <Searchbar @submit="searchbarSubmitted" />
              </v-col>
              <v-col cols="1" class="pt-0 pl-5"> </v-col>
            </v-row>
          </v-container>
        </template>

        <template v-slot:[`item.sdiStatus`]="{ item }">
          <div class="text-left">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-if="item.sdiNotSent()" color="warning" v-on="on"
                  >mdi-minus-circle</v-icon
                >
                <v-icon v-else-if="item.sdiPending()" color="warning" v-on="on"
                  >mdi-help-circle</v-icon
                >
                <v-icon v-else-if="item.sdiFailed()" color="error" v-on="on"
                  >mdi-close-circle</v-icon
                >
                <v-icon v-else color="success" v-on="on"
                  >mdi-checkbox-marked-circle</v-icon
                >
              </template>
              {{ invoiceStatusAsString(item) }}
            </v-tooltip>
          </div>
        </template>

        <template v-slot:[`item.branch`]="{ item }">
          <small>{{ item.branch | dig('description') }}</small>
        </template>

        <template v-slot:[`item.date`]="{ item }">
          <small>{{ item.date | date }}</small>
        </template>
        <template v-slot:[`item.dueDate`]="{ item }">
          <small>{{ item.dueDate | date }}</small>
        </template>
        <template v-slot:[`item.paymentDate`]="{ item }">
          <small>{{ item.paymentDate | date }}</small>
        </template>

        <template v-slot:[`item.creationDate`]="{ item }">
          <small>{{ item.creationDate | dateTime }}</small>
        </template>

        <template v-slot:[`item.lastModified`]="{ item }">
          <small>{{ item.lastModified | dateTime }}</small>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="actions">
            <v-icon
              class="mr-2 show-invoice-button"
              @click="$emit('goto-show', item.id)"
              >mdi-eye</v-icon
            >
          </div>
        </template>
      </v-data-table>
    </material-card>
  </v-container>
</template>

<script>
import actsAsAList from '@/mixins/actsAsAList'

import Searchbar from '@/components/invoices/InvoiceSearchbar.vue'

export default {
  name: 'InvoicesList',
  mixins: [actsAsAList],
  components: { Searchbar },
  props: {
    loading: Boolean,
    items: Array,
    page: Number,
    totalItems: Number
  },
  data() {
    return {
      options: { sortBy: ['code', 'version'], sortDesc: [true, true] },
      headers: [
        {
          text: this.$t('entities.invoice.sdiStatus'),
          value: 'sdiStatus'
        },
        {
          text: this.$t('entities.invoice.code'),
          value: 'code'
        },
        {
          text: this.$t('entities.invoice.version'),
          value: 'version'
        },
        {
          text: this.$t('entities.invoice.type'),
          value: 'type'
        },
        {
          text: this.$t('entities.invoice.branch'),
          value: 'branch'
        },
        {
          text: this.$t('entities.invoice.name'),
          value: 'name'
        },
        {
          text: this.$t('entities.invoice.date'),
          value: 'date'
        },
        {
          text: this.$t('entities.invoice.dueDate'),
          value: 'dueDate'
        },
        {
          text: this.$t('entities.invoice.paymentDate'),
          value: 'paymentDate'
        },
        {
          text: this.$t('common.fields.creationDate'),
          value: 'creationDate'
        },
        {
          text: this.$t('common.fields.lastModified'),
          value: 'lastModified'
        },

        { text: '', value: 'actions' }
      ]
    }
  },
  methods: {
    invoiceStatusAsString: function(invoice) {
      return this.$t('entities.invoice.statuses.' + invoice.statusAsString())
    }
  }
}
</script>

<style scoped>
.actions {
  min-width: 100px;
}
</style>
