import axios from '@/axios.js'

import CustomerContractFormatter from '../formatters/CustomerContract'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, id) {
  try {
    const { data } = await axios.get(`/api/v1/custContracts/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    })

    const customerContract = CustomerContractFormatter.fromResponseResource(
      data.custContract
    )

    return { error: false, customerContract }
  } catch (e) {
    return exceptionToResponse(e)
  }
}
