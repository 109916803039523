/**
 * Provides a standard behaviour for error handling in FE components and views.
 *
 * This is a parallel system to vuetify rules for form-field validation, and
 * needs to be supported in new components and use cases/adapters:
 *
 * - use-cases should return an Error (@/lib/Error) instance when en error occurs,
 *   if the error is about one or more fields, Error.fields should be filled accordingly.
 *
 * - views should pass data.errors to any included component that could show errors
 *   related to fields of an entity. This should be done with a `errors` prop;
 *
 * - components should pass the `errors` prop along nested components that could use it;
 *
 * - form components that represent an entity's field should receive that field
 *   specific error through the `error` prop;
 *
 * the data.errors value is an Object containing a subset of the tree-like view
 * of an Entity: only those paths that end in a field with an actual error will
 * be present in the object.
 *
 * Expects:
 *  - data.errors: an object, possibly nested, representing subset of an Entity
 *                 that ends in fields with errors.
 *                 It will be changed by methods in this mixin;
 *
 * Provides:
 *  - this.$_hasErrors(): returns true if data.errors is not empty;
 *
 *  - this.$_handleError(error): expects an Error argument; according to the structure
 *    of `error`, this method will do one or more of these things:
 *    - redirect to the login page (error.status === 401),
 *    - set a new value for data.errors,
 *    - prepare an alert to show to the user.
 *    - log error information in the console.
 *
 *  - this.$_clearErrors(): resets data.errors and the any related alert.
 *
 *  - this.backUrl: URL of the previous url visited.
 */
import { get } from 'vuex-pathify'

export default {
  computed: {
    backUrl: get('app/lastUrl')
  },
  methods: {
    $_hasErrors() {
      if (!this.errors) return false
      return Object.entries(this.errors).length > 0
    },
    $_handleError(error, goBack = false) {
      if (error.status && error.status === 401) {
        this.$router.push('/login', () =>
          this.$store.set('app/flash', error.toAlert())
        )
        return null
      }

      const showOnNextPage = goBack
      this.$store.set('app/flash', error.toAlert(showOnNextPage))

      if (goBack) {
        this.$router.push(this.backUrl)
        return null
      }

      if (error.data) {
        console.info("Unexpected error encountered, here's what I have:")
        console.info(error.data)
      }

      return error.fields || {}
    },
    $_clearErrors() {
      this.errors = {}
      this.$store.set('app/flash', null)
    }
  }
}
