import validateUser from '@/lib/validateUser'
import paymentsListAdapter from '@/adapters/backoffice/payments/list'

export default class {
  user = null

  constructor(user) {
    this.user = user
  }

  async call(options) {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    return await paymentsListAdapter(this.user.token, options)
  }
}
