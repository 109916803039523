import { isArray, isEmpty } from '@/lib/Utils'
import Decimal from '@/lib/Decimal'

export default class Base {
  static fromResponseResource(resource) {
    if (!resource) return null
    return this.fieldNamesFromResponse(resource)
  }

  static toRequestResource(entity) {
    if (!entity) return null
    return this.fieldNamesForRequest(entity)
  }

  static fromResponseErrors(errors, resource = null) {
    const fieldErrors = resource ? errors[resource] : errors
    return {
      fields: this.fieldNamesFromResponse(fieldErrors, true),
      extraMessages: []
    }
  }

  static formatFilterOptions(filter) {
    if (isEmpty(filter)) return null

    let result = this.fieldNamesForRequest(filter, true)
    /// These last modified date is only sent when sorting or searching.
    /// TODO: too hard coded?
    if (
      filter.creationDate ||
      filter.startDate ||
      filter.endDate ||
      filter.lastModified ||
      filter.lastModifiedBy
    ) {
      result = result || {}
      this.formatInternalValuesForRequest(
        {
          creationDate: filter.creationDate,
          startDate: filter.startDate,
          endDate: filter.endDate,
          lastModified: filter.lastModified,
          lastModifiedBy: filter.lastModifiedBy
        },
        result
      )
    }
    return this.removeEmptyAttributes(result)
  }

  static formatOnlyFieldsOption(fields) {
    if (isEmpty(fields)) return null
    return this.formatFilterOptions(fields, true)
  }

  // Sorting options formatting for mono-dimensional entities
  static formatSortingOptions(sorting) {
    if (isEmpty(sorting)) return null

    const sortingObject = this.expandSortingOptionsKeys(sorting)
    return this.formatFilterOptions(sortingObject)
  }

  static formatPlaceholdersResource(resource) {
    if (isEmpty(resource)) return null
    return this.fieldNamesFromResponse(resource, true)
  }

  static expandSortingOptionsKeys(sorting) {
    if (isEmpty(sorting)) return null
    const result = {}

    for (const fieldPath in sorting) {
      const [resourceOrField, fieldOrSubresource, ...rest] = fieldPath.split(
        '.'
      )
      const value = sorting[fieldPath]

      // Normal attribute
      if (!fieldOrSubresource) {
        result[resourceOrField] = value
      }
      // Just one resource down
      else if (!rest.length) {
        result[resourceOrField] = Object.assign(result[resourceOrField] || {}, {
          [fieldOrSubresource]: value
        })
      }
      // More than one resource down, go recursive!
      else {
        result[resourceOrField] = Object.assign(result[resourceOrField] || {}, {
          [fieldOrSubresource]: this.expandSortingOptionsKeys({ [rest]: value })
        })
      }
    }

    return result
  }

  static removeEmptyAttributes(obj) {
    if (!obj) return null

    if (isArray(obj)) return obj.length > 0 ? obj : null

    Object.keys(obj).forEach(key => isEmpty(obj[key]) && delete obj[key])

    return Object.keys(obj).length > 0 ? obj : null
  }

  static formatInternalValuesFromResponse(from, to) {
    const { creationDate, startDate, endDate, lastModDate, lastModLogin } = from

    if (creationDate) to.creationDate = creationDate
    if (startDate) to.startDate = startDate
    if (endDate) to.endDate = endDate
    if (lastModDate) to.lastModified = lastModDate
    if (lastModLogin) to.lastModifiedBy = lastModLogin

    return to
  }

  static formatInternalValuesForRequest(from, to) {
    const {
      creationDate,
      startDate,
      endDate,
      lastModified,
      lastModifiedBy
    } = from

    if (creationDate) to.creationDate = creationDate
    if (startDate) to.startDate = startDate
    if (endDate) to.endDate = endDate
    if (lastModified) to.lastModDate = lastModified
    if (lastModifiedBy) to.lastModLogin = lastModifiedBy

    return to
  }

  static fromResponseErrorsList(errorsList) {
    const fields = []
    const extraMessages = []

    if (isArray(errorsList)) {
      for (let i = 0; i < errorsList.length; i++) {
        const errors = errorsList[i]
        const {
          fields: errorFields,
          extraMessages: errorExtraMessages
        } = this.fromResponseErrors(errors)

        if (errorFields) fields.push(errorFields)
        if (errorExtraMessages) extraMessages.push(errorExtraMessages)
      }
    }

    return { fields, extraMessages }
  }

  static fromResponseResourcesList(results) {
    const resources = []
    if (results)
      for (let i = 0; i < results.length; i++) {
        const resource = this.fromResponseResource(results[i])
        if (resource) resources.push(resource)
      }
    return resources
  }

  static toResourceIdsList(resources) {
    const list = []
    if (isArray(resources))
      for (let i = 0; i < resources.length; i++)
        list.push(this.toResourceIdObject(resources[i]))
    return list
  }

  static toRequestResourcesList(resources) {
    const list = []
    if (isArray(resources))
      for (let i = 0; i < resources.length; i++)
        list.push(this.toRequestResource(resources[i]))
    return list
  }

  static fromResponseIdObject(resource) {
    return resource ? { id: resource.id } : null
  }
  static toResourceIdObject(resource) {
    return resource ? { id: resource.id } : null
  }

  // example of the format expected for "sorting":
  // {'person.name': 'ASC'}
  static toRequestSortingOption(sorting) {
    if (!sorting) return null
    return this.formatSortingOptions(sorting, true)
  }

  static formatDecimalFromResponse(value) {
    return value ? Decimal.fromBE(value).forUI() : null
  }

  static formatDecimalsFromResponseResource(resource, ...fields) {
    fields.forEach(field => {
      const decimal = this.formatDecimalFromResponse(resource[field])
      resource[field] = decimal
    })
    return resource
  }

  static formatDecimalForRequest(value) {
    return value ? Decimal.fromUI(value).forBE() : null
  }

  static formatDecimalsForRequestResource(resource, ...fields) {
    fields.forEach(field => {
      const decimal = this.formatDecimalForRequest(resource[field])
      resource[field] = decimal
    })
    return resource
  }
}
