<template>
  <div :class="_class">
    <v-textarea
      :label="label"
      :value="value"
      :rules="_rules"
      :required="required"
      :error-messages="error"
      :outlined="readonly"
      :filled="readonly"
      :rows="rows"
      dense
      @input="onChange"
    />
  </div>
</template>

<script>
import validators from '@/domain/validations/rules'

export default {
  name: 'Textarea',
  props: {
    value: String,
    label: String,
    rules: Array,
    required: Boolean,
    error: String,
    readonly: Boolean,
    rows: [Number, String]
  },
  computed: {
    _class() {
      return this.required ? 'required' : ''
    },
    _rules() {
      const _rules = this.rules || []
      if (this.required) _rules.push(validators(this).presence)
      return _rules
    }
  },
  methods: {
    onChange(newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>
