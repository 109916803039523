<template>
  <app-card class="mt-4 text-center job-data-menu">
    <v-card-text class="mt-0 pt-0 text-left">
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-subtitle>
            {{ $t('entities.job.name') }}
            {{ entity.name }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ $t('entities.job.notes') }}
            {{ entity.notes }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>

    <slot />

    <v-card-text>
      <slot name="controls" />
    </v-card-text>
  </app-card>
</template>

<script>
import Job from '@/domain/entities/Job'

export default {
  name: 'JobSummary',
  props: { entity: Job }
}
</script>

<style scoped>
.job-data-menu {
  position: sticky;
  top: 50px;
}
</style>
