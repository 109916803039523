<template>
  <v-autocomplete
    :label="label"
    :value="value"
    :items="items"
    :outlined="outlined"
    :hide-details="hideDetails"
    dense
    @change="$emit('input', $event)"
  />
</template>

<script>
export default {
  name: 'UserCompanyPositionSelect',
  props: {
    label: String,
    value: String,
    outlined: Boolean,
    hideDetails: Boolean
  },
  data() {
    return {
      items: [
        {
          value: 'internal',
          text: this.$t('entities.personCompany.positions.internal')
        },
        {
          value: 'external',
          text: this.$t('entities.personCompany.positions.external')
        }
      ]
    }
  }
}
</script>
