/**
 * Provides autocompletion behaviour to an element, usually a v-autocomplete
 * component or similar.
 *
 *
 * Required:
 *  - this.searchword: a watchable value, any change will cause the
 *                     repopulation of selection candidates;
 *  - data.actsAsAnAutocomplete: options for this mixin:
 *    - resource:  a String, name used for the resource in the adapters (e.g.
 *                 when building the request URI);
 *    - get:       Use-case Function to use to populate the list, e.g.
 *                 GetCustomers;
 *    - formatter: optional Function that will receive the completion
 *                 candidates array and return a new one formatted list that
 *                 will be the one actually shown;
 *
 * Emits:
 *  - @input: when one candidate in the autocomplete is selected;
 *
 */
import { get } from 'vuex-pathify'
import { isArray } from '@/lib/Utils'
import validators from '@/domain/validations/rules'
import Error from '@/lib/Error'

export default {
  props: {
    rules: Array,
    value: [Object, Array],
    label: String,
    hint: String,
    required: Boolean,
    error: String,
    readonly: Boolean,
    outlined: Boolean,
    hideDetails: Boolean,
    clearable: Boolean
  },
  data() {
    return {
      loading: false,
      items: [],
      item: this.value,
      searchword: ''
    }
  },
  computed: {
    autocompleteMinChars: get('app/autocompleteMinChars'),
    user: get('user'),
    noDataText() {
      const text = this.searchword || ''
      if (text.length < this.autocompleteMinChars)
        return this.$t('common.autocomplete.at-least-n-chars', {
          n: this.autocompleteMinChars
        })

      return this.$t('common.autocomplete.no-data')
    },
    validates() {
      return validators(this)
    },
    _rules() {
      const _rules = this.rules || []
      return this.required ? [validators(this).presence] : _rules
    },
    _class() {
      return this.required ? 'required' : ''
    }
  },
  methods: {
    option(name) {
      return this.actsAsAnAutocomplete[name]
    },
    onChange(newValue) {
      this.$emit('input', newValue)
    },
    onValueChange() {
      this.item = this.value
      if (!this.value) return
      if (isArray(this.value)) this.items = this.value
      else if (!this.items.includes(this.value)) this.items.push(this.value)
    }
  },
  watch: {
    searchword(text) {
      if (text !== null) {
        if (text.length < this.autocompleteMinChars) return
        if (this.loading) return

        this.loading = true

        this.items = []
        if (this.autocompleteSelectedItem && this.autocompleteSelectedItem.id)
          this.items = [this.autocompleteSelectedItem]

        const useCase = this.option('get')
        const resource = this.option('resource')
        const formatter = this.option('formatter') || false

        new useCase(this.user)
          .call({ fullText: text, itemsPerPage: 10, page: 1 })
          .then(result => {
            if (result.error) {
              new Error({
                message: 'common.autocomplete.error-loading'
              }).toAlert()
              console.info(`Error loading resources for autocomplete:`, result)
            } else {
              let items = result[resource]
              if (formatter) items = this[`${formatter}`](items)

              this.items = [...this.items, ...items]
              this.loading = false
            }
          })
      }
    },
    value() {
      this.onValueChange()
    }
  },
  mounted() {
    this.onValueChange()
  }
}
