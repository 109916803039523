import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import DefaultLayout from '@/layouts/DefaultLayout.vue'
import LoginPage from '@/views/LoginPage.vue'
import Error404Page from '@/views/404Page.vue'
import DashboardPage from '@/views/dashboard/DashboardPage.vue'

import customers from './resources/customers'
import suppliers from './resources/suppliers'
import products from './resources/products'
import editions from './resources/editions'
import offers from './resources/offers'
import contracts from './resources/contracts'
import customerContracts from './resources/customerContracts'
import purchases from './resources/purchases'
import invoices from './resources/invoices'
import payments from './resources/payments'
import costCenters from './resources/costCenters'
import jobs from './resources/jobs'

import procedures from './resources/procedures'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    children: [
      {
        path: '/dashboard',
        name: 'DashboardPage',
        component: DashboardPage,
        alias: '/',
        meta: {
          title: 'menu.long.dashboard'
        }
      },
      ...customers,
      ...suppliers,
      ...products,
      ...editions,
      ...offers,
      ...contracts,
      ...customerContracts,
      ...purchases,
      ...invoices,
      ...payments,
      ...costCenters,
      ...jobs,
      ...procedures
    ]
  },
  {
    path: '*',
    name: '404',
    component: Error404Page
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/app/',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

// Authentication guard
router.beforeEach((to, _from, next) => authGuard(to, next))

router.beforeEach((_to, from, next) => rememberLastUrl(from, next))

export default router

async function authGuard(to, next) {
  const user = await store.dispatch('getUser')

  if (to.name === 'LoginPage' || user) {
    next()
  } else next({ name: 'LoginPage', params: { nextUrl: to.fullPath } })
}

async function rememberLastUrl(from, next) {
  await store.set('app/lastUrl', from.fullPath || '/')
  next()
}
