import {
  ProductsListPage,
  NewProductPage,
  EditProductPage,
  ShowProductPage
} from '@/views/products/index.js'

export default [
  {
    path: '/products',
    name: 'ProductsPage',
    component: ProductsListPage,
    meta: {
      title: 'menu.long.resources.products'
    }
  },
  {
    path: '/products/new',
    name: 'NewProductPage',
    component: NewProductPage,
    meta: {
      title: 'menu.long.resources.new-product'
    }
  },
  {
    path: '/products/:id/edit',
    name: 'EditProductPage',
    component: EditProductPage,
    props: true,
    meta: {
      title: 'menu.long.resources.edit-customer'
    }
  },
  {
    path: '/products/:id',
    name: 'ShowProductPage',
    component: ShowProductPage,
    props: true,
    meta: {
      title: 'menu.long.resources.show-customer'
    }
  }
]
