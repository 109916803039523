import axios from '@/axios.js'

import CostCenterFormatter from '../formatters/CostCenter'
import resourceErrorsToResponse from '../formatters/resourceErrorsToResponse'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, id, entity) {
  const headers = { Authorization: `Bearer ${token}` }
  const payload = { costCenter: CostCenterFormatter.toRequestResource(entity) }

  try {
    const { data } = await axios.put(`/api/v1/costCenters/${id}`, payload, {
      headers
    })

    const costCenter = CostCenterFormatter.fromResponseResource(data.costCenter)

    return { error: false, costCenter }
  } catch (e) {
    return (
      resourceErrorsToResponse(e, CostCenterFormatter, 'costCenter') ||
      exceptionToResponse(e)
    )
  }
}
