import axios from '@/axios.js'

import EditionFormatter from '../formatters/Edition'
import resourceErrorsToResponse from '../formatters/resourceErrorsToResponse'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, resource) {
  if (!resource) {
    return {
      error: true,
      errorData: { message: 'errors.resource.invalid' }
    }
  }
  const headers = { Authorization: `Bearer ${token}` }
  const payload = { edition: EditionFormatter.toRequestResource(resource) }

  try {
    const { data } = await axios.post(`/api/v1/editions`, payload, { headers })

    const edition = EditionFormatter.fromResponseResource(data.edition)

    return { error: false, edition }
  } catch (e) {
    return (
      resourceErrorsToResponse(e, EditionFormatter) || exceptionToResponse(e)
    )
  }
}
