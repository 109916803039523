import validateUser from '@/lib/validateUser'
import customerCreateAdapter from '@/adapters/backoffice/customers/create'
import Error from '@/lib/Error'

export default class {
  user = null
  customer = null

  constructor(user, customer) {
    this.user = user
    this.customer = customer
  }

  async call() {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    if (
      !this.customer ||
      !this.customer.person ||
      !this.customer.personCompanies ||
      !this.customer.personCompanies.length
    )
      return new Error({ message: 'errors.resource.invalid' })

    if (this.customer.person.id)
      return new Error({ message: 'errors.resource.existing' })

    return await customerCreateAdapter(this.user.token, this.customer)
  }
}
