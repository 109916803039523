<template>
  <v-form ref="form" @submit.prevent>
    <v-container>
      <v-row>
        <v-col cols="12" lg="6">
          <TextField
            :label="$t('entities.job.name')"
            v-model="entity.name"
            :error="errors.name"
          />
        </v-col>
        <v-col cols="12" lg="3">
          <DateField
            :label="$t('common.fields.startDate')"
            v-model="entity.startDate"
            :error="errors.startDate"
          />
        </v-col>
        <v-col cols="12" lg="3">
          <DateField
            :label="$t('common.fields.endDate')"
            v-model="entity.endDate"
            :error="errors.endDate"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <Textarea :label="$t('entities.job.notes')" v-model="entity.notes" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              {{ $t('entities.job.details') }}
            </v-card-title>
            <v-btn
              class="mb-2 ml-2"
              small
              left
              color="primary"
              @click="addNewItem()"
              :alt="$t('common.job.detail.add')"
              :title="$t('common.job.detail.add')"
            >
              <v-icon left dark>mdi-plus</v-icon>
              {{ $t('common.job.detail.add') }}
            </v-btn>
            <div
              v-for="(jobAndDetail, index) in entity.jobAndDetails"
              :key="index"
            >
              <JobDetailSelectOrCreate
                v-if="isNew(index)"
                v-model="entity.jobAndDetails[index]"
                :errors="getErrors(index)"
              />
              <JobDetailForm
                v-else
                v-model="entity.jobAndDetails[index]"
                :errors="getErrors(index)"
              />
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import actsAsAForm from '@/mixins/actsAsAForm'

import { TextField, Textarea, DateField } from '@/components/common/form'

import Job from '@/domain/entities/Job'
import JobDetailSelectOrCreate from './JobDetailSelectOrCreate.vue'
import JobDetailForm from './JobDetailForm.vue'
import JobAndDetail from '@/domain/entities/JobAndDetail'
import { dig } from '@/lib/Utils'

export default {
  name: 'JobForm',
  mixins: [actsAsAForm],
  components: {
    TextField,
    Textarea,
    DateField,
    JobDetailForm,
    JobDetailSelectOrCreate
  },
  props: {
    value: Job,
    errors: Object
  },
  methods: {
    getErrors(index) {
      return dig(this.errors, 'jobAndDetails', index) || {}
    },
    isNew(index) {
      return !dig(this.entity, 'jobAndDetails', index, 'id')
    },
    addNewItem() {
      this.entity.jobAndDetails.push(JobAndDetail.default())
    }
  }
}
</script>
