export default class {
  static new() {
    return new this({})
  }

  static default() {
    return this.new()
  }

  constructor({
    id = null,
    costCenter = null,
    costCenterDetail = null,
    percentage = null,
    amount = null,
    notes = null
  } = {}) {
    this.id = id
    this.costCenter = costCenter
    this.costCenterDetail = costCenterDetail
    this.percentage = percentage
    this.amount = amount
    this.notes = notes
  }
}
