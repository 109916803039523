<template>
  <v-select
    :class="_class"
    :label="label"
    :value="value"
    :return-object="returnObject"
    :items="items"
    :item-text="itemText"
    clearable
    :rules="rules"
    :required="required"
    @input="onChange"
    :loading="loading"
    :hide-details="hideDetails"
    :outlined="outlined"
    :no-data-text="$t('common.autocomplete.no-data')"
    dense
  />
</template>

<script>
import validators from '@/domain/validations/rules'

export default {
  name: 'SelectField',
  props: {
    label: String,
    value: [String, Object],
    returnObject: Boolean,
    items: Array,
    itemText: String,
    required: Boolean,
    loading: Boolean,
    hideDetails: Boolean,
    outlined: Boolean
  },
  computed: {
    rules() {
      return this.required ? [validators(this).presence] : []
    },
    _class() {
      return this.required ? 'required' : ''
    }
  },
  methods: {
    onChange(newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>
