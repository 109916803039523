<template>
  <v-form ref="form">
    <material-card
      icon="mdi-package-variant"
      iconSmall
      color="primary"
      :title="$t('entities.payment.costCenter.name')"
    >
      <div v-for="(costCenterDetail, index) in costCenterDetails" :key="index">
        <PaymentCostCenterCard :value="costCenterDetail" />
        <hr />
      </div>
    </material-card>
  </v-form>
</template>

<script>
import Payment from '@/domain/entities/Payment'
import PaymentCostCenterCard from './PaymentCostCenterCard.vue'
import { dig } from '@/lib/Utils'

export default {
  name: 'PaymentCostCentersCard',
  components: {
    PaymentCostCenterCard
  },
  props: {
    value: Payment,
    errors: Object
  },
  computed: {
    costCenterDetails() {
      return dig(this.value, 'costCenterDetails') || []
    }
  }
}
</script>
