<template>
  <div>
    <ContractsList
      :loading="loading"
      :items="items"
      :page="page"
      :totalItems="totalItems"
      @refresh="refresh"
      @delete="deleteEntity"
      @goto-new="gotoNew"
      @goto-edit="gotoEdit"
      @goto-show="gotoShow"
    />
    <v-dialog v-model="deleteDialog" max-width="400px">
      <ConfirmDialog
        :title="$t('common.contract.delete.title')"
        :message="$t('common.contract.delete.confirm-text')"
        :yesText="$t('common.delete.yes')"
        :noText="$t('common.delete.no')"
        @yesClick="deleteConfirmed"
        @noClick="deleteCanceled"
      />
    </v-dialog>
  </div>
</template>

<script>
import actsAsAListPage from '@/mixins/actsAsAListPage'

import ContractsList from '@/components/contracts/ContractsList'
import ConfirmDialog from '@/components/common/ConfirmDialog.vue'

import GetContracts from '@/domain/useCases/contracts/GetContracts'
import DeleteContract from '@/domain/useCases/contracts/DeleteContract'

export default {
  name: 'ContractsListPage',
  mixins: [actsAsAListPage],
  components: { ContractsList, ConfirmDialog },
  data() {
    return {
      actsAsAListPage: {
        resource: 'contracts',
        get: GetContracts,
        delete: DeleteContract
      }
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
