import validateUser from '@/lib/validateUser'
import Error from '@/lib/Error'
import supplierCreateAdapter from '@/adapters/backoffice/suppliers/create'

export default class {
  user = null
  supplier = null

  constructor(user, supplier) {
    this.user = user
    this.supplier = supplier
  }

  async call() {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    if (
      !this.supplier ||
      !this.supplier.person ||
      !this.supplier.personCompanies ||
      !this.supplier.personCompanies.length
    )
      return new Error({ message: 'errors.resource.invalid' })

    if (this.supplier.person.id)
      return new Error({ message: 'errors.resource.existing' })

    return await supplierCreateAdapter(this.user.token, this.supplier)
  }
}
