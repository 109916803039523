import validateUser from '@/lib/validateUser'
import contractGetAdapter from '@/adapters/backoffice/contracts/get'

export default class {
  user = null

  constructor(user) {
    this.user = user
  }

  async call(id) {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    return await contractGetAdapter(this.user.token, id)
  }
}
