import axios from '@/axios.js'
import { isFile } from '@/lib/Utils'

import PaymentFormatter from '../formatters/Payment'
import resourceErrorsToResponse from '../formatters/resourceErrorsToResponse'
import exceptionToResponse from '../formatters/exceptionToResponse'

import updateFile from './file/update'
import deleteFile from './file/delete'

export default async function(token, id, entity) {
  const file = entity.invoice

  const headers = { Authorization: `Bearer ${token}` }
  const payload = { payment: PaymentFormatter.toRequestResource(entity) }

  try {
    const { data } = await axios.put(`/api/v1/payments/${id}`, payload, {
      headers
    })

    const payment = PaymentFormatter.fromResponseResource(data.payment)
    const paymentId = payment.id

    if (!file || isFile(file)) {
      deleteFile(token, paymentId)

      if (file) {
        const uploadResponse = await updateFile(token, paymentId, file)

        if (uploadResponse.error) {
          uploadResponse.message = 'entities.payment.error.invoice-upload'
          return uploadResponse
        } else {
          payment.file = uploadResponse.file
        }
      }
    }
    return { error: false, payment }
  } catch (e) {
    return (
      resourceErrorsToResponse(e, PaymentFormatter, 'payment') ||
      exceptionToResponse(e)
    )
  }
}
