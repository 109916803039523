import axios from '@/axios.js'

import PersonAndCompaniesFormatter from '../formatters/PersonAndCompanies'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token) {
  try {
    const { data } = await axios.get(`/api/v1/suppliers/unknown`, {
      headers: { Authorization: `Bearer ${token}` }
    })

    const personAndCompanies = PersonAndCompaniesFormatter.formatPlaceholdersResource(
      data
    )

    return { error: false, supplier: personAndCompanies }
  } catch (e) {
    return exceptionToResponse(e)
  }
}
