import moment from 'moment'
import { isEmpty } from '@/lib/Utils'
import Decimal from '@/lib/Decimal'

export default function(vm) {
  return {
    presence: value => validatePresence(vm, value),

    // Deprecated, "presence" sounds better
    required: value => validatePresence(vm, value),

    email: value => {
      if (!value) return true
      return /.+@.+\..+/.test(value) || vm.$t('errors.field.email')
    },

    integer: value => {
      const v = toInteger(value)
      return isInteger(v) || vm.$t('errors.field.integer')
    },

    positiveInteger: value => {
      const v = toInteger(value)
      if (!isInteger(v)) return vm.$t('errors.field.integer')
      return v >= 0 || vm.$t('errors.field.positive-integer')
    },

    greaterOrEqualTo: min => value => {
      const minDecimal = Decimal.fromBE(min)
      const valueDecimal = Decimal.fromUI(value)

      switch (true) {
        case !minDecimal:
          throw 'Invalid min prop value, integer or decimal required.'
        case !valueDecimal:
          return vm.$t('errors.field.number')
      }

      return (
        valueDecimal.isGreaterOrEqualTo(minDecimal) ||
        vm.$t('errors.field.greater-equal-than', { min })
      )
    },

    longExactly: length => value => {
      if (!value) return true
      return value.length === length || vm.$t('errors.field.length', { length })
    },

    date: value => {
      if (value === null || value === '') return true
      return (
        moment(value, 'DD/MM/YYYY', true).isValid() ||
        vm.$t('errors.field.date')
      )
    },
    currency: value => {
      if (!value) return true

      if (!Decimal.isValid(value)) return vm.$t('errors.field.currency')

      const valueDecimal = Decimal.fromUI(value)
      return valueDecimal.isPositive()
        ? true
        : vm.$t('errors.field.positive-currency')
    },
    anyCurrency: value => {
      if (!value) return true
      if (!Decimal.isValid(value)) return vm.$t('errors.field.currency')

      return true
    },

    percent: value => {
      const invalidField = vm.$t('errors.field.percent')
      if (!value) return true
      if (!Decimal.isValid(value)) return invalidField
      const valueDecimal = Decimal.fromUI(value)

      const between0100 =
        valueDecimal.compare(Decimal.zero()) >= 0 &&
        valueDecimal.compare(Decimal.fromBE(100)) <= 0
      return between0100 || invalidField
    },
    fileExtensionIn: (extensions = []) => file => {
      if (!file || !extensions || !extensions.length) return true

      const extractExtension = /\.([\w\d]+)$/.exec(file.name)
      if (extractExtension) {
        const extension = extractExtension[1]

        if (extensions.includes(extension)) return true
      }
      return vm.$t('errors.file.extension')
    },
    fileSize: size => file => {
      if (!file || !size) return true

      const bytes = size * 1024 * 1024 // size should be in MB
      if (file.size <= bytes) return true

      return vm.$t('errors.file.size')
    }
  }
}

function validatePresence(vm, value) {
  const message = vm.$t('errors.field.required')
  if (isEmpty(value)) return message
  return String(value).trim() ? true : message
}

function toInteger(value) {
  let v = String(value).trim()
  if (!v) return null
  if (/^\d+$/.test(v)) return Number(v)
  return NaN
}

function isInteger(value) {
  if (!value) return true
  if (!isNaN(value) && Number.isInteger(value)) return true
  return false
}
