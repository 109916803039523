import loginAdapter from '@/adapters/backoffice/login.js'
import Error from '@/lib/Error'

export default class {
  email = null
  password = null

  constructor(email = '', password = '') {
    this.email = email.trim()
    this.password = password.trim()
  }

  async call() {
    if (!this.email || !this.password)
      return new Error({ message: 'errors.auth.invalid-login', status: 401 })

    return await loginAdapter(this.email, this.password)
  }
}
