<template>
  <SelectField
    :label="label"
    :items="items"
    v-model="selectedItem"
    @input="changed"
    :required="required"
    :outlined="outlined"
    :hideDetails="hideDetails"
    dense
  >
  </SelectField>
</template>

<script>
import { SelectField } from '@/components/common/form'
import Invoice from '@/domain/entities/Invoice'

export default {
  name: 'InvoiceTypeSelect',
  components: { SelectField },
  props: {
    label: String,
    value: String,
    required: Boolean,
    outlined: Boolean,
    hideDetails: Boolean
  },
  data() {
    return {
      selectedItem: this.value,
      items: [
        { value: Invoice.DEBIT(), text: this.$t('entities.invoice.types.ND') },
        { value: Invoice.CREDIT(), text: this.$t('entities.invoice.types.NC') }
      ]
    }
  },
  methods: {
    changed() {
      this.$emit('input', this.selectedItem)
    }
  },
  watch: {
    value() {
      this.selectedItem = this.value
    }
  }
}
</script>
