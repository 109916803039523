<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="12">
        <CardTextField
          :label="$t('entities.product.name')"
          :value="entity.name"
        />
      </v-col>

      <v-col cols="12" lg="4">
        <CardTextField
          :label="$t('entities.product.code')"
          :value="entity.code"
        />
      </v-col>

      <v-col cols="12" lg="2">
        <CardTextField
          :label="$t('entities.product.language')"
          :value="entity.language"
        />
      </v-col>

      <v-col cols="12" lg="3">
        <CardTextField
          :label="$t('entities.product.category')"
          :value="category"
        />
      </v-col>

      <v-col cols="12" lg="3">
        <CardTextField :label="$t('entities.product.type')" :value="type" />
      </v-col>

      <v-col cols="12" lg="12">
        <CardTextarea
          :label="$t('entities.product.description')"
          :value="entity.description"
        />
      </v-col>

      <v-col cols="12" lg="2">
        <CardTextField
          prefix="€"
          reverse
          :label="$t('entities.product.price')"
          :value="entity.price"
        />
      </v-col>

      <v-col cols="12" lg="3">
        <CardTextField
          :label="$t('entities.product.minAttendees')"
          :value="entity.minAttendees"
        />
      </v-col>

      <v-col cols="12" lg="3">
        <CardTextField
          :label="$t('entities.product.maxAttendees')"
          :value="entity.maxAttendees"
        />
      </v-col>

      <v-col cols="12" lg="2">
        <CardTextField
          :label="$t('entities.product.durationHours')"
          :value="entity.durationHours"
        />
      </v-col>

      <v-col cols="12" lg="2">
        <CardTextField
          :label="$t('entities.product.durationDays')"
          :value="entity.durationDays"
        />
      </v-col>

      <v-col cols="12" lg="12">
        <CardTextarea
          :label="$t('entities.product.prerequisites')"
          :value="entity.prerequisites"
        />
      </v-col>

      <v-col cols="12">
        <CardTextarea
          :label="$t('entities.product.notes')"
          :value="entity.notes"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { CardTextField, CardTextarea } from '@/components/common/card'

import Product from '@/domain/entities/Product'

export default {
  name: 'ProductCard',
  components: { CardTextField, CardTextarea },
  props: { entity: Product },
  computed: {
    category() {
      if (!this.entity.category || !this.entity.category.code) return ''
      return this.$t(`entities.product.categories.${this.entity.category.code}`)
    },
    type() {
      if (!this.entity.type || !this.entity.type.code) return ''
      return this.$t(`entities.product.types.${this.entity.type.code}`)
    }
  }
}
</script>
