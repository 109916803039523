import axios from '@/axios.js'

import OfferFormatter from '../formatters/Offer'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, id) {
  const headers = { Authorization: `Bearer ${token}` }
  try {
    const { data } = await axios.get(`/api/v1/offers/${id}`, { headers })

    const {
      data: editionsData
    } = await axios.get(`/api/v1/offers/${id}/editions`, { headers })

    data.offer.editions = editionsData.results

    const {
      data: overridesData
    } = await axios.get(`/api/v1/offers/${id}/contractOverrides`, { headers })

    data.offer.contractOverrides = overridesData.contractOverrides

    const offer = OfferFormatter.fromResponseResource(data.offer)

    return { error: false, offer }
  } catch (e) {
    return exceptionToResponse(e)
  }
}
