<template>
  <v-container id="products-list" fluid tag="section">
    <material-card
      icon="mdi-archive"
      icon-small
      :title="$t('list.products.title')"
      color="primary"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :footer-props="footerOptions"
        :server-items-length="totalItems"
        :loading="loading"
        :no-data-text="$t('common.list.no-data')"
      >
        <template v-slot:top>
          <v-container>
            <v-row dense>
              <v-col cols="11">
                <Searchbar @submit="searchbarSubmitted" />
              </v-col>
              <v-col cols="1" class="pt-0 pl-5">
                <RoundButton
                  icon="mdi-plus"
                  :alt="$t('list.products.new')"
                  @click="$emit('goto-new')"
                />
              </v-col>
            </v-row>
          </v-container>
        </template>

        <template v-slot:[`item.category`]="{ item }">
          {{ translateCategory(item.category) }}
        </template>

        <template v-slot:[`item.type`]="{ item }">
          {{ translateType(item.type) }}
        </template>

        <template v-slot:[`item.price`]="{ item }">
          {{ item.price | append('€') }}
        </template>

        <template v-slot:[`item.creationDate`]="{ item }">
          <small>{{ item.creationDate | dateTime }}</small>
        </template>

        <template v-slot:[`item.lastModified`]="{ item }">
          <small>{{ item.lastModified | dateTime }}</small>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="actions">
            <v-icon
              class="mr-2 show-product-button"
              @click="$emit('goto-show', item.id)"
              >mdi-eye</v-icon
            >
            <v-icon
              class="mr-2 edit-product-button"
              @click="$emit('goto-edit', item.id)"
              >mdi-pencil</v-icon
            >
            <v-icon
              class="delete-product-button"
              @click="$emit('delete', item.id)"
              >mdi-delete</v-icon
            >
          </div>
        </template>
      </v-data-table>
    </material-card>
  </v-container>
</template>

<script>
import actsAsAList from '@/mixins/actsAsAList'

import Searchbar from '@/components/products/ProductSearchbar.vue'
import RoundButton from '@/components/common/RoundButton.vue'

export default {
  name: 'ProductsList',
  mixins: [actsAsAList],
  components: { Searchbar, RoundButton },
  props: {
    loading: Boolean,
    items: Array,
    page: Number,
    totalItems: Number
  },
  data() {
    return {
      headers: [
        {
          sortable: true,
          text: this.$t('entities.product.code'),
          value: 'code'
        },
        {
          text: this.$t('entities.product.name'),
          value: 'name'
        },
        {
          text: this.$t('entities.product.category'),
          value: 'category'
        },
        { text: this.$t('entities.product.type'), value: 'type' },
        {
          text: this.$t('entities.product.price'),
          value: 'price'
        },
        {
          text: this.$t('common.fields.creationDate'),
          value: 'creationDate'
        },
        {
          text: this.$t('common.fields.lastModified'),
          value: 'lastModified'
        },
        { text: '', value: 'actions' }
      ]
    }
  },
  methods: {
    translateCategory(category) {
      const code = category.code
      return this.$t(`entities.product.categories.${code}`)
    },
    translateType(type) {
      const code = type.code
      return this.$t(`entities.product.types.${code}`)
    }
  }
}
</script>

<style scoped>
.actions {
  min-width: 100px;
}
</style>
