import ContractReason from '@/domain/entities/ContractReason'
import Base from './Base'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null

    const entity = this.fieldNamesFromResponse(resource)
    return new ContractReason(entity)
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    if (!resource) return null
    const { id, reason, descr } = resource
    const result = { id, name: reason, description: descr }
    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(entity, removeEmpty = false) {
    if (!entity) return null
    const { id, name, description } = entity
    const result = { id, reason: name, descr: description }
    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }
}
