import axios from '@/axios.js'
import Error from '@/lib/Error'

import User from '@/domain/entities/User'
import exceptionToResponse from './formatters/exceptionToResponse'

export default async function(email, password) {
  const tokenResult = await fetchToken(email, password)
  if (tokenResult.error) return tokenResult

  const { token, refreshToken } = tokenResult
  const userDataResult = await fetchUserData(token)
  if (userDataResult.error) return userDataResult

  const { id, fullName: name } = userDataResult.user
  const user = new User({ id, name, email, token, refreshToken })

  return { error: false, user }
}

async function fetchToken(email, password) {
  const appClientId = process.env.VUE_APP_CLIENT_ID
  const appClientSecret = process.env.VUE_APP_CLIENT_SECRET

  try {
    const response = await axios.post('/api/v1/token', {
      email,
      password,
      grant_type: 'password',
      client_id: appClientId,
      client_secret: appClientSecret
    })

    const { access_token: token, refresh_token: refreshToken } = response.data

    if (!token) return new Error({ message: 'errors.connection.backend-error' })

    return { error: false, token, refreshToken }
  } catch (e) {
    const response = exceptionToResponse(e)
    if (response.message == 'errors.auth.unauthorized') {
      response.message = 'errors.auth.invalid-login'
    }
    return response
  }
}

async function fetchUserData(token) {
  try {
    const response = await axios.get('/api/v1/me', {
      headers: { Authorization: `Bearer ${token}` }
    })

    const { id, fullName } = response.data

    if (!id) return new Error({ message: 'errors.connection.backend-error' })

    return {
      error: false,
      user: { id, fullName }
    }
  } catch (e) {
    return exceptionToResponse(e)
  }
}
