import validateUser from '@/lib/validateUser'
import Error from '@/lib/Error'
import purchaseCreateAdapter from '@/adapters/backoffice/purchases/create'

export default class {
  user = null
  purchase = null
  signedFileUpload = null

  constructor(user, purchase, signedFileUpload) {
    this.user = user
    this.purchase = purchase
    this.signedFileUpload = signedFileUpload
  }

  async call() {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    if (!this.purchase) return new Error({ message: 'errors.resource.invalid' })
    if (this.purchase.id)
      return new Error({
        message: 'errors.resource.existing'
      })

    return await purchaseCreateAdapter(
      this.user.token,
      this.purchase,
      this.signedFileUpload
    )
  }
}
