<template>
  <app-card class="mt-4 text-center edition-data-menu">
    <v-card-text class="mt-0 pt-0 text-left">
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title v-if="entity.genericProduct" class="headline">
            {{ entity.genericProduct.name }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="entity.genericProduct">
            {{ genericProductTypeAndCategory }}
            {{ fromDateToDate }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>

    <slot />

    <v-card-text>
      <slot name="controls" />
    </v-card-text>
  </app-card>
</template>

<script>
import { dateForUI } from '@/lib/Utils'

import Edition from '@/domain/entities/Edition'

export default {
  name: 'editionSummary',
  props: { entity: Edition },
  computed: {
    genericProductTypeAndCategory() {
      if (this.entity.genericProduct) {
        const category = this.genericProductCategory
        const type = this.genericProductType
        if (category && type) return `${category} ${type}`
        return category || type
      }
      return ''
    },
    category() {
      if (!this.entity.genericProduct || !this.entity.genericProduct.category)
        return ''

      const category = this.entity.genericProduct.category
      return category
        ? this.$t(`entities.product.categories.${category.code}`)
        : ''
    },
    type() {
      if (!this.entity.genericProduct || !this.entity.genericProduct.type)
        return ''

      const type = this.entity.genericProduct.type
      return type ? this.$t(`entities.product.types.${type.code}`) : ''
    },
    fromDateToDate() {
      const from = this.entity.fromDate ? dateForUI(this.entity.fromDate) : ''
      const to = this.entity.toDate ? dateForUI(this.entity.toDate) : ''

      if (from && to) return `${from} - ${to}`
      else return `${from}${to}`
    }
  }
}
</script>

<style scoped>
.edition-data-menu {
  position: sticky;
  top: 50px;
}
</style>
