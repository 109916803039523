<template>
  <v-form ref="form" @submit.prevent>
    <v-container>
      <v-row>
        <v-col cols="12" lg="2">
          <v-switch
            color="success"
            :label="$t('entities.offer.active')"
            v-model="entity.active"
            :error="errors.active"
          />
        </v-col>

        <v-col cols="12" lg="5">
          <DateField
            :label="$t('entities.offer.fromDate')"
            v-model="entity.fromDate"
            required
            :error="errors.fromDate"
          />
        </v-col>

        <v-col cols="12" lg="5">
          <DateField
            :label="$t('entities.offer.toDate')"
            v-model="entity.toDate"
            required
            :error="errors.toDate"
          />
        </v-col>

        <v-col cols="12" lg="12">
          <TextField
            :label="$t('entities.offer.name')"
            v-model="entity.name"
            required
            :error="errors.name"
          />
        </v-col>

        <v-col cols="12" lg="12">
          <Textarea
            :label="$t('entities.offer.description')"
            v-model="entity.description"
            required
            :error="errors.description"
          />
        </v-col>

        <v-col cols="12">
          <Textarea
            :label="$t('entities.offer.notes')"
            v-model="entity.notes"
            :error="errors.notes"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import actsAsAForm from '@/mixins/actsAsAForm'

import { TextField, DateField, Textarea } from '@/components/common/form'

import Offer from '@/domain/entities/Offer'

export default {
  name: 'OfferForm',
  mixins: [actsAsAForm],
  components: {
    TextField,
    DateField,
    Textarea
  },
  props: {
    value: Offer,
    errors: Object
  }
}
</script>
