<template>
  <v-container id="payments-list" fluid tag="section">
    <material-card
      icon="mdi-credit-card-minus"
      icon-small
      :title="$t('list.payments.title')"
      color="primary"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :footer-props="footerOptions"
        :server-items-length="totalItems"
        :loading="loading"
        :no-data-text="$t('common.list.no-data')"
      >
        <template v-slot:top>
          <v-container class="mx-1">
            <v-row dense>
              <v-col>
                <Searchbar @submit="searchbarSubmitted" />
              </v-col>
              <v-col cols="1" class="pt-0 pl-5">
                <RoundButton
                  :icon="`mdi-plus`"
                  :alt="$t('list.payments.new')"
                  @click="$emit('goto-new')"
                />
              </v-col>
            </v-row>
          </v-container>
        </template>

        <template v-slot:[`item.paymentDate`]="{ item }">
          {{ item.paymentDate | date }}
        </template>

        <template v-slot:[`item.duoDate`]="{ item }">
          {{ item.duoDate | date }}
        </template>

        <template v-slot:[`item.untaxedPrice`]="{ item }">
          {{ item.untaxedPrice | append('€') }}
        </template>

        <template v-slot:[`item.creationDate`]="{ item }">
          <small>{{ item.creationDate | dateTime }}</small>
        </template>

        <template v-slot:[`item.lastModified`]="{ item }">
          <small>{{ item.lastModified | dateTime }}</small>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="actions">
            <v-icon
              class="mr-2 show-payment-button"
              @click="$emit('goto-show', item.id)"
              >mdi-eye</v-icon
            >
            <v-icon
              class="mr-2 edit-payment-button"
              @click="$emit('goto-edit', item.id)"
              >mdi-pencil</v-icon
            >
            <v-icon
              class="delete-payment-button"
              @click="$emit('delete', item.id)"
              >mdi-delete</v-icon
            >
          </div>
        </template>
      </v-data-table>
    </material-card>
  </v-container>
</template>

<script>
import actsAsAList from '@/mixins/actsAsAList'

import Searchbar from '@/components/payments/PaymentSearchbar.vue'
import RoundButton from '@/components/common/RoundButton.vue'

export default {
  name: 'PaymentsList',
  mixins: [actsAsAList],
  components: { Searchbar, RoundButton },
  props: {
    loading: Boolean,
    items: Array,
    page: Number,
    totalItems: Number
  },
  data() {
    return {
      selected: [],
      headers: [
        {
          text: this.$t('entities.payment.duoDate'),
          value: 'duoDate'
        },
        {
          text: this.$t('entities.payment.paymentDate'),
          value: 'paymentDate'
        },
        {
          text: this.$t('entities.payment.untaxedPrice'),
          value: 'untaxedPrice'
        },
        {
          text: this.$t('entities.payment.supplier.name'),
          value: 'supplier.person.name'
        },
        {
          text: this.$t('entities.payment.supplier.surname'),
          value: 'supplier.person.surname'
        },
        {
          text: this.$t('common.fields.creationDate'),
          value: 'creationDate'
        },
        {
          text: this.$t('common.fields.lastModified'),
          value: 'lastModified'
        },

        { text: '', value: 'actions', sortable: false }
      ]
    }
  }
}
</script>

<style scoped>
.actions {
  min-width: 100px;
}
</style>
