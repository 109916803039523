import CustomerContractDetail from '@/domain/entities/CustomerContractDetail'
import Base from './Base'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null
    const entity = this.fieldNamesFromResponse(resource)
    return new CustomerContractDetail(entity)
  }

  static toRequestResource(entity) {
    if (!entity) return null
    return this.fieldNamesForRequest(entity)
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    const { id, descr, count, max, notes } = resource

    const result = {
      id,
      description: descr,
      value: count,
      max,
      notes
    }

    this.formatInternalValuesFromResponse(resource, result)

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(entity, removeEmpty = false) {
    const { id, description, value, max, notes } = entity

    const result = {
      id,
      descr: description,
      count: value,
      max: max,
      notes
    }

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }
}
