import axios from '@/axios.js'

import OfferFormatter from '../formatters/Offer'
import resourceErrorsToResponse from '../formatters/resourceErrorsToResponse'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, id, data) {
  const payload = { offer: OfferFormatter.toRequestResource(data) }

  if (!payload || !id) {
    return {
      error: true,
      errorData: { message: 'errors.resource.invalid' }
    }
  }

  try {
    const { data } = await axios.put(`/api/v1/offers/${id}`, payload, {
      headers: { Authorization: `Bearer ${token}` }
    })

    const offer = OfferFormatter.fromResponseResource(data.offer)
    return { error: false, offer }
  } catch (e) {
    return resourceErrorsToResponse(e, OfferFormatter) || exceptionToResponse(e)
  }
}
