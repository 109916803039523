export default class {
  static toRequestOptions(options, fieldNamesFormatter) {
    if (!options) return null

    const {
      page,
      itemsPerPage,
      sortBy,
      sortDesc,
      filters,
      fullText,
      fields,
      except
    } = options

    const newItemsPerPage =
      Number(itemsPerPage) === -1 ? 'all' : Number(itemsPerPage)

    const result = {
      page,
      itemsPerPage: newItemsPerPage,
      search: fullText,
      filter: this.formatFilterOptions(filters, fieldNamesFormatter),
      fields: this.formatOnlyFieldsOption(fields, fieldNamesFormatter),
      sorting: this.toRequestSortingOption(
        sortBy,
        sortDesc,
        fieldNamesFormatter
      ),
      except
    }

    return result
  }

  static formatFilterOptions(filter, fieldNamesFormatter) {
    return fieldNamesFormatter.formatFilterOptions(filter)
  }

  static formatOnlyFieldsOption(fields, fieldNamesFormatter) {
    return fieldNamesFormatter.formatOnlyFieldsOption(fields)
  }

  // example of the format expected for "sortBy":
  // ['person.name']
  static toRequestSortingOption(sortBy, sortDesc, fieldNamesFormatter) {
    let sorting = null
    if (sortBy && sortBy.length) {
      sorting = {}
      sortBy.forEach((field, index) => {
        sorting[field] = sortDesc[index] ? 'DESC' : 'ASC'
      })
      sorting = fieldNamesFormatter.toRequestSortingOption(sorting)
    }

    return sorting
  }
}
