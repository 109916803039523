import CreateContractOfferProcedure from '@/views/procedures/CreateContractOfferProcedure.vue'

export default [
  {
    path: '/procedures/contract-offer',
    name: 'CreateContractOfferProcedure',
    component: CreateContractOfferProcedure,
    meta: {
      title: 'menu.long.procedures.contract-offer'
    }
  }
]
