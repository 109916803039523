import Base from './Base'
import Company from '@/domain/entities/Company'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null

    const entity = this.fieldNamesFromResponse(resource)
    entity.invoiceNation = resource.invoNation
      ? resource.invoNation.toUpperCase()
      : ''
    entity.fiscalCode = resource.fiscalCode
      ? resource.fiscalCode.toUpperCase()
      : ''

    return new Company(entity)
  }

  static toRequestResource(company) {
    if (!company) return null
    const formattedCustomer = this.fieldNamesForRequest(company)

    formattedCustomer.invoNation = company.invoiceNation
      ? company.invoiceNation.toUpperCase()
      : ''
    formattedCustomer.fiscalCode = company.fiscalCode
      ? company.fiscalCode.toUpperCase()
      : ''

    return formattedCustomer
  }

  static fromResponseErrors(errors) {
    const fields = this.fieldNamesFromResponse(errors, true) || {}
    const extraMessages = []

    if (errors['vatCodeOrFiscalCode']) {
      extraMessages.push('errors.company.vat-or-fiscal-code-required')
      fields.fiscalCode = 'required-or-vatCode'
      fields.vatCode = 'required-or-fiscalCode'
    }

    return { fields, extraMessages }
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    if (!resource) return null

    const {
      id,
      fiscalCode,
      vatCode,
      iban,
      invoName,
      invoAddress,
      invoNation,
      invoRegion,
      invoCounty,
      invoCity,
      invoZip,
      invoPec,
      invoSdi,
      invoCig,
      invoCup,
      startDate,
      notes
    } = resource

    const result = {
      id,
      fiscalCode,
      vatCode,
      iban,
      invoiceName: invoName,
      invoiceAddress: invoAddress,
      invoiceNation: invoNation,
      invoiceRegion: invoRegion,
      invoiceCounty: invoCounty,
      invoiceCity: invoCity,
      invoicePostalCode: invoZip,
      invoicePec: invoPec,
      invoiceSdi: invoSdi,
      invoiceCig: invoCig,
      invoiceCup: invoCup,
      startDate,
      notes
    }

    this.formatInternalValuesFromResponse(resource, result)

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(company, removeEmpty = false) {
    if (!company) return null

    const {
      id,
      fiscalCode,
      vatCode,
      iban,
      invoiceName,
      invoiceAddress,
      invoiceNation,
      invoiceRegion,
      invoiceCounty,
      invoiceCity,
      invoicePostalCode,
      invoicePec,
      invoiceSdi,
      invoiceCig,
      invoiceCup,
      notes
    } = company

    const result = {
      id,
      fiscalCode,
      vatCode,
      iban,
      invoName: invoiceName,
      invoAddress: invoiceAddress,
      invoNation: invoiceNation,
      invoRegion: invoiceRegion,
      invoCounty: invoiceCounty,
      invoCity: invoiceCity,
      invoZip: invoicePostalCode,
      invoPec: invoicePec,
      invoSdi: invoiceSdi,
      invoCig: invoiceCig,
      invoCup: invoiceCup,
      notes
    }

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }
}
