<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="3">
        <CardTextField
          :label="$t('entities.invoice.job.name')"
          :value="value.jobAndDetail | dig('job', 'name')"
        />
      </v-col>
      <v-col cols="12" lg="3">
        <CardTextField
          :label="$t('entities.invoice.job.detail.name')"
          :value="value.jobAndDetail | dig('detail', 'name')"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <CardTextField
          type="percentage"
          :label="$t('entities.invoice.job.percentage')"
          :value="value.percentage"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <CardTextField
          type="currency"
          :label="$t('entities.invoice.job.amount')"
          :value="value.amount"
        />
      </v-col>
      <v-col cols="12" lg="12">
        <CardTextArea
          :label="$t('entities.invoice.notes')"
          :value="value.notes"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardTextField from '@/components/common/card/CardTextField.vue'
import CardTextArea from '@/components/common/card/CardTextarea.vue'
import InvoiceJobDetail from '@/domain/entities/InvoiceJobDetail'

export default {
  name: 'InvoiceJobCard',
  components: {
    CardTextField,
    CardTextArea
  },
  props: {
    value: InvoiceJobDetail,
    errors: Object
  }
}
</script>
