<template>
  <v-container fluid tag="section">
    <v-stepper v-model="step" @change="stepChanged">
      <v-stepper-header>
        <v-stepper-step
          :color="color(1)"
          :editable="editable(1)"
          :rules="[() => valid(1)]"
          :complete="step > 1"
          step="1"
        >
          {{ $t('procedures.contract-offer.steps.contract') }}
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :color="color(2)"
          :editable="editable(2)"
          :rules="[() => valid(2)]"
          :complete="step > 2"
          step="2"
        >
          {{ $t('procedures.contract-offer.steps.edition') }}
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :color="color(3)"
          :editable="editable(3)"
          :rules="[() => valid(3)]"
          :complete="step > 3"
          step="3"
        >
          {{ $t('procedures.contract-offer.steps.offer') }}
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :color="color(4)"
          :editable="editable(4)"
          :rules="[() => valid(4)]"
          step="4"
        >
          {{ $t('procedures.contract-offer.steps.offer-prices') }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <ContractSelectOrCreate
            ref="contractSelectOrCreate"
            v-model="contract"
          />
          <v-btn color="primary" @click="validateContract">
            {{ $t('common.next') }}
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="2">
          <div v-if="this.contract" class="text-h4 mb-2">
            {{ $t('procedures.edition-of', { name: this.contract.name }) }}
          </div>
          <EditionForm
            v-if="edition"
            ref="editionForm"
            v-model="edition"
            no-generic-product-select
            :errors="errorsFor('edition')"
          />

          <v-btn color="primary" @click="validateEdition">
            {{ $t('common.next') }}
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="3">
          <div v-if="this.contract && this.edition" class="text-h4 mb-2">
            {{ $t('procedures.contract-offer-title', { offer: this.offer }) }}
          </div>

          <OfferForm
            v-if="offer"
            ref="offerForm"
            v-model="offer"
            :errors="errorsFor('offer')"
          />

          <v-btn color="primary" @click="validateOffer">
            {{ $t('common.next') }}
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="4">
          <div v-if="this.offer" class="text-h4 mb-2">
            {{
              $t('procedures.contract-offer-prices-title', {
                offer: this.offer
              })
            }}
          </div>
          <OfferPricesForm
            v-if="offer"
            ref="offerPricesForm"
            v-model="offer"
            :errors="errorsFor('offer')"
          />

          <v-btn color="success" @click="saveOffer">
            {{ $t('common.finalize') }}
          </v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import { get } from 'vuex-pathify'
import { dig, dateForUI } from '@/lib/Utils'
import handlesErrors from '@/mixins/handlesErrors'

import ContractSelectOrCreate from '@/components/contracts/ContractSelectOrCreate.vue'
import EditionForm from '@/components/editions/EditionForm.vue'
import OfferForm from '@/components/offers/OfferForm.vue'
import OfferPricesForm from '@/components/offers/OfferPricesForm.vue'

import Edition from '@/domain/entities/Edition'
import Offer from '@/domain/entities/Offer'
import CreateOffer from '@/domain/useCases/offers/CreateOffer'

export default {
  name: 'CreateContractOfferProcedure',
  mixins: [handlesErrors],
  components: {
    ContractSelectOrCreate,
    EditionForm,
    OfferForm,
    OfferPricesForm
  },
  data() {
    return {
      step: 1,
      editableSteps: [1],
      invalidStep: null,
      contract: null,
      edition: null,
      offer: null,
      errors: {}
    }
  },
  computed: {
    user: get('user')
  },
  methods: {
    errorsFor(nestedEntity) {
      return dig(this.errors, nestedEntity) || {}
    },
    date(date) {
      return dateForUI(date)
    },
    color(step) {
      switch (true) {
        case !this.valid(step):
          return 'error'
        case this.step != step && this.editable(step):
          return 'success'
        default:
          return 'primary'
      }
    },
    editable(step) {
      return this.editableSteps.includes(step)
    },
    valid(step) {
      return step !== this.invalidStep
    },
    stepChanged(step) {
      switch (step) {
        case '3':
        case '4':
          this.offer.recalculateEditionTotals()
          this.offer = new Offer(this.offer)
          break
      }
    },
    validateContract() {
      if (this.$refs.contractSelectOrCreate.validate()) {
        if (!this.edition) this.edition = Edition.fromContract(this.contract)
        this.editableSteps = [1, 2]
        this.step = 2
        if (this.invalidStep === 1) this.invalidStep = null
      } else {
        this.invalidStep = 1
        this.editableSteps = [1]
      }
    },
    validateEdition() {
      if (this.$refs.editionForm.validate()) {
        if (!this.offer) {
          this.offer = Offer.fromEditions([this.edition])
          this.offer.fromDate = this.edition.fromDate
          this.offer.toDate = this.edition.toDate
        }
        this.step = 3
        this.editableSteps = [1, 2, 3]
        if (this.invalidStep === 2) this.invalidStep = null
      } else {
        this.invalidStep = 2
        this.editableSteps = [1, 2]
      }
    },
    validateOffer() {
      if (this.$refs.offerForm.validate()) {
        this.step = 4
        this.editableSteps = [1, 2, 3, 4]
        if (this.invalidStep === 3) this.invalidStep = null
      } else {
        this.invalidStep = 3
        this.editableSteps = [1, 2, 3]
      }
    },
    saveOffer() {
      const validOffer = this.$refs.offerForm.validate()
      const validEdition = this.$refs.editionForm.validate()
      const validOfferPrices = this.$refs.offerPricesForm.validate()
      if (validOffer && validEdition && validOfferPrices) {
        new CreateOffer(this.user, this.offer).call().then(result => {
          if (result.error) this.errors = this.$_handleError(result)
          else {
            alert('OK!')
          }
        })
      } else {
        this.invalidStep = 4
      }
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
