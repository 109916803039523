export default {
  short: {
    manage: 'Gestione',
    wizards: 'Procedure',
    add: 'Aggiungi',
    dashboard: 'Dashboard',
    login: 'Login',
    resources: {
      customers: 'Clienti',
      'new-customer': 'Cliente',
      suppliers: 'Fornitori',
      'new-supplier': 'Fornitore',
      products: 'Prodotti',
      'new-product': 'Prodotto',
      editions: 'Edizioni',
      'new-edition': 'Edizione',
      offers: 'Offerte',
      'new-offer': 'Offerte',
      contracts: 'Contratti',
      'new-contract': 'Contratto',
      'customer-contracts': 'Istanze di contratti',
      'new-customer-contract': 'Nuova istanza di contratto',
      purchases: 'Acquisti',
      'new-purchase': 'Acquisto',
      invoices: 'Fatture',
      'new-invoice': 'Fattura', // Not used probably, as invoices are created starting from purchases.
      payments: 'Pagamenti',
      'new-payment': 'Pagamento',
      'cost-centers': 'Centri di costo',
      'new-cost-center': 'Nuovo centro di costo',
      jobs: 'Commesse',
      'new-job': 'Nuova commessa'
    },
    procedures: {
      'contract-offer': 'Offerta di contratto'
    }
  },
  long: {
    manage: 'Gestione',
    wizards: 'Procedure',
    dashboard: 'Dashboard',
    login: 'Login',
    resources: {
      customers: 'Gestione clienti',
      'new-customer': 'Nuovo cliente',
      'edit-customer': 'Modifica cliente',
      'show-customer': 'Scheda cliente',
      suppliers: 'Gestione fornitori',
      'new-supplier': 'Nuovo fornitore',
      'edit-supplier': 'Modifica fornitore',
      'show-supplier': 'Scheda fornitore',
      products: 'Gestione prodotti',
      'new-product': 'Nuovo prodotto',
      'edit-product': 'Modifica prodotto',
      'show-product': 'Scheda prodotto',
      editions: 'Gestione edizioni',
      'new-edition': 'Nuova edizione',
      'edit-edition': 'Modifica edizione',
      'show-edition': 'Scheda edizione',
      offers: 'Gestione offerte',
      'new-offer': 'Nuova offerta',
      'edit-offer': 'Modifica offerta',
      'show-offer': 'Scheda offerta',
      contracts: 'Gestione contratti',
      'new-contract': 'Nuovo contratto',
      'edit-contract': 'Modifica contratto',
      'show-contract': 'Scheda contratto',
      customerContracts: 'Gestione contratti',
      'new-customerContract': 'Nuovo istanza di contratto',
      'edit-customerContract': 'Modifica istanza di contratto',
      'show-customerContract': 'Scheda istanza di contratto',
      purchases: 'Gestione acquisti',
      'new-purchase': 'Nuovo acquisto',
      'edit-purchase': 'Modifica acquisto',
      'show-purchase': 'Scheda acquisto',
      'create-purchase-invoice': 'Fatturazione acquisti',
      invoices: 'Gestione fatture',
      'new-invoice': 'Nuovo fattura',
      'edit-invoice': 'Modifica fattura',
      'amend-invoice': 'Storno totale',
      'partial-amend-invoice': 'Storno pariziale',
      'show-invoice': 'Scheda fattura',
      'edit-invoice-cost-centers': 'Modifica centri di costo fattura',
      'show-invoice-cost-centers': 'Mostra centri di costo fattura',
      'edit-invoice-jobs': 'Modifica commesse fattura',
      'show-invoice-jobs': 'Mostra commesse fattura',
      payments: 'Gestione pagamenti',
      'new-payment': 'Nuovo pagamento',
      'edit-payment': 'Modifica pagamento',
      'show-payment': 'Scheda pagamento',
      'edit-payment-cost-centers': 'Modifica centri di costo pagamento',
      'show-payment-cost-centers': 'Mostra centri di costo pagamento',
      'cost-centers': 'Gestione centri di costo',
      'new-cost-center': 'Nuovo centro di costo',
      'edit-cost-center': 'Modifica centro di costo',
      'show-cost-center': 'Scheda centro di costo',
      'edit-payment-jobs': 'Modifica commesse pagamento',
      'show-payment-jobs': 'Mostra commesse pagamento',
      jobs: 'Gestione commesse',
      'new-job': 'Nuovo commessa',
      'edit-job': 'Modifica commessa',
      'show-job': 'Scheda commessas'
    },
    procedures: {
      'contract-offer': 'Nuova offerta di contratto'
    }
  }
}
