import validateUser from '@/lib/validateUser'
import Error from '@/lib/Error'
import invoiceCreateAdapter from '@/adapters/backoffice/invoices/create'

export default class {
  user = null
  purchaseId = null
  invoice = null

  constructor(user, invoice) {
    this.user = user
    this.invoice = invoice
  }

  async call() {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    if (!this.invoice) return new Error({ message: 'errors.resource.invalid' })

    if (this.invoice.id)
      return new Error({ message: 'errors.resource.existing' })

    return await invoiceCreateAdapter(this.user.token, this.invoice)
  }
}
