<template>
  <v-container id="costCenters-list" fluid tag="section">
    <material-card
      icon="mdi-package-variant"
      icon-small
      :title="$t('list.costCenters.title')"
      color="primary"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :footer-props="footerOptions"
        :server-items-length="totalItems"
        :loading="loading"
        :no-data-text="$t('common.list.no-data')"
        show-expand
      >
        <template v-slot:top>
          <v-container class="mx-1">
            <v-row dense>
              <v-col>
                <Searchbar @submit="searchbarSubmitted" />
              </v-col>
              <v-col cols="1" class="pt-0 pl-5">
                <RoundButton
                  :icon="`mdi-plus`"
                  :alt="$t('list.costCenters.new')"
                  @click="$emit('goto-new')"
                />
              </v-col>
            </v-row>
          </v-container>
        </template>

        <template v-slot:[`item.startDate`]="{ item }">
          <small>{{ item.startDate | date }}</small>
        </template>

        <template v-slot:[`item.endDate`]="{ item }">
          <small>{{ item.endDate | date }}</small>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="actions">
            <v-icon
              class="mr-2 show-costCenter-button"
              @click="$emit('goto-show', item.id)"
              >mdi-eye</v-icon
            >
            <v-icon
              class="mr-2 edit-costCenter-button"
              @click="$emit('goto-edit', item.id)"
              >mdi-pencil</v-icon
            >
            <v-icon
              class="delete-costCenter-button"
              @click="$emit('delete', item.id)"
              >mdi-delete</v-icon
            >
          </div>
        </template>

        <template v-slot:expanded-item="{ item, headers }">
          <td :colspan="headers.length">
            <v-card class="mb-3 mt-2 mx-2">
              <v-card-title class="pb-1">
                {{ $t('entities.costCenter.details') }}
              </v-card-title>
              <v-card-text>
                <ul>
                  <li v-for="detail in item.details" :key="detail.id">
                    {{ detail.name }}
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </td>
        </template>
      </v-data-table>
    </material-card>
  </v-container>
</template>

<script>
import actsAsAList from '@/mixins/actsAsAList'

import Searchbar from '@/components/costCenters/CostCenterSearchbar.vue'
import RoundButton from '@/components/common/RoundButton.vue'

export default {
  name: 'CostCentersList',
  mixins: [actsAsAList],
  components: { Searchbar, RoundButton },
  props: {
    loading: Boolean,
    items: Array,
    page: Number,
    totalItems: Number
  },
  data() {
    return {
      selected: [],
      headers: [
        {
          text: this.$t('entities.costCenter.name'),
          value: 'name'
        },
        {
          text: this.$t('common.fields.startDate'),
          value: 'startDate'
        },
        {
          text: this.$t('common.fields.endDate'),
          value: 'endDate'
        },
        { text: '', value: 'actions', sortable: false }
      ]
    }
  }
}
</script>

<style scoped>
.actions {
  min-width: 100px;
}
</style>
