import validateUser from '@/lib/validateUser'
import productPlaceholdersGetAdapter from '@/adapters/backoffice/products/getPlaceholders'

export default class {
  user = null

  constructor(user) {
    this.user = user
  }

  async call(entity = null) {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    const response = await productPlaceholdersGetAdapter(this.user.token)

    if (response && entity) {
      const { product: placeholders } = response
      for (const field in placeholders) {
        entity[field] = entity[field] || placeholders[field]
      }
    }

    return response ? response.placeholders : null
  }
}
