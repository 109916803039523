import validateUser from '@/lib/validateUser'
import Error from '@/lib/Error'
import costCenterCreateAdapter from '@/adapters/backoffice/costCenters/create'

export default class {
  user = null
  costCenter = null

  constructor(user, costCenter) {
    this.user = user
    this.costCenter = costCenter
  }

  async call() {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    if (!this.costCenter)
      return new Error({ message: 'errors.resource.invalid' })
    if (this.costCenter.id)
      return new Error({
        message: 'errors.resource.existing'
      })

    return await costCenterCreateAdapter(this.user.token, this.costCenter)
  }
}
