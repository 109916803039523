import axios from '@/axios.js'

import ProductFormatter from '../formatters/Product'
import resourceErrorsToResponse from '../formatters/resourceErrorsToResponse'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, id, data) {
  const headers = { Authorization: `Bearer ${token}` }
  const payload = { product: ProductFormatter.toRequestResource(data) }

  if (!data) {
    return {
      error: true,
      errorData: { message: 'errors.resource.invalid' }
    }
  }

  try {
    const { data } = await axios.put(`/api/v1/products/${id}`, payload, {
      headers
    })

    const product = ProductFormatter.fromResponseResource(data.product)
    return { error: false, product }
  } catch (e) {
    return (
      resourceErrorsToResponse(e, ProductFormatter) || exceptionToResponse(e)
    )
  }
}
