import axios from '@/axios.js'
import exceptionToResponse from '../../formatters/exceptionToResponse'

export default async function(token, id) {
  try {
    await axios.delete(`/api/v1/contracts/${id}/signFile`, {
      headers: { Authorization: `Bearer ${token}` }
    })
  } catch (e) {
    return exceptionToResponse(e)
  }
}
