import validateUser from '@/lib/validateUser'
import Error from '@/lib/Error'
import offerUpdateAdapter from '@/adapters/backoffice/offers/update'

export default class {
  user = null
  id = null
  offer = null

  constructor(user, id, offer) {
    this.user = user
    this.id = id
    this.offer = offer
  }

  async call() {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    if (!this.offer || !this.id)
      return new Error({ message: 'errors.resource.invalid' })

    return await offerUpdateAdapter(this.user.token, this.id, this.offer)
  }
}
