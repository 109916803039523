<template>
  <v-form ref="form" @submit.prevent>
    <v-container>
      <v-row>
        <v-col cols="12" lg="12">
          <OfferSelect
            :label="$t('entities.purchase.offer')"
            v-model="entity.offer"
            required
            :error="errors.offer"
            @input="offerChanged"
          />
        </v-col>
        <v-col cols="12" lg="2">
          <TextField
            type="number"
            :label="$t('entities.purchase.quantity')"
            v-model="entity.quantity"
            :error="errors.quantity"
            min="1"
            required
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12">
          <CustomerSelect
            :label="$t('entities.purchase.customer')"
            v-model="entity.customer"
            required
            :error="errors.customer"
            @input="customerChanged"
          />
          <CreateCustomerModal @saved="onCustomerCreated" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12">
          <BeneficiaryContractSelect
            :label="$t('entities.purchase.customerContract')"
            :customer="entity.customer"
            v-model="customerContract"
            :error="errors.customerContract"
            @input="customerContractChanged"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12">
          <CustomerSelect
            :label="$t('entities.purchase.beneficiary')"
            v-model="entity.beneficiary"
            required
            :error="errors.beneficiary"
            @input="beneficiaryChanged"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12">
          <CustomerSelect
            :label="$t('entities.purchase.customer2')"
            v-model="entity.customer2"
            :error="errors.customer2"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="3">
          <DateField
            :label="$t('entities.purchase.purchaseDate')"
            v-model="entity.purchaseDate"
            :error="errors.purchaseDate"
            required
          />
        </v-col>
        <v-col cols="12" lg="3">
          <CardTextField
            v-if="entity.contractNumber"
            :label="$t('entities.purchase.contractNumber')"
            :value="entity.contractNumber"
          />
          <CardTextField
            v-else
            :label="$t('entities.purchase.contractNumber')"
            :value="$t('common.purchase.contract-number-autoassigned')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="3">
          <PurchaseSourceSelect
            :label="$t('entities.purchase.purchaseSource')"
            v-model="entity.purchaseSource"
            :error="errors.purchaseSource"
            required
          />
        </v-col>
        <v-col cols="12" lg="3">
          <PurchaseStatusSelect
            :label="$t('entities.purchase.purchaseStatus')"
            v-model="entity.purchaseStatus"
            :error="errors.purchaseStatus"
            required
          />
        </v-col>
        <v-col cols="12" lg="3">
          <PaymentSourceSelect
            v-model="entity.paymentSource"
            :error="errors.paymentSource"
            required
          />
        </v-col>
        <v-col cols="12" lg="3">
          <PurchaseTermSelect
            :label="$t('entities.purchase.purchaseTerm')"
            v-model="entity.purchaseTerm"
            :error="errors.purchaseTerm"
            required
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="2">
          <TextField
            type="currency"
            :label="$t('entities.purchase.finalPrice')"
            v-model="entity.finalPrice"
            :error="errors.finalPrice"
            required
          />
        </v-col>
        <v-col cols="12" lg="2">
          <TextField
            type="currency"
            :label="$t('entities.purchase.finalDiscount')"
            v-model="entity.finalDiscount"
            :error="errors.finalDiscount"
            required
          />
        </v-col>
        <v-col cols="12" lg="2">
          <CardTextField
            type="currency"
            :label="$t('entities.purchase.untaxedPrice')"
            :value="untaxedPrice"
          />
        </v-col>
        <v-col cols="12" lg="2">
          <VatSelect
            :label="$t('entities.purchase.vatPercent')"
            v-model="entity.vatPercent"
            :error="errors.vatPercent"
            @input="vatChanged"
            required
          />
        </v-col>
        <v-col cols="12" lg="2">
          <CardTextField
            type="currency"
            :label="$t('entities.purchase.vatAmount')"
            :value="tax"
            required
          />
        </v-col>
        <v-col cols="12" lg="2">
          <CardTextField
            type="currency"
            :label="$t('entities.purchase.taxedPrice')"
            :value="taxedPrice"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="2">
          <TextField
            type="number"
            :label="$t('entities.purchase.numberOfInstallments')"
            v-model="entity.numberOfInstallments"
            :error="errors.numberOfInstallments"
            min="1"
            required
          />
        </v-col>
        <v-col cols="12" lg="2">
          <TextField
            type="number"
            :label="$t('entities.purchase.paidInstallments')"
            v-model="entity.paidInstallments"
            :error="errors.paidInstallments"
            :min="1"
            required
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="2">
          <v-switch
            color="success"
            :label="$t('entities.purchase.signatureRequired')"
            v-model="entity.signatureRequired"
            :error="errors.signatureRequired"
          />
        </v-col>
        <v-col cols="12" lg="2">
          <v-switch
            color="success"
            :label="$t('entities.purchase.signed')"
            v-model="entity.signed"
            :error="errors.signed"
          />
        </v-col>
        <v-col cols="12" lg="6">
          <FileField
            :label="$t('entities.purchase.signatureFile')"
            v-model="entity.signatureFile"
            @input="onSignatureFileChange"
            max-size="5"
            :extensions="['pdf']"
            :error="errors.signatureFile"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <Textarea
            :label="$t('entities.purchase.notes')"
            v-model="entity.notes"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { get } from 'vuex-pathify'

import Decimal from '@/lib/Decimal'
import actsAsAForm from '@/mixins/actsAsAForm'
import Error from '@/lib/Error'

import {
  DateField,
  TextField,
  Textarea,
  FileField
} from '@/components/common/form'
import { CardTextField } from '@/components/common/card'

import CustomerSelect from '@/components/personCompanies/CustomerSelect.vue'
import CreateCustomerModal from '@/components/personCompanies/CreateCustomerModal.vue'
import BeneficiaryContractSelect from '@/components/personCompanies/BeneficiaryContractSelect.vue'
import OfferSelect from '@/components/offers/OfferSelect.vue'
import PurchaseStatusSelect from './PurchaseStatusSelect'
import PurchaseTermSelect from './PurchaseTermSelect'
import PurchaseSourceSelect from './PurchaseSourceSelect'
import PaymentSourceSelect from './PaymentSourceSelect'
import VatSelect from './VatSelect'

import Purchase from '@/domain/entities/Purchase'
import Offer from '@/domain/entities/Offer'
import PersonAndCompany from '@/domain/entities/PersonAndCompany'
import GetOffer from '@/domain/useCases/offers/GetOffer'

export default {
  name: 'PurchaseForm',
  mixins: [actsAsAForm],
  components: {
    CustomerSelect,
    CreateCustomerModal,
    BeneficiaryContractSelect,
    OfferSelect,
    DateField,
    TextField,
    Textarea,
    FileField,
    CardTextField,
    PurchaseStatusSelect,
    PurchaseTermSelect,
    PurchaseSourceSelect,
    PaymentSourceSelect,
    VatSelect
  },
  props: {
    value: Purchase,
    errors: Object
  },
  data() {
    return { customerContract: null }
  },
  computed: {
    user: get('user'),
    tax() {
      let result = Decimal.fromUI(this.untaxedPrice) || Decimal.zero()
      const vat = Decimal.fromUI(this.entity.vatPercent) || Decimal.zero()

      return result.percentage(vat).forUI()
    },
    untaxedPrice() {
      if (!this.entity || !this.entity.finalPrice) return null

      const { finalPrice, finalDiscount } = this.entity

      let result = Decimal.fromUI(finalPrice) || Decimal.zero()
      const discount = Decimal.fromUI(finalDiscount) || Decimal.zero()

      return result.subtract(discount).forUI()
    },
    taxedPrice() {
      if (!this.entity || !this.entity.finalPrice) return null

      let result = Decimal.fromUI(this.untaxedPrice) || Decimal.zero()
      const vat = Decimal.fromUI(this.tax) || Decimal.zero()

      return result.add(vat).forUI()
    }
  },
  methods: {
    offerChanged() {
      const zero = Decimal.zeroForUI()
      const selectedOffer = this.entity.offer

      /// Note that after the call to the use case this.entity.offer is a _full_
      /// Offer with nested objects, until a new offer is selected.
      if (selectedOffer) {
        /// Request full offer data from the backend.
        new GetOffer(this.user).call(selectedOffer.id).then(result => {
          if (result.error) {
            new Error({
              message: 'common.autocomplete.error-loading'
            }).toAlert()
          } else {
            this.entity.offer = result.offer
            this.entity.finalPrice = this.entity.offer.offerPrice
            this.setDiscount(this.entity.offer.offerPrice)
          }
        })
      } else {
        this.entity.finalDiscount = zero
        this.entity.finalPrice = zero
      }
    },
    customerChanged() {
      const { customer, beneficiary } = this.entity
      if (customer && !beneficiary)
        this.entity.beneficiary = new PersonAndCompany(customer)
      this.setDiscount()
    },
    customerContractChanged() {
      this.setDiscount()
      this.entity.contract = this.customerContract
    },
    beneficiaryChanged() {
      const { beneficiary, customer } = this.entity
      if (beneficiary && !customer)
        this.entity.customer = new PersonAndCompany(beneficiary)
    },
    vatChanged() {
      let result = Decimal.fromUI(this.untaxedPrice)
      const vat = Decimal.fromUI(this.entity.vatPercent)

      this.entity.vatAmount = result.percentage(vat).forUI()
    },
    // Note: the price that needs to be discounted could be either from a
    // freshly selected offer or from user input.
    setDiscount(price = this.entity.finalPrice) {
      if (!this.entity.offer) return

      const offer = new Offer(this.entity.offer)

      this.entity.finalDiscount = offer
        ? offer.calculateDiscount(this.customerContract, price)
        : Decimal.zeroForUI()
    },
    onSignatureFileChange(signatureFile) {
      this.entity.signed = !!signatureFile
    },
    onCustomerCreated(customerCreated) {
      const personCompany = customerCreated.personCompanies[0]
      const { id, company, position, roles } = personCompany
      this.entity.customer = PersonAndCompany.newCustomer({
        id,
        person: customerCreated.person,
        company,
        position,
        roles
      })
    }
  },
  watch: {
    tax(newValue) {
      this.entity.vatAmount = newValue
    }
  }
}
</script>
