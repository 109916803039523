<template>
  <div>
    <PurchasesList
      :loading="loading"
      :items="items"
      :page="page"
      :totalItems="totalItems"
      @refresh="refresh"
      @delete="deleteEntity"
      @goto-new="gotoNew"
      @goto-edit="gotoEdit"
      @goto-show="gotoShow"
      @create-invoice="gotoCreateInvoice"
      @show-invoices="gotoShowInvoices"
    />
    <v-dialog v-model="deleteDialog" max-width="400px">
      <ConfirmDialog
        :title="$t('common.purchase.delete.title')"
        :message="$t('common.purchase.delete.confirm-text')"
        :yesText="$t('common.delete.yes')"
        :noText="$t('common.delete.no')"
        @yesClick="deleteConfirmed"
        @noClick="deleteCanceled"
      />
    </v-dialog>
  </div>
</template>

<script>
import actsAsAListPage from '@/mixins/actsAsAListPage'
import { isArray } from '@/lib/Utils'

import PurchasesList from '@/components/purchases/PurchasesList'
import ConfirmDialog from '@/components/common/ConfirmDialog.vue'

import GetPurchases from '@/domain/useCases/purchases/GetPurchases'
import DeletePurchase from '@/domain/useCases/purchases/DeletePurchase'

export default {
  name: 'PurchasesListPage',
  mixins: [actsAsAListPage],
  components: { PurchasesList, ConfirmDialog },
  data() {
    return {
      actsAsAListPage: {
        resource: 'purchases',
        get: GetPurchases,
        delete: DeletePurchase
      }
    }
  },
  methods: {
    gotoCreateInvoice(idOrIds) {
      if (!idOrIds) return
      const ids = !isArray(idOrIds) ? [idOrIds] : idOrIds
      this.$router.push({
        name: 'NewPurchaseInvoicePage',
        params: { ids }
      })
    },
    gotoShowInvoices(purchase) {
      if (!purchase) return

      this.$router.push({
        name: 'InvoicesPageByPurchase',
        params: { purchase }
      })
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
