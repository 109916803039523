import axios from '@/axios.js'

import PurchaseFormatter from '../formatters/Purchase'
import resourceErrorsToResponse from '../formatters/resourceErrorsToResponse'
import exceptionToResponse from '../formatters/exceptionToResponse'

import updateSignatureFile from './signatureFile/update'

export default async function(token, entity) {
  const signatureFile = entity.signatureFile
  const headers = { Authorization: `Bearer ${token}` }
  const payload = { purchase: PurchaseFormatter.toRequestResource(entity) }

  try {
    const { data } = await axios.post('/api/v1/purchases', payload, { headers })

    const purchase = PurchaseFormatter.fromResponseResource(data.purchase)

    if (signatureFile) {
      const purchaseId = purchase.id
      const uploadResponse = await updateSignatureFile(
        token,
        purchaseId,
        signatureFile
      )

      if (uploadResponse.error) {
        uploadResponse.message = 'entities.purchase.error.signed-document'
        return uploadResponse
      } else {
        purchase.signatureFile = uploadResponse.signatureFile
      }
    }

    return { error: false, purchase }
  } catch (e) {
    return (
      resourceErrorsToResponse(e, PurchaseFormatter, 'purchase') ||
      exceptionToResponse(e)
    )
  }
}
