import validateUser from '@/lib/validateUser'
import ContractReasonsListAdapter from '@/adapters/backoffice/customerContracts/getContractReasons'

export default class {
  user = null

  constructor(user) {
    this.user = user
  }

  async call() {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    return await ContractReasonsListAdapter(this.user.token)
  }
}
