export default class {
  static new() {
    return new this()
  }

  static default() {
    return new this()
  }

  constructor({ id = null, name = null } = {}) {
    this.id = id
    this.name = name
  }
}
