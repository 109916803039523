import PurchaseSource from '@/domain/entities/PurchaseSource'
import Base from './Base'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null

    const entity = this.fieldNamesFromResponse(resource)
    return new PurchaseSource(entity)
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    if (!resource) return null
    const { id, descr } = resource
    const result = { id, name: descr }
    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(entity, removeEmpty = false) {
    if (!entity) return null
    const { id, name } = entity
    const result = { id, descr: name }
    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }
}
