<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12" lg="9">
        <material-card
          icon="mdi-file-document-multiple"
          icon-small
          :title="$t('common.sections.titles.contract')"
          color="primary"
        >
          <ContractForm ref="form" v-model="entity" :errors="errors" />
        </material-card>
      </v-col>
      <v-col cols="12" lg="3" order="first" order-lg="last">
        <ContractSummary :entity="entity">
          <v-divider />
          <template #controls>
            <v-row>
              <v-col cols="3" md="6">
                <v-btn color="success" width="100%" @click="save()">
                  {{ $t('common.form.save') }}
                </v-btn>
              </v-col>
              <v-col cols="3" md="6">
                <v-btn color="warning" width="100%" @click="leave()">
                  {{ $t('common.leave') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </ContractSummary>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsACreatePage from '@/mixins/actsAsACreatePage'

import ContractForm from '@/components/contracts/ContractForm.vue'
import ContractSummary from '@/components/contracts/ContractSummary.vue'

import Contract from '@/domain/entities/Contract'
import CreateContract from '@/domain/useCases/contracts/CreateContract'

export default {
  name: 'NewContractPage',
  mixins: [actsAsACreatePage],
  components: { ContractForm, ContractSummary },
  data() {
    return {
      actsAsACreatePage: {
        entity: 'contract',
        resource: 'contracts',
        create: CreateContract
      },
      entity: Contract.default()
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
