import axios from '@/axios.js'

import PaymentFormatter from '../formatters/Payment'
import resourceErrorsToResponse from '../formatters/resourceErrorsToResponse'
import exceptionToResponse from '../formatters/exceptionToResponse'

import updateFile from './file/update'

export default async function(token, entity) {
  const file = entity.invoice
  const headers = { Authorization: `Bearer ${token}` }
  const payload = { payment: PaymentFormatter.toRequestResource(entity) }

  try {
    const { data } = await axios.post('/api/v1/payments', payload, { headers })

    const payment = PaymentFormatter.fromResponseResource(data.payment)

    if (file) {
      const paymentId = payment.id
      const uploadResponse = await updateFile(token, paymentId, file)

      if (uploadResponse.error) {
        uploadResponse.message = 'entities.payment.error.signed-document'
        return uploadResponse
      } else {
        payment.file = uploadResponse.file
      }
    }

    return { error: false, payment }
  } catch (e) {
    return (
      resourceErrorsToResponse(e, PaymentFormatter, 'payment') ||
      exceptionToResponse(e)
    )
  }
}
