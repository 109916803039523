<template>
  <SelectField
    :label="label"
    :value="value"
    :items="items"
    :error="error"
    :required="required"
    @input="onChange"
  />
</template>

<script>
import { get } from 'vuex-pathify'
import Error from '@/lib/Error'
import SelectField from '@/components/common/form/SelectField'
import GetVats from '@/domain/useCases/invoices/GetVats'

export default {
  name: 'VatSelect',
  components: { SelectField },
  props: {
    label: String,
    value: String,
    required: Boolean,
    error: String
  },
  data() {
    return { items: [] }
  },
  computed: {
    user: get('user')
  },
  methods: {
    onChange(newValue) {
      this.$emit('input', newValue)
    }
  },
  created() {
    new GetVats(this.user).call().then(result => {
      if (result.error) {
        new Error({
          message: 'common.autocomplete.error-loading'
        }).toAlert()
        console.info(`Error loading vats information for select:`, result)
      } else {
        this.items = result.vats.map(vat => vat.value)
      }
    })
  }
}
</script>
