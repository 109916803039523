import validateUser from '@/lib/validateUser'
import Error from '@/lib/Error'
import paymentCreateAdapter from '@/adapters/backoffice/payments/create'

export default class {
  user = null
  payment = null
  invoiceDocument = null

  constructor(user, payment, invoiceDocument) {
    this.user = user
    this.payment = payment
    this.invoiceDocument = invoiceDocument
  }

  async call() {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    if (!this.payment) return new Error({ message: 'errors.resource.invalid' })
    if (this.payment.id)
      return new Error({
        message: 'errors.resource.existing'
      })

    return await paymentCreateAdapter(
      this.user.token,
      this.payment,
      this.invoiceDocument
    )
  }
}
