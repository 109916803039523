import Company from './Company'
export default class PersonCompanies {
  static new() {
    return new this({
      company: Company.new()
    })
  }

  static default() {
    return this.new()
  }

  static newCustomerPersonCompany() {
    return new this({
      type: 'customer',
      position: 'external',
      roles: [],
      company: Company.new()
    })
  }

  static newSupplierPersonCompany() {
    return new this({
      type: 'supplier',
      position: 'internal',
      roles: [],
      company: Company.new()
    })
  }

  constructor({
    id = null,
    type = null,
    position = null,
    roles = [],
    company = null,
    startDate = null,
    endDate = null
  } = {}) {
    this.id = id
    this.type = type // "customer" or "supplier"
    this.position = position
    this.roles = roles
    this.company = company // Instance of Company
    this.startDate = startDate
    this.endDate = endDate
  }
}
