<template>
  <v-form ref="form" @submit.prevent v-if="costCenters">
    <material-card
      color="primary"
      :title="$t('entities.payment.costCenter.name')"
    >
      <v-col>
        <v-btn color="secondary" @click="addNew">
          {{ $t('common.payment.costCenterDetail.add') }}
        </v-btn>
      </v-col>
      <div v-for="(costCenterDetail, index) in costCenterDetails" :key="index">
        <PaymentCostCenterForm
          :costCenters="costCenters"
          v-model="entity.costCenterDetails[index]"
          :errors="errorsAtIndex(index)"
          :payment="value"
          @remove="removeAt"
        />
        <hr />
      </div>
    </material-card>
  </v-form>
</template>

<script>
import Payment from '@/domain/entities/Payment'
import PaymentCostCenterDetail from '@/domain/entities/PaymentCostCenterDetail'
import actsAsAForm from '@/mixins/actsAsAForm'
import PaymentCostCenterForm from './PaymentCostCenterForm.vue'
import { dig } from '@/lib/Utils'
import { get } from 'vuex-pathify'
import GetCostCenters from '@/domain/useCases/costCenters/GetCostCenters'

export default {
  name: 'PaymentCostCentersForm',
  mixins: [actsAsAForm],
  components: {
    PaymentCostCenterForm
  },
  props: {
    value: Payment,
    errors: Array
  },
  data() {
    return { costCenters: [] }
  },
  computed: {
    user: get('user'),
    costCenterDetails() {
      return dig(this.value, 'costCenterDetails') || []
    }
  },
  methods: {
    addNew() {
      this.entity.costCenterDetails.push(
        new PaymentCostCenterDetail({
          payment: this.value
        })
      )
      this.$emit('input', this.entity)
    },
    removeAt(idx) {
      this.entity.costCenterDetails = this.entity.costCenterDetails.filter(
        (_el, _idx) => _idx !== idx
      )
    },
    errorsAtIndex(idx) {
      return dig(this.errors, idx) || {}
    }
  },
  mounted() {
    new GetCostCenters(this.user).call().then(result => {
      if (result.error) {
        new Error({
          message: 'common.autocomplete.error-loading'
        }).toAlert()
        console.info(`Error loading job for autocomplete:`, result)
      } else this.costCenters = result.costCenters
    })
  }
}
</script>
