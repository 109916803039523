import { dateForUI } from '@/lib/Utils'

export default {
  names: {
    edition: 'Edizione',
    product: 'Prodotto',
    contract: 'Contratto'
  },
  person: {
    id: 'ID',
    active: 'Attivo',
    name: 'Nome',
    surname: 'Cognome',
    fiscalCode: 'Codice fiscale',
    phone: 'Telefono',
    mobile: 'Cellulare',
    email: 'E-Mail',
    notes: 'Note',
    intolerances: 'Intolleranze',
    nation: 'Nazione',
    birthDate: 'Data di nascita',
    region: 'Regione',
    county: 'Provincia',
    city: 'Città',
    postalCode: 'CAP',
    address1: 'Indirizzo',
    address2: 'Indirizzo (continuo)',
    startDate: 'Data di acquisizione',
    endDate: 'Data di chiusura'
  },

  company: {
    id: 'ID',
    fiscalCode: 'Codice fiscale',
    vatCode: 'Partita IVA',
    iban: 'Codice IBAN',
    invoiceName: 'Ragione sociale',
    invoiceAddress: 'Indirizzo (fattura)',
    invoiceNation: 'Nazione (fattura)',
    invoiceRegion: 'Regione (fattura)',
    invoiceCounty: 'Provincia (fattura)',
    invoiceCity: 'Città (fattura)',
    invoicePostalCode: 'CAP (fattura)',
    invoicePec: 'PEC',
    invoiceSdi: 'SDI',
    invoiceCig: 'CIG',
    invoiceCup: 'CUP',
    startDate: 'Data acquisizione cliente',
    endDate: 'Data di chiusura cliente',
    notes: 'Note cliente'
  },

  personCompany: {
    company: 'Azienda',
    roles: 'Ruoli',
    position: 'Posizione',
    positions: {
      internal: 'Interno',
      external: 'Esterno'
    }
  },

  product: {
    id: 'ID',
    name: 'Nome',
    code: 'Codice',
    category: 'Categoria',
    categories: {
      course: 'Corso',
      'special-course': 'Corso speciale',
      seminar: 'Seminario',
      class: 'Classe',
      event: 'Evento',
      unknown: '?'
    },
    type: 'Tipo',
    types: {
      'course-only-video': 'Video corso',
      video: 'Video',
      live: 'Online',
      unknown: '?'
    },
    price: 'Prezzo listino',
    language: 'Lingua prodotto',
    description: 'Descrizione',
    durationHours: 'Ore per sessione',
    durationDays: 'Durata in giorni',
    prerequisites: 'Prerequisiti',
    minAttendees: 'Minimo partecipanti',
    maxAttendees: 'Massimo partecipanti',
    notes: 'Note'
  },

  edition: {
    id: 'ID',
    active: 'Attiva',
    genericProduct: 'Nome',
    genericProductType: 'Tipo prodotto',
    genericProductTypes: {
      product: 'Prodotto',
      contract: 'Contratto'
    },
    showOnCalendar: 'Mostra nel calendario',
    price: 'Prezzo',
    fromDate: 'Da',
    toDate: 'A',
    durationHours: 'Ore per sessione',
    durationDays: 'Durata in giorni',
    lecturers: 'Relatori',
    lecturer: {
      lecturer: 'Relatore',
      fee: 'Compenso',
      hourlyFee: 'Compenso orario',
      type: 'Tipo relatore'
    },
    placeDescription: 'Tipo di locazione',
    place: 'Locazione',
    minAttendees: 'Minimo partecipanti',
    maxAttendees: 'Massimo partecipanti',
    language: 'Lingua',
    notes: 'Note'
  },

  offer: {
    id: 'ID',
    active: 'Attivo',
    name: 'Nome',
    description: 'Descrizione',
    editionsTotalPrice: 'Prezzo commerciale',
    offerPrice: 'Prezzo finale offerta',
    fromDate: 'Da',
    toDate: 'A',
    standardDiscountApplicable: 'Sconto standard?',
    discountOverride: 'Forza sconto?',
    deposit: 'Anticipo',
    paymentDelay: 'Pagamento posticipato (mesi)',
    installmentsNumber: 'Numero rate',
    installmentsAmountEach: 'Prezzo per rata',
    installmentsAmountExtra: 'Maxi rata finale',
    notes: 'Note',
    contractOverrides: {
      title: 'Sconti personalizzati',
      id: 'ID',
      contract: 'Contratto',
      overridden: 'Forzato?',
      discountType: 'Tipo sconto',
      discount: 'Nuovo sconto',
      discountTypes: {
        percentage: 'Percentuale',
        value: 'Valore'
      },
      discountPercentage: 'Percentuale sconto',
      discountValue: 'Valore sconto',
      notes: 'Note'
    },
    editions: 'Edizioni'
  },

  contract: {
    id: 'ID',
    active: 'Attivo',
    code: 'Codice',
    version: '# versione',
    type: 'Tipo di contratto',
    types: {
      coaching: 'Coaching',
      product: 'Prodotto'
    },
    name: 'Nome',
    description: 'Descrizione',
    price: 'Prezzo',
    discount: 'Sconto standard',
    validFrom: 'Valido da',
    validTo: 'Valido a',
    duration: 'Durata',
    autoRenewal: 'Tacito rinnovo',
    renewalStep: 'Intervallo rinnovo (Mesi)',
    pdf: 'Contratto formato PDF',
    details: 'Dettagli del contratto',
    detail: {
      id: 'ID',
      description: 'Descrizione',
      value: 'Valore',
      bonus: 'È un extra?'
    }
  },

  customerContract: {
    id: 'ID',
    code: 'Prog. acquisto',
    version: '# versione',
    type: 'Tipo di contratto',
    types: {
      coaching: 'Coaching',
      product: 'Prodotto'
    },
    contract: {
      name: 'Nome contratto del cliente'
    },
    name: 'Nome',
    description: 'Descrizione',
    previousContract: { name: 'Contratto precedente del cliente' },
    buyer: 'Acquirente',
    beneficiary: 'Beneficiario',
    discount: 'Sconto standard',
    validFrom: 'Valido da',
    validTo: 'Valido a',
    endReason: 'Motivazione',
    cancellationDate: 'Data disdetta contratto',
    purchaseDate: 'Data acquisto',
    partnersip: 'Partnership',
    autoRenewal: 'Tacito rinnovo',
    renewalStep: 'Intervallo rinnovo (Mesi)',
    pdf: 'Contratto firmato',
    notes: 'Note',
    details: "Dettagli dell'istanza del contratto",
    detail: {
      id: 'ID',
      description: 'Descrizione',
      value: 'Valore',
      max: 'Max',
      notes: 'Note'
    }
  },

  purchase: {
    id: 'ID',
    customer: 'Cliente',
    'purchase-customer': {
      name: 'Nome cliente',
      surname: 'Cognome cliente'
    },
    customerContract: 'Contratto per applicazione sconti',
    beneficiary: 'Beneficiario',
    'purchase-beneficiary': {
      name: 'Nome beneficiario',
      surname: 'Cognome beneficiario'
    },
    customer2: 'Partner',
    'purchase-customer2': {
      name: 'Nome cliente 2',
      surname: 'Cognome cliente 2'
    },
    offer: 'Offerta',
    quantity: 'Quantità',
    sellerLine: 'Linea vendita',

    contractNumber: 'Prog. acquisto',
    purchaseDate: 'Data di acquisto',
    purchaseDateFilter: {
      start: 'Data acquisto inizio',
      end: 'Data acquisto fine'
    },
    purchaseStatus: 'Pronto per la fatturazione',
    purchaseStatuses: {
      KO: 'No',
      OK: 'Si'
    },
    purchaseSource: 'Luogo di acquisto',
    paymentSource: 'Metodo di pagamento',
    purchaseTerm: 'Codice pagamento',

    finalPrice: 'Prezzo',
    finalDiscount: 'Sconto',
    vatPercent: 'IVA %',
    vatAmount: 'Ammontare IVA',
    untaxedPrice: 'Prezzo senza IVA',
    taxedPrice: 'Prezzo con IVA',
    signatureRequired: 'Firma richiesta',
    signed: 'Firmato',
    signatureFile: 'File della firma',
    numberOfInstallments: 'Numero rate',
    paidInstallments: 'Rate pagate',
    notes: 'Note',
    error: {
      'signed-document': 'Errore upload documento di acquisto'
    }
  },

  invoice: {
    id: 'ID',
    code: 'Numero fattura',
    version: 'Versione fattura',
    type: 'Tipo nota',
    types: {
      ND: 'Debito',
      NC: 'Credito'
    },
    branch: 'Ramo',
    date: 'Data fatturazione',
    dueDate: 'Data scadenza',
    paymentDate: 'Data incasso pagamento',
    sdiStatus: 'Stato SDI',
    statuses: {
      sdiNotSent: 'Non inviato allo SDI',
      sdiPending: 'Inviato e in attesa di risposta dallo SDI',
      sdiFailed: 'Inviato e rifiutato dallo SDI',
      sdiOk: 'Inviata corretamente allo SDI'
    },
    sdiInvoiceNumber: '# SDI',
    sdiSubmissionDate: 'Data invo a SDI',
    sdiResponseDate: 'Data risposta da SDI',
    sdiCheckResult: 'Controllo SDI',
    sdiResponseCode: 'Codice risposta SDI',
    name: 'Ragione sociale',
    vatCode: 'P. IVA',
    fiscalCode: 'Codice fiscale',
    address: 'Indirizzo',
    contractName: 'Contratto applicato',
    sdi: 'Codice SDI',
    pec: 'PEC',
    amount: 'Imponibile',
    vatAmount: 'Importo IVA',
    taxAmount: "Ritenuta d'acconto",
    stampAmount: 'Bollo',
    totalAmount: 'Prezzo finale',
    bankAccount: 'Conto corrente di ricezione',
    bankAccountName: 'Nome conto',
    bankAccountIban: 'IBAN conto',
    notes: 'Note',
    issuable: 'Emittibile',
    fiscalType: 'Codice natura fattura',
    fiscalTypeDescription: 'Descrizione natura fattura',
    fiscalTypeRegsReference: 'Riferimento normativo',
    purchaseSourceDescription: 'Fonte acquisto',
    paymentSourceDescription: 'Fonte del pagamento',
    deductionType: 'Tipo ritenuta',
    deductionRate: 'Aliquota ritenuta',
    details: 'Dettaglio fattura',
    detail: {
      'default-description': ({ named }) => {
        return `Dettaglio acquisto numero contratto ${named(
          'contractNumber'
        )}, "${named('offerName')}" in data ${dateForUI(named('date'))}.`
      },
      date: 'Data acquisto',
      contractNumber: 'Numero contratto',
      offerName: 'Offerta',
      description: 'Descrizione',
      amount: ' Ammontare',
      discount: 'Sconto',
      vatAmount: 'Ammontare IVA',
      vatPerc: 'Percentuale IVA'
    },
    costCenter: {
      name: 'Centro di costo',
      detail: {
        name: 'Nome team'
      },
      percentage: 'Percentuale imponibile',
      amount: 'Imponibile'
    },
    job: {
      name: 'Commessa',
      detail: {
        name: 'Sotto commessa'
      },
      percentage: 'Percentuale imponibile',
      amount: 'Imponibile'
    }
  },

  payment: {
    supplier: {
      name: 'Nome fornitore',
      surname: 'Cognome fornitore',
      fullName: 'Fornitore'
    },
    type: 'Tipo fattura',
    date: 'Data fattura',
    duoDate: 'Data scadenza',
    paymentDate: 'Data pagamento',
    bankAccount: 'Conto corrente',
    paymentCard: 'Carta',
    untaxedPrice: 'Imponibile',
    vatAmount: 'Ammontare IVA',
    description: 'Causale',
    notes: 'Note',
    invoice: 'Scansione fattura',
    error: {
      'invoice-upload': 'Errore upload fattura'
    },
    costCenter: {
      name: 'Centro di costo',
      detail: {
        name: 'Nome team'
      },
      percentage: 'Percentuale imponibile',
      amount: 'Imponibile'
    },
    job: {
      name: 'Commessa',
      detail: {
        name: 'Sotto commessa'
      },
      percentage: 'Percentuale imponibile',
      amount: 'Imponibile'
    }
  },
  costCenter: {
    active: 'Attivi',
    branch: 'Filiale',
    name: 'Nome',
    notes: 'Note',
    details: 'Nome team',
    detail: {
      id: 'ID',
      name: 'Nome team'
    }
  },
  job: {
    id: 'ID',
    active: 'Attivi',
    branch: 'Filiale',
    name: 'Nome',
    notes: 'Note',
    details: 'Nome sottocommessa',
    jobAndDetail: {
      id: 'ID'
    },
    detail: {
      id: 'ID',
      name: 'Nome sottocommessa'
    }
  }
}
