import Error from '@/lib/Error'

export default function(exception) {
  let message = 'errors.connection.error'
  let status
  if (exception.response) {
    status = exception.response.status

    switch (true) {
      case status === 401:
        message = 'errors.auth.unauthorized'
        break
      case status === 403:
        message = 'errors.auth.forbidden'
        break
      case status === 404:
        message = 'errors.not-found'
        break
      case status < 500:
        message = 'errors.connection.invalid-request'
        break
      default:
        message = 'errors.connection.backend-error'
        status = 500
    }
  } else if (exception.request) {
    message = 'errors.connection.backend-unreachable'
  }

  return new Error({ message, status, data: exception })
}
