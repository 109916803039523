import { today, dig } from '@/lib/Utils'
import Decimal from '@/lib/Decimal'

export default class {
  static new() {
    return new this()
  }

  static default() {
    return new this({
      quantity: 1,
      purchaseDate: today(),
      finalPrice: '0,00',
      finalDiscount: '0,00',
      paidInstallments: '0',
      signatureRequired: false,
      signed: false
    })
  }

  constructor({
    id = null,

    invoice = null, // Reference and possibly data for the related invoice if present
    customer = null, // PersonCompany Entity
    // This is a CustomerContract the user is already suscribed to, and it's used to
    // determine which discount, if any, to apply
    contract = null,
    beneficiary = null, // PersonCompany Entity
    customer2 = null, // PersonCompany Entity
    offer = null,
    quantity = null,
    // sellerLine = null, // TODO
    purchaseSource = null, // Where the purchase started from (e.g. Kajabi, Shopify)
    purchaseTerm = null, // How the purchase  was/will be paid (formal code, e.g. MP19 or "SEPA direct debit")
    paymentSource = null, // How the purchase  was/will be paid (general name, e.g. credit card, Paypal)

    contractNumber = null,
    purchaseDate = null,
    purchaseStatus = null,

    finalDiscount = null,
    finalPrice = null,
    vatPercent = null, // Percent to tax
    vatAmount = null, // Price addition due to taxes
    signatureRequired = null,
    signed = null,
    signatureFile = null,
    numberOfInstallments = null,
    paidInstallments = null,
    notes = null,

    creationDate = null,
    startDate = null,
    lastModified = null,
    lastModifiedBy = null
  } = {}) {
    this.id = id
    this.invoice = invoice
    this.customer = customer
    this.contract = contract
    this.customer2 = customer2
    this.beneficiary = beneficiary
    this.offer = offer
    this.quantity = quantity
    // this.sellerLine = sellerLine // TODO
    this.purchaseSource = purchaseSource
    this.purchaseTerm = purchaseTerm
    this.paymentSource = paymentSource

    this.contractNumber = contractNumber
    this.purchaseDate = purchaseDate
    this.purchaseStatus = purchaseStatus

    this.finalDiscount = finalDiscount
    this.finalPrice = finalPrice
    this.vatPercent = vatPercent
    this.vatAmount = vatAmount
    this.signatureRequired = signatureRequired
    this.signed = signed
    this.signatureFile = signatureFile
    this.numberOfInstallments = numberOfInstallments
    this.paidInstallments = paidInstallments
    this.notes = notes

    this.creationDate = creationDate
    this.startDate = startDate
    this.lastModified = lastModified
    this.lastModifiedBy = lastModifiedBy
  }

  isValid() {
    return this.id && String(this.purchaseStatus) === 'OK'
  }

  hasInvoice() {
    return !!dig(this, 'invoice', 'id')
  }

  isFree() {
    return (
      [null, undefined, 0, '0'].includes(this.finalPrice) ||
      Decimal.fromUI(this.finalPrice).toNumber() == 0
    )
  }

  isInvoiceable() {
    return this.isValid() && !this.hasInvoice() && !this.isFree()
  }
}
