<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12" lg="9">
        <material-card
          icon="mdi-package-variant"
          icon-small
          color="primary"
          :title="$t('common.sections.titles.invoice')"
        >
          <InvoiceCard :entity="entity" />
          <InvoiceJobsCard :value="entity" />
          <InvoiceDetailsCard :items="entity.details" />
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsAShowPage from '@/mixins/actsAsAShowPage'

import InvoiceCard from '@/components/invoices/InvoiceCard.vue'
import InvoiceDetailsCard from '@/components/invoices/InvoiceDetailsCard.vue'
import InvoiceJobsCard from '@/components/invoices/InvoiceJobsCard.vue'

import Invoice from '@/domain/entities/Invoice'
import GetInvoice from '@/domain/useCases/invoices/GetInvoice'

export default {
  name: 'EditInvoiceJobsPage',
  mixins: [actsAsAShowPage],
  components: {
    InvoiceCard,
    InvoiceDetailsCard,
    InvoiceJobsCard
  },
  props: { id: String },
  data() {
    return {
      actsAsAShowPage: {
        resource: 'invoices',
        entity: 'invoice',
        get: GetInvoice
      },
      entity: Invoice.default(),
      sendDialog: false
    }
  },
  setaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
