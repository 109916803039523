import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import { i18n, vuetify, Vue2Filters } from './plugins'
import './filters'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  vuetify,
  mixins: [Vue2Filters.mixin],
  render: h => h(App)
}).$mount('#app')
