import validateUser from '@/lib/validateUser'
import paymentCostCentersAdapter from '@/adapters/backoffice/payments/updateCostCenters'

export default class {
  user = null
  id = null
  payment = null

  constructor(user, id, payment) {
    this.user = user
    this.id = id
    this.payment = payment
  }

  async call() {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    return await paymentCostCentersAdapter(
      this.user.token,
      this.id,
      this.payment
    )
  }
}
