import axios from '@/axios.js'

import PersonAndCompaniesFormatter from '../formatters/PersonAndCompanies'
import resourceErrorsToResponse from '../formatters/resourceErrorsToResponse'
import exceptionToResponse from '../formatters/exceptionToResponse'

import updatePersonPhoto from '../person/photo/update'

export default async function(token, entity) {
  const photo = entity.person.photo

  const headers = { Authorization: `Bearer ${token}` }
  const payload = {
    userCompanies: PersonAndCompaniesFormatter.toRequestResource(entity)
  }

  try {
    const { data } = await axios.post(
      '/api/v1/userCompanies/customer',
      payload,
      { headers }
    )

    const customer = PersonAndCompaniesFormatter.fromResponseResource(data)
    /// Save the photo separately now that the customer exists.
    if (photo) {
      const id = customer.person.id
      const result = await updatePersonPhoto(token, id, photo)

      if (result.error) {
        return result
      } else {
        customer.person.photo = result.photo
      }
    }

    return { error: false, customer }
  } catch (e) {
    return (
      resourceErrorsToResponse(e, PersonAndCompaniesFormatter) ||
      exceptionToResponse(e)
    )
  }
}
