var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"invoices-list","fluid":"","tag":"section"}},[_c('material-card',{attrs:{"icon":"mdi-receipt","icon-small":"","title":_vm.$t('list.invoices.title'),"color":"primary"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"footer-props":_vm.footerOptions,"server-items-length":_vm.totalItems,"loading":_vm.loading,"no-data-text":_vm.$t('common.list.no-data')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{staticClass:"mx-1"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('Searchbar',{on:{"submit":_vm.searchbarSubmitted}})],1),_c('v-col',{staticClass:"pt-0 pl-5",attrs:{"cols":"1"}})],1)],1)]},proxy:true},{key:"item.sdiStatus",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.sdiNotSent())?_c('v-icon',_vm._g({attrs:{"color":"warning"}},on),[_vm._v("mdi-minus-circle")]):(item.sdiPending())?_c('v-icon',_vm._g({attrs:{"color":"warning"}},on),[_vm._v("mdi-help-circle")]):(item.sdiFailed())?_c('v-icon',_vm._g({attrs:{"color":"error"}},on),[_vm._v("mdi-close-circle")]):_c('v-icon',_vm._g({attrs:{"color":"success"}},on),[_vm._v("mdi-checkbox-marked-circle")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.invoiceStatusAsString(item))+" ")])],1)]}},{key:"item.branch",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("dig")(item.branch,'description')))])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("date")(item.date)))])]}},{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("date")(item.dueDate)))])]}},{key:"item.paymentDate",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("date")(item.paymentDate)))])]}},{key:"item.creationDate",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("dateTime")(item.creationDate)))])]}},{key:"item.lastModified",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("dateTime")(item.lastModified)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions"},[_c('v-icon',{staticClass:"mr-2 show-invoice-button",on:{"click":function($event){return _vm.$emit('goto-show', item.id)}}},[_vm._v("mdi-eye")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }