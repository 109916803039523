<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12" lg="9">
        <material-card
          icon="mdi-credit-card"
          icon-small
          color="primary"
          :title="$t('common.sections.titles.payment')"
        >
          <PaymentCard :entity="entity" />
          <PaymentCostCentersCard :value="entity" />
          <PaymentJobsCard :value="entity" />
        </material-card>
      </v-col>
      <v-col cols="12" lg="3" order="first" order-lg="last">
        <PaymentSummary :entity="entity">
          <SummaryCommandBanner
            icon="mdi-package-variant"
            :text="$t('common.payment.costCenterDetail.edit')"
            @click="gotoCostCenters"
          />
          <SummaryCommandBanner
            icon="mdi-package-variant"
            :text="$t('common.payment.jobDetail.edit')"
            @click="gotoJobs"
          />
          <SummaryCommandBanner
            icon="mdi-view-list"
            :text="$t('common.back-to-list')"
            @click="gotoList"
          />
          <SummaryCommandBanner
            icon="mdi-pencil"
            :text="$t('common.payment.edit')"
            @click="gotoEdit"
          />
        </PaymentSummary>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actsAsAShowPage from '@/mixins/actsAsAShowPage'

import PaymentCard from '@/components/payments/PaymentCard.vue'
import PaymentSummary from '@/components/payments/PaymentSummary.vue'
import SummaryCommandBanner from '@/components/common/SummaryCommandBanner.vue'
import PaymentCostCentersCard from '@/components/payments/PaymentCostCentersCard.vue'
import PaymentJobsCard from '@/components/payments/PaymentJobsCard.vue'

import Payment from '@/domain/entities/Payment'
import GetPayment from '@/domain/useCases/payments/GetPayment'

export default {
  name: 'ShowPaymentPage',
  mixins: [actsAsAShowPage],
  components: {
    PaymentCard,
    PaymentSummary,
    SummaryCommandBanner,
    PaymentCostCentersCard,
    PaymentJobsCard
  },
  props: { id: String },
  data() {
    return {
      actsAsAShowPage: {
        resource: 'payments',
        entity: 'payment',
        get: GetPayment
      },
      entity: Payment.default()
    }
  },
  computed: {
    invoiceable() {
      return this.entity.isInvoiceable()
    }
  },
  methods: {
    createInvoice() {
      if (this.entity.isValid())
        this.$router.push({
          name: 'NewPaymentInvoicePage',
          params: { ids: [this.entity.id] }
        })
    },
    createCustomerContract() {
      if (this.entity.isValid())
        this.$router.push({
          name: 'NewCustomerContractPage',
          params: { id: this.entity.id }
        })
    },
    gotoCostCenters() {
      this.$router.push(
        `/${this.option('resource')}/${this.id}/edit/cost-centers`
      )
    },
    gotoJobs() {
      this.$router.push(`/${this.option('resource')}/${this.id}/edit/jobs`)
    }
  },
  metaInfo() {
    return { title: this.$t(this.$route.meta.title) }
  }
}
</script>
