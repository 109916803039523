<template>
  <app-card class="mt-4 text-center product-data-menu">
    <v-card-text class="mt-0 pt-0 text-left">
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="headline">
            {{ entity.name }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="typeAndCategory">
            {{ typeAndCategory }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>

    <v-card-text class="pt-0 text-left text--secondary">
      {{ entity.description | truncate(100) }}
    </v-card-text>

    <slot />

    <v-card-text>
      <slot name="controls" />
    </v-card-text>
  </app-card>
</template>

<script>
import Product from '@/domain/entities/Product'

export default {
  name: 'productSummary',
  props: { entity: Product },
  computed: {
    typeAndCategory() {
      if (this.entity.category && this.entity.type)
        return `${this.category} ${this.type}`
      return this.category || this.type
    },
    category() {
      if (!this.entity.category) return ''
      // The translation could already be set if we are in the new/edit form.
      const category = this.entity.category
      const text =
        category.text || this.$t(`entities.product.categories.${category.code}`)
      return text
    },
    type() {
      // The translation could already be set if we are in the new/edit form.
      if (!this.entity.type) return ''
      const type = this.entity.type
      const text = type.text || this.$t(`entities.product.types.${type.code}`)
      return text
    }
  }
}
</script>

<style scoped>
.product-data-menu {
  position: sticky;
  top: 50px;
}
</style>
