import axios from '@/axios.js'

import ListOptionsFormatter from '../formatters/ListOptions'
import ProductFormatter from '../formatters/Product'
import exceptionToResponse from '../formatters/exceptionToResponse'

export default async function(token, listOptions) {
  try {
    const options = ListOptionsFormatter.toRequestOptions(
      listOptions,
      ProductFormatter
    )
    const { data } = await axios.get('/api/v1/products', {
      params: options,
      headers: { Authorization: `Bearer ${token}` }
    })

    const { results, totalItems, page } = data
    const products = ProductFormatter.fromResponseResourcesList(results)

    return { error: false, products, totalItems, page }
  } catch (e) {
    return exceptionToResponse(e)
  }
}
