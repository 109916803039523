import Base from './Base'
import CustomerContract from '@/domain/entities/CustomerContract'
import CustomerContractDetailFormatter from './CustomerContractDetail'
import ContractFormatter from './Contract'
import PersonAndCompanyFormatter from './PersonAndCompany'
import PurchaseFormatter from './Purchase'
import { isEmpty } from '@/lib/Utils'
import ContractReasonFormatter from './ContractReason'

export default class extends Base {
  static fromResponseResource(resource) {
    if (!resource) return null

    const entity = this.fieldNamesFromResponse(resource)

    entity.buyer = PersonAndCompanyFormatter.fromResponseResource(entity.buyer)

    entity.beneficiary = PersonAndCompanyFormatter.fromResponseResource(
      entity.beneficiary
    )

    entity.previousContract = ContractFormatter.fromResponseResource(
      entity.previousContract
    )

    entity.purchase = PurchaseFormatter.fromResponseResource(entity.purchase)

    entity.details = CustomerContractDetailFormatter.fromResponseResourcesList(
      entity.details
    )

    entity.contract = ContractFormatter.fromResponseResource(entity.contract)

    entity.endReason = ContractReasonFormatter.fromResponseResource(
      entity.endReason
    )

    return new CustomerContract(entity)
  }

  static toRequestResource(customerContract) {
    if (!customerContract) return null

    const resource = this.fieldNamesForRequest(customerContract)

    resource.custContractCounters = CustomerContractDetailFormatter.toRequestResourcesList(
      customerContract.details
    )
    return resource
  }

  static fieldNamesFromResponse(resource, removeEmpty = false) {
    const {
      id,
      custContractCounters,

      contract,
      contractNumber,
      version,
      previousContract,
      endReason,
      buyer,
      beneficiary,
      purchase,
      partnership,
      flagAutoRenewed,
      notes,
      cancellationDate,
      contractStartDate,
      contractEndDate
    } = resource

    const result = {
      id,
      details: custContractCounters,
      contract,
      code: contractNumber,
      version,
      previousContract,
      endReason,
      buyer,
      beneficiary,
      purchase,
      partnership,
      autoRenewal: flagAutoRenewed,
      cancellationDate,
      notes,
      validFrom: contractStartDate,
      validTo: contractEndDate
    }

    this.formatInternalValuesFromResponse(resource, result)

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static fieldNamesForRequest(customerContract, removeEmpty = false) {
    if (!customerContract) return null

    const {
      id,
      details,

      contract,
      code,
      version,
      previousContract,
      endReason,
      buyer,
      beneficiary,
      purchase,
      partnership,
      autoRenewal,
      cancellationDate,
      validFrom,
      validTo,
      notes
    } = customerContract

    const result = {
      id,
      custContractCounters: details,

      contract,
      contractNumber: code,
      version,
      previousContract,
      endReason,
      buyer,
      beneficiary,
      purchase,
      partnership,
      flagAutoRenewed: autoRenewal,
      cancellationDate,
      contractStartDate: validFrom,
      contractEndDate: validTo,
      notes: notes
    }

    return removeEmpty ? this.removeEmptyAttributes(result) : result
  }

  static formatFilterOptions(filter) {
    if (isEmpty(filter)) return null

    const result = {}

    if (!isEmpty(filter.contract))
      result.contract = this.toResourceIdObject(filter.contract)

    if (!isEmpty(filter.buyer))
      result.buyer = PersonAndCompanyFormatter.formatFilterOptions(filter.buyer)
    // TODO ? Person and company formattter creare override metodo formatFilterOPtions che chiama
    // formatFilterOptions sui sotto oggetti

    return this.removeEmptyAttributes(result)
  }
}
