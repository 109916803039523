import validateUser from '@/lib/validateUser'
import VatsListAdapter from '@/adapters/backoffice/purchases/getVats'

export default class {
  user = null

  constructor(user) {
    this.user = user
  }

  async call() {
    const userValidation = validateUser(this.user)
    if (!userValidation) return userValidation

    return await VatsListAdapter(this.user.token)
  }
}
